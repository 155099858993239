import colors from './colors'

export default {
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  center2: {
    justifyContent: 'center',
  },
  center3: {
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  divider: {
    height: 1, width: '100%',
    marginTop: 20, marginBottom: 10,
    backgroundColor: '#eaeaea'
  },
  footerBooking: {
    position: 'absolute',
    bottom: 0,
    height: 53,
    paddingTop: 15,
    zIndex: 20,
    width: '100vw',
    borderColor: '#EAEAEA',
    paddingLeft: 0,
    backgroundColor: 'white',
    alignItems: 'center',
  },
  cardSelect: {
    height: 50,
    marginTop: 10,
    borderWidth: 1,
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 5,
    shadowColor: '#46474B',
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 15,
    borderColor: '#eaeaea',
    shadowOpacity: 0.05,
  },
  cardSelectFlex: {
    flex: 1,
    borderWidth: 1,
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 5,
    shadowColor: '#46474B',
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 15,
    borderColor: '#eaeaea',
    shadowOpacity: 0.05,
  },
  cardSelectOn: {
    height: 50,
    marginTop: 10,
    borderWidth: 1,
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 5,
    shadowColor: '#46474B',
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 15,
    borderColor: colors.primary,
    shadowOpacity: 0,
  },
}