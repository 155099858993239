import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import { Fonts } from '../../../utils/Font'

import { initApp } from '../../../actions/initAppActions'
import { updateStepLogin } from '../../../actions/loginActions'
import * as firebase from 'firebase'
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      loader: 'initial'
    };
  }
  handleChange = name => event => {
    var val = event.target.value
    if (name === 'firstname') {
      this.props.updateStepLogin('setFirstname', { value: val })
    } else if (name === 'lastname') {
      this.props.updateStepLogin('setLastname', { value: val })
    } else if (name === 'email') {
      this.props.updateStepLogin('setPhone', { value: val })
    }
  }
  submit() {
    this.setState({ loader: true })
    var url = 'https://us-central1-instabo-production.cloudfunctions.net/updateUserInfo2'

    var phoneNumber = this.props.dataUser.trueVaultInfoUser.phoneNumber
    const promiseAxios = axios.get(url, {
      params: {
        userID: this.props.dataUser.userID,
        firstname: this.props.dataLogin.firstname,
        lastname: this.props.dataLogin.lastname,
        email: this.props.dataLogin.phone,
        phoneNumber: phoneNumber
      }
    })

    return Promise.all([promiseAxios]).then(results => {
      this.props.initApp('setTruvaultUserInfo', {
        value: {
          firstname: this.props.dataLogin.firstname,
          lastname: this.props.dataLogin.lastname,
          email: this.props.dataLogin.phone,
          phoneNumber: phoneNumber
        }
      })
      firebase.database().ref('users/' + this.props.dataUser.userID).update({
        'profileCompleted': true,
      })
      this.props.initApp('showLoginModal', { value: false });
    })
  }
  render() {
    return (
      <div >
        <Grid container alignItems="center" justify="center" style={{ marginTop: 20, paddingLeft: 15, paddingRight: 15 }}>
          <Grid md={12} xs={12} item>
            <TextField
              style={{ width: '100%', height: 53, fontFamily: Fonts.MarkOT }}
              id="outlined-password-input"
              label="First name"
              value={this.props.dataLogin.firstname}
              onChange={this.handleChange('firstname')}
              margin="normal"
              autoFocus={true}
              color="secondary"
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="center" style={{ marginTop: 10, paddingLeft: 15, paddingRight: 15 }}>
          <Grid md={12} xs={12} item>
            <TextField
              style={{ width: '100%', height: 53, fontFamily: Fonts.MarkOT }}
              id="outlined-password-input"
              label="Last name"
              value={this.props.dataLogin.lastname}
              onChange={this.handleChange('lastname')}
              margin="normal"
              color="secondary"
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="center" style={{ marginTop: 10, paddingLeft: 15, paddingRight: 15 }}>
          <Grid md={12} xs={12} item>
            <TextField
              style={{ width: '100%', height: 53, fontFamily: Fonts.MarkOT }}
              id="outlined-password-input"
              label="Email address"
              type="email"
              value={this.props.dataLogin.phone}
              onChange={this.handleChange('email')}
              autoComplete="email"
              margin="normal"
              color="secondary"
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center" style={{ marginTop: 10, paddingLeft: 15, paddingRight: 15 }}>
          <Grid md={12} xs={12} item>
            <div style={styles.textCheckbox}>
              By clicking confirm I accept the <a href="https://wlnss.co/terms-service" target="_blank" rel="noopener noreferrer" style={{ color: '#699580', textDecorationLine: 'underline' }}>Terms of Service</a> and the <a href="https://wlnss.co/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: '#699580', textDecorationLine: 'underline' }}>Privacy Policy</a> of Wlnss.
            </div>
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center" style={{ marginTop: 20, paddingLeft: 15, paddingRight: 15 }}>
          <Grid md={12} xs={12} item>
            {
              this.props.dataLogin.lastname === '' || this.props.dataLogin.fistname === '' || this.props.dataLogin.phone === '' ?
                <Button style={{ height: 53, color: '#699580', fontFamily: Fonts.MarkOTBold, width: '100%', fontSize: 14 }} variant="outlined" color="primary">
                  Confirm
                </Button>
                : this.state.loader ?
                  <Button style={{ height: 53, color: '#699580', fontFamily: Fonts.MarkOTBold, width: '100%', fontSize: 14 }} variant="contained" color="primary">
                    <CircularProgress style={{ color: 'white' }} size={24} opacity={50} />
                  </Button>
                  :
                  <Button style={{ height: 53, color: '#699580', fontFamily: Fonts.MarkOTBold, width: '100%', fontSize: 14 }} variant="contained" color="primary" onClick={() => { this.submit() }}>
                    Confirm
                  </Button>
            }
          </Grid>
        </Grid>
      </div>
    );
  }
}
const styles = {
  textCheckbox: {
    textAlign: 'justify',
    color: '#4A4A4A',
    fontSize: 13,
    fontFamily: Fonts.MarkOTMedium,
  },
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataLogin: state.login,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { initApp, updateStepLogin })(SignIn);
