import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios'
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { initApp } from '../../actions/initAppActions'
import * as firebase from 'firebase'
import ListCB from './elementsPayments/ListCB';
import NewMethod from './elementsPayments/NewMethod';
import NewCreditCard from './elementsPayments/NewCreditCard';
import DetailCreditCard from './elementsPayments/DetailCreditCard';
import EditCreditCard from './elementsPayments/EditCreditCard';
import ApplePay from './elementsPayments/ApplePay';
import { Fonts } from '../../utils/Font'
import ScrollViewPage from '../layout/scrollviews/ScrollViewPage'
class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'list',
      editable: false,
      loader: false,
      loader2: false,
      infoCard: {},
      cardSelected: {},
      showPopupDelete: false,
      showPopupCannotDelete: false,
    };
  }
  async componentWillMount() {
    if (this.props.straightToCard) {
      await this.setState({ cardSelected: this.props.dataUser.infoUser.wallet.cards[this.props.dataUser.defaultCB.id] })
      this.setState({ step: 'edit' })
    }
  }
  back() {
    if (this.state.step === 'list') {
      this.props.close(false)
    } else if (this.state.step === 'new' || this.state.step === 'detailCard') {
      this.setState({ step: 'list' })
    } else if (this.state.step === 'cb') {
      this.setState({ step: 'new' })
    } else if (this.state.step === 'edit') {
      this.setState({ step: 'detailCard' })
    } else if (this.state.step === 'applePay') {
      this.setState({ step: 'new' })
    }
  }
  addCreditCard() {
    this.setState({ showAddCreditCard: true });
  }
  closeAddCreditCard() {
    this.setState({ showAddCreditCard: false });
  }
  openModifyCreditCard(card) {
    this.setState({ editedCard: card })
    this.setState({ showModifyCB: true });
  }
  closeModifyCreditCard() {
    this.setState({ showModifyCB: false });
  }
  closePayments() {
    this.props.close('payments')
  }
  changeStep(val) {
    this.setState({ step: val })
  }
  async selectDefaultCard(cardSelected) {
    this.setState({ step: 'list' })
    this.setState({ loader2: true })
    if (cardSelected.id === this.props.dataUser.defaultCB.id) {
      this.setState({ loader2: false })
    } else {
      await firebase.database().ref('users/' + this.props.dataUser.userID + '/wallet/defaultCard/').update(cardSelected)
      this.setState({ loader2: false })
    }
  }
  async cardScanned(card) {
    this.setState({ step: 'cb' })
    this.addCBRef.autoFillCB(card)
  }
  async deleteCard(card) {
    var check = true
    // check if card can be deleted
    if (!!this.props.dataUser.infoUser.appointments) {
      var filterActiveAppt = Object.values(this.props.dataUser.infoUser.appointments).filter(appt => appt.status !== 'past')
      if (filterActiveAppt.length !== 0) {
        check = false
      }
    }
    if (check) {
      await this.setState({ cardSelected: card })
      this.setState({ showPopupDelete: true })
    } else {
      this.setState({ showPopupCannotDelete: true })
    }
  }
  async confirmDeleteCard(card) {
    this.setState({ loader: true })
    var url = 'https://us-central1-instabo-production.cloudfunctions.net/deleteUserCreditCard'
    const results = await axios.get(url, {
      params: {
        CardID: this.state.cardSelected.id,
        userID: this.props.dataUser.userID,
        tokenStripeCus: this.props.dataUser.infoUser.wallet.tokenCusStripe,
      }
    })
    if (results.data.response) {
      if (card.id === this.props.dataUser.defaultCB.id && this.props.dataUser.numberOfCB > 0) {
        await firebase.database().ref('users/' + this.props.dataUser.userID + '/wallet/defaultCard/').update(Object.values(this.props.dataUser.infoUser.wallet.cards)[0])
      } else if (card.id === this.props.dataUser.defaultCB.id) {
        await firebase.database().ref('users/' + this.props.dataUser.userID + '/wallet/defaultCard/').remove()
      }
      this.setState({ loader: false })
      this.setState({ showPopupDelete: false })
    } else {
      this.setState({ loader: false })
      this.setState({ showPopupDelete: false })
    }
  }
  setLoader(val) {
    this.setState({ loader2: val })
  }
  async clickOnCard(card) {
    this.setState({ cardSelected: card })
    this.setState({ step: 'detailCard' })
  }
  async openEditCreditCard() {
    this.setState({ step: 'edit' })
  }
  reload(zipCode) {
    var cardSelected = this.state.cardSelected
    cardSelected.zipCode = zipCode
    this.setState({ cardSelected: cardSelected })
  }
  bodyContent() {
    return (
      <div style={{ marginTop: -40 }}>
        <List style={{ marginTop: this.props.journeyShow ? 145 : 80 }}>
          {
            this.state.step === 'list' ?
              <ListCB
                clickOnCard={this.clickOnCard.bind(this)}
                selectDefaultCard={this.selectDefaultCard.bind(this)}
                deleteCard={this.deleteCard.bind(this)}
                changeStep={this.changeStep.bind(this)}
              />
              : this.state.step === 'new' ?
                <NewMethod changeStep={this.changeStep.bind(this)} />
                : this.state.step === 'cb' ?
                  <NewCreditCard changeStep={this.changeStep.bind(this)} close={this.closePayments.bind(this)} />
                  : this.state.step === 'detailCard' ?
                    <DetailCreditCard back={this.back.bind(this)} changeStep={this.changeStep.bind(this)} cardSelected={this.state.cardSelected} openEditCreditCard={this.openEditCreditCard.bind(this)} selectDefaultCard={this.selectDefaultCard.bind(this)} />
                    : this.state.step === 'edit' ?
                      <EditCreditCard close={this.closePayments.bind(this)} reload={this.reload.bind(this)} cardSelected={this.state.cardSelected} back={this.back.bind(this)} changeStep={this.changeStep.bind(this)} />
                      : this.state.step === 'applePay' ?
                        <ApplePay changeStep={this.changeStep.bind(this)} />
                        : null
          }
        </List>
      </div>
    )
  }
  render() {
    return (
      <div className="App" >
        <AppBar className={(this.props.journeyShow ? 'marginMenu' : '')}>
          <Toolbar style={{ backgroundColor: 'white', height: 60 }}>
            <IconButton color="inherit" onClick={() => this.back()} aria-label="Close">
              <Icon style={{ color: 'black' }}>arrow_back</Icon>
            </IconButton>

            {
              this.state.step === 'list' ?
                <Typography variant="h9" color="secondary" style={styles.textHeader}>Payment</Typography>
                : this.state.step === 'detailCard' && this.state.cardSelected.brand === 'applePay' ?
                  <Typography variant="h9" color="secondary" style={styles.textHeader}>Apple pay</Typography>
                  : this.state.step === 'detailCard' && this.state.cardSelected.brand === 'googlePay' ?
                    <Typography variant="h9" color="secondary" style={styles.textHeader}>Google pay</Typography>
                    : this.state.step === 'detailCard' ?
                      <Typography variant="h9" color="secondary" style={styles.textHeader}>{this.state.cardSelected.brand}</Typography>
                      : this.state.step === 'new' ?
                        <Typography variant="h9" color="secondary" style={styles.textHeader}>Payment method</Typography>
                        : this.state.step === 'cb' ?
                          <Typography variant="h9" color="secondary" style={styles.textHeader}>Credit/Debit card</Typography>
                          : this.state.step === 'applePay' ?
                            <Typography variant="h9" color="secondary" style={styles.textHeader}>Apple Pay</Typography>
                            : this.state.step === 'edit' ?
                              <Typography variant="h9" color="secondary" style={styles.textHeader}>Edit Card</Typography>
                              : null
            }
          </Toolbar>
        </AppBar>
        <ScrollViewPage
          content={this.bodyContent.bind(this)}
          loader={false}
          bookingFlow={true}
          header={false}
          styleTitle={{ fontSize: 27, fontFamily: Fonts.MarkOTBold }}
        />
      </div>
    );
  }
}
const styles = {
  textHeader: {
    color: 'black', fontFamily: Fonts.MarkOTMedium
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { initApp })(Payments);
