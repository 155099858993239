import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ReactPixel from 'react-facebook-pixel';
import ListBenefits from './ListBenefits'
import { Fonts } from '../../utils/Font';
import { withRouter } from 'react-router-dom'
import { CSSTransition } from "react-transition-group";
import { bookingFlowAction } from '../../actions/bookingFlowActions'
import colors from './../layout/colors'
import mixpanel from 'mixpanel-browser'
const mixPanelToken = 'bef3efb4cd721628459b35e7a4c1abb6'

mixpanel.init(mixPanelToken);
class CardCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }
  componentDidMount() {
    this.setState({ show: true })
  }
  clickCategory(category) {
    if (!this.props.productCard) {
      if (category.straightToProduct) {
        mixpanel.track('Click on product', { 'userID': this.props.dataUser.userID, 'product': this.props.product.name });
        ReactPixel.track('Click on product', { 'userID': this.props.dataUser.userID, 'product': this.props.product.name });
        this.props.bookingFlowAction('setCurrentPage', { value: 'Product' })
        this.props.history.push('/book/' + this.props.imgName + '/' + this.props.imgName)
      } else {
        mixpanel.track('Click on category', { 'userID': this.props.dataUser.userID, 'product': this.props.product.name });
        ReactPixel.track('Click on category', { 'userID': this.props.dataUser.userID, 'product': this.props.product.name });
        this.props.history.push('/category/' + this.props.imgName)
      }
    } else {
      mixpanel.track('Click on product', { 'userID': this.props.dataUser.userID, 'product': this.props.product.name });
      ReactPixel.track('Click on product', { 'userID': this.props.dataUser.userID, 'product': this.props.product.name });
      this.props.bookingFlowAction('setCurrentPage', { value: 'Product' })
      this.props.history.push('/book/' + category.type + '/' + this.props.imgName)
    }
  }
  render() {
    return (
      <CSSTransition className="cardProduct pointer" onClick={() => { this.clickCategory(this.props.category) }} timeout={0}>
        <div>
          {
            !this.props.productCard ?
              <img src={this.props.product.imageMedium} className="cardProductImage" alt={this.props.product.name} />
              :
              <img src={this.props.product.imageMedium} className="cardProductImage" alt={this.props.product.name} />
          }
          <Grid container direction="column">
            <Grid container direction="row">
              <Grid xs={9} item>
                <div className="containerTextCard">
                  <div className="titleCard" style={{ color: '#4a4a4a', fontFamily: Fonts.MarkOTBold, marginLeft: 0, fontSize: 18 }}>{this.props.product.name}</div>
                </div>
                <div className="subtitleCard" style={{ color: '#757575', fontFamily: Fonts.MarkOT, marginLeft: 0, fontSize: 12, marginTop: 4 }}>{this.props.product.description}</div>
              </Grid>

              {this.props.productCard ?
                <Grid xs={3} container alignItems="center" justify="flex-end">
                  <Grid item style={styles.textPrice}>From ${this.props.product.pricesGroup.filter(price => price.type === 'greater')[0].price}</Grid>
                </Grid>
                :
                <Grid xs={3} container alignItems="center" justify="flex-end">
                  <Grid item style={styles.textPrice}>${this.props.product.priceRange.from} - ${this.props.product.priceRange.to}</Grid>
                </Grid>
              }
            </Grid>

            {
              !this.props.productCard ?
                <ListBenefits benefits={this.props.product.benefitsCard} />
                :
                <ListBenefits benefits={this.props.product.benefits} />
            }
          </Grid>
        </div>
      </CSSTransition>
    );
  }
}
const styles = {
  boxPrice: {
    width: 70,
    height: 30,
    borderRadius: 15,
    position: 'absolute',
    bottom: 20,
    right: 15,
    backgroundColor: '#FAF9F9',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#EAEAEA',
  },
  textPrice: {
    color: colors.primary,
    fontSize: 14,
    fontFamily: Fonts.MarkOTBlack,
    marginRight: 5
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction })(withRouter(CardCategory));
