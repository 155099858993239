import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ReactPixel from 'react-facebook-pixel';
import '../../../css/Home.css';
import HeaderBooking from './HeaderBooking'
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'
import { Fonts } from '../../../utils/Font';
import ScrollViewPage from '../../layout/scrollviews/ScrollViewPage'
import colors from '../../layout/colors'
import CardSelection from './elementsProviderPreference/CardSelection'
import firebase from 'firebase'
import DateSelector from './elementsProviderPreference/DateSelector'
import ButtonBooking from '../../layout/buttons/ButtonBooking'
import Dialog from '@material-ui/core/Dialog';
import Grow from '@material-ui/core/Grow';
import axios from 'axios'
import moment from 'moment'
import ProviderList from './elementsProviderPreference/ListProviders'
import ProviderPage from '../../elementsHome/elementsProviders/ProviderPage'
import momentTZ from 'moment-timezone'
import mixpanel from 'mixpanel-browser'

function Transition(props) {
  return <Grow {...props} timeout={300} />;
}

class ProviderPreference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      containerWidth: 0,
      small: false,
      providersPreference: {
        type: '',
      },
      rebookFilterDone: 'done',
      options: {
        0: {
          text: 'Pick a new provider',
          subtitle: 'Book one of our platinum provider partners. They may not have immediate availability for urgent requests.',
          type: 'pick',
          expanded: false
        },
        1: {
          text: "Express book",
          subtitle: 'Book with any of our provider partners for quickest delivery (as little as 60 minutes).',
          type: 'match',
          expanded: false
        }
      },
      genderPreferenceOptions: {
        0: {
          text: 'No preference',
          selected: true
        },
        1: {
          text: 'Male',
          selected: false
        },
        2: {
          text: 'Female',
          selected: false
        },
        expanded: false
      },
      genderPreference: 'anyone',
      dateSelected: undefined,
      timeSelected: undefined,
      providerList: undefined,
      showProviderPage: false
    };
  }
  async componentWillMount() {
    window.scrollTo(0, 0)
    this.checkRebook()
    this.updateDimensions();
    this.setState({ dateSelected: moment(new Date()) })
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }
  updateDimensions() {
    const containerWidth = window.innerWidth;
    this.setState({ containerWidth: containerWidth, small: (containerWidth < 947) });
  }
  back() {
    this.props.bookingFlowAction('setCurrentPage', { value: 'Location' })
  }
  doneTime() {
    this.timePicker.doneTime()
  }
  async checkRebook() {
    var rebookFilterDone = false
    if (!this.props.dataUser.userConnected) {
      this.setState({ rebookFilterDone: rebookFilterDone })
    } else if (this.state.rebookFilterDone === 'done') {
      var appts = await firebase.database().ref('users/' + this.props.userID + '/appointments/').once('value')
      appts = appts.val() || {}
      rebookFilterDone = Object.values(appts).filter(appt => (appt.status === 'past' && appt.infoOffer.product.type === this.props.productChosen.type)).length !== 0
    } else {
      rebookFilterDone = this.state.rebookFilterDone
    }
    this.setState({ rebookFilterDone: rebookFilterDone })
    return rebookFilterDone
  }
  genderPreference() {
    return (
      <Grid
        container
        direction="column"
        className="borderOff"
        style={{ ...styles.expansionBody, borderRadius: 4, borderColor: '#cccccc', textAlign: 'left', marginTop: 10 }}
      >
        <Grid
          container
          direction="column" style={{}} >
          {Object.values(this.state.genderPreferenceOptions).map((option, i) => (

            (option.selected) ?
              <Grid item
                className="pointer"
                onClick={() => {
                  this.setState({
                    genderPreferenceOptions: { ...this.state.genderPreferenceOptions, expanded: !this.state.genderPreferenceOptions.expanded }
                  })
                }}>
                <Grid style={{ marginTop: 10, marginBottom: (i === Object.keys(this.state.genderPreferenceOptions).length - 2 || !this.state.genderPreferenceOptions.expanded) ? -3 : 10, marginLeft: 10, color: (option.selected && this.state.genderPreferenceOptions.expanded) ? colors.primary : colors.subtitle }}>
                  {option.text}
                </Grid>
              </Grid>
              : (this.state.genderPreferenceOptions.expanded && !!option.text) ?
                <Grid item
                  className="pointer"
                  onClick={() => {
                    var genPref = this.state.genderPreferenceOptions
                    for (var op in genPref) {
                      if (!!genPref[op].selected) {
                        if (op === i.toString()) {
                          genPref[op].selected = true
                          this.setState({ genderPreference: (op === 0) ? 'anyone' : (op === 1) ? 'male' : (op === 2) ? 'female' : null })
                        }
                        else genPref[op].selected = false
                      }
                    }
                    genPref.expanded = false
                    this.setState({
                      genderPreferenceOptions: genPref,
                    })
                  }}>
                  <Grid style={{ marginTop: 10, marginBottom: (i === Object.keys(this.state.genderPreferenceOptions).length - 2 || !this.state.genderPreferenceOptions.expanded) ? -3 : 10, marginLeft: 10, color: (option.selected) ? colors.primary : colors.subtitle }}>
                    {option.text}
                  </Grid>
                </Grid>
                : null
          ))}
        </Grid>
      </Grid>
    )
  }
  datePicker() {
    return (
      <Grid
        container
        direction="column"
        className="borderOff"
        style={{ ...styles.expansionBody, fontFamily: Fonts.MarkOTMedium, borderColor: '#cccccc00', textAlign: 'left', marginTop: 0, fontSize: 15 }}
      >
        <DateSelector date onPickDate={(date) => { this.datePicked(date) }} />
      </Grid>
    )
  }
  dateAndTimePicker() {
    return (
      <Grid
        container
        direction="column"
        className="borderOff"
        style={{ ...styles.expansionBody, fontFamily: Fonts.MarkOTMedium, borderColor: '#cccccc00', textAlign: 'left', marginTop: 0, fontSize: 15 }}
      >
        <DateSelector
          date
          time
          timeZone={this.props.dataHome.timeZone.timeZoneId}
          onPickDate={(date) => { this.datePicked(date) }}
          onPickTime={(time) => { this.timePicked(time) }} />
      </Grid>
    )
  }
  datePicked(date) {
    this.setState({ dateSelected: date })
  }
  timePicked(time) {
    this.setState({ timeSelected: time })
  }
  rebookExpansion() {
    return (
      <div>
        rebookBoy
      </div>
    )
  }
  pickExpansion() {
    return (
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" style={{ paddingLeft: 20, paddingRight: 20, width: '100%' }}>
        <Grid item style={{ ...styles.expansionTitle, marginTop: 0 }}>Gender preference</Grid>
        {this.genderPreference()}
        <Grid item style={{ ...styles.expansionTitle, marginTop: 15 }}>Booking date</Grid>
        {this.datePicker()}
        <Grid item style={{ width: '100%', marginTop: 20 }}>
          <ButtonBooking text="Search" click={() => { this.newProviderSearch() }} loading={this.state.loading} />
        </Grid>
      </Grid>
    )
  }
  async newProviderSearch() {
    this.setState({ loading: true })
    if (!this.state.rebookFilterDone) {
      var url = 'https://us-central1-instabo-production.cloudfunctions.net/getProvidersAvailableInRadius'
      var results = await (axios.get(url, {
        params: {
          typeProduct: this.props.dataHome.productChosen.type,
          latOffer: this.props.dataLocation.region.latitude,
          lngOffer: this.props.dataLocation.region.longitude,
          dateAppt: this.props.dataHome.dateAppt,
          genderFilter: this.state.genderPreference
        }
      }))
      await this.setState({ providerList: results.data.nursesAvailable, loading: false, providerPickerDialog: true })
      await this.setState({ providersPreference: { type: 'pick', genderFilter: this.state.genderPreference, rebookFilter: false } })
      this.props.homeAction('setProvidersPreference', { value: this.state.providersPreference })
    }
  }
  matchExpansion() {
    return (
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" style={{ paddingLeft: 20, paddingRight: 20, width: '100%' }}>
        <Grid item style={{ ...styles.expansionTitle, marginTop: 0 }}>Gender preference</Grid>
        {this.genderPreference()}
        <Grid item style={{ ...styles.expansionTitle, marginTop: 15 }}>Booking date & time</Grid>
        {this.dateAndTimePicker()}
        <Grid item style={{ width: '100%', marginTop: 20 }}>
          <ButtonBooking text="Confirm" click={() => { this.matchProvider() }} loading={this.state.loading} />
        </Grid>
      </Grid>
    )
  }
  matchProvider() {
    this.setState({ providersPreference: { type: 'match' } })
    this.props.homeAction('setProvidersPreference', { value: { type: 'match', genderFilter: this.state.genderPreference } })
    const time = moment(this.state.timeSelected)
    const date = moment(this.state.dateSelected).startOf('day').add(time.hour(), 'hours').add(time.minute(), 'minutes')
    this.props.homeAction('setDateAppt', { value: date.format() })
    this.props.homeAction('setDateSlotAppt', { value: { dateNew: date.format(), date: date.format() } })
    mixpanel.track('Click on next to person', { 'userID': this.props.dataUser.userID });
    ReactPixel.track('Click on next to person', { 'userID': this.props.dataUser.userID });
    this.props.bookingFlowAction('setCurrentPage', { value: 'Person' })
  }
  onExpand(i) {
    var opt = this.state.options
    for (var option in opt) {
      if (option === i) opt[i].expanded = !opt[i].expanded
      else opt[option].expanded = false
    }
    this.setState({ options: opt })
  }
  selectProvider(provider) {
    this.setState({ showProviderPage: true, providerPage: provider })
  }
  async setSlotAppt(availability, nurseID) {
    this.setState({ providerPickerDialog: false, showProviderPage: false })
    var date = (moment(availability.date.split(' GMT')[0]).format('ddd, MMM Do')).toString()
    var time = (moment(availability.date.split(' GMT')[0]).format('h:mm a')).toString()
    var totalDate = date + '_' + time

    var dateDisplay = (totalDate.split('_'))[0] + ' at ' + (totalDate.split('_'))[1]
    var dateModif = dateDisplay.split(' at ')
    var matTime = dateModif[1].split(' ')
    var minute = Number((matTime[0].split(':'))[1])
    var hour = Number((matTime[0].split(':'))[0])
    var period = matTime[1]
    if (period === 'pm' && hour !== 12) {
      hour = hour + 12
    }

    var offDate = momentTZ.tz(date, 'ddd, MMM Do', this.props.dataHome.timeZone.timeZoneId).add(hour, 'h').add(minute, 'm').format()
    this.props.homeAction('setProvidersPreference', { value: { ...this.props.dataHome.providersPreference, dateNew: offDate, availability: availability, date: availability.date, nurseID: nurseID } })
    await this.props.homeAction('setDateSlotAppt', { value: { dateNew: offDate, nurseID: nurseID, availability: availability, date: availability.date } })
    mixpanel.track('Click on next to person', { 'userID': this.props.dataUser.userID });
    ReactPixel.track('Click on next to person', { 'userID': this.props.dataUser.userID });
    this.props.bookingFlowAction('setCurrentPage', { value: 'Person' })
  }
  bodyContent() {
    return (
      <div style={{ marginTop: -40, paddingLeft: 15, paddingRight: 15, }}>
        <Grid container style={styles.subtitle} onClick={() => { console.log(this.props) }}>
          Please select your provider preference for this booking.
        </Grid>
        {Object.values(this.state.options).map((option, i) => (
          <CardSelection
            text={option.text}
            subtext={option.subtitle}
            expanded={option.expanded}
            onExpand={() => { this.onExpand(i) }}
            expansion={() => {
              if (i === 0) return this.pickExpansion()
              else if (i === 1) return this.matchExpansion()
              else return this.rebookExpansion()
            }}
          />
        ))}
        <Dialog
          maxWidth="lg"
          open={this.state.providerPickerDialog && !this.state.small}
          TransitionComponent={Transition}
          onBackdropClick={() => this.setState({ providerPickerDialog: false, showProviderPage: false })}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}>
          <Grid container direction="row" alignItems="flex-start" justify="center" style={{ minHeight: 400, maxHeight: 800, overflowY: 'hidden' }}>
            <Grid item style={{ backgroundColor: 'white', height: '100%', minHeight: 400, maxHeight: 800, borderRadius: 4, width: (this.state.small || !this.state.showProviderPage) ? '100%' : 500, minWidth: 300, maxWidth: null, overflowY: 'hidden' }}>
              <ProviderList
                provPage={this.state.showProviderPage}
                selectProvider={(provider) => {
                  this.setState({ showProviderPage: true, providerPage: provider })
                }}
                date={this.state.dateSelected}
                list={this.state.providerList}
                setSlotAppt={this.setSlotAppt.bind(this)}
                close={() => (this.setState({ providerPickerDialog: false, showProviderPage: false }))} />
            </Grid>
            <Grid item style={{ maxHeight: (this.state.small || !this.state.showProviderPage) ? 0 : '100%', marginLeft: 50, backgroundColor: 'white', height: '100%', borderRadius: 4, width: 300, overflow: 'hidden' }}>
              <ProviderPage hidden={(this.state.small || !this.state.showProviderPage)} provider={this.state.providerPage} specialtiesEnabled={() => true} close={() => { this.setState({ showProviderPage: false }) }} />
            </Grid>
          </Grid>
        </Dialog>
        <Dialog
          fullScreen
          open={this.state.providerPickerDialog && this.state.small}
          TransitionComponent={Transition}
        >
          {(!this.state.showProviderPage) ?
            <ProviderList
              journeyShow={this.props.journeyShow}
              small={this.state.small}
              selectProvider={(provider) => { this.setState({ showProviderPage: true, providerPage: provider }) }}
              date={this.state.dateSelected}
              list={this.state.providerList}
              setSlotAppt={this.setSlotAppt.bind(this)}
              close={() => (this.setState({ providerPickerDialog: false, showProviderPage: false }))} />
            :
            <ProviderPage
              hidden={(!this.state.showProviderPage)}
              provider={this.state.providerPage}
              specialtiesEnabled={() => true}
              close={() => { this.setState({ showProviderPage: false }) }} />
          }
        </Dialog>
      </div>
    )
  }
  render() {
    return (
      <div className="App">
        <HeaderBooking title={'Who will deliver the service?'} back={this.back.bind(this)} loader={false} journeyShow={this.props.journeyShow} />
        <ScrollViewPage
          content={this.bodyContent.bind(this)}
          loader={false}
          bookingFlow={true}
          header={false}
          styleTitle={{ fontSize: 27, fontFamily: Fonts.MarkOTBold }}
        />
      </div>
    );
  }
}
const styles = {
  incrementBtn: {
    color: colors.primary,
    fontSize: 23,
    fontFamily: Fonts.MarkOTBold,
  },
  buttonBack: {
    position: 'absolute',
    top: 75,
    left: 15,
    zIndex: 20,
  },
  title: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 23,
    paddingLeft: 15,
    color: '#4a4a4a',
    marginTop: 10,
  },
  subText: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 16,
    color: '#4a4a4a',
    textAlign: 'center'
  },
  subTextOff: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 16,
    fontWeight: 'normal',
    color: '#CACACF',
  },
  subtitle: {
    fontFamily: Fonts.MarkOT,
    fontSize: 16,
    color: colors.subtitle,
    fontWeight: 'normal',
    marginTop: 15,
    marginBottom: 15,
    textAlign: 'left',
  },
  subtitle2: {
    fontFamily: Fonts.MarkOT,
    fontSize: 16,
    fontWeight: 'normal',
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 25,
    marginBottom: 5,
    color: colors.subtitle,
    textAlign: 'left',
  },
  expansionTitle: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 14,
    color: colors.title,
    textAlign: 'left',
  },
  expansionBody: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 13,
    width: '100%',
    color: colors.title,
    textAlign: 'left',
    paddingBottom: 15,
  },
  secondRes: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  mainRes: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 17,
    fontWeight: 'bold',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  rowResult: {
    flex: 1, borderBottomWidth: 1, borderColor: '#EAEAEA', paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15
  },
  textIngredient: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
    paddingLeft: 15,
  },
  textBenefit: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    marginTop: 10,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    paddingLeft: 15,
    height: 30,
  },
  titleBenefit: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 19,
    fontWeight: 'Bold',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
  },
  row: {
    textAlign: 'left',
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    color: '#4a4a4a',
    paddingTop: 5
  },
  boxPrice: {
    width: 70,
    height: 25,
    borderRadius: 15,
    bottom: 20,
    right: 15,
    backgroundColor: '#FAF9F9',
    alignItems: 'center',
    fontFamily: Fonts.MarkOT,
    justifyContent: 'center',
    borderColor: '#EAEAEA',
    color: '#8CB5A1',
    paddingTop: 9,
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(ProviderPreference);
