import React, { Component } from 'react';
import { connect } from 'react-redux';
import { initApp } from '../../actions/initAppActions'
import { updateStepLogin } from '../../actions/loginActions'
import ListProfile from './elementsProfilePage/ListProfile';
import TabProfile from './elementsProfilePage/TabProfile';
import Divider from '@material-ui/core/Divider'

class SignIn extends Component {
  componentWillMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="App" >
        <TabProfile />
        <Divider style={{ width: '96%', marginLeft: '3%' }} />
        <ListProfile {...this.props} journeyShow={this.props.journeyShow} />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataLogin: state.login,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { initApp, updateStepLogin })(SignIn);
