

import {
  OPEN_ALL_CLINICS,
  SET_REGION_MAP,
  OPEN_LIST_CLINIC,
  OPEN_SEARCH,
  OPEN_FILTER,
  OPEN_INFO_CLINIC,
  SET_COUNTER_SELECT,
  SET_CLINICS_REQUEST,
  SET_INSTANT_ONLY,
  SET_SEARCH_PROVIDER,
  SET_USER_LOCATION,
  SET_PINS_MAP,
  RESET_DATA_LOCATION,
  SET_AVAILABILITIES_CALENDAR,
  OPEN_LIST_CLINIC2,
  SET_SEARCH_REGION,
  LOCATION_ENTERED
} from './types';
import moment from 'moment'

const setAllClinics = (AllClinics) => ({
  type: OPEN_ALL_CLINICS,
  AllClinics: AllClinics,
});

const setListVisible = (value) => ({
  type: OPEN_LIST_CLINIC,
  showList: value
});

const setListVisible2 = (value) => ({
  type: OPEN_LIST_CLINIC2,
  showList2: value
});

const openSearch = (value) => ({
  type: OPEN_SEARCH,
  showSearch: value
});

const openFilter = (value) => ({
  type: OPEN_FILTER,
  showFilter: value
});

const openInfoClinic = (value, data) => ({
  type: OPEN_INFO_CLINIC,
  showInfoClinic: value,
  dataCurrentClinic: data
});

const setCounterSelect = (value) => ({
  type: SET_COUNTER_SELECT,
  counterSelect: value,
});

const setRegionMap = (region, locationAllowCar) => ({
  type: SET_REGION_MAP,
  region: region,
  locationAllowCar: locationAllowCar
});

const setInstantOnly = (value) => ({
  type: SET_INSTANT_ONLY,
  instantOnly: value,
});

const setSearchProvider = (value) => ({
  type: SET_SEARCH_PROVIDER,
  searchProvider: value,
});

const setClinicsRequest = (value) => ({
  type: SET_CLINICS_REQUEST,
  clinicsRequestJSON: value,
});

const setUserLocation = (value) => ({
  type: SET_USER_LOCATION,
  showUserLocation: value,
});

const setPinsMap = (value) => ({
  type: SET_PINS_MAP,
  pinsMap: value,
});

const setAvailabilitiesCalendar = (value) => ({
  type: SET_AVAILABILITIES_CALENDAR,
  availabilitiesCalendar: value,
});

const setSearchRegion = (value) => ({
  type: SET_SEARCH_REGION,
  searchRegion: value,
});

const locationEntered = (value) => ({
  type: LOCATION_ENTERED,
  locationEntered: value
});

const resetLocationData = () => ({
  type: RESET_DATA_LOCATION,
});

export const locationAction = (val, data) => {
  return async function (dispatch) {
    if (val === 'setAllClinics') {
      dispatch(setAllClinics(data.AllClinics))
    }
    else if (val === 'setRegionMap') {
      dispatch(setRegionMap(data.region))
    }
    else if (val === 'setListVisible') {
      dispatch(setListVisible(data.value))
    }
    else if (val === 'setListVisible2') {
      dispatch(setListVisible2(data.value))
    }
    else if (val === 'openSearch') {
      dispatch(openSearch(data.value))
    }
    else if (val === 'openFilter') {
      dispatch(openFilter(data.value))
    }
    else if (val === 'openInfoClinic') {
      dispatch(openInfoClinic(data.value, data.data))
    }
    else if (val === 'setCounterSelect') {
      dispatch(setCounterSelect(data))
    }
    else if (val === 'setInstantOnly') {
      dispatch(setInstantOnly(data.value))
    }
    else if (val === 'setClinicsRequest') {
      dispatch(setClinicsRequest(data.value))
    }
    else if (val === 'setUserLocation') {
      dispatch(setUserLocation(data.value))
    }
    else if (val === 'setPinsMap') {
      dispatch(setPinsMap(data))
    }
    else if (val === 'locationEntered') {
      dispatch(locationEntered(data.value))
    }
    else if (val === 'setAvailabilitiesCalendar2') {
      dispatch(setAvailabilitiesCalendar(data.value))
    }
    else if (val === 'setRegionMap') {
      dispatch(setRegionMap(data.region, data.locationAllowCar))
    }
    else if (val === 'setAvailabilitiesCalendar') {
      var clinics = data.clinics
      var today = moment(data.date).format('YYYY-MM-DD')
      var availabilitiesObject = {}
      availabilitiesObject = {
        ...availabilitiesObject,
        [today]: {
          selected: true
        }
      }
      for (var i in clinics) {
        var clinic = clinics[i]
        if (clinic.futureAvailabilities.length !== 0) {
          var availabilities = clinic.futureAvailabilities
          for (var j in availabilities) {
            var dateFormat = moment(availabilities[j].date).format('YYYY-MM-DD')
            if (availabilitiesObject[dateFormat] === undefined) {
              availabilitiesObject = {
                ...availabilitiesObject,
                [dateFormat]: {
                  marked: true
                }
              }
            } else {
              if (availabilitiesObject[dateFormat].selected === true) {
                availabilitiesObject = {
                  ...availabilitiesObject,
                  [dateFormat]: {
                    selected: true,
                    marked: true
                  }
                }
              } else {
                availabilitiesObject = {
                  ...availabilitiesObject,
                  [dateFormat]: {
                    marked: true
                  }
                }
              }
            }
          }
        }
      }
      dispatch(setAvailabilitiesCalendar(availabilitiesObject))
    }
    else if (val === 'setSearchProvider') {
      dispatch(setSearchProvider(data.value))
    }
    else if (val === 'resetLocationData') {
      dispatch(resetLocationData())
    }
    else if (val === 'setSearchRegion') {
      dispatch(setSearchRegion(data.value))
    }
  }
}

