import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import '../../../../css/Home.css';
import { bookingFlowAction } from '../../../../actions/bookingFlowActions'
import { homeAction } from '../../../../actions/homeActions'
import { locationAction } from '../../../../actions/locationActions'
import Typography from '@material-ui/core/Typography'
import { Fonts } from '../../../../utils/Font';
import colors from '../../../layout/colors'
import SelectAreas from './SelectAreas'
import Toolbar from '@material-ui/core/Toolbar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ScrollViewPage from '../../../layout/scrollviews/ScrollViewPage'
import ButtonBooking from '../../../layout/buttons/ButtonBooking'
import styleApp from '../../../layout/style.js'
import Divider from '@material-ui/core/Divider'

var headerPosition = 76
class CardUnitSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areaTop: false,
      areaBottom: false,
      areaSideEyes: false,
      areaBottomEyes: false,
      areaMiddleEyes: false,
      areaIntMouse: false,
      areas: { ...this.props.areas },
      totalUnits: 0,
    };
  }
  componentWillMount() {
    if (window.pageYOffset > 76 || !this.props.journeyShow) headerPosition = 0;
    else headerPosition = 76 - window.pageYOffset
  }
  changeArea(state, value) {
    var areas = this.state.areas
    var area;
    if (state === 'areaTop') {
      area = Object.values(areas).filter(area => area.nameID === 'Forehead')[0]
      areas = {
        ...areas,
        [area.id]: {
          ...area,
          selected: value
        }
      }
    }
    if (state === 'areaBottom') {
      area = Object.values(areas).filter(area => area.nameID === 'Chin')[0]
      areas = {
        ...areas,
        [area.id]: {
          ...area,
          selected: value
        }
      }
    }
    if (state === 'areaSideEyes') {
      area = Object.values(areas).filter(area => area.nameID === 'Crowsfeet')[0]
      areas = {
        ...areas,
        [area.id]: {
          ...area,
          selected: value
        }
      }
    }
    if (state === 'areaMiddleEyes') {
      area = Object.values(areas).filter(area => area.nameID === 'Glabella')[0]
      areas = {
        ...areas,
        [area.id]: {
          ...area,
          selected: value
        }
      }
    }
    if (state === 'areaIntMouse') {
      area = Object.values(areas).filter(area => area.nameID === 'Masseter')[0]
      areas = {
        ...areas,
        [area.id]: {
          ...area,
          selected: value
        }
      }
    }
    var totalUnits = 0
    for (var i in Object.values(areas).filter(area => area.selected)) {
      totalUnits = totalUnits + Object.values(areas).filter(area => area.selected)[i].numberUnit
    }
    this.setState({ areas: areas, totalUnits: totalUnits });
  }
  submit() {
    var numberBigArea = 0
    var numberSmallArea = 0;

    if (this.state.areaTop) {
      numberBigArea = numberBigArea + 1;
    }
    if (this.state.areaBottom) {
      numberBigArea = numberBigArea + 1;
    }
    if (this.state.areaMiddleEyes) {
      numberBigArea = numberBigArea + 1;
    }
    if (this.state.areaExtChicks) {
      numberBigArea = numberBigArea + 1;
    }

    if (this.state.areaSideEyes) {
      numberSmallArea = numberSmallArea + 1;
    }
    if (this.state.areaBottomEyes) {
      numberSmallArea = numberSmallArea + 1;
    }
    if (this.state.areaIntChicks) {
      numberSmallArea = numberSmallArea + 1;
    }
    if (this.state.areaBottomNose) {
      numberSmallArea = numberSmallArea + 1;
    }
    if (this.state.areaBottomMouse) {
      numberSmallArea = numberSmallArea + 1;
    }
    if (this.state.areaIntMouse) {
      numberSmallArea = numberSmallArea + 1;
    }

    var coeffBig = 25
    var coeffSmall = 15

    var numberUnits = (numberSmallArea * coeffSmall) + (numberBigArea * coeffBig);
    this.props.submit(numberUnits)
  }
  bodyContent() {
    return (
      <Grid direction="column" container style={{ width: '93vw', maxWidth: 600 }}>
        <SelectAreas changeArea={this.changeArea.bind(this)} />

        <Divider style={{ width: '100%', marginTop: -10 }} />

        {
          Object.values(this.state.areas).filter(area => area.selected).length === 0 ?
            <Typography style={{ ...styles.text, marginTop: 20 }}>No area has been selected</Typography>
            :
            <div style={{ marginBottom: 40 }}>
              <Grid direction="row" container style={{ height: 30, marginTop: 10 }}>
                <Grid item xs={6} style={styleApp.center2}>
                  <Typography style={styles.text}>Area</Typography>
                </Grid>
                <Grid item xs={6} style={styleApp.center3}>
                  <Typography style={{ ...styles.text, textAlign: 'right' }}>Number of units</Typography>
                </Grid>
              </Grid>

              {Object.values(this.state.areas).filter(area => area.selected).map((area, i) => (
                <Grid direction="row" container style={{ height: 30, marginTop: 10 }} key={i}>
                  <Grid item xs={6} style={styleApp.center2}>
                    <Typography style={styles.text2}>{area.name}</Typography>
                  </Grid>
                  <Grid item xs={6} style={styleApp.center3}>
                    <Typography style={{ ...styles.text2, textAlign: 'right' }}>{area.numberUnit}</Typography>
                  </Grid>
                </Grid>
              ))}

              <Divider style={{ width: '100%' }} />

              <Grid direction="row" container style={{ height: 30, marginTop: 10, marginBottom: 30 }}>
                <Grid item xs={6} style={styleApp.center2}>
                  <Typography style={styles.text}>Total</Typography>
                </Grid>
                <Grid item xs={6} style={styleApp.center3}>
                  <Typography style={{ ...styles.text, textAlign: 'right' }}>{this.state.totalUnits}</Typography>
                </Grid>
              </Grid>
            </div>
        }
      </Grid>
    )
  }
  render() {
    return (
      <Grid container direction="column" className="App" style={{ overflowX: 'hidden' }}>
        <Toolbar style={{ position: 'absolute', zIndex: 2, top: 0, width: '80%', backgroundColor: 'white', height: 60 }}>
          <Grid container direction="row">
            <Grid item xs={1}>
              <IconButton color="inherit" onClick={() => this.props.close()} aria-label="Close">
                <Icon style={{ color: 'black' }}>clear</Icon>
              </IconButton>
            </Grid>
            <Grid item xs={11}></Grid>
          </Grid>
        </Toolbar>
        <div style={{ marginTop: 60 + headerPosition }}>
          <ScrollViewPage
            content={this.bodyContent.bind(this)}
            title="Select treatment areas"
            loader={false}
            header={false}
            styleTitle={{ fontSize: 27, fontFamily: Fonts.MarkOTBold, }}
          />
        </div>
        <Grid container className="viewFooterBooking shadeOnSubmit" justify="center">
          <ButtonBooking
            off={this.state.totalUnits === 0}
            bookingFlow
            text="Confirm"
            click={() => this.props.submit(this.state.totalUnits)}
          />
        </Grid>
      </Grid>
    );
  }
}
const styles = {
  content: {
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    height: '100vh',
    zIndex: 10,
    width: '100vw',
  },
  text: {
    color: colors.title,
    fontSize: 14,
    fontFamily: Fonts.MarkOTMedium,
    textAlign: 'left'
  },
  text2: {
    color: colors.title,
    fontSize: 14,
    fontFamily: Fonts.MarkOT,
    textAlign: 'left'
  },
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(CardUnitSelection);
