

import {
  SET_USER_CONNECTED,
  SET_INSTAVICE_VARIABLES,
  SET_INFO_USER,
  SET_USER_ID,
  SET_CARDS_INFO,
  SHOW_FULL_LOADER,
  SET_LIST_APPTS,
  SET_LIST_TRANSFERS,
  RESET_USER_DATA,
  SET_DEFAULT_CB,
  SET_REFERAL,
  SET_EMAIL_VERIFIED,
  SET_NO_FOOTER,
  SET_FIREBASE_CLINICS,
  SET_TRUE_VAULT_USER,
  SET_INFO_REVIEW,
  SET_FOCUS_PHONE_INPUT,
  OPEN_REVIEWS,
  SET_STEP_LOGIN,
  SHOW_LOGIN_MODAL,
  SET_COUNTRY
} from '../actions/types';

var ListCountry = require('./listCountry.json')
var ListFlags = require('./listCountryFlags.json')

const initialState = {
  userConnected: 'load',
  showFullScreenLoader: false,
  instaviceVariables: {},
  infoUser: {},
  userID: '',
  listCards: [],
  listCardsJSON: [],
  numberOfCB: 0,
  defaultCB: {},
  listAppts: [],
  listTransfers: [],
  noFooter: false,
  idReferal: 'noReferal',
  emailVerified: true,
  firebaseClinics: {},
  trueVaultInfoUser: {},
  focusPhoneInput: true,
  infoReview: {},
  showReviews: false,
  stepLogin: 'verify',
  showLoginModal: false,
  country: {
    "name": "United States",
    "dial_code": "+1",
    "code": "US"
  },
  ListFlags: ListFlags,
  ListCountry: ListCountry
}

const initAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_CONNECTED:
      return { ...state, userConnected: action.userConnected };
    case SHOW_FULL_LOADER:
      return { ...state, showFullScreenLoader: action.showFullScreenLoader };
    case SET_INSTAVICE_VARIABLES:
      return { ...state, instaviceVariables: action.instaviceVariables };
    case SET_INFO_USER:
      return { ...state, infoUser: action.infoUser };
    case SET_USER_ID:
      return { ...state, userID: action.userID };
    case SET_CARDS_INFO:
      return { ...state, listCards: action.listCards, listCardsJSON: action.listCardsJSON, numberOfCB: action.numberOfCB, defaultCB: action.defaultCB };
    case SET_LIST_APPTS:
      return { ...state, listAppts: action.listAppts };
    case SET_LIST_TRANSFERS:
      return { ...state, listTransfers: action.listTransfers };
    case RESET_USER_DATA:
      return { ...state, infoUser: {}, defaultCB: {}, userID: '', numberOfCB: 0 };
    case SET_DEFAULT_CB:
      return { ...state, defaultCB: action.defaultCB };
    case SET_NO_FOOTER:
      return { ...state, noFooter: action.noFooter };
    case SET_COUNTRY:
      return { ...state, country: action.country };
    case SET_EMAIL_VERIFIED:
      return { ...state, emailVerified: action.emailVerified };
    case SET_FIREBASE_CLINICS:
      return { ...state, firebaseClinics: action.firebaseClinics };
    case SET_INFO_REVIEW:
      return { ...state, infoReview: action.infoReview };
    case OPEN_REVIEWS:
      return { ...state, showReviews: action.showReviews };
    case SET_REFERAL:
      return { ...state, idReferal: action.idReferal };
    case SET_STEP_LOGIN:
      return { ...state, stepLogin: action.stepLogin };
    case SHOW_LOGIN_MODAL:
      return { ...state, showLoginModal: action.showLoginModal };
    case SET_TRUE_VAULT_USER:
      return { ...state, trueVaultInfoUser: action.trueVaultInfoUser };
    case SET_FOCUS_PHONE_INPUT:
      return { ...state, focusPhoneInput: action.focusPhoneInput };
    default:
      return state;
  }
}

export default initAppReducer;
