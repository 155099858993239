import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { initApp } from '../../../actions/initAppActions'
import { Fonts } from '../../../utils/Font';

class SignIn extends Component {

  render() {
    return (
      <Grid container style={{ marginTop: 20, marginBottom: 15 }}>
        <Grid style={{ textAlign: 'left', marginLeft: '3%' }}>
          <div style={styles.text}>
            {this.props.dataUser.trueVaultInfoUser.firstname} {this.props.dataUser.trueVaultInfoUser.lastname}
          </div>
          <br />
          <div style={styles.subtext}>({this.props.dataUser.trueVaultInfoUser.phoneNumber[0] + this.props.dataUser.trueVaultInfoUser.phoneNumber[1] + this.props.dataUser.trueVaultInfoUser.phoneNumber[2]}) {this.props.dataUser.trueVaultInfoUser.phoneNumber[3] + this.props.dataUser.trueVaultInfoUser.phoneNumber[4] + this.props.dataUser.trueVaultInfoUser.phoneNumber[5]}-{this.props.dataUser.trueVaultInfoUser.phoneNumber[6] + this.props.dataUser.trueVaultInfoUser.phoneNumber[7] + this.props.dataUser.trueVaultInfoUser.phoneNumber[8] + this.props.dataUser.trueVaultInfoUser.phoneNumber[9]}
          </div>
        </Grid>
      </Grid>
    );
  }
}
const styles = {
  text: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 19,
    color: '#4a4a4a',
  },
  subtext: {
    fontFamily: Fonts.MarkOT,
    fontSize: 15,
    fontWeight: 'normal',
    color: '#4a4a4a',
  },
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { initApp })(SignIn);
