import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import Input from '@material-ui/core/Input';
import { Fonts } from '../../../utils/Font'
import { initApp } from '../../../actions/initAppActions'
import InputAdornment from '@material-ui/core/InputAdornment';
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSettings: false,
      step: 1,
      cardNumber: '',
      expDate: '',
      cvv: '',
      cardType: '',
      digitsRequired: 16,
      lengthCVV: 3,
      inputFocus: '',
      loader: false,
      error: false,
      errorMessage: '',
      zipCode: '',
      showChoiceCountry: false
    };
  }
  handleChangeNumber = name => event => {
    var rawText = event.target.value
    var currentText = this.state.cardNumber
    var text = rawText.replace(' ', '')
    var slice4 = Number(text.slice(0, 4))
    var slice2 = Number(text.slice(0, 2))
    var slice6 = Number(text.slice(0, 6))
    var lengthCVV = 3
    if (text[0] === '4') {
      this.setState({ cardType: 'visa' })
      this.setState({ digitsRequired: 23 })
    } else if (text[0] === '3' && (text[1] === '4' || text[1] === '7')) {
      this.setState({ cardType: 'amEx' })
      this.setState({ digitsRequired: 19 })
      lengthCVV = 4
    } else if ((text[0] === '5' && (text[1] === '1' || text[1] === '2' || text[1] === '3' || text[1] === '4' || text[1] === '5')) || text[0] === '2' && (text[1] === '3' || text[1] === '4' || text[1] === '5' || text[1] === '6' || text[1] === '7')) {
      this.setState({ cardType: 'masterCard' })
      this.setState({ digitsRequired: 19 })
    } else if ((slice6 >= 300000 && slice6 <= 305999) || (slice6 >= 309500 && slice6 <= 309599) || (slice6 >= 360000 && slice6 <= 369999) || (slice6 >= 380000 && slice6 <= 399999)) {
      this.setState({ cardType: 'dinnersClub' })
      this.setState({ digitsRequired: 17 })
    } else if (slice6 === 601174 || (slice6 >= 601100 && slice6 <= 601109) || (slice6 >= 601120 && slice6 <= 601149) || (slice6 >= 601177 && slice6 <= 601179) || (slice6 >= 601186 && slice6 <= 601199) || (slice6 >= 644000 && slice6 <= 659999)) {
      this.setState({ cardType: 'discover' })
      this.setState({ digitsRequired: 19 })
    } else if (slice2 === 50 || (slice2 >= 56 && slice2 <= 64) || (slice2 >= 66 && slice2 <= 69)) {
      this.setState({ cardType: 'maestro' })
      this.setState({ digitsRequired: 23 })
    } else if (slice4 >= 3528 && slice4 <= 3589) {
      this.setState({ cardType: 'jcb' })
      this.setState({ digitsRequired: 23 })
    } else {
      this.setState({ cardType: '' })
      this.setState({ digitsRequired: 23 })
    }
    var newText = ''
    if (rawText.length === 4) {
      newText = rawText + ' '
    } else if (rawText.length === 5 && currentText.length === 6) {
      newText = rawText.replace(' ', '')
    } else if (rawText.length === 5 && currentText.length === 4) {
      newText = currentText + ' ' + rawText[4]
    } else if (rawText.length === 9) {
      newText = rawText + ' '
    } else if (rawText.length === 10 && currentText.length === 11) {
      newText = rawText.slice(0, 9)
    } else if (rawText.length === 10 && currentText.length === 9) {
      newText = currentText + ' ' + rawText[9]
    } else if (rawText.length === 14) {
      newText = rawText + ' '
    } else if (rawText.length === 15 && currentText.length === 16) {
      newText = rawText.slice(0, 14)
    } else if (rawText.length === 15 && currentText.length === 14) {
      newText = currentText + ' ' + rawText[14]
    } else {
      newText = rawText
    }

    this.setState({ lengthCVV: lengthCVV })
    this.setState({ cardNumber: newText })
  }
  handleChangeDate = name => event => {
    var text = event.target.value
    var newText = ''
    var currentVal = this.state.expDate
    if (text.length === 2) {
      newText = text + '/'
    } else if (text.length === 3 && currentVal.length === 4) {
      newText = text.replace('/', '')
    } else if (text.length === 3 && currentVal.length === 2) {
      newText = currentVal + '/' + text[2]
    } else if (text.length === 5) {
      newText = text
    } else {
      newText = text
    }
    this.setState({ expDate: newText })
  }
  handleChangeCVC = name => event => {
    var text = event.target.value
    var currentVal = this.state.cvv
    if (text.length <= 3) {
      this.setState({ cvv: text })
    } else {
      this.setState({ cvv: currentVal })
    }
  }
  handleChangeZip = name => event => {
    var text = event.target.value
    this.setState({ zipCode: text })
  }
  async submit() {
    this.setState({ loader: true })
    var expiry = this.state.expDate;
    expiry = expiry.split("/")
    var monthExpiry = expiry[0]
    var yearExpiry = expiry[1]

    var objCard = {
      number: this.state.cardNumber,
      exp_month: monthExpiry,
      exp_year: yearExpiry,
      cvc: this.state.cvv,
      address_zip: this.state.zipCode
    }
    var url = 'https://us-central1-instabo-production.cloudfunctions.net/createTokenCard'
    const promiseAxios = await axios.get(url, {
      params: {
        number: objCard.number,
        exp_month: objCard.exp_month,
        exp_year: objCard.exp_year,
        address_zip: objCard.address_zip,
        cvc: objCard.cvc,
      }
    })
    if (!promiseAxios.data.results) {
      this.wrongCB('An error has occured. Please verify your card details.')
    } else {
      var tokenStripeCus = this.props.dataUser.infoUser.wallet.tokenCusStripe
      var tokenCard = promiseAxios.data.results.id
      url = 'https://us-central1-instabo-production.cloudfunctions.net/addUserCreditCard2'
      const promiseAxios2 = await axios.get(url, {
        params: {
          tokenCard: tokenCard,
          userID: this.props.dataUser.userID,
          tokenStripeCus: tokenStripeCus,
          brand: promiseAxios.data.results.brand
        }
      })
      if (promiseAxios2.data.response) {
        this.setState({ loader: false })
        this.props.close()
      } else {
        if (!!promiseAxios2.data.message) {
          this.wrongCB(promiseAxios2.data.message)
        } else {
          this.wrongCB('An error has occured. Please verify your card details.')
        }
      }
    }
  }
  wrongCB(message) {
    this.setState({ loader: false })
    this.setState({ error: true })
    this.setState({ errorMessage: message })
  }
  render() {
    return (
      <div className="App" >

        <Grid container alignItems="center" justify="center" style={{ paddingLeft: 15, paddingRight: 15, }}>
          <Grid item xs={12}>
            <Input
              id="input-with-icon-adornment"
              style={{ width: '100%', fontFamily: Fonts.MarkOT }}
              label="Card number"
              type="tel"
              autoFocus={true}
              value={this.state.cardNumber}
              onChange={this.handleChangeNumber()}
              ref={(input) => { this.numberTextInput = input }}
              margin="normal"
              color="secondary"
              startAdornment={
                <InputAdornment position="start">
                  {
                    this.state.cardType === '' ?
                      <img style={{ width: 29, height: 31, borderRadius: 1 }} src={require('../../../img/addCB/creditCard.png')} alt="" />
                      : this.state.cardType === 'visa' ?
                        <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/visa.png')} alt="" />
                        : this.state.cardType === 'masterCard' ?
                          <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/mastercard.png')} alt="" />
                          : this.state.cardType === 'amEx' ?
                            <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/amEx.png')} alt="" />
                            : this.state.cardType === 'maestro' ?
                              <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/maestro.png')} alt="" />
                              : this.state.cardType === 'jcb' ?
                                <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/jcb.png')} alt="" />
                                : this.state.cardType === 'discover' ?
                                  <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/discover.png')} alt="" />
                                  : this.state.cardType === 'dinnersClub' ?
                                    <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/dinnersClub.png')} alt="" />
                                    :
                                    <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/visa.png')} alt="" />
                  }
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center" style={{ paddingLeft: 15, paddingRight: 15 }}>
          <Grid item xs={5}>
            <TextField
              style={{ width: '100%', height: 50, fontFamily: Fonts.MarkOT }}
              id="outlined-password-input"
              label="MM/YY"
              value={this.state.expDate}
              type="tel"
              onChange={this.handleChangeDate()}
              margin="normal"
              ref={(input) => { this.dateTextInput = input }}
              color="secondary"
            />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={5}>
            <TextField
              style={{ width: '100%', height: 50, fontFamily: Fonts.MarkOT }}
              id="outlined-password-input"
              label="CVV"
              value={this.state.cvv}
              type="tel"
              onChange={this.handleChangeCVC()}
              ref={(input) => { this.cvvTextInput = input }}
              margin="normal"
              color="secondary"
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center" style={{ paddingLeft: 15, paddingRight: 15 }}>
          <Grid item xs={12}>
            <TextField
              style={{ width: '100%', height: 50, fontFamily: Fonts.MarkOT }}
              id="outlined-password-input"
              label="Zip code"
              value={this.state.zipCode}
              type="tel"
              onChange={this.handleChangeZip()}
              ref={(input) => { this.zipTextInput = input }}
              // autoComplete="phone-number"
              margin="normal"
              color="secondary"
            // variant="outlined"
            // maxLength={this.state.digitsRequired}
            />
          </Grid>
        </Grid>

        <Grid container style={{ paddingLeft: 15, paddingRight: 15, marginTop: 15 }}>
          {
            this.state.loader ?
              <Button style={{ height: 53, color: 'white', width: '100%', backgroundColor: '#5DB380' }} variant="contained">
                <CircularProgress style={{ color: 'white' }} size={24} opacity={50} />
              </Button>
              : this.state.cardNumber.length === 0 || this.state.cvv.length < 3 || this.state.expDate.length < 5 || this.state.zipCode.length < 3 ?
                <Button style={styles.buttonOff} variant="outlined">
                  Confirm
          </Button>
                :
                <Button style={styles.button} variant="contained" onClick={() => this.submit()}>
                  Confirm
          </Button>
          }
        </Grid>

        <Grid alignItems="center" justify="center" style={{ marginTop: 20 }}>
          {
            this.state.error ?
              <div style={styles.subtitle}>{this.state.errorMessage}</div>
              : null
          }
        </Grid>
      </div>
    );
  }
}
const styles = {
  text: {
    fontFamily: Fonts.MarkOT,
    fontSize: 15,
    fontWeight: 'bold',
    color: '#3173ED',
  },
  subtitle: {
    fontFamily: Fonts.MarkOT,
    fontSize: 15,
    fontWeight: 'bold',
    color: '#4a4a4a',
  },
  button: {
    height: 53, color: 'white', fontFamily: Fonts.MarkOTBold, width: '100%', backgroundColor: '#5DB380',
    fontSize: 14
  },
  buttonOff: {
    height: 53, color: '#5DB380', fontFamily: Fonts.MarkOTBold, width: '100%', border: '1px solid #5DB380'
  },
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { initApp })(SignIn);
