import React, { Component } from 'react';
import logo from '../img/home/wellLogo.png';
import { connect } from 'react-redux';
import '../css/App.css';
import CircularProgress from '@material-ui/core/CircularProgress';
class MainApp extends Component {
  render() {
    return (
      <div className="App">
        <header className="firstScreen">
          <img src={logo} className="App-logo" alt="wlnss" />
          <CircularProgress className="loader" style={{ color: 'white' }} size={25} opacity={50} />
        </header>
      </div>
    );
  }
}

export default connect()(MainApp);
