
import {
  SET_CURRENT_PAGE,
  SET_FROM_MAP_TO_BOOK,
  SET_FROM_INFOCLINIC_TO_BOOK,
  SET_INSTANT_BOOK,
  SET_INSTANT_BOOK_FROM_MAP,
  SET_ALMOST_DONE,
  SET_PREVIOUS_PAGE
} from './types';

const setCurrentPage = (value) => ({
  type: SET_CURRENT_PAGE,
  currentPage: value,
});

const setPreviousPage = (value) => ({
  type: SET_PREVIOUS_PAGE,
  previousPage: value,
});

const setFromMapToBook = (value) => ({
  type: SET_FROM_MAP_TO_BOOK,
  fromMapToBook: value,
});
const setFromInfoClinicToBook = (value) => ({
  type: SET_FROM_INFOCLINIC_TO_BOOK,
  fromInfoClinicToBook: value,
});

const setInstantBook = (value) => ({
  type: SET_INSTANT_BOOK,
  instantBook: value,
});

const setInstantBookFromMap = (value) => ({
  type: SET_INSTANT_BOOK_FROM_MAP,
  instantBookFromMap: value,
});

const setAlmostDone = (value) => ({
  type: SET_ALMOST_DONE,
  showAlmostDone: value,
});

export const bookingFlowAction = (val, data) => {
  return async function (dispatch) {
    if (val === 'setCurrentPage') {
      dispatch(setCurrentPage(data.value))
    } else if (val === 'setPreviousPage') {
      dispatch(setPreviousPage(data.value))
    }
    else if (val === 'setFromMapToBook') {
      dispatch(setFromMapToBook(data.value))
    }
    else if (val === 'setFromInfoClinicToBook') {
      dispatch(setFromInfoClinicToBook(data.value))
    }
    else if (val === 'setInstantBook') {
      dispatch(setInstantBook(data.value))
    }
    else if (val === 'setInstantBookFromMap') {
      dispatch(setInstantBookFromMap(data.value))
    }
    else if (val === 'setAlmostDone') {
      dispatch(setAlmostDone(data.value))
    }
  }
}

