import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import '../../../css/Home.css';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider'
import EditAdditional from '../../elementsHome/modulesBooking/EditAdditional';
import { Fonts } from '../../../utils/Font';
import colors from '../../layout/colors'
function Transition(props) {
  return <Slide direction="up" {...props} />;
}
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showShare: false,
      loaders: {},
      showAppt: false,
      link: '',
      showMore: false,
      showDetailAdditional: false,
      showDetailSecondary: false,
      category: {}
    };
  }
  componentWillMount() {
    var type = this.props.data.infoOffer.product.type
    var category = this.props.dataUser.instaviceVariables.homePage2.filter(cat => cat.type === type)[0]
    this.setState({ category: category })
  }
  pressDetailPrice(val) {
    this.setState({ showDetailAdditional: val })
  }
  pressDetailSecondary(val) {
    this.setState({ showDetailSecondary: val })
  }
  chargeDataCheckout() {

  }
  render() {
    return (
      <div>
        {
          !this.props.data.infoOffer.subscribe && this.props.data.status !== 'past' ?
            <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 5, }} >
              <Grid item xs={9} style={{ textAlign: 'left', paddingLeft: 15 }}>
                <div style={styles.title}>Total {this.state.category.unit.charAt(0) + this.state.category.unit.slice(1)}s {this.state.category.verb}</div>
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'right', paddingRight: 15 }}>
                {
                  !this.props.data.infoOffer.subscribe ?
                    <div style={styles.title}>{this.props.data.infoOffer.numberUnit}</div>
                    :
                    <div style={styles.title}>1</div>
                }

              </Grid>
            </Grid>
            : null
        }
        {
          !this.props.data.infoOffer.subscribe && this.props.data.status !== 'past' ?
            <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4, }} >
              <Grid item xs={9} style={{ textAlign: 'left', paddingLeft: 15 }}>
                {
                  this.props.data.status === 'past' ?
                    <div style={styles.title}>{this.state.category.unit.charAt(0).toUpperCase() + this.state.category.unit.slice(1)}s you paid for</div>
                    :
                    <div style={styles.title}>{this.state.category.unit.charAt(0).toUpperCase() + this.state.category.unit.slice(1)}s you pay for</div>
                }
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'right', paddingRight: 15 }}>
                {
                  !this.props.data.infoOffer.subscribe && ((this.props.data.infoOffer.receiver === 'else' && this.props.data.infoOffer.paymentOption === 'single')) ?
                    <div style={styles.title}>0</div>
                    : this.props.data.infoOffer.subscribe && (this.props.data.infoOffer.receiver === 'else' && this.props.data.infoOffer.paymentOption === 'group') ?
                      <div style={styles.title}>0</div>
                      : !this.props.data.infoOffer.subscribe && this.props.data.infoOffer.paymentOption === 'group' && this.props.data.status === 'past' ?
                        <div style={styles.title}>{this.props.data.infoOffer.numberUnit}</div>
                        : !this.props.data.infoOffer.subscribe && this.props.data.infoOffer.paymentOption === 'group' && this.props.data.status !== 'past' ?
                          <div style={styles.title}>{this.props.data.infoOffer.numberUnit}</div>
                          :
                          <div style={styles.title}>1</div>
                }
              </Grid>
            </Grid>
            : null
        }
        {
          this.props.data.status !== 'past' ?
            <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4, }} >
              <Grid item xs={9} style={{ textAlign: 'left', paddingLeft: 15 }}>
                {
                  !this.props.data.infoOffer.product.unit ?
                    <div style={styles.title}>Price per bag</div>
                    :
                    <div style={styles.title}>Price per {this.props.data.infoOffer.product.unit}</div>
                }
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'right', paddingRight: 15 }}>
                {
                  this.props.data.pricing ?
                    <div style={styles.title}>${this.props.data.pricing.pricePerUnit}</div>
                    : this.props.data.infoOffer.product.pricesGroup.filter(price => Number(this.props.data.infoOffer.numberUnit) === price.value).length !== 0 ?
                      <div style={styles.title}>${this.props.data.infoOffer.product.pricesGroup.filter(price => Number(this.props.data.infoOffer.numberUnit) === price.value)[0].price}</div>
                      :
                      <div style={styles.title}>${this.props.data.infoOffer.product.pricesGroup.filter(price => price.type === 'greater')[0].price}</div>
                }
              </Grid>
            </Grid>
            : null
        }

        {
          this.props.data.status === 'past' ?
            <div>
              {
                this.props.data.invoice.finalPricing ?
                  <div>
                    {Object.values(this.props.data.invoice.finalPricing).map((product, i) => (
                      <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4, }} >
                        <Grid item xs={9} style={{ textAlign: 'left', paddingLeft: 15 }}>
                          <div style={styles.title}>{product.number} x {product.name}</div>
                        </Grid>
                        <Grid item xs={3} style={{ textAlign: 'right', paddingRight: 15 }}>
                          <div style={styles.title}>${product.price}</div>
                        </Grid>
                      </Grid>
                    ))}
                  </div>
                  :
                  <div>
                    <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4, }} >
                      <Grid item xs={9} style={{ textAlign: 'left', paddingLeft: 15 }}>
                        <div style={styles.title}>{this.state.category.unit.charAt(0).toUpperCase() + this.state.category.unit.slice(1)}s you paid for</div>
                      </Grid>
                      <Grid item xs={3} style={{ textAlign: 'right', paddingRight: 15 }}>
                        {
                          !this.props.data.infoOffer.paymentOption ?
                            <div style={styles.title}>1</div>
                            : (!this.props.data.infoOffer.subscribe && ((this.props.data.infoOffer.receiver === 'else' && this.props.data.infoOffer.paymentOption === 'single'))) || Number(this.props.data.invoice.totalPrice) === 0 ?
                              <div style={styles.title}>0</div>
                              : this.props.data.infoOffer.subscribe && (this.props.data.infoOffer.receiver === 'else' && this.props.data.infoOffer.paymentOption === 'group') ?
                                <div style={styles.title}>0</div>
                                : !this.props.data.infoOffer.subscribe && this.props.data.infoOffer.paymentOption === 'group' ?
                                  <div style={styles.title}>{this.props.data.invoice.totalUsersTreated}</div>
                                  :
                                  <div style={styles.title}>1</div>
                        }
                      </Grid>
                    </Grid>
                    <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4, }} >
                      <Grid item xs={9} style={{ textAlign: 'left', paddingLeft: 15 }}>
                        {
                          !this.props.data.infoOffer.product.unit ?
                            <div style={styles.title}>Price per bag</div>
                            :
                            <div style={styles.title}>Price per {this.props.data.infoOffer.product.unit}</div>
                        }
                      </Grid>
                      <Grid item xs={3} style={{ textAlign: 'right', paddingRight: 15 }}>
                        {
                          !this.props.data.infoOffer.paymentOption && !this.props.data.infoOffer.subscribe ?
                            <div style={styles.title}>${(Number(this.props.data.invoice.totalPrice))}</div>
                            : !this.props.data.infoOffer.subscribe && this.props.data.infoOffer.paymentOption === 'group' && this.props.data.invoice.totalUsersTreated !== 0 ?
                              <div style={styles.title}>${(Number(this.props.data.invoice.totalPrice) / Number(this.props.data.invoice.totalUsersTreated)).toFixed(0)}</div>
                              : !this.props.data.infoOffer.subscribe && this.props.data.infoOffer.paymentOption === 'group' && this.props.data.invoice.totalUsersTreated === 0 ?
                                <div style={styles.title}>$0</div>
                                :
                                <div style={styles.title}>${this.props.data.invoice.totalPrice}</div>
                        }
                      </Grid>
                    </Grid>
                  </div>
              }
            </div>
            : null
        }
        <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 15, marginBottom: 15 }} />
        <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 5, }} >
          <Grid item xs={9} style={{ textAlign: 'left', paddingLeft: 15 }}>
            {
              this.props.data.status !== 'past' ?
                <div style={styles.title2}>Estimated total</div>
                :
                <div style={styles.title2}>Total</div>
            }
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'right', paddingRight: 15 }}>
            {
              this.props.data.status !== 'past' && this.props.data.pricing ?
                <div style={styles.title2}>${this.props.data.pricing.estimatedTotal}</div>
                : this.props.data.status !== 'past' && this.props.data.infoOffer.paymentOption === 'single' && this.props.data.infoOffer.receiver !== 'else' && this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit)).length !== 0 ?
                  <div style={styles.title2}>${Number((this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit))[0].price))}</div>
                  : this.props.data.status !== 'past' && this.props.data.infoOffer.paymentOption === 'single' && this.props.data.infoOffer.receiver !== 'else' && this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit)).length === 0 ?
                    <div style={styles.title2}>${Number((this.props.data.infoOffer.product.pricesGroup.filter(price => price.type === 'greater')[0].price))}</div>
                    : this.props.data.status !== 'past' && this.props.data.infoOffer.paymentOption === 'single' && this.props.data.infoOffer.receiver === 'else' && this.props.data.infoUser.userID !== this.props.dataUser.userID && this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit)).length !== 0 ?
                      <div style={styles.title2}>${Number((this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit))[0].price))}</div>
                      : this.props.data.status !== 'past' && this.props.data.infoOffer.paymentOption === 'single' && this.props.data.infoOffer.receiver === 'else' && this.props.data.infoUser.userID !== this.props.dataUser.userID && this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit)).length === 0 ?
                        <div style={styles.title2}>${Number((this.props.data.infoOffer.product.pricesGroup.filter(price => price.type === 'greater')[0].price))}</div>
                        : this.props.data.status !== 'past' && this.props.data.infoOffer.paymentOption === 'single' && this.props.data.infoOffer.receiver === 'else' && this.props.data.infoUser.userID === this.props.dataUser.userID ?
                          <div style={styles.title2}>$0</div>
                          : this.props.data.status !== 'past' && this.props.data.infoOffer.paymentOption === 'group' && this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit)).length !== 0 && !this.props.data.infoOffer.subscribe ?
                            <div style={styles.title2}>${Number(this.props.data.infoOffer.numberUnit * (this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit))[0].price))}</div>
                            : this.props.data.status !== 'past' && this.props.data.infoOffer.paymentOption === 'group' && this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit)).length === 0 && !this.props.data.infoOffer.subscribe ?
                              <div style={styles.title2}>${Number(this.props.data.infoOffer.numberUnit * (this.props.data.infoOffer.product.pricesGroup.filter(price => price.type === 'greater')[0].price))}</div>
                              : this.props.data.status !== 'past' ?
                                <div style={styles.title2}>$0</div>
                                :
                                <div style={styles.title2}>${(this.props.data.invoice.totalPrice)}</div>
            }
          </Grid>
        </Grid>
        <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4, }} >
          <Grid item xs={6} style={{ textAlign: 'left', paddingLeft: 15 }}>
            <div style={styles.title3}>Credits</div>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right', paddingRight: 15 }}>
            {
              this.props.data.status !== 'past' ?
                <div style={styles.title3}>${this.props.dataUser.infoUser.wallet.totalWallet}</div>
                :
                <div style={styles.title3}>${this.props.data.invoice.credits}</div>
            }
          </Grid>
        </Grid>

        <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 15, marginBottom: 15 }} />

        <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 5, }} >
          <Grid item xs={9} style={{ textAlign: 'left', paddingLeft: 15 }}>
            {
              this.props.data.status !== 'past' ?
                <div style={styles.title2}>Estimated charge</div>
                :
                <div style={styles.title2}>Paid</div>
            }
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'right', paddingRight: 15 }}>
            {
              this.props.data.status !== 'past' && this.props.data.pricing ?
                <div style={styles.title2}>${Math.max(0, Number(this.props.data.pricing.estimatedTotal) - Number(this.props.dataUser.infoUser.wallet.totalWallet))}</div>
                : this.props.data.status !== 'past' && this.props.data.infoOffer.paymentOption === 'single' && ((this.props.data.infoOffer.receiver !== 'else' && this.props.data.infoOffer.subscribe) || this.props.data.infoOffer.subscribe) && this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit)).length !== 0 ?
                  <div style={styles.title2}>${Math.max(0, Number((this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit))[0].price)) - Number(this.props.dataUser.infoUser.wallet.totalWallet))}</div>
                  : this.props.data.status !== 'past' && this.props.data.infoOffer.paymentOption === 'single' && ((this.props.data.infoOffer.receiver !== 'else' && this.props.data.infoOffer.subscribe) || this.props.data.infoOffer.subscribe) && this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit)).length === 0 ?
                    <div style={styles.title2}>${Math.max(0, Number((this.props.data.infoOffer.product.pricesGroup.filter(price => price.type === 'greater')[0].price)) - Number(this.props.dataUser.infoUser.wallet.totalWallet))}</div>
                    : this.props.data.status !== 'past' && this.props.data.infoOffer.paymentOption === 'group' && this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit)).length !== 0 && !this.props.data.infoOffer.subscribe ?
                      <div style={styles.title2}>${Math.max(0, Number(this.props.data.infoOffer.numberUnit * (this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit))[0].price)) - Number(this.props.dataUser.infoUser.wallet.totalWallet))}</div>
                      : this.props.data.status !== 'past' && this.props.data.infoOffer.paymentOption === 'group' && this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit)).length === 0 && !this.props.data.infoOffer.subscribe ?
                        <div style={styles.title2}>${Math.max(0, Number(this.props.data.infoOffer.numberUnit * (this.props.data.infoOffer.product.pricesGroup.filter(price => price.type === 'greater')[0].price)) - Number(this.props.dataUser.infoUser.wallet.totalWallet))}</div>
                        : this.props.data.status !== 'past' ?
                          <div style={styles.title2}>$0</div>
                          :
                          <div style={styles.title2}>${Math.max(0, this.props.data.invoice.totalPrice - this.props.data.invoice.credits)}</div>
            }
          </Grid>
        </Grid>

        {(this.props.data.infoOffer.numberUnit > 1 || this.props.data.infoOffer.receiver === 'else') && !!this.props.data.inviteCode ?
          <div>
            <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 15, marginBottom: 15 }} />

            <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 5, }} >
              <Grid item xs={6} style={{ textAlign: 'left', paddingLeft: 15 }}>
                <div style={styles.title}>Invite code</div>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right', paddingRight: 15 }}>
                <div style={styles.title}>{this.props.data.inviteCode}</div>
              </Grid>
            </Grid>
          </div>
          : null
        }
        <div>
          {
            this.props.data.pricing && !this.state.category.secondary && this.props.data.status !== 'past' ?
              <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4, }} >
                <Grid item xs={9} style={{ textAlign: 'left', paddingLeft: 15 }}>
                  <div style={styles.title}>Additional products</div>
                </Grid>
                <Grid item xs={3} style={{ textAlign: 'right', paddingRight: 15 }}>
                  <div style={styles.titleButton} onClick={() => { this.pressDetailPrice(true) }}>View</div>
                </Grid>
              </Grid>
              : null
          }
        </div>

        {
          this.props.data.status !== 'past' && this.props.data.infoOffer.paymentOption === 'group' && !this.props.data.infoOffer.subscribe ?
            <div >
              <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 15, marginBottom: 15 }} />
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} style={{ textAlign: 'left', paddingLeft: 15, paddingRight: 15 }}>
                  <div style={styles.smallTitle}><div style={{ fontWeight: 'bold' }}>Reminder • </div>You are in charge of the group’s bill. We will process payment after the appointment takes place.</div>
                </Grid>
              </Grid>
            </div>
            : this.props.data.status !== 'past' && this.props.data.infoOffer.paymentOption === 'group' && this.props.data.infoOffer.subscribe ?
              <div>
                <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 15, marginBottom: 15 }} />
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={12} style={{ textAlign: 'left', paddingLeft: 15, paddingRight: 15 }}>
                    <div style={styles.smallTitle}><div style={{ fontWeight: 'bold' }}>Reminder • </div>Your event host is taking care of the bill. You won’t be charged for this event.</div>
                  </Grid>
                </Grid>
              </div>
              : this.props.data.status !== 'past' && this.props.data.infoOffer.receiver === 'else' && this.props.data.infoOffer.numberUnit === 1 && !this.props.data.infoOffer.subscribe ?
                <div>
                  <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 15, marginBottom: 15 }} />
                  <Grid container justify="center" alignItems="center">
                    <Grid item xs={12} style={{ textAlign: 'left', paddingLeft: 15, paddingRight: 15 }}>
                      <div style={styles.smallTitle}><div style={{ fontWeight: 'bold' }}>Reminder • </div>Your event attendee is taking care of the bill. You won’t be charged for this event.</div>
                    </Grid>
                  </Grid>
                </div>
                : this.props.data.status !== 'past' && this.props.data.infoOffer.receiver === 'else' && this.props.data.infoOffer.numberUnit !== 1 && !this.props.data.infoOffer.subscribe ?
                  <div>
                    <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 15, marginBottom: 15 }} />
                    <Grid container justify="center" alignItems="center">
                      <Grid item xs={12} style={{ textAlign: 'left', paddingLeft: 15, paddingRight: 15 }}>
                        <div style={styles.smallTitle}><div style={{ fontWeight: 'bold' }}>Reminder • </div>Your event attendees are taking care of the bill. You won’t be charged for this event.</div>
                      </Grid>
                    </Grid>
                  </div>
                  : this.props.data.status !== 'past' && this.props.data.infoOffer.paymentOption === 'group' && !this.props.data.infoOffer.subscribe ?
                    <div>
                      <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 15, marginBottom: 15 }} />
                      <Grid container justify="center" alignItems="center">
                        <Grid item xs={12} style={{ textAlign: 'left', paddingLeft: 15, paddingRight: 15 }}>
                          <div style={styles.smallTitle}><div style={{ fontWeight: 'bold' }}>Reminder • </div>Your are in charge of the group's bill. We will proceed to the payment after the appointment takes place.</div>
                        </Grid>
                      </Grid>
                    </div>
                    : this.props.data.status !== 'past' && this.props.data.infoOffer.paymentOption === 'group' && this.props.data.infoOffer.subscribe ?
                      <div>
                        <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 15, marginBottom: 15 }} />
                        <Grid container justify="center" alignItems="center">
                          <Grid item xs={12} style={{ textAlign: 'left', paddingLeft: 15, paddingRight: 15 }}>
                            <div style={styles.smallTitle}><div style={{ fontWeight: 'bold' }}>Reminder • </div>Your event host is taking care of the bill. You won’t be charged for this event.</div>
                          </Grid>
                        </Grid>
                      </div>
                      : this.props.data.status !== 'past' && this.props.data.infoOffer.receiver === 'else' && this.props.data.infoOffer.numberUnit === 1 && !this.props.data.infoOffer.subscribe ?
                        <div>
                          <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 15, marginBottom: 15 }} />
                          <Grid container justify="center" alignItems="center">
                            <Grid item xs={12} style={{ textAlign: 'left', paddingLeft: 15, paddingRight: 15 }}>
                              <div style={styles.smallTitle}><div style={{ fontWeight: 'bold' }}>Reminder • </div>Your event attendee is taking care of the bill. You won’t be charged for this event.</div>
                            </Grid>
                          </Grid>
                        </div>
                        : this.props.data.status !== 'past' && this.props.data.infoOffer.receiver === 'else' && this.props.data.infoOffer.numberUnit !== 1 && !this.props.data.infoOffer.subscribe ?
                          <div>
                            <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 15, marginBottom: 15 }} />
                            <Grid container justify="center" alignItems="center">
                              <Grid item xs={12} style={{ textAlign: 'left', paddingLeft: 15, paddingRight: 15 }}>
                                <div style={styles.smallTitle}><div style={{ fontWeight: 'bold' }}>Reminder • </div>Your event attendees are taking care of the bill. You won’t be charged for this event.</div>
                              </Grid>
                            </Grid>
                          </div>
                          : null
        }
        <Dialog
          fullScreen
          scroll={'paper'}
          open={this.state.showDetailAdditional}
          TransitionComponent={Transition}
        >
          <EditAdditional journeyShow={this.props.journeyShow} secondary={this.props.data.infoOffer.secondaryProducts} additionalOn={true} category={this.state.category} additional={this.props.data.infoOffer.additionalProducts} editable={false} chargeDataCheckout={this.chargeDataCheckout.bind(this)} close={this.pressDetailPrice.bind(this)} />
        </Dialog>
      </div>
    );
  }
}

const styles = {
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  shareButton: {
    position: 'absolute',
    top: 10,
    width: 30,
    height: 25,
    zIndex: 400,
    left: window.innerWidth - 70
  },
  divider: {
    width: '90vw',
    height: 1,
    marginLeft: '5vw',
    backgroundColor: '#eaeaea',
    marginTop: 20,
    marginBottom: 10,
  },
  title: {
    color: colors.title,
    fontSize: 14,
    fontFamily: Fonts.MarkOT,
  },
  smallTitle: {
    color: '#7A7A7A',
    fontSize: 13,
    fontFamily: 'Avenir',
  },
  titleSelected: {
    color: '#F4BC9C',
    fontSize: 14,
    fontFamily: 'AvenirMedium',
  },
  title2: {
    color: colors.title,
    fontSize: 14,
    fontFamily: Fonts.MarkOTMedium,
  },
  title3: {
    color: colors.secondary,
    fontSize: 14,
    fontFamily: Fonts.MarkOTMedium,
  },
  subtitle: {
    color: '#585858',
    fontSize: 14,
    fontFamily: 'Avenir',
  },
  titleButton: {
    color: colors.primary,
    fontSize: 14,
    fontFamily: Fonts.MarkOTMedium,
    cursor: 'pointer'
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, {})(Home);
