import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import '../../css/Home.css';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { Fonts } from '../../utils/Font'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import CircularProgress from '@material-ui/core/CircularProgress';
import colors from '../layout/colors'

class CardAppts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAppt: false,
      showInvoice: false,
      timeRemaining: 0,
      isVisible: false,
      durationOff: 0,
      img: undefined
    };
  }
  componentWillMount() {
    this.getImg()
  }
  getImg() {
    try {
      this.setState({ img: this.props.data.infoOffer.product.imageSmall })
    } catch {
      try {
        this.setState({ img: require(`../../img/category/${this.props.data.infoOffer.product.type}/${this.props.data.infoOffer.product.name.toLowerCase()}.jpg`) })
      } catch {
        if (this.props.data.infoOffer.product.type === 'shots') {
          this.setState({ img: require(`../../img/category/${this.props.data.infoOffer.product.type}/${this.props.data.infoOffer.product.type}.jpg`) })
        }
      }
    }
  }
  render() {
    return (
      <div style={styles.cardHolder}>
        <Grid container justify='center' alignItems='center' style={styles.cardAppts} className="pointer" onClick={() => this.props.openDetailAppt(true, this.props.userOfferID)}>
          <Grid item xs={3} alignContent='center'>
            {(this.state.img) ?
              <div style={{ width: '80%' }}>
                <img
                  className="imgCheckout"
                  src={this.state.img}
                  alt=""
                />
              </div>
              : null
            }
          </Grid>
          <Grid item xs={7} style={{ textAlign: 'left' }}>
            <div >
              {
                this.props.data.infoOffer.product.type === 'shots' ?
                  <p style={styles.title}>{this.props.data.infoOffer.product.name}</p>
                  : this.props.data.infoOffer.product.categoryName ?
                    <p style={styles.title}>{this.props.data.infoOffer.product.categoryName} • {this.props.data.infoOffer.product.name}</p>
                    :
                    <p style={styles.title}>{this.props.data.infoOffer.product.place} • {this.props.data.infoOffer.product.name}</p>
              }

              {
                !this.props.data.location.timeZone ?
                  <div style={{ marginTop: 0 }}>
                    {
                      this.props.data.infoOffer.product.type === 'shots' ?
                        <p style={styles.contentText3}>{moment(this.props.data.infoOffer.date).format('ddd, Do MMM • h:mm')} to {moment(this.props.data.infoOffer.date).add(this.props.data.infoOffer.durationEvent, 'm').format('h:mm a')}</p>
                        :
                        <p style={styles.contentText3}>{moment(this.props.data.infoOffer.date).format('ddd, Do MMM • h:mm a')}</p>
                    }
                  </div>
                  :
                  <div style={{ marginTop: 0 }}>
                    {
                      this.props.data.infoOffer.product.type === 'shots' && moment().format('Z').toString() !== this.props.data.location.timeZone.offSet ?
                        <p style={styles.contentText3}>{momentTZ.tz(this.props.data.infoOffer.date, this.props.data.location.timeZone.timeZoneId).format('ddd, Do MMM • h:mm a')} to {momentTZ.tz(this.props.data.infoOffer.date, this.props.data.location.timeZone.timeZoneId).add(this.props.data.infoOffer.durationEvent, 'm').format('h:mm a')} ({this.props.data.location.timeZone.timeZoneName2})</p>
                        : this.props.data.infoOffer.product.type === 'shots' ?
                          <p style={styles.contentText3}>{momentTZ.tz(this.props.data.infoOffer.date, this.props.data.location.timeZone.timeZoneId).format('ddd, Do MMM • h:mm a')} to {momentTZ.tz(this.props.data.infoOffer.date, this.props.data.location.timeZone.timeZoneId).add(this.props.data.infoOffer.durationEvent, 'm').format('h:mm a')}</p>
                          : moment().format('Z').toString() !== this.props.data.location.timeZone.offSet ?
                            <p style={styles.contentText3}>{momentTZ.tz(this.props.data.infoOffer.date, this.props.data.location.timeZone.timeZoneId).format('ddd, Do MMM • h:mm a')} ({this.props.data.location.timeZone.timeZoneName2})</p>
                            :
                            <p style={styles.contentText3}>{momentTZ.tz(this.props.data.infoOffer.date, this.props.data.location.timeZone.timeZoneId).format('ddd, Do MMM • h:mm a')}</p>
                    }
                  </div>
              }
              {
                this.props.data.status !== 'past' && this.props.data.pricing && !this.props.data.infoOffer.subscribe && this.props.data.infoOffer.receiver === 'else' && this.props.data.infoOffer.numberUnit === 1 ?
                  <p style={styles.contentText3}>Charged on attendee</p>
                  : this.props.data.status !== 'past' && this.props.data.pricing && !this.props.data.infoOffer.subscribe && this.props.data.infoOffer.receiver === 'else' && this.props.data.infoOffer.numberUnit !== 1 ?
                    <p style={styles.contentText3}>Charged on attendees</p>
                    : this.props.data.status !== 'past' && this.props.data.pricing && this.props.data.infoOffer.subscribe && this.props.data.infoOffer.paymentOption === 'group' ?
                      <p style={styles.contentText3}>Charged on host</p>
                      : this.props.data.status !== 'past' && this.props.data.pricing ?
                        <p style={styles.contentText3}>${this.props.data.pricing.estimatedTotal} est. total</p>
                        : this.props.data.status !== 'past' && !this.props.data.infoOffer.product.pricesGroup ?
                          <p style={styles.contentText3}>${this.props.data.infoOffer.product.price} est. total</p>
                          : this.props.data.status !== 'past' && ((this.props.data.infoOffer.receiver !== 'else' && !this.props.data.infoOffer.subscribe) || this.props.data.infoOffer.subscribe) && this.props.data.infoOffer.paymentOption === 'single' && this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit)).length !== 0 ?
                            <p style={styles.contentText3}>${Number((this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit))[0].price))} est. total</p>
                            : this.props.data.status !== 'past' && ((this.props.data.infoOffer.receiver !== 'else' && !this.props.data.infoOffer.subscribe) || this.props.data.infoOffer.subscribe) && this.props.data.infoOffer.paymentOption === 'single' && this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit)).length === 0 ?
                              <p style={styles.contentText3}>${Number((this.props.data.infoOffer.product.pricesGroup.filter(price => price.type === 'greater')[0].price))} est. total</p>
                              : this.props.data.status !== 'past' && this.props.data.infoOffer.paymentOption === 'group' && this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit)).length !== 0 && !this.props.data.infoOffer.subscribe ?
                                <p style={styles.contentText3}>${Number(this.props.data.infoOffer.numberUnit * (this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit))[0].price))} est. total</p>
                                : this.props.data.status !== 'past' && this.props.data.infoOffer.paymentOption === 'group' && this.props.data.infoOffer.product.pricesGroup.filter(price => price.value === Number(this.props.data.infoOffer.numberUnit)).length === 0 && !this.props.data.infoOffer.subscribe ?
                                  <p style={styles.contentText3}>${Number(this.props.data.infoOffer.numberUnit * (this.props.data.infoOffer.product.pricesGroup.filter(price => price.type === 'greater')[0].price))} est. total</p>
                                  : this.props.data.status !== 'past' && this.props.data.infoOffer.receiver === 'else' && this.props.data.infoOffer.numberUnit === 1 ?
                                    <p style={styles.contentText3}>Charged on attendee</p>
                                    : this.props.data.status !== 'past' && this.props.data.infoOffer.receiver === 'else' && this.props.data.infoOffer.numberUnit !== 1 ?
                                      <p style={styles.contentText3}>Charged on attendees</p>
                                      : this.props.data.status !== 'past' ?
                                        <p style={styles.contentText3}>Charged on host</p>
                                        : this.props.data.status === 'past' && this.props.data.invoice ?
                                          <p style={styles.contentText3}>${(this.props.data.invoice.totalPrice)}</p>
                                          : null
              }
            </div>
          </Grid>
          <Grid item xs={2}>
            {
              this.props.data.status === 'past' ?
                <Icon style={{ color: colors.primary }} size={16}>receipt</Icon>
                : this.props.loaders[this.props.userOfferID].loader ?
                  <CircularProgress style={{ color: '#b6d8c8' }} size={20} opacity={50} />
                  :
                  <FontAwesomeIcon style={{ color: '#eaeaea' }} className='fa-lg' icon={faCheck} />
            }
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = {
  cardHolder: {
    borderRadius: 7,
    marginTop: 2,
    width: '100%'
  },
  cardAppts: {
    borderRadius: 7,
    height: 105,
    width: '100%',
    maxWidth: 600,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewPastAppt: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 28,
    width: 28,
    backgroundColor: '#699580',
    borderRadius: 14,
  },
  viewLiveAppt: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 28,
    width: 28,
    backgroundColor: '#b6d8c8',
    borderRadius: 14,
  },
  title: {
    color: colors.title,
    fontSize: 15,
    fontFamily: Fonts.MarkOTMedium,
    margin: 3,
  },
  contentText: {
    color: '#959393',
    fontSize: 14,
    fontFamily: Fonts.MarkOTBold,
    margin: 0,
    marginTop: 0
  },
  contentText2: {
    color: '#97BDAB',
    fontSize: 16,
    fontFamily: Fonts.MarkOTBold,
    margin: 0,
    marginTop: 0,
  },
  contentText3: {
    color: colors.subtitle,
    fontSize: 12,
    fontFamily: Fonts.MarkOT,
    margin: 3
  },
  contentTextOff: {
    color: 'white',
    fontSize: 13,
    fontFamily: Fonts.MarkOT,
  },
};

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, {})(CardAppts);
