import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import '../../../css/Home.css';
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'
import colors from '../../layout/colors'
import { Fonts } from '../../../utils/Font'

class RowProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProduct: false,
      colorBorder: '#eaeaea',
      borderOn: false
    };
  }
  openProduct() {
    if (this.props.clickable) {
      this.props.openProduct(true, this.props.product)
    }
  }
  closeProduct() {
    this.setState({ showProduct: false })
  }
  render() {
    return (
      <div style={styles.cardProduct} className={this.state.borderOn ? 'borderOn' : 'borderOff'}>
        <Grid container alignItems="center" style={{ height: '100%' }} >
          <Grid xs={4} item onClick={() => { this.openProduct() }}>
            <img
              style={{ width: '100%', height: 80, borderTopLeftRadius: 7, borderBottomLeftRadius: 7, objectFit: 'cover' }}
              src={this.props.product.image}
              alt=""
            />
          </Grid>
          {
            this.props.clickable ?
              <Grid xs={4} item style={styles.center} onClick={() => { this.openProduct() }}>
                <div style={styles.title}>{this.props.product.name}</div>
                <div style={styles.subtitle}>${this.props.product.price} / {this.props.product.unit.charAt(0).toUpperCase() + this.props.product.unit.slice(1)}</div>
              </Grid>
              :
              <Grid xs={5} item style={styles.center}>
                <div style={styles.title}>{this.props.product.name}</div>
                <div style={styles.subtitle}>${this.props.product.price} / {this.props.product.unit.charAt(0).toUpperCase() + this.props.product.unit.slice(1)}</div>
              </Grid>
          }
          {
            this.props.product.numberAdded === 0 || (this.props.additional.numberAdded === 0 && !this.props.clickable) ?
              <Grid xs={1} style={styles.center} item>
                <i className="fa fa-lg fa-minus-square" style={{ color: '#eaeaea' }}></i>
              </Grid>
              :
              <Grid xs={1} item style={styles.center} onClick={() => {
                this.props.add(this.props.index, -1)
                if (Number(this.props.product.numberAdded) === 0 || (Number(this.props.additional.numberAdded) === 0 && !this.props.clickable)) {
                  this.setState({ borderOn: false})
                } else {
                  this.setState({ borderOn: true})
                }
              }} >
                <i className="fa fa-lg fa-minus-square" style={{ color: '#8CB5A1' }}></i>
              </Grid>
          }
          <Grid xs={1} item style={styles.title2}>
            {this.props.product.numberAdded}
          </Grid>
          <Grid xs={1} item style={styles.center} onClick={() => {
            this.props.add(this.props.index, 1)
            if (this.props.product.numberAdded + 1 === 0 || (this.props.additional.numberAdded + 1 === 0 && !this.props.clickable)) {
              this.setState({ borderOn: false})
            } else {
              this.setState({ borderOn: true})
            }
          }} >
            <i className="fa fa-lg fa-plus-square" style={{ color: '#8CB5A1' }}></i>
          </Grid>

          {
            this.props.clickable ?
              <Grid size={1} item onClick={() => { this.openProduct() }}>
                <Icon styles={{ color: '#4a4a4a' }}>keyboard_arrow_right</Icon>
              </Grid>
              : null
          }
        </Grid>
      </div>
    );
  }
}
const styles = {
  buttonBack: {
    position: 'absolute',
    top: 75,
    left: 15,
    zIndex: 20,
  },
  cardProduct: {
    marginTop: 10,
    backgroundColor: 'white',
    borderRadius: 7,
    height: 80,
    marginLeft: 10,
    marginRight: 10,
  },
  buttonOff: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 23,
    width: 27,
    borderRadius: 13.5,
    textAlign: 'center',
    paddingTop: 3,
    borderColor: '#C1DACE',
    backgroundColor: '#eaeaea',
    borderWidth: 2
  },
  buttonOn: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 23,
    width: 27,
    borderRadius: 13.5,
    textAlign: 'center',
    paddingTop: 3,
    borderColor: '#C1DACE',
    backgroundColor: '#C1DACE',
    borderWidth: 2
  },
  title: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 14,
    textAlign: 'left',
    color: colors.title,
    paddingLeft: 15,
  },
  title2: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 13,
    color: colors.title,
  },
  subtitle: {
    fontFamily: Fonts.MarkOT,
    fontSize: 11,
    textAlign: 'left',
    color: colors.subtitle,
    marginTop: 2,
    paddingLeft: 15,
  },
  secondRes: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    color: colors.title,
    textAlign: 'left',
  },
  mainRes: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 17,
    color: colors.title,
    textAlign: 'left',
  },
  rowResult: {
    flex: 1, borderBottomWidth: 1, borderColor: '#EAEAEA', paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15
  },
  textIngredient: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    color: colors.title,
    textAlign: 'left',
    height: 30,
    paddingLeft: 15,
  },
  textBenefit: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    marginTop: 10,
    color: colors.title,
    textAlign: 'left',
    paddingLeft: 15,
    height: 30,
  },
  titleBenefit: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 19,
    color: colors.title,
    textAlign: 'left',
    height: 30,
  },
  divider: {
    width: window.innerWidth - 30,
    height: 1,
    marginLeft: 15,
    backgroundColor: '#eaeaea',
    marginTop: 10,
    marginBottom: 10,
  },
  row: {
    textAlign: 'left',
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    paddingLeft: 15,
    color: colors.title,
    paddingTop: 5
  },
  boxPrice: {
    width: 70,
    height: 25,
    borderRadius: 15,
    bottom: 20,
    right: 15,
    backgroundColor: '#FAF9F9',
    alignItems: 'center',
    fontFamily: 'Avenir',
    justifyContent: 'center',
    borderColor: '#EAEAEA',
    color: '#8CB5A1',
    paddingTop: 9,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(RowProduct);
