import { combineReducers } from 'redux';
import loginReducer from './loginReducers';
import initAppReducer from './initAppReducer';
import initConnectedReducer from './initConnectedReducer';
import settingsReducer from './settingsReducers';
import homeReducer from './homeReducers';
import walletReducer from './walletReducers';
import calendarReducer from './calendarReducers';
import locationReducer from './locationReducers';
import bookingFlowReducer from './bookingFlowReducers';

export default combineReducers({
  initApp: initAppReducer,
  login: loginReducer,
  initConnected: initConnectedReducer,
  settings: settingsReducer,
  home: homeReducer,
  wallet: walletReducer,
  calendar: calendarReducer,
  location: locationReducer,
  bookingFlow: bookingFlowReducer
});