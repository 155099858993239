
import {
  UPDATE_STEP_LOGIN,
  SET_USER_EMAIL,
  SET_USER_PWD,
  SET_USER_LASTNAME,
  SET_USER_FIRSTNAME,
  SET_USER_PHONE,
  SET_USER_BIRTH,
  SET_STEP_LOG,
  SET_ANSWERS,
  RESET_LOGIN_DATA
} from '../actions/types';

const initialState = {
  stepSignUp: 'name',
  stateLogin: 1,
  email: '',
  password: '',
  firstname: '',
  lastname: '',
  birth: '',
  phone: '',
  answers: {},
}

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_STEP_LOGIN:
      return { ...state, stepSignUp: action.stepSignUp }
    case SET_STEP_LOG:
      return { ...state, stateLogin: action.stateLogin }
    case SET_USER_EMAIL:
      return { ...state, email: action.email }
    case SET_USER_PWD:
      return { ...state, password: action.password }
    case SET_USER_LASTNAME:
      return { ...state, lastname: action.lastname }
    case SET_USER_FIRSTNAME:
      return { ...state, firstname: action.firstname }
    case SET_USER_BIRTH:
      return { ...state, birth: action.birth }
    case SET_USER_PHONE:
      return { ...state, phone: action.phone }
    case SET_ANSWERS:
      return { ...state, answers: action.answers }
    case RESET_LOGIN_DATA:
      return initialState
    default:
      return state;
  }
}

export default loginReducer;
