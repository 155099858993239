import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { Fonts } from '../../../utils/Font'
import ScrollViewPage from '../../layout/scrollviews/ScrollViewPage'
import { initApp } from '../../../actions/initAppActions'

class SignIn extends Component {
  openFlags(val) {
    this.setState({ openFlags: val })
  }
  selectCountry(country) {
    this.props.initApp('setCountry', { value: country })
    this.props.openFlags(false)
  }
  bodyContent() {
    return (
      <div style={{ marginTop: -40 }}>
        <List style={{ marginTop: this.props.journeyShow ? 145 : 80 }}>

          {this.props.dataUser.ListCountry.map((country, i) => (
            <div>
              <ListItem button>
                {
                  !country.dial_code ?
                    <Grid container>
                      <Grid xs={12} item style={{ fontFamily: Fonts.MarkOTMedium, paddingLeft: 10, fontWeight: 'Medium' }}>
                        {country.name}
                      </Grid>
                    </Grid>
                    :
                    <Grid container onClick={() => { this.selectCountry(country) }}>
                      <Grid xs={2} item>
                        <img src={'data:image/png;base64,' + country.flag} style={{ width: 24, height: 16, borderRadius: 2 }} alt="" />
                      </Grid>
                      <Grid xs={9} item style={{ fontFamily: Fonts.MarkOT }}>
                        {country.name}
                      </Grid>
                      <Grid xs={1} item>
                        {
                          this.props.dataUser.country.name === country.name ?
                            <Icon style={{ fontSize: 11 }}>check</Icon>
                            :
                            null
                        }
                      </Grid>

                    </Grid>
                }
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
      </div>
    )
  }
  render() {
    return (
      <div className="">
        <AppBar className={(this.props.journeyShow ? 'marginMenu' : '')}>
          <Toolbar style={{ backgroundColor: 'white', height: 60 }}>
            <IconButton color="inherit" onClick={() => this.props.openFlags(false)} aria-label="Close">
              <Icon style={{ color: 'black' }}>close</Icon>
            </IconButton>
            <Typography variant="h9" color="secondary" style={{ color: 'black', fontFamily: Fonts.MarkOTBold }}>
              Select your country
              </Typography>
          </Toolbar>
        </AppBar>
        <ScrollViewPage
          content={this.bodyContent.bind(this)}
          loader={false}
          bookingFlow={true}
          header={false}
          styleTitle={{ fontSize: 27, fontFamily: Fonts.MarkOTBold }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { initApp })(SignIn);
