import React, { Component } from 'react';
import { connect } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import Icon from '@material-ui/core/Icon';
import { Fonts } from '../../../utils/Font'
import { initApp } from '../../../actions/initAppActions'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
class SignIn extends Component {
  selectDefaultCard(card) {
    this.props.selectDefaultCard(card)
  }
  deleteCard(card) {
    this.props.deleteCard(card)
  }
  clickOnCard(card) {
    this.props.clickOnCard(card)
  }
  render() {
    return (
      <ListItem button onClick={() => this.clickOnCard(this.props.card)}>
        <ListItemIcon>
          {
            this.props.card.brand === 'Visa' ?
              <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/visa.png')} alt="" />
              : this.props.card.brand === 'applePay' ?
                <img style={{ width: 29, height: 18, borderRadius: 1 }} src={require('../../../img/addCB/applePay.png')} alt="" />
                : this.props.card.brand === 'googlePay' ?
                  <img style={{ width: 29, height: 20, borderRadius: 3 }} src={require('../../../img/addCB/googlePay.png')} alt="" />
                  : this.props.card.brand === 'MasterCard' ?
                    <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/mastercard.png')} alt="" />
                    : this.props.card.brand === 'American Express' ?
                      <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/amEx.png')} alt="" />
                      : this.props.card.brand === 'JCB' ?
                        <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/jcb.png')} alt="" />
                        : this.props.card.brand === 'Discover' ?
                          <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/discover.png')} alt="" />
                          : this.props.card.brand === 'Diners Club' ?
                            <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/dinnersClub.png')} alt="" />
                            :
                            <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/creditCard.png')} alt="" />
          }
        </ListItemIcon>
        {
          this.props.card.brand === 'applePay' || this.props.card.brand === 'googlePay' ?
            <div style={styles.text}>{this.props.card.title}</div>
            :
            <div style={styles.text}>{'•••• ' + this.props.card.last4 + '        ' + this.props.card.exp_month + '/' + this.props.card.exp_year}</div>
        }

        <ListItemSecondaryAction style={{ paddingRight: 15 }}>
          {
            this.props.card.id === this.props.dataUser.defaultCB.id ?
              <Icon style={{ color: '#8CB5A1' }}>check</Icon>
              :
              <Icon style={{ color: '#9E9E9E' }}>keyboard_arrow_right</Icon>
          }
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}
const styles = {
  text: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 15,
    color: '#4a4a4a',
  },
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { initApp })(SignIn);
