import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import '../../../css/Home.css';
import HeaderBooking from './HeaderBooking'
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'
import Receiver from '../modulesBooking/Receiver'
import PaymentOptions from '../modulesBooking/PaymentOptions'
import { Fonts } from '../../../utils/Font'
import colors from '../../layout/colors'
import styled from 'styled-components';
import { TextField, NoSsr } from '@material-ui/core';
import ScrollViewPage from '../../layout/scrollviews/ScrollViewPage'
import { Fragment } from 'react';

const StyledTextField = styled(TextField)`
  label.Mui-focused {
    color: ${colors.primary};
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: #eaeaea; 
    }
    &:hover fieldset {
      border-color: ${colors.primaryLight}; 
    }
  }
`;

class Product extends Component {
  async componentWillMount() {
    window.scrollTo(0, 0)
    if (this.props.dataHome.productChosen.type === 'shots' && Number(this.props.dataHome.durationEvent) === 0) {
      this.props.homeAction('setDurationEvent', { value: this.props.dataHome.productChosen.duration.min })
    }

    this.props.homeAction('setPaymentOption', { value: { value: 'single' } })
    this.props.homeAction('setReceiver', { value: 'me' })
  }
  back() {
    const { type } = this.props.dataHome.productChosen;
    if (type === 'cup' || type === 'covid') {
      this.props.bookingFlowAction('setCurrentPage', { value: 'Time' })
    } else if (type === 'btx' || type === 'iv' || type === 'ac') {
      this.props.bookingFlowAction('setCurrentPage', { value: 'ProviderPreference' })
    }
  }
  handleChange = name => event => {
    var val = event.target.value
    if (name === 'invite') {
      this.props.homeAction('setInviteCode', { value: val })
    } else if (name === 'businessName') {
      this.props.homeAction('setBusinessName', { value: val })
    } else if (name === 'businessPhone') {
      this.props.homeAction('setBusinessPhone', { value: val })
    } else if (name === 'notes') {
      this.props.homeAction('setNotes', { value: val })
    }
  }
  async addUnit(value) {
    var currentVal = this.props.dataHome.numberUnit

    var newVal = currentVal + value
    if ((currentVal === 1 && newVal === 2) || (currentVal === 2 && newVal === 1)) {
      this.props.homeAction('setPaymentOption', { value: {} })
    }
    if (this.props.dataHome.productChosen.type === 'covid' && newVal > 1) {
      const paymentOption = { text: "I will pay for everyone", value: "group" }
      this.props.homeAction('setPaymentOption', { value: paymentOption })
    }
    await this.props.homeAction('setNumberUnit', { value: newVal })
    if (this.props.dataHome.productChosen.type  !==  'shots' && this.props.dataHome.productChosen.type  !==  'covid') {
      this.personPicker.loadList()
      this.personPicker2.loadList()
    }
  }
  addDuration(value) {
    var currentVal = this.props.dataHome.durationEvent
    var newVal = currentVal + value
    this.props.homeAction('setDurationEvent', { value: newVal })
  }
  changeReceiver() {
    if (this.props.dataHome.numberUnit > 1 || (this.props.dataHome.numberUnit === 1 && this.props.dataHome.receiver === 'else')) {
      this.personPicker.reset()
    }
  }
  renderPaymentOptions() {
    if (this.props.dataHome.productChosen.type === 'covid') {
      return null;
    }

    return (
      <Fragment>
        {
          this.props.dataHome.productChosen.type === 'shots' ?
            <Grid container style={styles.subtitle2}>
              Select the duration of the event
            </Grid>
            :
            <Grid container style={styles.subtitle2}>
              Who is this {this.props.dataHome.productChosen.categoryNameLow} for?
            </Grid>
        }
        {
          this.props.dataHome.productChosen.type  !==  'shots' ?
            <Receiver onRef={ref => (this.personPicker2 = ref)} changeReceiver={this.changeReceiver.bind(this)} />
            :
            <Grid container style={{ marginTop: 25 }}>
              {
                this.props.dataHome.durationEvent === this.props.dataHome.productChosen.duration.min ?
                  <Grid item xs={3}>
                    <i className="fa fa-2x fa-minus-circle" style={{ color: '#C1DACE' }}></i>
                  </Grid>
                  :
                  <Grid item xs={3} className="pointer" onClick={() => { this.addDuration(-this.props.dataHome.productChosen.duration.increment) }} >
                    <i className="fa fa-2x fa-minus-circle" style={{ color: '#8CB5A1' }}></i>
                  </Grid>
              }
              <Grid item xs={6}>
                {
                  this.props.dataHome.durationEvent  !==  0 ?
                    <div style={styles.subText}>{this.props.dataHome.durationEvent} Minutes</div>
                    :
                    <div style={styles.subTextOff}>Duration</div>
                }
              </Grid>
              {
                this.props.dataHome.durationEvent === this.props.dataHome.productChosen.duration.max ?
                  <Grid item xs={3}>
                    <i className="fa fa-2x fa-plus-circle" style={{ color: '#C1DACE' }}></i>
                  </Grid>
                  :
                  <Grid item xs={3} className="pointer" onClick={() => { this.addDuration(this.props.dataHome.productChosen.duration.increment) }}>
                    <i className="fa fa-2x fa-plus-circle" style={{ color: '#8CB5A1' }}></i>
                  </Grid>
              }
            </Grid>
        }
        {
          this.props.dataHome.productChosen.type  !==  'shots' && (this.props.dataHome.numberUnit > 1 || (this.props.dataHome.numberUnit === 1 && this.props.dataHome.receiver === 'else')) && (
            <Fragment>
              <Grid container style={styles.subtitle2}>
                Select your payment option.
              </Grid>
              <PaymentOptions onRef={ref => (this.personPicker = ref)} />
            </Fragment>
          )
        }
        {
          (this.props.dataHome.numberUnit > 1 || (this.props.dataHome.numberUnit === 1 && this.props.dataHome.receiver === 'else') || this.props.dataHome.productChosen.type === 'shots') && (
            <Fragment>
              <Grid container style={{ ...styles.subtitle, marginTop: 30 }}>
                Create an invite code. Share this with anybody who wants to join the booking.
              </Grid>
              <Grid container justify="center" alignContent="center" style={styles.subtitle}>
                <NoSsr>
                  <StyledTextField
                    style={styles.input}
                    placeholder='Invite code'
                    variant="outlined"
                    autoFocus={true}
                    onChange={this.handleChange('invite')}
                    value={this.props.dataHome.inviteCodeEvent}
                  />
                </NoSsr>
              </Grid>
            </Fragment>
          )
        }
        {
          this.props.dataHome.receiver === 'else' ?
            <div>
              <Grid container style={{ ...styles.subtitle, marginTop: 30 }}>
                Contact number
            </Grid>
              <Grid container justify="center" alignContent="center" style={styles.subtitle}>
                <NoSsr>
                  <StyledTextField
                    style={styles.input}
                    variant="outlined"
                    placeholder='Phone number'
                    onChange={this.handleChange('businessPhone')}
                    value={this.props.dataHome.businessPhone}
                  />
                </NoSsr>
              </Grid>
            </div>
            : null
        }
      </Fragment>
    )
  }
  bodyContent() {
    return (
      <div style={{ marginTop: -40 }}>
        <Grid container style={styles.subtitle}>
          Please select the number of people that will receive treatment as part of this booking.
        </Grid>
        <Grid container row alignContent="center" justify="center" className="borderOff pointer" style={{ width: '96%', marginLeft: '3%', marginTop: 25, borderRadius: 7 }}>
          <Grid container row style={{ margin: 10 }}>
            {
              this.props.dataHome.numberUnit === this.props.dataHome.productChosen.people.min ?
                <Grid container justify="center" alignContent="center" xs={3}>
                  <Grid style={styles.incrementBtn} className="textButton">-</Grid>
                </Grid>
                :
                <Grid container justify="center" alignContent="center" xs={3} className="pointer" onClick={() => { this.addUnit(-this.props.dataHome.productChosen.people.increment) }}>
                  <Grid style={styles.incrementBtn} className="textButton">-</Grid>
                </Grid>
            }
            <Grid container alignContent="center" justify="center" alignItems="center" xs={6}>
              {
                this.props.dataHome.numberUnit === 0 ?
                  <Grid style={styles.subText}>People at event</Grid>
                  : this.props.dataHome.numberUnit === 1 ?
                    <Grid style={styles.subText}>{this.props.dataHome.numberUnit} person</Grid>
                    :
                    <Grid style={styles.subText}>{this.props.dataHome.numberUnit} people</Grid>
              }
            </Grid>
            {
              this.props.dataHome.numberUnit === this.props.dataHome.productChosen.people.max ?
                <Grid container justify="center" alignContent="center" xs={3}>
                  <Grid style={styles.incrementBtn} className="textButton">+</Grid>
                </Grid>
                :
                <Grid container justify="center" alignContent="center" xs={3} className="pointer" onClick={() => { this.addUnit(this.props.dataHome.productChosen.people.increment) }}>
                  <Grid style={styles.incrementBtn} className="textButton">+</Grid>
                </Grid>
            }
          </Grid>
        </Grid>
        {this.renderPaymentOptions()}
      </div>
    )
  }
  render() {
    return (
      <div className="App">
        <HeaderBooking title={'People'} back={this.back.bind(this)} loader={false} journeyShow={this.props.journeyShow} />
        <ScrollViewPage
          content={this.bodyContent.bind(this)}
          loader={false}
          bookingFlow={true}
          header={false}
          styleTitle={{ fontSize: 27, fontFamily: Fonts.MarkOTBold, }}
        />
      </div>
    );
  }
}
const styles = {
  incrementBtn: {
    color: colors.primary,
    fontSize: 23,
    fontFamily: Fonts.MarkOTBold,
  },
  buttonBack: {
    position: 'absolute',
    top: 75,
    left: 15,
    zIndex: 20,
  },
  title: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 23,
    paddingLeft: 15,
    color: '#4a4a4a',
    marginTop: 10,
  },
  subText: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 16,
    color: '#4a4a4a',
    textAlign: 'center'
  },
  subTextOff: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 16,
    fontWeight: 'normal',
    color: '#CACACF',
  },
  subtitle: {
    fontFamily: Fonts.MarkOT,
    fontSize: 16,
    color: colors.subtitle,
    fontWeight: 'normal',
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 15,
    marginBottom: 5,
    textAlign: 'left',
  },
  subtitle2: {
    fontFamily: Fonts.MarkOT,
    fontSize: 16,
    fontWeight: 'normal',
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 25,
    marginBottom: 5,
    color: colors.subtitle,
    textAlign: 'left',
  },
  secondRes: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  mainRes: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 17,
    fontWeight: 'bold',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  rowResult: {
    flex: 1, borderBottomWidth: 1, borderColor: '#EAEAEA', paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15
  },
  textIngredient: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
    paddingLeft: 15,
  },
  textBenefit: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    marginTop: 10,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    paddingLeft: 15,
    height: 30,
  },
  titleBenefit: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 19,
    fontWeight: 'Bold',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
  },
  row: {
    textAlign: 'left',
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    color: '#4a4a4a',
    paddingTop: 5
  },
  boxPrice: {
    width: 70,
    height: 25,
    borderRadius: 15,
    bottom: 20,
    right: 15,
    backgroundColor: '#FAF9F9',
    alignItems: 'center',
    fontFamily: Fonts.MarkOT,
    justifyContent: 'center',
    borderColor: '#EAEAEA',
    color: '#8CB5A1',
    paddingTop: 9,
  },
  input: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    width: '96%',
    marginLeft: '3%'
  },
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(Product);
