import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import '../../../css/Home.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Fonts } from '../../../utils/Font'
import colors from '../../layout/colors';

class Product extends Component {
  render() {
    return (
      <Grid container alignItems="center" justify="center" className={(this.props.journeyShow ? 'viewHeaderBooking2' : 'viewHeaderBooking')}>
        <Grid item xs={2} md={1} onClick={() => { this.props.back() }} className="pointer">
          <Icon style={{ color: colors.title }}>{(!this.props.closeIcon) ? 'arrow_back' : 'clear'}</Icon>
        </Grid>
        <Grid item xs={8} md={9} style={styles.title}>
          {this.props.title}
        </Grid>
        <Grid item xs={2} md={2}>
          {
            this.props.loader ?
              <CircularProgress style={{ color: colors.primary }} size={20} opacity={50} />
              : null
          }
        </Grid>
      </Grid>
    );
  }
}
const styles = {
  title: {
    fontFamily: Fonts.MarkOTBold,
    textAlign: 'left',
    color: colors.title,
    fontSize: 18,
    marginTop: -3,
  },
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location,
  dataFlow: state.bookingFlow
})
export default connect(mapStateToProps, {})(Product);
