import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';

import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import configureStore from './store';

import App from './app';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import branch from 'branch-sdk'
import ReactPixel from 'react-facebook-pixel';

branch.init('key_live_oiHkmoajwxYBJ0HDyJhaCbjaxFjHh811',
  {
    metadata: {
      'foo': 'bar'
    }
  }
);
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#8CB5A1'
    },
    secondary: {
      main: '#000000'
    },
    textSecondary: {
      main: '#000000'
    },
  },
})

ReactPixel.init('223744189158412');

ReactDOM.render(
  <Provider store={configureStore()}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
