import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ReactPixel from 'react-facebook-pixel';
import '../../../css/Home.css';
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import CircularProgress from '@material-ui/core/CircularProgress';
import FooterCheckout from './FooterCheckout';

import Dialog from '@material-ui/core/Dialog';
import Alert from '../../layout/alerts/AlertSubtitle'
import ButtonBooking from '../../layout/buttons/ButtonBooking'
import mixpanel from 'mixpanel-browser'
const mixPanelToken = 'bef3efb4cd721628459b35e7a4c1abb6'
mixpanel.init(mixPanelToken);
class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'product',
      product: {},
      loader: false,
      showAlert: false,
      titleAlert: '',
      messageAlert: '',
    };
  }
  async next() {
    var allInviteCodes;
    var arraycontainsID;

    if (this.props.dataFlow.currentPage === 'Product') {
      this.props.homeAction('setSubscribe', { value: false })
      this.props.homeAction('setCreditCardRequired', { value: true })
      var nurseInfo = {
        nurseID: '',
        nurseInfo: {}
      }
      if (this.props.category.unitPrice) {
        this.props.homeAction('setNumberUnitOrderedPerPatient', { value: this.props.category.minimumUnitsReceivedPerUser })
      }
      this.props.homeAction('setAttendeeProduct', { value: {} })
      this.props.homeAction('setSecondaryProducts', { value: [] })
      this.props.homeAction('setAdditionalProduct', { value: {} })
      this.props.homeAction('setNurseInfo', { value: nurseInfo })
      this.props.homeAction('setUsersConfirmed', { value: [] })
      this.props.homeAction('setInviteCode', { value: '' })
      await this.props.homeAction('setProductChosen', { value: this.props.product })
      mixpanel.track('Click on next to location', { 'userID': this.props.dataUser.userID });
      ReactPixel.track('Click on next to location', { 'userID': this.props.dataUser.userID });
      this.props.bookingFlowAction('setCurrentPage', { value: 'Location' })

    } else if (this.props.dataFlow.currentPage === 'Location') {
      if (this.props.category.enableProvidersPreference3 !== false) {
        mixpanel.track('Click on next to provider picker', { 'userID': this.props.dataUser.userID });
        ReactPixel.track('Click on next to provider picker', { 'userID': this.props.dataUser.userID });
        this.props.bookingFlowAction('setCurrentPage', { value: 'ProviderPreference' })
      } else {
        mixpanel.track('Click on next to date picker', { 'userID': this.props.dataUser.userID });
        ReactPixel.track('Click on next to date picker', { 'userID': this.props.dataUser.userID });
        this.props.bookingFlowAction('setCurrentPage', { value: 'Time' })
      }
    } else if (this.props.dataFlow.currentPage === 'ProviderPreference') {
      mixpanel.track('Click on next to date picker', { 'userID': this.props.dataUser.userID });
      ReactPixel.track('Click on next to date picker', { 'userID': this.props.dataUser.userID });
      this.props.bookingFlowAction('setCurrentPage', { value: 'Time' })
    } else if (this.props.dataFlow.currentPage === 'AddSecondary') {
      mixpanel.track('Click on next to location', { 'userID': this.props.dataUser.userID });
      ReactPixel.track('Click on next to location', { 'userID': this.props.dataUser.userID });
      this.props.bookingFlowAction('setCurrentPage', { value: 'Location' })
    } else if (this.props.dataFlow.currentPage === 'Time') {
      await this.props.doneTime()
      mixpanel.track('Click on next to person', { 'userID': this.props.dataUser.userID });
      ReactPixel.track('Click on next to location', { 'userID': this.props.dataUser.userID });
      this.props.bookingFlowAction('setCurrentPage', { value: 'Person' })
    } else if (this.props.dataFlow.currentPage === 'Person') {
      if (this.props.dataHome.productChosen.type === 'covid') {
        const paymentOption = { text: "I will pay for everyone", value: "group" }
        this.props.homeAction('setPaymentOption', { value: paymentOption })
      }

      if (this.props.dataHome.productChosen.type === 'shots') {
        mixpanel.track('Click on next to booster', { 'userID': this.props.dataUser.userID });
        ReactPixel.track('Click on next to booster', { 'userID': this.props.dataUser.userID });
        this.props.bookingFlowAction('setCurrentPage', { value: 'Booster' })
      } else {
        mixpanel.track('Click on next to more info', { 'userID': this.props.dataUser.userID });
        ReactPixel.track('Click on next to more info', { 'userID': this.props.dataUser.userID });
        this.setState({ loader: true })

        allInviteCodes = this.props.dataUser.instaviceVariables.allInviteCodes || []
        arraycontainsID = (allInviteCodes.indexOf(this.props.dataHome.inviteCodeEvent.toLowerCase()) > -1)
        if (!arraycontainsID) {
          this.setState({ loader: false })
          if (this.props.category.enableAddProduct) {
            if (this.props.dataHome.numberUnit === 1) {
              mixpanel.track('Click on next to add additional', { 'userID': this.props.dataUser.userID });
              ReactPixel.track('Click on next to add additional', { 'userID': this.props.dataUser.userID });
              await this.loadDataProduct()
              this.props.bookingFlowAction('setCurrentPage', { value: 'AddAdditional' })
            } else {
              mixpanel.track('Click on next to add products', { 'userID': this.props.dataUser.userID });
              ReactPixel.track('Click on next to add products', { 'userID': this.props.dataUser.userID });
              await this.loadDataProduct()
              this.props.bookingFlowAction('setCurrentPage', { value: 'AddProduct' })
            }
          } else if (this.props.category.enableAddSecondary) {
            await this.props.homeAction('setSecondaryProducts', { value: this.props.category.secondaryProducts.list.reverse() })
            mixpanel.track('Click on next to add additional', { 'userID': this.props.dataUser.userID });
            ReactPixel.track('Click on next to add additional', { 'userID': this.props.dataUser.userID });
            await this.loadDataProduct()
            this.props.bookingFlowAction('setCurrentPage', { value: 'AddAdditional' })
          } else {
            if (this.props.category.unitPrice) {
              await this.loadDataProduct()
              this.props.bookingFlowAction('setCurrentPage', { value: 'UnitSelection' })
            } else {
              mixpanel.track('Click on next to checkout', { 'userID': this.props.dataUser.userID });
              ReactPixel.track('Click on next to checkout', { 'userID': this.props.dataUser.userID });
              await this.loadDataProduct()
              this.props.bookingFlowAction('setCurrentPage', { value: 'Checkout' })
            }
          }
        } else {
          this.setState({ loader: false })
          this.setState({ showAlert: true })
          this.setState({ titleAlert: 'Invite code already used!' })
          this.setState({ messageAlert: 'Please pick a new one.' })
        }
      }
    } else if (this.props.dataFlow.currentPage === 'Booster') {
      mixpanel.track('Click on next to more info', { 'userID': this.props.dataUser.userID });
      ReactPixel.track('Click on next to more info', { 'userID': this.props.dataUser.userID });
      this.props.bookingFlowAction('setCurrentPage', { value: 'Checkout' })
    } else if (this.props.dataFlow.currentPage === 'MoreInfo') {
      this.setState({ loader: true })
      var optionObj = {}
      if (this.props.dataHome.numberUnit === 1 && this.props.dataHome.receiver !== 'else' && this.props.dataHome.productChosen.type !== 'shots') {
        optionObj.text = 'Everyone pays for themselves'
        optionObj.value = 'single'
        this.props.homeAction('setPaymentOption', { value: optionObj })
        this.props.bookingFlowAction('setCurrentPage', { value: 'Checkout' })
      } else if (this.props.dataHome.productChosen.type === 'shots') {
        optionObj.text = 'Everyone pays for themselves'
        optionObj.value = 'single'
        this.props.homeAction('setPaymentOption', { value: optionObj })
        this.props.bookingFlowAction('setCurrentPage', { value: 'Checkout' })
      }
      allInviteCodes = this.props.dataUser.instaviceVariables.allInviteCodes || []
      arraycontainsID = (allInviteCodes.indexOf(this.props.dataHome.inviteCodeEvent.toLowerCase()) > -1)
      if (!arraycontainsID) {
        this.setState({ loader: false })
        await this.loadDataProduct()
        this.props.bookingFlowAction('setCurrentPage', { value: 'Checkout' })
      } else {
        this.setState({ loader: false })
        this.setState({ showAlert: true })
        this.setState({ titleAlert: 'Invite code already used!' })
        this.setState({ messageAlert: 'Please pick a new one.' })
      }
    } else if (this.props.dataFlow.currentPage === 'AddProduct') {
      if (this.props.dataHome.subscribe) {
        mixpanel.track('Click on next to checkout', { 'userID': this.props.dataUser.userID });
        ReactPixel.track('Click on next to checkout', { 'userID': this.props.dataUser.userID });
        this.props.bookingFlowAction('setCurrentPage', { value: 'Checkout' })
      } else {
        var productsSelected = Object.values(this.props.dataHome.attendeeProduct).filter(product => product.numberAdded !== 0)
        var numberBagsOrdered = 0
        for (var j in productsSelected) {
          numberBagsOrdered = numberBagsOrdered + Number(productsSelected[j].numberAdded)
        }
        var number;
        if (numberBagsOrdered > this.props.dataHome.numberUnit) {
          number = numberBagsOrdered - Number(this.props.dataHome.numberUnit)
          await this.setState({ titleAlert: 'You have selected too many products.' })
          await this.setState({ messageAlert: 'Please reduce the number of ' + this.props.category.unit + 's by ' + number + '.' })
          await this.setState({ showAlert: true })
        } else if (numberBagsOrdered < this.props.dataHome.numberUnit) {
          number = Number(this.props.dataHome.numberUnit) - numberBagsOrdered
          await this.setState({ titleAlert: 'You have to select one product per attendee.' })
          await this.setState({ messageAlert: 'Please add ' + number + ' more.' })
          await this.setState({ showAlert: true })
        } else {
          mixpanel.track('Click on next to add additional', { 'userID': this.props.dataUser.userID });
          ReactPixel.track('Click on next to add additional', { 'userID': this.props.dataUser.userID });
          this.props.bookingFlowAction('setCurrentPage', { value: 'AddAdditional' })
        }
      }
    } else if (this.props.dataFlow.currentPage === 'AddAdditional') {
      var check = true
      var additionalProduct = this.props.dataHome.additionalProduct
      for (var l in additionalProduct) {
        var product = { ...additionalProduct[l] }
        check = true
        if (product.numberAdded > this.props.dataHome.numberUnit) {
          var reduce = product.numberAdded - this.props.dataHome.numberUnit
          var nameProduct = product.name.toLowerCase()
          await this.setState({ titleAlert: 'You can add a maximum of one ' + nameProduct + ' per person.' })
          await this.setState({ messageAlert: 'Please reduce by ' + reduce + '.' })
          await this.setState({ showAlert: true })
          check = false
          break
        }
      }
      if (check) {
        mixpanel.track('Click on next to checkout', { 'userID': this.props.dataUser.userID });
        ReactPixel.track('Click on next to checkout', { 'userID': this.props.dataUser.userID });
        this.props.bookingFlowAction('setCurrentPage', { value: 'Checkout' })
      }
    } else if (this.props.dataFlow.currentPage === 'UnitSelection') {
      mixpanel.track('Click on next to checkout', { 'userID': this.props.dataUser.userID });
      ReactPixel.track('Click on next to checkout', { 'userID': this.props.dataUser.userID });
      this.props.bookingFlowAction('setCurrentPage', { value: 'Checkout' })
    }
  }
  loadDataProduct() {
    var list = {}
    if (!!this.props.category.list) {
      var totalList = Object.values(this.props.category.list).slice(0)
      totalList = totalList.reverse()
      for (var i in totalList) {
        var product = { ...totalList[i] }
        var productSaved = product
        productSaved.numberAdded = 0
        productSaved.name = product.name
        productSaved.image = product.image
        var price = 0
        if ((product.pricesGroup.filter(price => price.value === Number(this.props.dataHome.numberUnit)).length !== 0)) {
          price = product.pricesGroup.filter(price => price.value === Number(this.props.dataHome.numberUnit))[0].price
        } else {
          price = product.pricesGroup.filter(price => price.type === 'greater')[0].price
        }
        if (product.name === this.props.dataHome.productChosen.name) {
          productSaved.numberAdded = this.props.dataHome.numberUnit
        }
        productSaved.price = price
        list = {
          ...list,
          [i]: productSaved
        }
      }
    } else {
      var product2 = this.props.dataHome.productChosen
      product2.numberAdded = this.props.dataHome.numberUnit

      if ((this.props.product.pricesGroup.filter(price => price.value === Number(this.props.dataHome.numberUnit)).length !== 0)) {
        product2.price = this.props.product.pricesGroup.filter(price => price.value === Number(this.props.dataHome.numberUnit))[0].price
      } else {
        product2.price = this.props.product.pricesGroup.filter(price => price.type === 'greater')[0].price
      }

      list = {
        0: product2
      }
    }
    this.props.homeAction('setAttendeeProduct', { value: list })
  }
  closeAlert() {
    this.setState({ showAlert: false })
  }
  openPayments() {
    this.props.openPayments()
  }
  render() {
    return (
      <Grid container className="viewFooterBooking shadeOnSubmit" justify="center">
        {
          this.state.loader ?
            <div className="Vbtn">
              <CircularProgress style={{ color: 'white' }} size={24} opacity={50} />
            </div>
            : this.props.dataFlow.currentPage === 'Product' ?
              <ButtonBooking
                bookingFlow
                text="Request a time"
                click={() => this.next()}
              />
              : this.props.dataFlow.currentPage === 'Location' ?
                <ButtonBooking
                  off={!this.props.dataLocation.locationEntered}
                  bookingFlow
                  text="Next"
                  click={() => this.next()}
                />
                : this.props.dataFlow.currentPage === 'AddSecondary' ?
                  <ButtonBooking
                    bookingFlow
                    text={(this.props.dataHome.secondaryProducts.filter(product => product.selected).length === 0) ? 'Skip' : 'Next'}
                    click={() => this.next()}
                  />
                  : this.props.dataFlow.currentPage === 'Time' ?
                    <ButtonBooking
                      bookingFlow
                      text="Next"
                      click={() => this.next()}
                    />
                    : this.props.dataFlow.currentPage === 'Person' &&
                      (((this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.productChosen.type !== 'covid') &&
                        (this.props.dataHome.numberUnit > 1 ||
                          (this.props.dataHome.numberUnit === 1 && this.props.dataHome.receiver === 'else')) &&
                        (!this.props.dataHome.paymentOption.value ||
                          !this.props.dataHome.paymentOption.text ||
                          this.props.dataHome.inviteCodeEvent === '' ||
                          (this.props.dataHome.receiver === 'else' && this.props.dataHome.businessPhone === ''))) ||
                        (this.props.dataHome.productChosen.type === 'shots' &&
                          (this.props.dataHome.durationEvent === 0 ||
                            this.props.dataHome.numberUnit === 0))) ?
                      <ButtonBooking
                        off
                        bookingFlow
                        text="Next"
                      />
                      : this.props.dataFlow.currentPage === 'Person' ?
                        <ButtonBooking
                          bookingFlow
                          text="Next"
                          click={() => this.next()}
                        />
                        : this.props.dataFlow.currentPage === 'UnitSelection' ?
                          <ButtonBooking
                            bookingFlow
                            text="Next"
                            click={() => this.next()}
                          />
                          : this.props.dataFlow.currentPage === 'Booster' && this.props.dataHome.numberBoosters === 0 ?
                            <ButtonBooking
                              off
                              bookingFlow
                              text="Next"
                            />
                            : this.props.dataFlow.currentPage === 'Booster' ?
                              <ButtonBooking
                                bookingFlow
                                text="Next"
                                click={() => this.next()}
                              />
                              : this.props.dataFlow.currentPage === 'MoreInfo' && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.numberUnit === 1 && this.props.dataHome.receiver === 'me' && this.props.dataHome.notes === '' ?
                                <ButtonBooking
                                  bookingFlow
                                  text="Skip"
                                  click={() => this.next()}
                                />
                                : this.props.dataFlow.currentPage === 'MoreInfo' && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.numberUnit === 1 && this.props.dataHome.receiver === 'me' && this.props.dataHome.notes !== '' ?
                                  <ButtonBooking
                                    bookingFlow
                                    text="Next"
                                    click={() => this.next()}
                                  />
                                  : this.props.dataFlow.currentPage === 'MoreInfo' && ((this.props.dataHome.productChosen.type !== 'shots' && (this.props.dataHome.inviteCodeEvent === '' || (this.props.dataHome.businessPhone === '' && this.props.dataHome.receiver === 'else'))) || (this.props.dataHome.productChosen.type === 'shots' && this.props.dataHome.inviteCodeEvent === '')) ?
                                    <ButtonBooking
                                      off
                                      bookingFlow
                                      text="Next"
                                    />
                                    : this.props.dataFlow.currentPage === 'MoreInfo' ?
                                      <div
                                        className="Vbtn"
                                        onClick={() => this.next()}>
                                        <p>Next</p>
                                      </div>
                                      : this.props.dataFlow.currentPage === 'AddProduct' && this.props.dataHome.subscribe && !this.props.dataHome.productChosen.name ?
                                        <ButtonBooking
                                          off
                                          bookingFlow
                                          text="Next"
                                        />
                                        : this.props.dataFlow.currentPage === 'AddProduct' ?
                                          <ButtonBooking
                                            bookingFlow
                                            text="Next"
                                            click={() => this.next()}
                                          />
                                          : this.props.dataFlow.currentPage === 'AddAdditional' && Object.values(this.props.dataHome.additionalProduct).filter(product => product.numberAdded !== 0).length === 0 && Object.values(this.props.dataHome.secondaryProducts).filter(product => product.selected).length === 0 ?
                                            <ButtonBooking
                                              bookingFlow
                                              text="Skip"
                                              click={() => this.next()}
                                            />
                                            : this.props.dataFlow.currentPage === 'AddAdditional' ?
                                              <ButtonBooking
                                                bookingFlow
                                                text="Next"
                                                click={() => this.next()}
                                              />
                                              : this.props.dataFlow.currentPage === 'Checkout' ?
                                                <FooterCheckout pricing={this.props.pricing} openPayments={this.openPayments.bind(this)} journeyShow={this.props.journeyShow} />
                                                : null
        }

        <Dialog
          open={this.state.showAlert}
          onBackdropClick={() => this.setState({ showAlert: false })}>
          <Alert
            textButtonSubmit="Got it!"
            title={this.state.titleAlert}
            subtitle={this.state.messageAlert}
            close={() => this.closeAlert()} />
        </Dialog>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location,
  dataFlow: state.bookingFlow
})
export default connect(mapStateToProps, { bookingFlowAction, homeAction })(Product);
