import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import '../../../css/Home.css';
import Divider from '@material-ui/core/Divider'
import { Fonts } from '../../../utils/Font'
import colors from '../../layout/colors'
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showShare: false,
      loaders: {},
      showAppt: false,
      link: '',
      showMore: false
    };
  }
  callNurse(phone) {
    window.open("tel:" + phone);
  }
  render() {
    return (
      <div>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} style={{ textAlign: 'left', paddingLeft: 15 }}>
            {
              !this.props.data.infoOffer.subscribe ?
                <div style={styles.title2}>Boosters selected</div>
                :
                <div style={styles.title2}>Boosters offered</div>
            }
          </Grid>
        </Grid>
        {this.props.data.infoOffer.boostersSelected.map((booster, i) => (
          <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4 }}>
            <Grid item xs={8} style={{ textAlign: 'left', paddingLeft: 15 }}>
              <div style={styles.divCheckout}>{booster.name}</div>
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'right', paddingRight: 15 }}>
              {
                !!booster.oldPrice ?
                  <div style={styles.divCheckoutOld}>${booster.oldPrice}</div>
                  : null
              }
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'right', paddingRight: 15 }}>
              <div style={styles.divCheckout}>${booster.price}</div>
            </Grid>
          </Grid>
        ))}

        {
          !this.props.data.infoOffer.subscribe ?
            <div>
              <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 15, marginBottom: 15 }} />
              <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4 }}>
                <Grid item xs={12} style={{ textAlign: 'left', paddingLeft: 15 }}>
                  <div style={styles.title2}>Event information</div>
                </Grid>
              </Grid>
              <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4 }}>
                <Grid item xs={9} style={{ textAlign: 'left', paddingLeft: 15 }}>
                  <div style={styles.divCheckout}>Estimated number of people</div>
                </Grid>
                <Grid item xs={3} style={{ textAlign: 'right', paddingRight: 15 }}>
                  <div style={styles.divCheckout}>{this.props.data.infoOffer.numberUnit}</div>
                </Grid>
              </Grid>
              <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4 }}>
                <Grid item xs={7} style={{ textAlign: 'left', paddingLeft: 15 }}>
                  <div style={styles.divCheckout}>Invite code</div>
                </Grid>
                <Grid item xs={5} style={{ textAlign: 'right', paddingRight: 15 }}>
                  <div style={styles.divCheckout}>{this.props.data.inviteCode}</div>
                </Grid>
              </Grid>
              <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4 }}>
                <Grid item xs={7} style={{ textAlign: 'left', paddingLeft: 15 }}>
                  {
                    this.props.data.status === 'past' ?
                      <div style={styles.divCheckout}>People attended</div>
                      :
                      <div style={styles.divCheckout}>Confirmed attendance</div>
                  }
                </Grid>
                <Grid item xs={5} style={{ textAlign: 'right', paddingRight: 15 }}>
                  {
                    !this.props.data.usersConfirmed ?
                      <div style={styles.divCheckout}>0</div>
                      : this.props.data.status === 'past' ?
                        <div style={styles.divCheckout}>{Object.values(this.props.data.usersConfirmed).filter(user => (user.treatment.result === 'treated')).length}</div>
                        :
                        <div style={styles.divCheckout}>{Object.values(this.props.data.usersConfirmed).length}</div>
                  }
                </Grid>
              </Grid>
            </div>
            : null
        }

        <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 15, marginBottom: 15 }} />

        {
          !!this.props.data.nurseID ?
            <div>
              <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4 }}>
                <Grid item xs={12} style={{ textAlign: 'left', paddingLeft: 15 }}>
                  <div style={styles.title2}>Nurse information</div>
                </Grid>
              </Grid>
              <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4 }}>
                <Grid item xs={4} style={{ textAlign: 'left', paddingLeft: 15 }}>
                  <div style={styles.divCheckout}>Name</div>
                </Grid>
                <Grid item xs={8} style={{ textAlign: 'right', paddingRight: 15 }}>
                  <div style={styles.divCheckout}>{this.props.data.nurseInfo.firstname} {this.props.data.nurseInfo.lastname}</div>
                </Grid>
              </Grid>
              <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4 }}>
                <Grid item xs={8} style={{ textAlign: 'left', paddingLeft: 15 }}>
                  <div style={styles.divCheckout}>Phone number</div>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'right', paddingRight: 15 }} onClick={() => { this.callNurse(this.props.data.nurseInfo.phone) }}>
                  <div style={styles.divPhone}>{this.props.data.nurseInfo.phone}</div>
                </Grid>
              </Grid>
            </div>
            : null
        }

        {
          !!this.props.data.invoice  && this.props.data.infoOffer.subscribe ?
            <div>
              <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4 }}>
                <Grid item xs={12} style={{ textAlign: 'left', paddingLeft: 15 }}>
                  <div style={styles.title2}>Invoice</div>
                </Grid>
              </Grid>
              <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4 }}>
                <Grid item xs={4} style={{ textAlign: 'left', paddingLeft: 15 }}>
                  <div style={styles.divCheckout}>Total price</div>
                </Grid>
                <Grid item xs={8} style={{ textAlign: 'right', paddingRight: 15 }}>
                  <div style={styles.divCheckout}>${this.props.data.invoice.totalPrice}</div>
                </Grid>
              </Grid>
              <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4 }}>
                <Grid item xs={7} style={{ textAlign: 'left', paddingLeft: 15 }}>
                  <div style={styles.divCheckout}>Credits</div>
                </Grid>
                <Grid item xs={5} style={{ textAlign: 'right', paddingRight: 15 }}>
                  <div style={styles.divCheckout}>${this.props.data.invoice.credits}</div>
                </Grid>
              </Grid>

              <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 15, marginBottom: 15 }} />

              <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4 }}>
                <Grid item xs={8} style={{ textAlign: 'left', paddingLeft: 15 }}>
                  <div style={[styles.divCheckout, { fontWeight: 'bold' }]}>Credit card charge</div>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'right', paddingRight: 15 }}>
                  <div style={[styles.divCheckout, { fontWeight: 'bold' }]}>${Math.max(0, this.props.data.invoice.totalPrice - this.props.data.invoice.credits)}</div>
                </Grid>
              </Grid>

              <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 15, marginBottom: 15 }} />

              <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4 }}>
                <Grid item xs={12} style={{ textAlign: 'left', paddingLeft: 15 }}>
                  <div style={styles.title2}>Treatment received</div>
                </Grid>
              </Grid>
              <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4 }}>
                <Grid item xs={5} style={{ textAlign: 'left', paddingLeft: 15 }}>
                  <div style={styles.divCheckout}>Product</div>
                </Grid>
                <Grid item xs={7} style={{ textAlign: 'right', paddingRight: 15 }}>
                  <div style={styles.divCheckout}>{this.props.data.invoice.treatment.selectedProduct}</div>
                </Grid>
              </Grid>
              <Grid container justify="center" alignItems="center" style={{ height: 25, marginTop: 4 }}>
                <Grid item xs={5} style={{ textAlign: 'left', paddingLeft: 15 }}>
                  <div style={styles.divCheckout}>Place</div>
                </Grid>
                <Grid item xs={7} style={{ textAlign: 'right', paddingRight: 15 }}>
                  {
                    !this.props.data.invoice.treatment.selectedTreatment2 ?
                      <div style={styles.divCheckout}>{this.props.data.invoice.treatment.treatment.two}</div>
                      :
                      <div style={styles.divCheckout}>{this.props.data.invoice.treatment.selectedTreatment2}</div>
                  }
                </Grid>
              </Grid>
            </div>
            : null
        }
      </div>
    );
  }
}

const styles = {
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  shareButton: {
    position: 'absolute',
    top: 10,
    width: 30,
    height: 30,
    zIndex: 400,
    left: window.innerWidth - 70
  },
  divider: {
    width: '90vw',
    height: 1,
    marginLeft: '5vw',
    backgroundColor: '#eaeaea',
    marginTop: 20,
    marginBottom: 10,
  },
  divPhone: {
    color: colors.primary,
    fontSize: 16,
    divDecorationLine: 'underline',
    fontFamily: Fonts.MarkOT,
  },
  divCheckout: {
    color: colors.subtitle,
    fontSize: 16,
    fontFamily: Fonts.MarkOT,

  },
  divCheckoutOld: {
    color: colors.secondary,
    fontSize: 16,
    fontFamily: Fonts.MarkOT,
    divDecorationLine: 'line-through'
  },
  title2: {
    color: colors.title,
    fontSize: 16,
    fontFamily: Fonts.MarkOTBold
  },
  divHeader: {
    color: 'white', fontFamily: Fonts.MarkOTMedium
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, {})(Home);
