import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ReactPixel from 'react-facebook-pixel';
import '../../css/Home.css';
import CardAppts from './CardAppts.js';
import Divider from '@material-ui/core/Divider'
import Appt from './Appt';
import axios from 'axios'
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { Fonts } from '../../utils/Font'
import colors from '../layout/colors'
import ScrollViewPage from '../layout/scrollviews/ScrollViewPage'
function Transition(props) {
  return <Slide direction="left" {...props} />;
}

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaders: {},
      apptSelected: {},
      showAppt: false
    };
  }
  componentWillMount() {
    window.scrollTo(0, 0)
    this.resetLoaders()
    ReactPixel.pageView()
  }
  async openDetailAppt(val, userOfferID) {
    if (val) {
      await this.setState({ apptSelected: userOfferID })
    }
    this.setState({ showAppt: val })
  }
  resetLoaders() {
    var loaders = {}
    if (!!this.props.dataUser.listAppts) {
      for (var i in this.props.dataUser.listAppts) {
        loaders = {
          ...loaders,
          [i]: {
            loader: false
          }
        }
      }
    }
    this.setState({ loaders: loaders })
  }
  async cancelAppt(offerID, keyAppt) {
    var loaders = this.state.loaders
    loaders = {
      ...loaders,
      [keyAppt]: {
        loader: true
      }
    }
    await this.setState({ loaders: loaders })
    var url = 'https://us-central1-instabo-production.cloudfunctions.net/cancelAppt'
    await axios.get(url, {
      params: {
        offerID: offerID
      }
    })
    this.resetLoaders()
  }
  closeAppt() {
    this.setState({ showAppt: false })
  }
  newApptBtn() {
    return (
      <Grid container direction='row' justify='flex-end' style={{ marginTop: 0 }}>
        <Grid item textAlign='center' className="pointer" style={styles.buttonNew} onClick={() => this.props.history.push('/')}>
          <div style={{ fontFamily: Fonts.MarkOTBold, color: 'white', paddingTop: 5 }}>New</div>
        </Grid>
      </Grid>
    )
  }
  bodyContent() {
    return (
      <div style={{ width: '95vw', maxWidth: 600, margin: '-15px auto 0' }}>
        <Grid container justify='center'>
          {
            !this.props.dataUser.infoUser.appointments ?
              <div>
                <img height="100" width="100" src={require('../../img/appts/awaiting.png')} alt="" />
              </div>
              :
              <div style={styles.center}>
                {Object.values(this.props.dataUser.listAppts).map((appt, i) => (
                  <div>
                    <CardAppts
                      loaders={this.state.loaders}
                      openDetailAppt={this.openDetailAppt.bind(this)}
                      data={appt}
                      userOfferID={i} key={i}
                      onRef={ref => (this.cardRef = ref)}
                    />
                    <Divider />
                  </div>
                ))}
              </div>
          }
        </Grid>

        <Dialog
          fullScreen
          open={this.state.showAppt}
          TransitionComponent={Transition}
        >
          <Appt close={this.openDetailAppt.bind(this)} data={this.props.dataUser.listAppts[this.state.apptSelected]} keyAppt={this.state.apptSelected} cancelAppt={this.cancelAppt.bind(this)} journeyShow={this.props.journeyShow} />
        </Dialog>
      </div>
    )
  }
  render() {
    return (
      <div className="App">
        <ScrollViewPage
          content={this.bodyContent.bind(this)}
          title="Appointments"
          newAppt={this.newApptBtn.bind(this)}
          loader={false}
          header={false}
          styleTitle={{ fontSize: 27, fontFamily: Fonts.MarkOTBold, }}
        />
      </div>
    );
  }
}

const styles = {
  center: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '96%',
    marginLeft: '3%'
  },
  buttonNew: {
    backgroundColor: colors.primary,
    marginRight: '5vw',
    minWidth: 70,
    height: 30,
    borderRadius: 5,
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, {})(Home);
