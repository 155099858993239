import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import '../../../css/Home.css';
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'

class Product extends Component {
  componentWillMount() {
    if (this.props.dataHome.boosters.length === 0) {
      const boosters = this.props.dataUser.instaviceVariables.boosters.map(a => {
        let booster = { ...a };
        booster.selected = false
        booster.quantity = 1
        return booster;
      });
      this.setState({ boosters: boosters })
    } else {
      this.setState({ boosters: this.props.dataHome.boosters })
    }
  }
  async tick(index) {
    var boosters = this.state.boosters
    boosters[index].selected = !boosters[index].selected
    await this.setState({ boosters: boosters })
    await this.props.homeAction('setBoosters', { boosters: this.state.boosters })
  }
  render() {
    return (
      <div style={{ marginTop: 15 }}>
        {Object.values(this.state.boosters).map((product, i) => (
          <Grid container onClick={() => this.tick(i)} style={{ height: 40 }} alignItems="center" justify="center">
            <Grid item xs={3} style={{ paddingLeft: 20 }}>
              {
                product.selected ?
                  <div style={styles.buttonOn}>
                    <img style={{ width: 12, height: 12 }} src={require('../../../img/appts/tick.png')} alt="" />
                  </div>
                  :
                  <div style={styles.buttonOff}></div>
              }
            </Grid>
            <Grid item xs={9} style={styles.subtitle}>
              {product.name} shot
            </Grid>
          </Grid>
        ))}
      </div>
    );
  }
}
const styles = {
  buttonBack: {
    position: 'absolute',
    top: 75,
    left: 15,
    zIndex: 20,
  },
  buttonOn: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 22,
    width: 25,
    borderRadius: 6,
    borderColor: '#C1DACE',
    backgroundColor: '#C1DACE',
    borderWidth: 1.5,
    paddingTop: 3,
  },
  buttonOff: {
    height: 22,
    width: 25,
    borderRadius: 6,
    borderColor: '#A8CDBB',
    backgroundColor: '#eaeaea',
    borderWidth: 1.5,
    paddingTop: 4,
  },
  title: {
    fontFamily: 'Avenir',
    fontSize: 23,
    fontWeight: 'Bold',
    paddingLeft: 15,
    color: '#4a4a4a',
    marginTop: 10,
  },
  subtitle: {
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'normal',
    textAlign: 'left',
    color: '#4a4a4a',
  },
  secondRes: {
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  mainRes: {
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'bold',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  rowResult: {
    flex: 1, borderBottomWidth: 1, borderColor: '#EAEAEA', paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15
  },
  textIngredient: {
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
    paddingLeft: 15,
  },
  textBenefit: {
    fontFamily: 'Avenir',
    fontSize: 17,
    marginTop: 10,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    paddingLeft: 15,
    height: 30,
  },
  titleBenefit: {
    fontFamily: 'Avenir',
    fontSize: 19,
    fontWeight: 'Bold',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
  },
  divider: {
    width: window.innerWidth - 30,
    height: 1,
    marginLeft: 15,
    backgroundColor: '#eaeaea',
    marginTop: 10,
    marginBottom: 10,
  },
  row: {
    textAlign: 'left',
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    color: '#4a4a4a',
    paddingTop: 5
  },
  boxPrice: {
    width: 70,
    height: 25,
    borderRadius: 15,
    bottom: 20,
    right: 15,
    backgroundColor: '#FAF9F9',
    alignItems: 'center',
    fontFamily: 'Avenir',
    justifyContent: 'center',
    borderColor: '#EAEAEA',
    color: '#8CB5A1',
    paddingTop: 9,
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(Product);
