import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../css/Home.css';
import HeaderBooking from './HeaderBooking'
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'
import CheckoutOff from '../modulesBooking/CheckoutOff'
import ScrollViewPage from '../../layout/scrollviews/ScrollViewPage'
import { Fonts } from '../../../utils/Font'

class Product extends Component {
  async componentWillMount() {
    window.scrollTo(0, 0)
    this.props.onRef(this)
  }
  back() {
    if (this.props.dataHome.productChosen.type === 'shots') {
      this.props.bookingFlowAction('setCurrentPage', { value: 'Booster' })
    } else if (this.props.dataHome.productChosen.type === 'iv' || this.props.dataHome.productChosen.type === 'ac') {
      this.props.bookingFlowAction('setCurrentPage', { value: 'AddAdditional' })
    } else if (this.props.dataHome.productChosen.type === 'btx') {
      this.props.bookingFlowAction('setCurrentPage', { value: 'UnitSelection' })
    } else if (this.props.dataHome.productChosen.type === 'cup') {
      this.props.bookingFlowAction('setCurrentPage', { value: 'Person' })
    } else if (this.props.category.enableAddProduct) {
      this.props.bookingFlowAction('setCurrentPage', { value: 'AddAdditional' })
    } else if (!this.props.dataHome.subscribe) {
      this.props.bookingFlowAction('setCurrentPage', { value: 'Person' })
    } else {
      if (this.props.category.list) {
        this.props.bookingFlowAction('setCurrentPage', { value: 'AddAdditional' })
      } else {
        this.props.homeAction('openIVFLow', { value: false })
      }
    }
  }
  doneTime() {
    this.timePicker.doneTime()
  }
  setPricing(pricing) {
    this.props.setPricing(pricing)
  }
  openPayments() {
    this.checkoutRef.openPayments()
  }
  bodyContent() {
    return (
      <div style={{ marginTop: -40 }}>
        <CheckoutOff setPricing={this.setPricing.bind(this)} category={this.props.category} onRef={ref => (this.checkoutRef = ref)} journeyShow={this.props.journeyShow} />
      </div>
    )
  }
  render() {
    return (
      <div className="App">
        <HeaderBooking title={'Checkout'} back={this.back.bind(this)} loader={false} journeyShow={this.props.journeyShow} />
        <ScrollViewPage
          content={this.bodyContent.bind(this)}
          loader={false}
          bookingFlow={true}
          header={false}
          styleTitle={{ fontSize: 27, fontFamily: Fonts.MarkOTBold }}
        />
        <div style={{ height: 40 }}></div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(Product);
