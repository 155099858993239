
import {
  SET_LOADER_HOME,
  SET_NUMBER_UNIT,
  SET_ESTIMATE_PPU,
  SET_PRICE_PER_UNIT,
  SET_AVAILABILITY_TYPE,
  SET_PREFERENCE_DAY1,
  SET_PREFERENCE_DAY2,
  SET_PREFERENCE_TIME1_FROM,
  SET_PREFERENCE_TIME2_FROM,
  SET_PREFERENCE_TIME1_TO,
  SET_PREFERENCE_TIME2_TO,
  SET_PREFERENCE_DISPLAY,
  SET_PROVIDERS_PREFERENCE,
  SET_NAME_LOCATION,
  RESET_DATA_HOME,
  SET_ACCESSORIES,
  OPEN_REVIEWS,
  SET_STEP_HOME,
  SET_TYPE_CHOICE,
  SHOW_COMPLETE_PROFILE,
  SET_PROVIDER_CHOICE_TYPE,
  SET_PROVIDER_CHOICE_INFO,
  SET_TYPE_DEMAND,
  SET_OPEN_LINK_PARTY,
  SET_DOC_OBJ,
  SET_DATE_FILTER,
  SET_DISCOUNT_CODE,
  SET_ID_APPT,
  OPEN_IV_FLOW,
  OPEN_PARTY_FLOW,
  SET_TIME_IV,
  SET_PRODUCT_CHOSEN,
  SET_ADDRESS_HOME_REQUEST,
  SET_NOTES,
  SET_BOOSTERS,
  SET_DURATION_EVENT,
  SET_BUSINESS_NAME,
  SET_BUSINESS_PHONE,
  SET_INVITE_CODE_EVENT,
  SET_SUBSCRIBE,
  SET_DATE_APPT,
  SET_ORGANIZER_ID,
  SET_USERS_CONFIRMED,
  SET_NURSE_INFO,
  SET_TIME_ZONE,
  SET_DATE_SLOT_APPT,
  SET_LOCATION_HIGH_UP,
  SET_CREDIT_CARD_REQUIRED,
  SET_PAYMENT_OPTION,
  SET_RECEIVER,
  SET_SECONDARY_PRODUCTS,
  SET_ATTENDEE_PRODUCT,
  SET_ADDITIONAL_PRODUCT,
  SET_NUMBER_UNIT_ORDERED_PER_PATIENT
} from '../actions/types';

const initialState = {
  showLoaderHome: false,
  numberUnit: 1,
  pricePerUnit: 0,
  typeProduct: 'IV',
  typeDemand: 'In-office',
  pricePerUnitDisplay: '0.0',
  estimatedPricePerUnit: {},
  productChosen: {},
  availabilityType: '',
  numberUnitOrderedPerPatient: 30,
  preferenceday1: '',
  preferenceday2: '',
  preferencetime1from: '',
  preferencetime1to: '',
  preferencetime2from: '',
  preferencetime2to: '',
  preferencesDisplay: '',
  providersPreference: {
    type: '',
    value: '',
    genderFilter: '',
    rebookFilter: false,
  },
  nameLocation: '',
  idAppt: '',
  showReviews: false,
  showCompleteProfile: false,
  stepHome: 'choiceProduct',
  providerChoiceType: '',
  providerChoiceInfo: { 'firstname': '', 'lastname': '', 'id': '' },
  openLinkParty: false,
  docObjMat: [],
  dateFilter: new Date(),
  locationAllowCar: true,
  discountCodeUsed: false,
  infoCode: {},
  showIVFlow: false,
  showPartyFlow: false,
  timeIV: '',
  addressHomeRequest: '',
  notes: '',
  boosters: [],
  boostersSelected: [],
  numberBoosters: 0,
  durationEvent: 0,
  businessName: '',
  businessPhone: '',
  inviteCodeEvent: '',
  subscribe: false,
  dateAppt: '',
  organizerID: '',
  usersConfirmed: [],
  nurseInfo: {},
  creditCardRequired: true,
  paymentOption: '',
  receiver: 'me',
  timeZone: {},
  secondaryProducts: [],
  attendeeProduct: {},
  additionalProduct: {},
  locationHighUp: false,
}

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADER_HOME:
      return { ...state, showLoaderHome: action.showLoaderHome };
    case SET_NUMBER_UNIT:
      return { ...state, numberUnit: action.numberUnit };
    case SET_PRICE_PER_UNIT:
      return { ...state, pricePerUnit: action.pricePerUnit, pricePerUnitDisplay: action.pricePerUnitDisplay };
    case SET_ESTIMATE_PPU:
      return { ...state, estimatedPricePerUnit: action.estimatedPricePerUnit };
    case SET_AVAILABILITY_TYPE:
      return { ...state, availabilityType: action.availabilityType };
    case SET_PREFERENCE_DAY1:
      return { ...state, preferenceday1: action.preferenceday1 };
    case SET_PROVIDERS_PREFERENCE:
      return { ...state, providersPreference: action.providersPreference };
    case SET_PREFERENCE_DAY2:
      return { ...state, preferenceday2: action.preferenceday2 };
    case SET_DATE_SLOT_APPT:
      return { ...state, dateSlotAppt: action.dateSlotAppt };
    case SET_ATTENDEE_PRODUCT:
      return { ...state, attendeeProduct: action.attendeeProduct };
    case SET_ADDITIONAL_PRODUCT:
      return { ...state, additionalProduct: action.additionalProduct };
    case SET_PREFERENCE_TIME1_FROM:
      return { ...state, preferencetime1from: action.preferencetime1from };
    case SET_PREFERENCE_TIME1_TO:
      return { ...state, preferencetime1to: action.preferencetime1to };
    case SET_PREFERENCE_TIME2_FROM:
      return { ...state, preferencetime2from: action.preferencetime2from };
    case SET_PREFERENCE_TIME2_TO:
      return { ...state, preferencetime2to: action.preferencetime2to };
    case SET_PREFERENCE_DISPLAY:
      return { ...state, preferencesDisplay: action.preferencesDisplay };
    case SET_NAME_LOCATION:
      return { ...state, nameLocation: action.nameLocation };
    case OPEN_REVIEWS:
      return { ...state, showReviews: action.showReviews };
    case SET_NUMBER_UNIT_ORDERED_PER_PATIENT:
      return { ...state, numberUnitOrderedPerPatient: action.numberUnitOrderedPerPatient }
    case SET_STEP_HOME:
      return { ...state, stepHome: action.stepHome };
    case SET_TYPE_CHOICE:
      return { ...state, typeProduct: action.typeProduct };
    case SET_RECEIVER:
      return { ...state, receiver: action.receiver };
    case SHOW_COMPLETE_PROFILE:
      return { ...state, showCompleteProfile: action.showCompleteProfile };
    case SET_PROVIDER_CHOICE_TYPE:
      return { ...state, providerChoiceType: action.providerChoiceType };
    case SET_PROVIDER_CHOICE_INFO:
      return { ...state, providerChoiceInfo: action.providerChoiceInfo }
    case SET_TYPE_DEMAND:
      return { ...state, typeDemand: action.typeDemand }
    case SET_ACCESSORIES:
      return { ...state, accessories: action.accessories };
    case SET_OPEN_LINK_PARTY:
      return { ...state, openLinkParty: action.openLinkParty }
    case SET_DOC_OBJ:
      return { ...state, docObjMat: action.docObjMat }
    case SET_SUBSCRIBE:
      return { ...state, subscribe: action.subscribe }
    case SET_PAYMENT_OPTION:
      return { ...state, paymentOption: action.paymentOption }
    case SET_DATE_APPT:
      return { ...state, dateAppt: action.dateAppt }
    case SET_DATE_FILTER:
      return { ...state, dateFilter: action.dateFilter }
    case SET_ID_APPT:
      return { ...state, idAppt: action.idAppt }
    case SET_DISCOUNT_CODE:
      return { ...state, discountCodeUsed: action.discountCodeUsed, infoCode: action.infoCode }
    case OPEN_IV_FLOW:
      return { ...state, showIVFlow: action.showIVFlow }
    case OPEN_PARTY_FLOW:
      return { ...state, showPartyFlow: action.showPartyFlow }
    case SET_TIME_IV:
      return { ...state, timeIV: action.timeIV }
    case SET_NURSE_INFO:
      return { ...state, nurseInfo: action.nurseInfo }
    case SET_LOCATION_HIGH_UP:
      return { ...state, locationHighUp: action.locationHighUp }
    case SET_ADDRESS_HOME_REQUEST:
      return { ...state, addressHomeRequest: action.addressHomeRequest, accessories: action.accessories, locationAllowCar: action.locationAllowCar, timeZone: action.timeZone }
    case SET_NOTES:
      return { ...state, notes: action.notes }
    case SET_PRODUCT_CHOSEN:
      return { ...state, productChosen: action.productChosen }
    case SET_DURATION_EVENT:
      return { ...state, durationEvent: action.durationEvent }
    case SET_BUSINESS_NAME:
      return { ...state, businessName: action.businessName }
    case SET_SECONDARY_PRODUCTS:
      return { ...state, secondaryProducts: action.secondaryProducts }
    case SET_BUSINESS_PHONE:
      return { ...state, businessPhone: action.businessPhone }
    case SET_INVITE_CODE_EVENT:
      return { ...state, inviteCodeEvent: action.inviteCodeEvent }
    case SET_ORGANIZER_ID:
      return { ...state, organizerID: action.organizerID }
    case SET_CREDIT_CARD_REQUIRED:
      return { ...state, creditCardRequired: action.creditCardRequired }
    case SET_USERS_CONFIRMED:
      return { ...state, usersConfirmed: action.usersConfirmed }
    case SET_TIME_ZONE:
      return { ...state, timeZone: action.timeZone }
    case SET_BOOSTERS:
      return { ...state, boosters: action.boosters, numberBoosters: action.numberBoosters, boostersSelected: action.boostersSelected }
    case RESET_DATA_HOME:
      return initialState;
    default:
      return state;
  }
}

export default homeReducer;
