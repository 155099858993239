import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import ReactPixel from 'react-facebook-pixel';
import CardCategory from './CardCategory';
import ScrollViewPage from './../layout/scrollviews/ScrollViewPage';
import { Fonts } from './../../utils/Font';
import colors from '../layout/colors'
import '../../css/Home.css';

class ListProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: {
        list: []
      }
    };
  }
  componentWillMount() {
    window.scrollTo(0, 0)
    var typeCategory = this.props.match.params.typeCategory
    var category = this.props.dataUser.instaviceVariables.homePage2.filter(category => category.type === typeCategory)[0]
    this.setState({ category: category })
    ReactPixel.pageView()
  }
  headerContent() {
    return (
      <div style={{ marginTop: 10 }}>
        {Object.values(this.state.category.infoCategory).map((info, i) => (
          <Grid container direction="row" key={i} style={{ flex: 1, width: '100%', marginTop: 12 }}>
            <Grid xs={false} md={1}></Grid>
            <Grid container xs={2} md={1} alignContent="center" justify="center" style={{ marginTop: -2 }}>
              <Icon style={{ color: colors.subtitle, fontSize: 18 }}> {info.icon.replace(/-/g, "_")} </Icon>
            </Grid>
            <Grid xs={10} md={10}>
              <div style={styles.textListInfo}>{info.text}</div>
            </Grid>
          </Grid>
        ))}
      </div>
    )
  }
  listProducts() {
    return (
      <div className="categoryContainer">
        <Grid container alignItems="center" justify="center">
          {Object.values(this.state.category.list).reverse().map((product, i) => (
            <Grid md={6} sm={9} xs={11} item key={i} >
              <CardCategory {...this.props} product={product} productCard={true} category={this.state.category} imgName={product.imgName} />
            </Grid>
          ))}
          {
            Object.values(this.state.category.list).length % 2 === 1 ?
              <Grid md={6} sm={0} xs={0} item></Grid>
              :
              null
          }
        </Grid>
      </div>
    )
  }
  render() {
    return (
      <div className="App">
        <ScrollViewPage
          content={this.listProducts.bind(this)}
          title={this.state.category.title}
          subtitle={this.state.category.subtitle}
          loader={false}
          back={true}
          header={this.headerContent.bind(this)}
          bookingFlow={false}
          history={this.props.history}
          styleTitle={{ fontSize: 27, fontFamily: Fonts.MarkOTBold, }}
        />
      </div>
    );
  }
}
const styles = {
  buttonBack: {
    marginLeft: 15,
    height: 25,
    marginTop: 10,
    textAlign: 'left'
  },
  textListInfo: {
    color: colors.subtitle,
    marginLeft: 3,
    fontSize: 14,
    fontFamily: Fonts.MarkOT,
    textAlign: 'left',
    width: '70vw',
    minWidth: 200,
  },
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, {})(ListProducts);
