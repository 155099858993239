import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ButtonFooterBooking from '../buttons/ButtonBooking'
import colors from '../colors';
import { Fonts } from '../../../utils/Font';

class AlertSingleAction extends Component {
  render() {
    return (
      <div>
        <Grid direction="column" justify="center" alignContent="center" style={{ marginTop: 20, marginLeft: 20, marginRight: 20 }}>
          <p style={styles.title}>{this.props.message.split('. ')[0]}</p>
          <p style={styles.subtitle}>{this.props.message.split('. ')[1]}</p>

          <div style={{ ...styles.viewButton, marginTop: 25 }}>
            <ButtonFooterBooking text={this.props.action} click={() => this.props.doAction()} />
          </div>
          <div style={{ ...styles.viewButton, marginBottom: 10 }}>
            <ButtonFooterBooking text={this.props.closeText} grey={true} click={() => this.props.close()} />
          </div>
        </Grid>
      </div>
    );
  }
}
export default AlertSingleAction;

const styles = {
  title: {
    color: colors.title,
    fontSize: 17,
    marginRight: '10px',
    fontFamily: Fonts.MarkOTMedium,
  },
  subtitle: {
    color: colors.subtitleCard,
    fontSize: 15,
    marginTop: 5,
    marginRight: '10px',
    fontFamily: Fonts.MarkOT,
  },
  viewButton: {
    marginTop: 15,
    marginBottom: -10,
    marginLeft: '10%',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
    height: 50,
  }
};
