import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import colors from '../../../layout/colors';
import { Fonts } from '../../../../utils/Font'
import moment from 'moment-timezone';

class CardSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dates: [],
      selectedDate: 0,
      times: [],
      selectedTime: 0,
      dateElement: React.createRef(),
      timeElement: React.createRef(),
      slotElement: React.createRef(),
      slotHeight: 34.25,
    }
  }
  handleDateScroll = e => {
    let element = e.target
    this.setState({ dateElement: e.target })
    var selected = (Math.round((element.scrollTop) / this.state.slotHeight))
    if (selected > this.state.dates.length - 1) selected = this.state.dates.length - 1
    this.setState({ selectedDate: selected })
    this.loadTimes(selected)
    this.props.onPickDate(this.state.dates[selected])
  }
  handleTimeScroll = e => {
    let element = e.target
    this.setState({ timeElement: e.target })
    var selected = (Math.round((element.scrollTop) / this.state.slotHeight))
    if (selected > this.state.times.length - 1) selected = this.state.times.length - 1
    this.setState({ selectedTime: selected })
    this.props.onPickTime(this.state.times[selected])
  }
  clickDate(selected) {
    let element = this.state.dateElement
    const selectedDiff = selected - this.state.selectedDate
    element.scrollTop += this.state.slotHeight * selectedDiff
    this.setState({ dateElement: element })
    this.setState({ selectedDate: selected })
    this.loadTimes(selected)
    this.props.onPickDate(this.state.dates[selected])
  }
  clickTime(selected) {
    let element = this.state.timeElement
    const selectedDiff = selected - this.state.selectedTime
    element.scrollTop += this.state.slotHeight * selectedDiff
    this.setState({ timeElement: element })
    this.setState({ selectedTime: selected })
    this.props.onPickDate(this.state.times[selected])
  }
  async componentWillMount() {
    if (this.props.date) await this.loadDates()
    if (this.props.time) await this.loadTimes(0)
    if (this.props.date) await this.props.onPickDate(this.state.dates[0])
    if (this.props.time) await this.props.onPickTime(this.state.times[0])
  }
  async componentDidMount() {
    await this.setState({
      dateElement: this.state.dateElement.current,
      timeElement: this.state.timeElement.current,
      slotElement: this.state.slotElement.current
    })
    this.setState({ slotHeight: this.state.dateElement.children[2].offsetTop - this.state.dateElement.children[1].offsetTop })
  }
  loadDates() {
    var dates = []
    for (var i = 0; i < 31; i++) {
      const date = moment(new Date()).add(i, 'days').startOf('day')
      dates.push(date)
    }
    if (moment(new Date()).isAfter(moment(new Date()).startOf('day').add(20, 'hours').add(45, 'minutes'))) {
      if (moment(dates[0]).startOf('day').isSame(moment(new Date()).startOf('day'))) dates.splice(0, 1)
    }
    this.setState({ dates })
  }
  loadTimes(selected) {
    this.setState({ selectedTime: 0 })
    var times = []
    if (moment(new Date()).tz(this.props.timeZone).isAfter(moment(new Date()).tz(this.props.timeZone).startOf('day').add(20, 'hours').add(45, 'minutes'))) {
      if (moment(this.state.dates[0]).tz(this.props.timeZone).startOf('day').isSame(moment(new Date()).tz(this.props.timeZone).startOf('day'))) this.state.dates.splice(0, 1)
    }
    var today = moment(this.state.dates[selected]).tz(this.props.timeZone).startOf('day').isSame(moment(new Date()).startOf('day'))
    for (var i = 0; i < 96; i++) {
      const time = moment(new Date()).tz(this.props.timeZone).startOf('day').add(i * 15, 'minutes')
      if ((!today || (today && time.isAfter(moment(new Date()).tz(this.props.timeZone).add(1, 'hours')))) &&
        time.isSameOrAfter(moment(new Date()).tz(this.props.timeZone).startOf('day').add(8, 'hours')) &&
        time.isBefore(moment(new Date()).tz(this.props.timeZone).startOf('day').add(22, 'hours'))) {
        times.push(time)
      }
    }
    this.setState({ times })
  }
  render() {
    if (this.props.date && !this.props.time) return (
      <Grid
        direction="column"
        ref={this.state.dateElement}
        className="dateTimeSelector"
        style={{ height: 100, overflowY: 'scroll', textAlign: 'center', marginBottom: -15 }}
        onScroll={this.handleDateScroll}
      >
        {this.state.dates.map((option, i) => (
          <Grid
            item
            className="pointer"
            ref={this.state.slotElement}
            onClick={() => { this.clickDate(i) }}
            style={{ marginTop: (i === 0) ? 35 : 15, marginBottom: (i === this.state.dates.length - 1) ? 40 : 0, fontFamily: (this.state.selectedDate === i) ? Fonts.MarkOTBlack : Fonts.MarkOTMedium, color: (this.state.selectedDate === i) ? colors.primary : null }}>
            {(moment(option).startOf('day').isSame(moment(new Date()).startOf('day'))) ?
              'Today'
              : (moment(option).startOf('day').isSame(moment(new Date()).startOf('day').add(1, 'days'))) ?
                'Tomorrow'
                : option.format('ddd, MMM Do')}
          </Grid>
        ))}

      </Grid>
    )
    else if (this.props.date && this.props.time) return (
      <Grid container direction="row" style={{ width: '100%' }}>
        <Grid item xs={8}>
          <Grid
            direction="column"
            ref={this.state.dateElement}
            className="dateTimeSelector"
            style={{ height: 100, overflowY: 'scroll', textAlign: 'center', marginBottom: -15 }}
            onScroll={this.handleDateScroll}
          >
            {this.state.dates.map((option, i) => (
              <Grid
                item
                className="pointer"
                ref={this.state.slotElement}
                onClick={() => { this.clickDate(i) }}
                style={{ marginTop: (i === 0) ? 35 : 15, marginBottom: (i === this.state.dates.length - 1) ? 40 : 0, fontFamily: (this.state.selectedDate === i) ? Fonts.MarkOTBlack : Fonts.MarkOTMedium, color: (this.state.selectedDate === i) ? colors.primary : null }}>
                {(moment(option).startOf('day').isSame(moment(new Date()).startOf('day'))) ?
                  'Today'
                  : (moment(option).startOf('day').isSame(moment(new Date()).startOf('day').add(1, 'days'))) ?
                    'Tomorrow'
                    : option.format('ddd, MMM Do')}
              </Grid>
            ))}

          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid
            direction="column"
            ref={this.state.timeElement}
            className="dateTimeSelector"
            style={{ height: 100, overflowY: 'scroll', textAlign: 'center', marginBottom: -15 }}
            onScroll={this.handleTimeScroll}
          >
            {this.state.times.map((option, i) => (
              <Grid
                item
                className="pointer"
                onClick={() => { this.clickTime(i) }}
                style={{ marginTop: (i === 0) ? 35 : 15, marginBottom: (i === this.state.times.length - 1) ? 40 : 0, fontFamily: (this.state.selectedTime === i) ? Fonts.MarkOTBlack : Fonts.MarkOTMedium, color: (this.state.selectedTime === i) ? colors.primary : null }}>
                {option.format('h:mm a')}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    )
    else return null
  }
}
export default CardSelection;
