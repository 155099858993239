import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import ListFlags from './elementsSignin/ListFlags'
import { initApp } from '../../actions/initAppActions'
import { updateStepLogin } from '../../actions/loginActions'
import SignInFlow from './elementsSignin/SignInFlow';
import { Fonts } from '../../utils/Font'
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      loader: false,
      error: false,
      openFlags: false
    };
    this.signIn = this.signIn.bind(this)
  }
  componentWillMount() {
    window.scrollTo(0, 0)
  }

  handleChange = name => event => {
    var currentVal = this.props.dataLogin.email
    var val = event.target.value
    var newVal = val
    if (val.length === 1 && currentVal.length === 0) {
      newVal = '(' + newVal
      this.props.updateStepLogin('setEmail', { value: newVal })
    } else if (val.length === 1 && currentVal.length === 2) {
      this.setState({ [name]: '' });
    } else if (val.length === 4 && currentVal.length === 3) {
      newVal = newVal + ') '
      this.props.updateStepLogin('setEmail', { value: newVal })
    } else if (val.length === 5 && currentVal.length === 4) {
      newVal = currentVal + ') ' + val[3]
      this.props.updateStepLogin('setEmail', { value: newVal })
    } else if ((val.length === 6 && currentVal.length === 7) || (val.length === 5 && currentVal.length === 6)) {
      newVal = newVal.replace(')', '')
      newVal = newVal.replace(' ', '')
      this.props.updateStepLogin('setEmail', { value: newVal })
    } else if (val.length === 9) {
      newVal = newVal + '-'
      this.props.updateStepLogin('setEmail', { value: newVal })
    } else if (val.length === 10 && currentVal.length === 9) {
      newVal = currentVal + '-' + newVal[9]
      this.props.updateStepLogin('setEmail', { value: newVal })
    } else if (val.length === 10 && currentVal.length === 11) {
      newVal = newVal.replace('-', '')
      this.props.updateStepLogin('setEmail', { value: newVal })
    } else if (val.length === 14) {
      this.props.updateStepLogin('setEmail', { value: newVal })
      this.signIn(newVal)
    } else {
      this.props.updateStepLogin('setEmail', { value: newVal })
    }
  };
  openFlags(val) {
    this.setState({ openFlags: val })
  }
  async signIn(phone) {
    this.setState({ loader: true })
    var url = 'https://us-central1-instabo-production.cloudfunctions.net/createUser'
    var phoneNumber = phone
    phoneNumber = phoneNumber.replace('-', '')
    phoneNumber = phoneNumber.replace(')', '')
    phoneNumber = phoneNumber.replace('(', '')
    phoneNumber = phoneNumber.replace(' ', '')

    const promiseAxios = await axios.get(url, {
      params: {
        phone: phoneNumber,
        giftAmount: this.props.dataUser.instaviceVariables.giftAmount
      }
    })
    var infoUser = promiseAxios.data.infoUser
    var firebaseSignInToken = promiseAxios.data.firebaseSignInToken
    if (promiseAxios.data.response) {
      await this.props.initApp('firstSignIn', { phoneNumber: phoneNumber, firebaseSignInToken: firebaseSignInToken, infoUser: infoUser, specialEvent: this.props.dataHome.productChosen.specialEvent })
    } else {
      this.setState({ error: true })
      this.setState({ loader: false })
    }
  }
  closeSignIn() {
    this.setState({ loader: false })
  }
  render() {
    return (
      <div className="App" >
        <Grid container alignItems="center" justify="center" style={{ marginTop: 20, paddingLeft: 30, paddingRight: 30 }}>
          <Grid md={3} xs={3} item>
            <Button style={{ height: 56, color: '#4a4a4a', marginTop: 10, fontFamily: Fonts.MarkOTMedium, width: '100%', fontWeight: 'bold', fontSize: 14 }} variant="outlined" size="medium" color="primary" onClick={() => this.openFlags(true)}>
              <img
                style={{ borderRadius: 3 }}
                src={'data:image/png;base64,' + Object.values(this.props.dataUser.ListFlags).filter(country2 => this.props.dataUser.country.name === country2.name)[0].flag}
                alt=""
              />
              &nbsp;&nbsp;{this.props.dataUser.country.dial_code}
            </Button>
          </Grid>
          <Grid md={1} xs={1} item></Grid>
          <Grid md={8} xs={8} item>
            <TextField
              style={{ width: '100%', height: 53, fontFamily: Fonts.MarkOT }}
              id="outlined-password-input"
              label="Your phone number"
              value={this.props.dataLogin.email}
              type="tel"
              onChange={this.handleChange('phone')}
              autoComplete="phone-number"
              margin="normal"
              color="secondary"
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center" style={{ marginTop: 20, paddingLeft: 30, paddingRight: 30 }}>
          <Grid md={12} xs={12} item>
            <div>
              {
                this.state.loader ?
                  <Button style={{ height: 53, color: 'white', width: '100%', backgroundColor: '#5DB380' }} variant="contained" color="primary">
                    <CircularProgress style={{ color: 'white' }} size={24} opacity={50} />
                  </Button>
                  :
                  <Button style={{ height: 53, color: 'white', fontFamily: Fonts.MarkOTBold, width: '100%', fontSize: 14, backgroundColor: '#5DB380' }} variant="contained" color="primary" onClick={() => this.signIn(this.props.dataLogin.email)}>
                    Sign in
          </Button>
              }
            </div>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="center">
          {
            this.state.error ?
              <div style={{ fontSize: 14, color: '#4a4a4a', marginTop: 20, fontFamily: Fonts.MarkOT }}>An error has occured, please try again.</div>
              :
              null
          }
        </Grid>
        <Dialog
          fullScreen
          open={this.state.openFlags}
          TransitionComponent={Transition}
        >
          <ListFlags openFlags={this.openFlags.bind(this)} journeyShow={this.props.journeyShow} />
        </Dialog>

        <Dialog
          fullScreen
          open={this.props.dataUser.showLoginModal}
          TransitionComponent={Transition}
        >
          <SignInFlow initiaStep={'verify'} close={this.closeSignIn.bind(this)} journeyShow={this.props.journeyShow} />
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataLogin: state.login,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { initApp, updateStepLogin })(SignIn);
