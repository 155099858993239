import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { initApp } from '../../../actions/initAppActions'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SecondaryAdd from './SecondaryAdd';
import ProductAdd from './ProductAdd';
import { Fonts } from '../../../utils/Font'
import colors from '../../layout/colors'
import ScrollViewPage from '../../layout/scrollviews/ScrollViewPage'
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      titleAlert: '',
      messageAlert: '',
    };
  }
  async done() {
    for (var l in this.props.dataHome.additionalProduct) {
      var product = this.props.dataHome.additionalProduct[l]
      if (product.numberAdded > this.props.dataHome.numberUnit) {
        var reduce = product.numberAdded - this.props.dataHome.numberUnit
        var nameProduct = product.name.toLowerCase()
        this.setState({ titleAlert: 'You can add a maximum of one additional ' + nameProduct + ' per person.' })
        this.setState({ messageAlert: 'Please reduce by ' + reduce + '.' })
        this.setState({ showAlert: true })
        break
      }
    }
    if (faFlagCheckered) {
      await this.props.chargeDataCheckout()
      this.props.close(false)
    }
  }
  closeAlert() {
    this.setState({ showAlert: false })
  }
  bodyContent() {
    return (
      <div>
        <div style={{ width: '95vw', maxWidth: 600, marginTop: -15 }}>
          {
            this.props.category.enableAddProduct ?
              <div>
                {
                  !this.props.dataHome.subscribe ?
                    <Grid container style={styles.subtitle}>
                      Here are the additional products you added for the booking.
              </Grid>
                    :
                    <Grid container style={styles.subtitle}>
                      Here are the additional products your host added for the booking.
              </Grid>
                }
              </div>
              : null
          }

          {
            this.props.category.enableAddProduct ?
              <ProductAdd additional={this.props.additional} additionalOn={this.props.additionalOn} category={this.props.category} list={this.props.category.additionalProduct} editable={this.props.editable} clickable={false} />
              : null
          }

          <SecondaryAdd additional={this.props.additional} additionalOn={true} category={this.props.category} list={this.props.category.secondaryProducts.list} editable={this.props.editable} clickable={false} secondary={this.props.secondary} />
        </div>

      </div>
    )
  }
  render() {
    return (
      <div className="App" >
        <Toolbar style={{ backgroundColor: 'white', height: 60 }}>
          <IconButton color="inherit" onClick={() => this.done()} aria-label="Close">
            <Icon style={{ color: 'black' }}>close</Icon>
          </IconButton>
        </Toolbar>
        <ScrollViewPage
          content={this.bodyContent.bind(this)}
          title="Additional Products"
          loader={false}
          header={false}
          styleTitle={{ fontSize: 27, fontFamily: Fonts.MarkOTBold, }}
        />

        <Grid container className="viewFooterBooking shadeOnSubmit" justify="center">
          <div
            className="Vbtn"
            onClick={() => this.done()}>
            <p>{(!this.props.dataHome.subscribe && this.props.editable) ? 'Done' : 'Close'}</p>
          </div>
        </Grid>
        <Dialog
          open={this.state.showAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ fontFamily: 'Avenir', }} id="alert-dialog-title">{this.state.titleAlert}</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ fontFamily: 'Avenir', }} id="alert-dialog-description">
              {this.state.messageAlert}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeAlert()} color="primary" style={{ fontFamily: 'Avenir', }} autoFocus>
              Got it!
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const styles = {
  button: {
    height: 53, color: 'white', fontFamily: 'Avenir', width: '90%',
    fontWeight: 'bold',
    fontSize: 16
  },
  subtitle: {
    fontFamily: Fonts.MarkOT,
    fontSize: 16,
    color: colors.subtitle,
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 15,
    marginBottom: -15,
    textAlign: 'left',
  },
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { initApp })(SignIn);
