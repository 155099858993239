
import {
  OPEN_ALL_CLINICS,
  SET_CLINICS_DISPLAY,
  SET_CLINICS_REQUEST,
  SET_REGION_MAP,
  OPEN_LIST_CLINIC,
  OPEN_SEARCH,
  OPEN_FILTER,
  OPEN_INFO_CLINIC,
  SET_COUNTER_SELECT,
  SET_INSTANT_ONLY,
  SET_SEARCH_PROVIDER,
  SET_USER_LOCATION,
  RESET_DATA_LOCATION,
  SET_PINS_MAP,
  SET_LIST_CITIES,
  SET_AVAILABILITIES_CALENDAR,
  OPEN_LIST_CLINIC2,
  SET_SEARCH_REGION,
  LOCATION_ENTERED
} from '../actions/types';

const initialState = {
  AllClinics: [],
  clinicsDisplay: [],
  clinicsDisplayJSON: [],
  clinicsRequestJSON: [],
  locationReachedRaw: [],
  region: {
    latitude: 34.073620,
    longitude: -118.400352,
    latitudeDelta: 0.021,
    longitudeDelta: 0.06
  },
  dataCurrentClinic: {},
  showList: false,
  showList2: false,
  showSearch: false,
  showFilter: false,
  showInfoClinic: false,
  counterSelect: ' ',
  instantOnly: false,
  locationEntered: false,
  showUserLocation: false,
  searchProvider: '',
  pinsMap: [],
  listCities: [],
  availabilitiesCalendar: {},
  searchRegion: 'Beverly Hills',
  locationAllowCar: true,
}

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_ALL_CLINICS:
      return { ...state, AllClinics: action.AllClinics };
    case SET_CLINICS_DISPLAY:
      return { ...state, clinicsDisplay: action.clinicsDisplay, clinicsDisplayJSON: action.clinicsDisplayJSON };
    case SET_CLINICS_REQUEST:
      return { ...state, clinicsRequestJSON: action.clinicsRequestJSON };
    case SET_REGION_MAP:
      return { ...state, region: action.region, locationAllowCar: action.locationAllowCar };
    case OPEN_LIST_CLINIC:
      return { ...state, showList: action.showList };
    case OPEN_LIST_CLINIC2:
      return { ...state, showList2: action.showList2 };
    case OPEN_SEARCH:
      return { ...state, showSearch: action.showSearch };
    case OPEN_FILTER:
      return { ...state, showFilter: action.showFilter };
    case SET_USER_LOCATION:
      return { ...state, showUserLocation: action.showUserLocation };
    case LOCATION_ENTERED:
      return { ...state, locationEntered: action.locationEntered }
    case OPEN_INFO_CLINIC:
      return { ...state, showInfoClinic: action.showInfoClinic, dataCurrentClinic: action.dataCurrentClinic };
    case SET_COUNTER_SELECT:
      return { ...state, counterSelect: action.counterSelect };
    case SET_INSTANT_ONLY:
      return { ...state, instantOnly: action.instantOnly };
    case SET_SEARCH_PROVIDER:
      return { ...state, searchProvider: action.searchProvider };
    case SET_PINS_MAP:
      return { ...state, pinsMap: action.pinsMap };
    case SET_LIST_CITIES:
      return { ...state, listCities: action.listCities };
    case SET_AVAILABILITIES_CALENDAR:
      return { ...state, availabilitiesCalendar: action.availabilitiesCalendar };
    case SET_SEARCH_REGION:
      return { ...state, searchRegion: action.searchRegion };
    case RESET_DATA_LOCATION:
      return initialState;
    default:
      return state;
  }
}

export default locationReducer;
