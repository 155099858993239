import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import '../../css/Home.css';

import Toolbar from '@material-ui/core/Toolbar';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog';
import SharePopup from './SharePopup';
import TabAppt from './elementsAppt/TabAppt';
import Grow from '@material-ui/core/Grow';
import ListOthers from './elementsAppt/ListOthers';
import ListShots from './elementsAppt/ListShots';
import ProviderPage from '../elementsHome/elementsProviders/ProviderPage'
import Divider from '@material-ui/core/Divider'
import { Fonts } from '../../utils/Font'
import ScrollViewPage from '../layout/scrollviews/ScrollViewPage'
import AlertDoubleAction from '../layout/alerts/AlertDoubleAction'
import AlertSingleAction from '../layout/alerts/AlertSingleAction'
import branch from 'branch-sdk'
import axios from 'axios'

var headerPosition = 76
function Transition(props) {
  return <Grow {...props} timeout={300} />;
}

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showShare: false,
      loaders: {},
      showAppt: false,
      link: '',
      showAlertCancel: false,
      showAlertConsent: false,
      showAlertEdit: false,
      category: {},
      providerDialog: false,
      providerInfo: undefined
    };
  }
  async componentWillMount() {
    if (window.pageYOffset > 76 || !this.props.journeyShow) headerPosition = 0;
    else headerPosition = 76 - window.pageYOffset
    var category = this.props.dataUser.instaviceVariables.homePage2.filter(cat => cat.type === this.props.data.infoOffer.product.type)[0]
    this.setState({ category: category })
    const providerInfo = await this.getInfoNurse(this.props.data.nurseID)
    this.setState({ providerInfo: providerInfo })
  }
  async loadLink() {
    var inviteCode = this.props.data.inviteCode
    var title = 'Wlnss | On demand wellness'
    var Description = 'Join me for ' + this.props.data.infoOffer.product.name + ' at ' + moment(this.props.data.infoOffer.date).format('h:mm a') + ' on ' + moment(this.props.data.infoOffer.date).format('ddd, MMM Do') + '. Enter invite code ' + inviteCode + ', or click below to sign up.'
    if (this.props.data.infoOffer.product.type === 'shots') {
      Description = 'Join me for ' + this.props.data.infoOffer.product.name + ' at ' + moment(this.props.data.infoOffer.date).format('h:mm a') + ' on ' + moment(this.props.data.infoOffer.date).format('ddd, MMM Do') + '. Enter invite code ' + inviteCode + ', or click below to sign up.'
    }
    var linkData = {
      campaign: 'content 123',
      channel: 'facebook',
      feature: 'dashboard',
      tags: ['tag1', 'tag2', 'tag3'],
      stage: 'new user',
      data: {
        'custom_string': 'hello',
        'custom_bool': true,
        'custom_int': Date.now(),
        '$og_title': title,
        '$og_description': Description,
        'inviteCode': inviteCode,
        '$og_image_url': this.props.data.infoOffer.product.image
      }
    };
    var that = this
    branch.link(linkData, function (err, link) {
      that.share(true, link)
    });
  }
  async share(val, link) {
    await this.setState({ link: link })
    this.setState({ showShare: val })
  }
  async getInfoNurse(nurseID) {
    if (nurseID !== undefined && nurseID !== ' ') {
      var url = 'https://us-central1-instabo-production.cloudfunctions.net/getProviderInfo'
      var promise = await (axios.get(url, {
        params: {
          nurseID: nurseID
        }
      }))
      return Promise.all([promise]).then(results => {
        return results[0].data.infoNurse
      })
    }
    return true
  }
  confirmCancelAppt() {
    this.setState({ showAlertCancel: false })
    var offerID = this.props.data.offerID
    this.props.cancelAppt(offerID, this.props.keyAppt)
    this.props.close(false)
  }
  consent() {
    this.setState({ showAlertConsent: true })
  }
  cancel() {
    this.setState({ showAlertCancel: true })
  }
  download(val) {
    if (val === 'ios') {
      window.open('https://itunes.apple.com/us/app/wlnss/id1420304128?mt=8', '_blank');
    } else {
      window.open('https://play.google.com/store/apps/details?id=wlnss.user', '_blank');
    }
  }
  openProviderPage() {
    this.setState({ providerDialog: true })
  }
  closeProviderPage() {
    this.setState({ providerDialog: false })
  }
  specialtiesEnabled() {
    return this.props.dataUser.instaviceVariables.homePage2.filter(category => category.type === this.props.data.infoOffer.product.type)[0].enableProvidersPreference3
  }
  bodyContent() {
    return (
      <div style={{ width: '95vw', maxWidth: 700, margin: '-15px auto 0' }}>
        <div className="scrollable">
          <TabAppt data={this.props.data} openProviderPage={this.openProviderPage.bind(this)} />

          <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 15, marginBottom: 15 }} />
          {
            this.props.data.infoOffer.product.type !== 'shots' ?
              <ListOthers data={this.props.data} journeyShow={this.props.journeyShow} />
              :
              <ListShots data={this.props.data} />
          }
          <div style={{ height: 230 }}></div>
        </div>

        <Dialog
          open={this.state.showShare}
          onBackdropClick={() => this.setState({ showShare: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <SharePopup link={this.state.link} data={this.props.data} close={this.share.bind(this)} />
        </Dialog>

        <Dialog
          open={this.state.showAlertCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onBackdropClick={() => this.setState({ showAlertCancel: false })}
        >
          <AlertSingleAction
            message={this.props.data.infoOffer.product.type === 'shots' ? 'Are you sure you want to cancel this event?' : 'Are you sure you want to cancel this appointment?'}
            action="Confirm"
            closeText="Cancel"
            doAction={() => this.confirmCancelAppt()}
            close={() => this.setState({ showAlertCancel: false })}
          />
        </Dialog>

        <Dialog
          open={this.state.showAlertConsent}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onBackdropClick={() => this.setState({ showAlertConsent: false })}
        >
          <AlertDoubleAction
            message="Download the WLNSS app to complete your consent"
            action1="iOS"
            action2="Android"
            closeText="Close"
            doAction1={() => this.download('ios')}
            doAction2={() => this.download('android')}
            close={() => this.setState({ showAlertConsent: false })}
          />
        </Dialog>

        <Dialog
          open={this.state.showAlertEdit}
          width="sm"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onBackdropClick={() => this.setState({ showAlertEdit: false })}
        >
          <AlertDoubleAction
            message="Download the WLNSS app to make changes to your booking"
            action1="iOS"
            action2="Android"
            closeText="Close"
            doAction1={() => this.download('ios')}
            doAction2={() => this.download('android')}
            close={() => this.setState({ showAlertEdit: false })}
          />
        </Dialog>
        <Dialog
          maxWidth="md"
          open={this.state.providerDialog}
          TransitionComponent={Transition}
          onBackdropClick={() => this.setState({ providerDialog: false })}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <ProviderPage
            provider={this.state.providerInfo}
            close={this.closeProviderPage.bind(this)}
            specialtiesEnabled={this.specialtiesEnabled.bind(this)}
          />
        </Dialog>
      </div>
    )
  }

  render() {
    return (
      <div className="App">
        <Toolbar style={{ position: 'absolute', top: 0, width: '90%', backgroundColor: 'white', height: 60 }}>
          <Grid container direction="row">
            <Grid item xs={1}>
              <IconButton color="inherit" onClick={() => this.props.close(false)} aria-label="Close">
                <Icon style={{ color: 'black' }}>arrow_back</Icon>
              </IconButton>
            </Grid>
            <Grid item md={2} sm={3} xs={5}>
              <div style={{ marginLeft: 15 }} alignItems='left'>
              </div>
            </Grid>
            <Grid item md={3} sm={2} xs={false}></Grid>
            <Grid item xs={6} direction='row' style={{ minWidth: 45 }}>
              <Grid container direction='row' justify='flex-end' style={{ minWidth: 45 }}>
                <Grid item sm={6} xs={false}></Grid>
                <Grid item sm={2} xs={4}>
                  {
                    this.props.data.status !== 'past' && (!!this.state.category.secondaryProducts || !!this.state.category.additionalProduct) ?
                      <Button style={styles.buttonCart} color="inherit" onClick={() => { this.setState({ showAlertEdit: true }) }}>
                        <i className="fa fa-lg fa-shopping-cart" style={{ color: 'black' }}></i>
                      </Button>
                      :
                      <Button style={styles.buttonCart} color="inherit">
                        <i className="fa fa-lg fa-shopping-cart" style={{ color: '#707070' }}></i>
                      </Button>
                  }
                </Grid>
                <Grid item sm={2} xs={4}>
                  {
                    ((this.props.data.status === 'created' || this.props.data.status === 'confirmed') && !this.props.data.infoOffer.subscribe && this.props.data.infoOffer.product.type !== 'shots') || ((this.props.data.status === 'created' || this.props.data.status === 'confirmed') && !this.props.data.infoOffer.subscribe && this.props.data.infoOffer.product.type === 'shots') ?
                      <Button style={styles.buttonCancel} color="inherit" onClick={() => this.cancel()}>
                        <i className="fa fa-lg fa-ban" style={{ color: 'black' }}></i>
                      </Button>
                      :
                      <Button style={styles.buttonCancel} color="inherit">
                        <i className="fa fa-lg fa-ban" style={{ color: '#707070' }}></i>
                      </Button>
                  }
                </Grid>
                <Grid item sm={2} xs={4}>
                  {
                    ((this.props.data.infoOffer.numberUnit > 1 || this.props.data.infoOffer.receiver === 'else') && this.props.data.status !== 'past' && this.props.data.infoOffer.product.type !== 'shots') || (this.props.data.status !== 'past' && this.props.data.infoOffer.product.type === 'shots') ?
                      <Button color="inherit" style={styles.shareButton} onClick={() => this.loadLink()}>
                        <img
                          style={{ width: 17, height: 17, }}
                          src={require('../../img/appts/upload.png')}
                          alt=""
                        />
                      </Button>
                      :
                      <Button color="inherit" style={styles.shareButton}>
                        <img
                          style={{ width: 17, height: 17, }}
                          src={require('../../img/appts/share.png')}
                          alt=""
                        />
                      </Button>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        <div style={{ marginTop: 60 + headerPosition }}>
          <ScrollViewPage
            content={this.bodyContent.bind(this)}
            title=
            {
              this.props.data.status === 'past' ?
                'Receipt Confirmation'
                : this.props.data.infoOffer.product.type === 'shots' && !this.props.data.infoOffer.subscribe ?
                  'Event Confirmation'
                  : this.props.data.infoOffer.product.type === 'shots' ?
                    'Appointment Confirmation'
                    :
                    'Booking Confirmation'
            }
            loader={false}
            header={false}
            styleTitle={{ fontSize: 27, fontFamily: Fonts.MarkOTBold, }}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  shareButton: {
    width: 15,
    height: 25,
    zIndex: 400,
    marginTop: 8.5
  },
  buttonCancel: {
    width: 15,
    height: 25,
    zIndex: 400,
    marginTop: 11
  },
  buttonCart: {
    width: 15,
    height: 25,
    zIndex: 400,
    marginTop: 11
  },
  apptContainer: {
    marginTop: 60,
  },
  text: {
    fontFamily: 'AvenirMedium',
    fontSize: 15,
    color: '#4a4a4a',
  },
  button: {
    height: 45, color: 'white', fontFamily: 'AvenirBold', width: '90%',
    fontWeight: 'bold',
    fontSize: 14,
  },
  button2: {
    height: 45, color: 'grey', fontFamily: 'AvenirBold', width: '100%',
    paddingTop: 15,
    fontWeight: 'bold',
    fontSize: 14,
  },
  textHeader: {
    color: 'white', fontFamily: 'AvenirMedium', fontSize: 12, marginTop: 15
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, {})(Home);
