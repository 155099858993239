import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../css/Home.css';
import Button from '@material-ui/core/Button';
import moment from 'moment'
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { isMobile, isIOS } from 'react-device-detect';
import DialogActions from '@material-ui/core/DialogActions';
import CopyToClipboard from 'react-copy-to-clipboard'

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showShare: false,
      loaders: {},
      showAppt: false,
      link: ''
    };
  }

  copytoState(link) {
    this.setState({ link: link })
  }
  onCopy() {
    this.props.close(false)
  }
  async shareOn(val) {
    var link = this.props.link
    var inviteCode = this.props.data.inviteCode
    var Description = 'Join me for ' + this.props.data.infoOffer.product.name + ' at ' + moment(this.props.data.infoOffer.date).format('h:mm a') + ' on ' + moment(this.props.data.infoOffer.date).format('ddd, MMM Do') + '. Enter invite code ' + inviteCode + ', or click below to sign up. ' + link
    var deeplink = ''
    if (val === 'whatsapp') {
      deeplink = 'https://wa.me/?text=' + encodeURIComponent(Description)
      window.open(deeplink);
    } else if (val === 'messenger') {
      deeplink = 'https://m.me/?text=' + encodeURIComponent(Description)
      window.open(deeplink);
    } else if (val === 'email') {
      window.location.href = "mailto:?subject=&body=" + Description;
    } else if (val === 'copy') {
      await this.copyStringToClipboard(link.toString())
    } else if (val === 'sms') {
      if (isIOS) {
        window.location.href = "sms:&body=" + Description;
      } else {
        window.location.href = "sms:?body=" + Description;
      }
    }
  }
  copyStringToClipboard(str) {
    var textarea = document.createElement('textarea');
    textarea.textContent = str;
    document.body.appendChild(textarea);

    var selection = window.getSelection();
    var range = document.createRange();
    range.selectNode(textarea);
    selection.removeAllRanges();
    selection.addRange(range);
    textarea.setSelectionRange(0, 999999);
    selection.removeAllRanges();
    document.body.removeChild(textarea);
    return true
  }

  render() {
    return (
      <div >
        <DialogTitle style={{ fontFamily: 'Avenir', }} id="alert-dialog-title">Share your invite link with your friends.</DialogTitle>
        <List component="nav">
          {isMobile ?
            <div>
              <ListItem button onClick={() => { this.shareOn('sms') }} >
                <ListItemIcon>
                  <i className="fa fa-1x fa-comments" style={{ color: '#26A65B' }}></i>
                </ListItemIcon>
                <div style={styles.text}>SMS</div>
              </ListItem>
            </div>
            : null
          }

          <ListItem button onClick={() => { this.shareOn('email') }} >
            <ListItemIcon>
              <i className="fa fa-1x fa-envelope" style={{ color: '#19B5FE' }}></i>
            </ListItemIcon>
            <div style={styles.text}>Email</div>
          </ListItem>
          <CopyToClipboard text={this.props.link} onCopy={() => { this.onCopy() }}>
            <ListItem button >
              <ListItemIcon>
                <i className="fa fa-1x fa-link" style={{ color: '#F3C13A' }}></i>
              </ListItemIcon>
              <div style={styles.text}>Copy link</div>
            </ListItem>
          </CopyToClipboard>
        </List>

        <DialogActions>
          <Button onClick={() => this.props.close(false)} color="primary" style={{ fontFamily: 'Avenir', }} autoFocus>
            Close
            </Button>
        </DialogActions>
      </div>
    );
  }
}

const styles = {
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontFamily: 'AvenirMedium',
    fontSize: 16,
    color: '#4a4a4a',
  },
  shareButton: {
    position: 'absolute',
    top: 10,
    width: 30,
    height: 30,
    zIndex: 400,
    left: window.innerWidth - 70
  },
  textHeader: {
    color: 'white', fontFamily: 'AvenirMedium'
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, {})(Home);
