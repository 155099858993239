import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import axios from 'axios'
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import { initApp } from '../../../actions/initAppActions'
import * as firebase from 'firebase'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopupDelete: false,
      showPopupCannotDelete: false
    };
  }
  async deleteCard(card) {
    var check = true
    if (!!this.props.dataUser.infoUser.appointments) {
      var filterActiveAppt = Object.values(this.props.dataUser.infoUser.appointments).filter(appt => appt.status !== 'past')
      if (filterActiveAppt.length !== 0) {
        check = false
      }
    }
    if (check) {
      await this.setState({ cardSelected: card })
      this.setState({ showPopupDelete: true })
    } else {
      this.setState({ showPopupCannotDelete: true })
    }
  }
  async confirmDeleteCard(card) {
    this.animate()
    var url = 'https://us-central1-instabo-production.cloudfunctions.net/deleteUserCreditCard'
    const results = await axios.get(url, {
      params: {
        CardID: card.id,
        userID: this.props.dataUser.userID,
        tokenStripeCus: this.props.dataUser.infoUser.wallet.tokenCusStripe,
      }
    })
    if (results.data.response) {
      if (card.id === this.props.dataUser.defaultCB.id && this.props.dataUser.numberOfCB > 0) {
        await firebase.database().ref('users/' + this.props.dataUser.userID + '/wallet/defaultCard/').update(Object.values(this.props.dataUser.infoUser.wallet.cards)[0])
      } else if (card.id === this.props.dataUser.defaultCB.id) {
        await firebase.database().ref('users/' + this.props.dataUser.userID + '/wallet/defaultCard/').remove()
      }
      this.setState({ showPopupDelete: false })
      this.props.back()
    } else {
      this.setState({ showPopupDelete: false })
    }
  }
  render() {
    return (
      <div className="App" >
        <Grid container style={{ marginBottom: 20 }} alignItems="center" justify="center">
          <Grid item xs={3}>
            {
              this.props.cardSelected.brand === 'Visa' ?
                <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/visa.png')} alt="" />
                : this.props.cardSelected.brand === 'applePay' ?
                  <img style={{ width: 29, height: 18, borderRadius: 1 }} src={require('../../../img/addCB/applePay.png')} alt="" />
                  : this.props.cardSelected.brand === 'googlePay' ?
                    <img style={{ width: 29, height: 20, borderRadius: 3 }} src={require('../../../img/addCB/googlePay.png')} alt="" />
                    : this.props.cardSelected.brand === 'MasterCard' ?
                      <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/mastercard.png')} alt="" />
                      : this.props.cardSelected.brand === 'American Express' ?
                        <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/amEx.png')} alt="" />
                        : this.props.cardSelected.brand === 'JCB' ?
                          <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/jcb.png')} alt="" />
                          : this.props.cardSelected.brand === 'Discover' ?
                            <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/discover.png')} alt="" />
                            : this.props.cardSelected.brand === 'Diners Club' ?
                              <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/dinnersClub.png')} alt="" />
                              :
                              <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/creditCard.png')} alt="" />
            }
          </Grid>
          <Grid item xs={9} style={styles.text}>
            {
              this.props.cardSelected.brand === 'applePay' || this.props.cardSelected.brand === 'googlePay' ?
                <div>{this.props.cardSelected.title}</div>
                :
                <div>{'•••• ' + this.props.cardSelected.last4 + '         ' + this.props.cardSelected.exp_month + '/' + this.props.cardSelected.exp_year}</div>
            }
          </Grid>
        </Grid>

        <div style={styles.divider}></div>

        <List component="nav">
          {
            this.props.cardSelected.brand !== 'applePay' && this.props.cardSelected.brand !== 'googlePay' ?
              <ListItem button onClick={() => { this.props.changeStep('edit') }} >
                <div style={styles.text}>Edit payment method</div>
                <ListItemSecondaryAction style={{ paddingRight: 15 }}>
                  <Icon style={{ color: '#9E9E9E' }}>keyboard_arrow_right</Icon>
                </ListItemSecondaryAction>
              </ListItem>
              : null
          }
          <ListItem button onClick={() => { this.deleteCard() }} >
            <div style={styles.text}>Delete payment method</div>
            <ListItemSecondaryAction style={{ paddingRight: 15 }}>
              <Icon style={{ color: '#9E9E9E' }}>keyboard_arrow_right</Icon>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button onClick={() => { this.props.selectDefaultCard(this.props.cardSelected) }} >
            <div style={styles.text}>Set as default</div>
            <ListItemSecondaryAction style={{ paddingRight: 15 }}>
              <Icon style={{ color: '#9E9E9E' }}>keyboard_arrow_right</Icon>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Dialog
          open={this.state.showPopupDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ fontFamily: 'Avenir', }} id="alert-dialog-title">Do you want to delete this payment method?</DialogTitle>
          <DialogActions>
            <Button onClick={() => this.setState({ showPopupDelete: false })} color="primary" style={{ fontFamily: 'Avenir', }} autoFocus>
              Cancel
            </Button>
            <Button onClick={() => this.confirmDeleteCard(this.props.cardSelected)} color="primary" style={{ fontFamily: 'Avenir', }} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.showPopupCannotDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ fontFamily: 'Avenir', }} id="alert-dialog-title">You can't delete this payment method until your appointment has taken place.</DialogTitle>
          <DialogActions>
            <Button onClick={() => this.setState({ showPopupCannotDelete: false })} color="primary" style={{ fontFamily: 'Avenir', }} autoFocus>
              Got it!
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const styles = {
  text: {
    fontFamily: 'AvenirMedium',
    fontSize: 15,
    color: '#4a4a4a',
    textAlign: 'left'
  },
  divider: {
    width: window.innerWidth - 30,
    height: 1,
    marginLeft: 15,
    backgroundColor: '#eaeaea',
    marginTop: 10,
    marginBottom: 10,
  },
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { initApp })(SignIn);
