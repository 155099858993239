import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import '../../../css/Home.css';
import HeaderBooking from './HeaderBooking'
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'
import ProductAdd from '../modulesBooking/ProductAdd'
import SecondaryAdd from '../modulesBooking/SecondaryAdd'
import { Fonts } from '../../../utils/Font'
import colors from '../../layout/colors'
import ScrollViewPage from '../../layout/scrollviews/ScrollViewPage'

class Product extends Component {
  async componentWillMount() {
    window.scrollTo(0, 0)
  }
  back() {
    this.props.bookingFlowAction('setCurrentPage', { value: 'Person' })
  }
  doneTime() {
    this.timePicker.doneTime()
  }
  bodyContent() {
    return (
      <Grid container direction="column" alignItems="center" justify="center" style={{ marginTop: -40 }}>
        <Grid item direction="column">
          <Grid item style={styles.subtitle}>{this.props.category.additionalProduct[0].sentence}</Grid>
          {
            this.props.category.enableAddProduct ?
              <ProductAdd category={this.props.category} editable={true} additionalOn={true} list={this.props.category.additionalProduct} clickable={false} additional={this.props.dataHome.additionalProduct} />
              : null
          }
          {!this.props.category.enableAddProduct ?
            <div style={{ marginTop: 30 }}></div>
            : null}
          {
            this.props.category.enableAddSecondary ?
              <SecondaryAdd editable={true} product={this.props.product} category={this.props.category} />
              : null
          }
        </Grid>
      </Grid>
    )
  }
  headerContent() {
    return (<HeaderBooking title={'Additional Products'} back={this.back.bind(this)} loader={false} journeyShow={this.props.journeyShow} />)
  }
  render() {
    return (
      <div className="App">
        {this.headerContent()}
        <ScrollViewPage
          content={this.bodyContent.bind(this)}
          loader={false}
          bookingFlow={true}
          header={false}
          styleTitle={{ fontSize: 27, fontFamily: Fonts.MarkOTBold, }}
        />
      </div>
    );
  }
}
const styles = {
  buttonBack: {
    position: 'absolute',
    top: 75,
    left: 15,
    zIndex: 20,
  },
  title: {
    fontFamily: 'Avenir',
    fontSize: 23,
    fontWeight: 'Bold',
    paddingLeft: 15,
    color: '#4a4a4a',
    marginTop: 10,
  },
  subtitle: {
    fontFamily: Fonts.MarkOT,
    fontSize: 16,
    color: colors.subtitle,
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 15,
    marginBottom: -15,
    textAlign: 'left',
  },
  secondRes: {
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  mainRes: {
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'bold',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  rowResult: {
    flex: 1, borderBottomWidth: 1, borderColor: '#EAEAEA', paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15
  },
  textIngredient: {
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
    paddingLeft: 15,
  },
  textBenefit: {
    fontFamily: 'Avenir',
    fontSize: 17,
    marginTop: 10,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    paddingLeft: 15,
    height: 30,
  },
  titleBenefit: {
    fontFamily: 'Avenir',
    fontSize: 19,
    fontWeight: 'Bold',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
  },
  divider: {
    width: window.innerWidth - 30,
    height: 1,
    marginLeft: 15,
    backgroundColor: '#eaeaea',
    marginTop: 10,
    marginBottom: 10,
  },
  row: {
    textAlign: 'left',
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    color: '#4a4a4a',
    paddingTop: 5
  },
  boxPrice: {
    width: 70,
    height: 25,
    borderRadius: 15,
    bottom: 20,
    right: 15,
    backgroundColor: '#FAF9F9',
    alignItems: 'center',
    fontFamily: 'Avenir',
    justifyContent: 'center',
    borderColor: '#EAEAEA',
    color: '#8CB5A1',
    paddingTop: 9,
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(Product);
