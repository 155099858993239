import {
  UPDATE_TAB_APP,
  UPDATE_TAB_LOADING
} from '../actions/types';

const initialState = {
  CurrentTab: 'home',
  PasswordSet: 'load',
}

const initConnectedReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TAB_APP:
      return { CurrentTab: action.newTab };
    case UPDATE_TAB_LOADING:
      return { ...state, PasswordSet: action.PasswordSet };
    default:
      return state;
  }
}

export default initConnectedReducer;
