import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import colors from './../layout/colors'
import { Fonts } from '../../utils/Font';

export default class CardBenefit extends Component {
  render() {
    return (
      <Grid container justify="center" alignItems="center" style={styles.cardBenefit}>
        <Grid item style={styles.textBenefit}>{this.props.benefit}</Grid>
      </Grid>
    )
  }
}
const styles = {
  cardBenefit: {
    borderRadius: 4,
    backgroundColor: colors.primary,
    color: 'white',
  },
  textBenefit: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 12,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 7,
    marginBottom: 7
  }
}