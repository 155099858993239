import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import colors from '../../../layout/colors';
import { Fonts } from '../../../../utils/Font'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import Typography from '@material-ui/core/Typography'
import firebase from 'firebase'
import moment from 'moment'
import jstz from 'jstz'

class ProviderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availabilities: {},
      todayAvailabilities: [],
      nextAvailability: '',
      loader: false,
      providerInfo: undefined
    }
  }
  componentWillMount() {
    this.initialLoad()
  }
  async initialLoad() {
    try {
      var totalAvailabilities = await firebase.database().ref('nurses/' + this.props.provider.nurseID + '/availabilities').once('value')
      totalAvailabilities = totalAvailabilities.val()
      if (totalAvailabilities != null) {
        this.loadAvailabilities(totalAvailabilities, this.props.date, this.props.provider, 'initial')
      }
    } catch (err) {
      return true
    }
  }
  async loadAvailabilities(totalAvailabilities1, dateAppt, providerInfo, valLoader) {
    this.setState({ loader: valLoader })
    var totalAvailabilities = Object.values(totalAvailabilities1).sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });
    if (totalAvailabilities == null) {
      this.setState({
        availabilities: {},
        todayAvailabilities: {},
        nextAvailability: '',
        loader: false,
        providerInfo: providerInfo
      })
    } else {
      var currentAvailability = {
        date: ''
      }
      var counter = 1
      var todayAvailabilities = []
      var nextAvailability = []
      for (var i in totalAvailabilities) {
        var availability = { ...totalAvailabilities[i] }
        if (currentAvailability.date === '' && !availability.booked) {
          currentAvailability = availability
          counter = 1
        } else if (currentAvailability.date !== '') {
          if (counter === 6) {
            var availabilityToPush = { ...currentAvailability }

            // availabilityToPush.date = currentAvailability
            if (moment(dateAppt).format('DDD') === moment(currentAvailability.date.split(' GMT')[0]).format('DDD') && Number(new Date()) + 60 * 60 * 1000 < Number(new Date(currentAvailability.date))) {
              todayAvailabilities.push(availabilityToPush)
              nextAvailability.push(availabilityToPush)
            } else if (Number(moment().format('DDD')) <= Number(moment(currentAvailability.date).format('DDD')) && Number(new Date(dateAppt)) < Number(new Date(currentAvailability.date)) && Number(new Date()) + 60 * 60 * 1000 < Number(new Date(currentAvailability.date))) {
              nextAvailability.push(availabilityToPush)
            }
            currentAvailability = availability
            counter = 1
          } else {
            if ((moment(currentAvailability.date).add(counter * 15, 'm')).toString() === (moment(availability.date)).toString() && !availability.booked) {
              counter = counter + 1
            } else if ((moment(currentAvailability.date).add(counter * 15, 'm')).toString() === (moment(availability.date)).toString() && !!availability.booked) {
              currentAvailability = { date: '' }
              counter = 1
            } else {
              currentAvailability = availability
              counter = 1
            }
          }
        }
      }
      this.setState({
        availabilities: totalAvailabilities,
        todayAvailabilities: todayAvailabilities,
        nextAvailability: nextAvailability,
        loader: false,
        providerInfo: providerInfo
      })
    }
  }
  specialty(specialties, specialty, i) {
    if (i === specialties.length - 1) return specialty
    return (specialty !== '') ? (specialty + ', ') : ''
  }
  displayAvailability(availability) {
    if (moment.tz(new Date(), jstz.determine().name()).format('Z') !== availability.date.split(' GMT')[1]) return moment(availability.date.split(' GMT')[0]).format('h:mm a') + ' (' + availability.timeZoneName + ')'
    return moment(availability.date.split(' GMT')[0]).format('h:mm a')
  }
  availabilityStyle(availability) {
    if (availability.date === this.props.date && this.props.provider.nurseID === this.props.nurseID) return styles.cardAvailabilityOn
    return styles.cardAvailability
  }
  availabilityTextStyle(availability) {
    if (availability.date === this.props.date && this.props.provider.nurseID === this.props.nurseID) return styles.availTextOn
    return styles.availText
  }
  timeNextAvailabilities() {
    if (moment.tz(new Date(), jstz.determine().name()).format('Z') !== this.state.nextAvailability[0].date.split(' GMT')[1]) {
      return moment(this.state.nextAvailability[0].date.split(' GMT')[0]).format('h:mm a') + ' (' + this.state.nextAvailability[0].timeZoneName + ')'
    }
    return moment(this.state.nextAvailability[0].date.split(' GMT')[0]).format('h:mm a')
  }
  NextAvailabilities() {
    return (
      <Grid container direction="row">
        <Grid>
          <Typography style={styles.titleAvailability}>Next availability:
              <Typography style={{ fontFamily: Fonts.MarkOTMedium, color: colors.subtitle, fontSize: 12 }}> {moment(this.state.nextAvailability[0].date.split(' GMT')[0]).format('ddd, Do MMM')} at {this.timeNextAvailabilities()}</Typography>
          </Typography>
        </Grid>
      </Grid>
    )
  }
  NoAvailabilities() {
    return (
      <Grid container direction="row">
        <Grid >
          <Typography style={styles.titleAvailability2}>No future availabilities</Typography>
        </Grid>
      </Grid>
    )
  }
  render() {
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid container xs={4} justify="flex-end" alignItems="center" style={{ height: 160, width: '100%', borderRadius: 4 }} className="pointer">
          <Grid item style={{ borderRadius: 4, paddingLeft: 20 }}>
            <img
              src={this.props.provider.picture}
              style={{
                width: '90%',
                maxWidth: 110,
                height: 110,
                borderRadius: 4,
                objectFit: 'cover'
              }}
              alt=""
            />
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid container direction="column" style={{ height: 160, marginLeft: 20, marginBottom: -15, marginTop: 5 }}>
            <Grid style={{ ...styles.providerTitle }}>{this.props.provider.firstname + ' ' + this.props.provider.lastname}</Grid>
            <Grid>
              {!!this.props.provider.specialties && !this.props.provider.specialties.every(spec => spec === '' || spec === ' ') ?
                <Typography style={styles.subtitle} >
                  {this.props.provider.specialties.map((specialty, i) => (
                    this.specialty(this.props.provider.specialties, specialty, i)
                  ))}
                </Typography>
                : null
              }
            </Grid>
            {
              this.state.loader && (Object.values(this.state.availabilities).length === 0 || this.state.nextAvailability.length === 0) ?
                this.NoAvailabilities()
                : this.state.loader && Object.values(this.state.todayAvailabilities).length === 0 ?
                  this.NextAvailabilities()
                  : this.state.loader || this.state.loader === 'initial' ?
                    <Grid style={{ height: 50, paddingTop: 5, }}></Grid>
                    : Object.values(this.state.availabilities).length === 0 || this.state.nextAvailability.length === 0 ?
                      this.NoAvailabilities()
                      : Object.values(this.state.todayAvailabilities).length === 0 ?
                        this.NextAvailabilities()
                        :
                        <div className="benefitScrollView">
                          {this.state.todayAvailabilities.map((availability, i) => (
                            <Grid
                              container
                              justify="center"
                              alignContent="center"
                              className="pointer"
                              style={this.availabilityStyle(availability)}
                              onClick={() => this.props.setSlotAppt(availability, this.props.provider.nurseID)}>
                              <Typography style={this.availabilityTextStyle(availability)}>
                                {this.displayAvailability(availability)}
                              </Typography>
                            </Grid>
                          ))}
                        </div>
            }
          </Grid>
        </Grid>
        <Grid container xs={3} justify="flex-start" alignContent="center" style={{ ...styles.center, height: 160 }}>
          <FontAwesomeIcon icon={faAngleRight} color={colors.subtitle} size="1x" />
        </Grid>
      </Grid>
    )
  }
}
export default ProviderList;

const styles = {
  header: {
    margin: 3,
    position: 'absolute',
    left: 'inherit',
    top: 0
  },
  title: {
    color: '#4a4a4a', fontFamily: Fonts.MarkOTBold, fontSize: 20, marginLeft: 40, marginTop: 10
  },
  providerTitle: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 16,
    textAlign: 'left',
    color: colors.title,
    paddingTop: 15
  },
  subtitle: {
    fontFamily: Fonts.MarkOT,
    fontSize: '11px',
    width: '100%',
    maxWidth: 600 * 0.5,
    lineHeight: '12px',
    maxHeight: '36px',
    overflowY: 'hidden',
    textAlign: 'left',
    color: colors.title,
    paddingBottom: 15,
    marginTop: 2
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardAvailability: {
    border: '1px solid ' + colors.primary,
    backgroundColor: 'white', borderRadius: 3, marginRight: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto',
    maxWidth: 120
  },
  cardAvailabilityOn: {
    height: 35, backgroundColor: colors.primary, borderRadius: 3, borderColor: colors.primary, borderWidth: 1, marginRight: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  availText: {
    color: colors.primary,
    fontSize: 13,
    fontFamily: Fonts.MarkOTMedium,
  },
  availTextOn: {
    color: 'white',
    fontSize: 13,
    marginLeft: 10,
    fontFamily: Fonts.MarkOTMedium,
  },
  titleAvailability: {
    color: colors.subtitle,
    fontSize: 13,
    fontFamily: Fonts.MarkOT,
  },
  titleAvailability2: {
    color: colors.subtitle,
    fontSize: 13,
    fontFamily: Fonts.MarkOT,
  },
};
