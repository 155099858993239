import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../css/Home.css';
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'
import Product from '../elementsBookingFlow/Product'
import RowProductAdd from './RowProductAdd'
import RowProductAdd2 from './RowProductAdd2'
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { omit } from 'lodash';
function Transition(props) {
  return <Slide direction="left" {...props} />;
}

class ProductAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: {},
      showProduct: false,
      product: {},
      category: {}
    };
  }
  componentWillMount() {
    var list = {}
    if (!this.props.additionalOn) {
      var totalList;
      var product;
      var productSaved;
      var price;

      if (Object.values(this.props.dataHome.attendeeProduct).length !== 0) {
        totalList = this.props.dataHome.attendeeProduct
        for (var i in totalList) {
          product = totalList[i]
          productSaved = product
          price = 0
          if ((product.pricesGroup.filter(price => price.value === Number(this.props.dataHome.numberUnit)).length !== 0)) {
            price = product.pricesGroup.filter(price => price.value === Number(this.props.dataHome.numberUnit))[0].price
          } else {
            price = product.pricesGroup.filter(price => price.type === 'greater')[0].price
          }
          productSaved.price = price
          list = {
            ...list,
            [i]: productSaved
          }
        }
      } else {
        totalList = this.props.list.reverse()
        for (i in totalList) {
          product = totalList[i]
          productSaved = product
          productSaved.numberAdded = 0
          productSaved.name = product.name
          productSaved.image = product.image
          price = 0
          if ((product.pricesGroup.filter(price => price.value === Number(this.props.dataHome.numberUnit)).length !== 0)) {
            price = product.pricesGroup.filter(price => price.value === Number(this.props.dataHome.numberUnit))[0].price
          } else {
            price = product.pricesGroup.filter(price => price.type === 'greater')[0].price
          }
          if (product.name === this.props.dataHome.productChosen.name) {
            productSaved.numberAdded = 1
          }
          productSaved.price = price
          list = {
            ...list,
            [i]: productSaved
          }
        }
      }
      this.props.homeAction('setAttendeeProduct', { value: list })
    } else {
      if (!this.props.editable) {
        list = this.props.additional || {}
        if (Object.values(list).length === 0) {
          totalList = this.props.list
          for (i in totalList) {
            product = totalList[i]
            product.numberAdded = 0
            list = {
              ...list,
              [i]: product
            }
          }
        }
      } else {
        if (Object.values(this.props.dataHome.additionalProduct).length !== 0) {
          list = this.props.dataHome.additionalProduct
        } else {
          totalList = this.props.list
          for (i in totalList) {
            product = totalList[i]
            product.numberAdded = 0
            list = {
              ...list,
              [i]: product
            }
          }
        }
      }
    }
    this.setState({ products: list })
  }
  add(name, value) {
    if (this.props.editable) {
      var list = this.state.products
      var index = Object.keys(this.state.products)[Object.values(this.state.products).findIndex(product => product.name === name)]
      var product = list[index]
      product.numberAdded = product.numberAdded + value
      list = {
        ...list,
        [index]: product
      }
      this.setState({ products: list })
      if (this.props.additionalOn) {
        this.props.homeAction('setAdditionalProduct', { value: list })
      } else {
        this.props.homeAction('setAttendeeProduct', { value: list })
      }
    }
  }
  async openProduct(val, product, category) {
    if (val) {
      await this.setState({ product: product, category: category })
    }
    this.setState({ showProduct: val })
  }
  render() {
    return (
      <div style={{ marginTop: 15 }}>
        {
          !this.props.dataHome.subscribe || !this.props.editable ?
            <div style={{ paddingTop: 10 }}>
              {Object.values(this.state.products).map((product, i) => (
                <RowProductAdd openProduct={this.openProduct.bind(this)} additional={this.props.additional} editable={this.props.editable} clickable={this.props.clickable} add={this.add.bind(this)} product={product} index={i} key={i} additionalOn={this.props.additionalOn} category={this.props.category} />
              ))}
            </div>
            :
            <div style={{ paddingTop: 10 }}>
              {Object.values(this.props.dataHome.attendeeProduct).filter(product => product.numberAdded !== 0).map((product, i) => (
                <RowProductAdd2 clickable={true} add={this.add.bind(this)} product={product} index={i} key={i} category={this.props.category} />
              ))}
            </div>
        }

        <Dialog
          fullScreen
          open={this.state.showProduct}
          TransitionComponent={Transition}
        >
          <Product journeyShow={this.props.journeyShow} category={this.state.category} product={this.state.product} popup={true} {...omit(this.props, ['product', 'category'])} close={this.openProduct.bind(this)} />
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(ProductAdd);
