import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';
import Product from './elementsBookingFlow/Product';
import Location from './elementsBookingFlow/Location';
import ProviderPreference from './elementsBookingFlow/ProviderPreference';
import '../../css/Home.css';
import FooterBooking from './elementsBookingFlow/FooterBooking';
import Time from './elementsBookingFlow/Time';
import AddSecondary from './elementsBookingFlow/AddSecondary';
import Person from './elementsBookingFlow/Person';
import UnitSelection from './elementsBookingFlow/UnitSelection'
import Booster from './elementsBookingFlow/Booster';
import MoreInfo from './elementsBookingFlow/MoreInfo';
import Checkout from './elementsBookingFlow/Checkout';
import AddProduct from './elementsBookingFlow/AddProduct';
import AddAdditional from './elementsBookingFlow/AddAdditional';
import FinalSummary from './elementsBookingFlow/FinalSummary';
import PatientConsent from './elementsBookingFlow/PatientConsent';

class ListProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      category: {},
      pricing: {}
    };
  }
  componentWillMount() {
    window.scrollTo(0, 0)
    var category;

    if (!this.props.category) {
      var typeCategory = this.props.match.params.typeCategory
      var nameImg = this.props.match.params.productName

      category = this.props.dataUser.instaviceVariables.homePage2.filter(category => category.type === typeCategory)[0]
      if (category.straightToProduct) {
        this.setState({ product: category.card })
      } else {
        this.setState({ product: category.list.filter(product => product.imgName === nameImg)[0] })
      }
    } else {
      category = this.props.category
      this.setState({ product: this.props.dataHome.productChosen })
    }

    this.setState({ category: category })
    ReactPixel.pageView()
  }
  async doneTime() {
    await this.timePicker.doneTime()
  }
  setPricing(pricing) {
    this.setState({ pricing: pricing })
  }
  openPayments() {
    this.checkoutRef.openPayments()
  }
  render() {
    return (
      <div className="App">
        {
          this.props.dataFlow.currentPage === 'Product' ?
            <Product {...this.props} category={this.state.category} product={this.state.product} journeyShow={this.props.journeyShow} />
            : this.props.dataFlow.currentPage === 'AddSecondary' ?
              <AddSecondary {...this.props} product={this.state.product} category={this.state.category} journeyShow={this.props.journeyShow} />
              : this.props.dataFlow.currentPage === 'Location' ?
                <Location {...this.props} category={this.state.category} journeyShow={this.props.journeyShow} />
                : this.props.dataFlow.currentPage === 'ProviderPreference' ?
                  <ProviderPreference {...this.props} category={this.state.category} journeyShow={this.props.journeyShow} />
                  : this.props.dataFlow.currentPage === 'Time' ?
                    <Time {...this.props} onRef={ref => (this.timePicker = ref)} journeyShow={this.props.journeyShow} />
                    : this.props.dataFlow.currentPage === 'Person' ?
                      <Person {...this.props} product={this.state.product} journeyShow={this.props.journeyShow} />
                      : this.props.dataFlow.currentPage === 'UnitSelection' ?
                        <UnitSelection {...this.props} category={this.state.category} journeyShow={this.props.journeyShow} />
                        : this.props.dataFlow.currentPage === 'Booster' ?
                          <Booster {...this.props} product={this.state.product} category={this.state.category} journeyShow={this.props.journeyShow} />
                          : this.props.dataFlow.currentPage === 'MoreInfo' ?
                            <MoreInfo {...this.props} product={this.state.product} journeyShow={this.props.journeyShow} category={this.state.category} />
                            : this.props.dataFlow.currentPage === 'PatientConsent' ?
                              <PatientConsent {...this.props} product={this.state.product} journeyShow={this.props.journeyShow} />
                              : this.props.dataFlow.currentPage === 'AddProduct' ?
                                <AddProduct {...this.props} product={this.state.product} category={this.state.category} journeyShow={this.props.journeyShow} />
                                : this.props.dataFlow.currentPage === 'AddAdditional' ?
                                  <AddAdditional {...this.props} product={this.state.product} category={this.state.category} journeyShow={this.props.journeyShow} />
                                  : this.props.dataFlow.currentPage === 'Checkout' ?
                                    <Checkout setPricing={this.setPricing.bind(this)} {...this.props} product={this.state.product} category={this.state.category} onRef={ref => (this.checkoutRef = ref)} journeyShow={this.props.journeyShow} />
                                    : this.props.dataFlow.currentPage === 'finalSummary' ?
                                      <FinalSummary {...this.props} product={this.state.product} category={this.state.category} pricing={this.state.pricing} journeyShow={this.props.journeyShow} />
                                      : null
        }

        {
          this.props.dataFlow.currentPage !== 'finalSummary' && this.props.dataFlow.currentPage !== 'ProviderPreference' && this.props.dataFlow.currentPage !== 'PatientConsent' ?
            <FooterBooking pricing={this.state.pricing} category={this.state.category} product={this.state.product} doneTime={this.doneTime.bind(this)} openPayments={this.openPayments.bind(this)} journeyShow={this.props.journeyShow} />
            :
            null
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location,
  dataFlow: state.bookingFlow
})
export default connect(mapStateToProps, {})(ListProducts);
