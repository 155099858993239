import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import '../../../css/Home.css';
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import colors from '../../layout/colors'
import { Fonts } from '../../../utils/Font'
class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: ['Myself', 'Somebody else'],
      options2: ['Me and my friends', 'Somebody else'],
      currentOptions: [],
      optionSelected: ''
    };
  }
  componentWillMount() {
    this.props.onRef(this)
    this.loadList()
  }
  loadList() {
    var currentOptions = []
    if (this.props.dataHome.numberUnit === 1) {
      currentOptions = this.state.options
    } else {
      currentOptions = this.state.options2
    }
    if (this.props.dataHome.receiver !== '') {
      if (this.props.dataHome.receiver === 'me') {
        this.setState({ optionSelected: currentOptions[0] })
      } else {
        this.setState({ optionSelected: currentOptions[1] })
      }
    }
    this.setState({ currentOptions: currentOptions })
  }
  tick(option) {
    var optionSelect = ''
    this.setState({ optionSelected: option })
    if (option === this.state.currentOptions[0]) {
      optionSelect = 'me'
    } else {
      optionSelect = 'else'
    }
    this.props.changeReceiver()
    this.props.homeAction('setPaymentOption', { value: {} })
    this.props.homeAction('setReceiver', { value: optionSelect })
  }
  render() {
    return (
      <Grid container row alignContent="center" justify="center" className="borderOff pointer" style={{ width: '96%', marginLeft: '3%', marginTop: 20, borderRadius: 7 }}>
        <Grid container column style={{ margin: 5 }}>
          {this.state.currentOptions.map((option, i) => (
            <Grid container row style={{ height: 45 }} onClick={() => { this.tick(option) }} className="pointer">
              <Grid container xs={2} alignContent="center" justify="center" alignItems="center">
                {
                  option === this.state.optionSelected ?
                    <Grid item><FontAwesomeIcon icon={faCheck} color={colors.primary} size="lg" /></Grid>
                    :
                    <Grid item><FontAwesomeIcon icon={faCheck} color='#eaeaea' size="lg" /></Grid>
                }
              </Grid>
              <Grid container alignContent="center" alignItems="center" justify="flex-start" xs={10} style={styles.subtitle}>
                <Grid item>{option}</Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  }
}
const styles = {
  buttonBack: {
    position: 'absolute',
    top: 75,
    left: 15,
    zIndex: 20,
  },
  buttonOff: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 23,
    width: 27,
    borderRadius: 13.5,
    textAlign: 'center',
    paddingTop: 3,
    borderColor: '#C1DACE',
    backgroundColor: '#eaeaea',
    borderWidth: 2
  },
  buttonOn: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 23,
    width: 27,
    borderRadius: 13.5,
    textAlign: 'center',
    paddingTop: 3,
    borderColor: '#C1DACE',
    backgroundColor: '#C1DACE',
    borderWidth: 2
  },
  title: {
    fontFamily: Fonts.MarkOT,
    fontSize: 23,
    fontWeight: 'Bold',
    paddingLeft: 15,
    color: '#4a4a4a',
    marginTop: 10,
  },
  subtitle: {
    fontFamily: Fonts.MarkOT,
    fontSize: 14,
    paddingLeft: 5,
    textAlign: 'left',
    color: '#4a4a4a',
  },
  secondRes: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  mainRes: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'bold',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  rowResult: {
    flex: 1, borderBottomWidth: 1, borderColor: '#EAEAEA', paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15
  },
  textIngredient: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
    paddingLeft: 15,
  },
  textBenefit: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    marginTop: 10,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    paddingLeft: 15,
    height: 30,
  },
  titleBenefit: {
    fontFamily: Fonts.MarkOT,
    fontSize: 19,
    fontWeight: 'Bold',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
  },
  divider: {
    width: window.innerWidth - 30,
    height: 1,
    marginLeft: 15,
    backgroundColor: '#eaeaea',
    marginTop: 10,
    marginBottom: 10,
  },
  row: {
    textAlign: 'left',
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    color: '#4a4a4a',
    paddingTop: 5
  },
  boxPrice: {
    width: 70,
    height: 25,
    borderRadius: 15,
    bottom: 20,
    right: 15,
    backgroundColor: '#FAF9F9',
    alignItems: 'center',
    fontFamily: Fonts.MarkOT,
    justifyContent: 'center',
    borderColor: '#EAEAEA',
    color: '#8CB5A1',
    paddingTop: 9,
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(Product);
