import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../css/Home.css';
import Icon from '@material-ui/core/Icon';
import { Fonts } from '../../../utils/Font'
import colors from '../../layout/colors'
import IconButton from '@material-ui/core/IconButton';
import { Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUniversity, faCertificate } from '@fortawesome/free-solid-svg-icons'

export default class ProviderPage extends Component {
  ageProvider() {
    return ((Number(new Date()) - Number(new Date(this.props.provider.birthdate))) / (1000 * 60 * 60 * 24 * 365)).toFixed(0) + ' years old'
  }
  specialty(specialty, i) {
    if (i === this.props.provider.specialties.length - 1) return specialty
    return (specialty !== '') ? (specialty + ', ') : ''
  }
  render() {
    if (!!this.props.provider) return (
      <div style={{ width: '100%', height: 700, overflowY: 'hidden', backgroundColor: 'rgba(255, 255, 255, 1.0)' }}>
        {!this.props.hidden ?
          <IconButton color="inherit" style={styles.backBtn} onClick={() => this.props.close()} aria-label="Close">
            <Icon style={{ color: 'white' }}>clear</Icon>
          </IconButton> : null}
        <img
          style={{ width: '100%', objectFit: 'cover', height: 300 }}
          src={this.props.provider.picture}
          alt=""
        />
        <Grid container direction="column" style={{ marginTop: 10, marginLeft: 20, maxWidth: 310, width: '80%' }} >
          <Typography style={styles.title} ></Typography>
          {
            !this.props.provider.title.name ?
              <Typography style={styles.title} >{this.props.provider.firstname} {this.props.provider.lastname}, {this.props.provider.title}</Typography>
              :
              <Typography style={styles.title} >{this.props.provider.firstname} {this.props.provider.lastname}, {this.props.provider.title.name}</Typography>
          }
          {this.props.specialtiesEnabled() && !this.props.provider.specialties.every(spec => spec === '') ?
            <Typography style={styles.subtitle} >
              {this.props.provider.specialties.map((specialty, i) => (
                this.specialty(specialty, i)
              ))}
            </Typography>
            : null
          }
          <Typography style={styles.subtitle} >{this.ageProvider()}</Typography>
          <Typography style={styles.subtitle} >{this.props.provider.description}</Typography>

          <Typography style={styles.title2}>Education</Typography>

          {Object.values(this.props.provider.education).reverse().map((education, i) => (
            <Grid container direction="row" key={i} style={{ width: '100%', marginBottom: 8 }}>
              <Grid container alignItems="flex-end" xs={2} style={styles.center}>
                <Grid item>
                  {
                    i === 0 ?
                      <FontAwesomeIcon icon={faUniversity} style={{ color: colors.subtitle }}></FontAwesomeIcon>
                      : i === 1 ?
                        <Icon style={{ color: colors.subtitle, marginRight: 1 }} fontSize="small">school</Icon>
                        :
                        <FontAwesomeIcon icon={faCertificate} style={{ color: colors.subtitle }}></FontAwesomeIcon>
                  }
                </Grid>
              </Grid>
              <Grid item xs={10} style={styles.center2}>
                <Typography style={{ ...styles.textBenefit, marginTop: (i === 1) ? 2 : 0 }}>{education}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    )
    else return (<div></div>)
  }
}
const styles = {
  backBtn: {
    margin: 3,
    position: 'absolute',
    top: 0,
    left: 'inherit',
  },
  title: {
    color: colors.title,
    fontSize: 22,
    fontFamily: Fonts.MarkOTBold,
  },
  title2: {
    color: colors.title,
    fontSize: 17,
    marginTop: 15,
    marginBottom: 10,
    fontFamily: Fonts.MarkOTBold,
  },
  subtitle: {
    marginTop: 5,
    color: colors.subtitle,
    fontSize: 13,
    fontFamily: Fonts.MarkOT,
  },
  textBenefit: {
    color: colors.subtitle,
    fontSize: 12,
    fontFamily: Fonts.MarkOT,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  center2: {
    justifyContent: 'center',
  },
}