import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../css/App.css';
import ReactPixel from 'react-facebook-pixel';
import LogIn from './elementsProfile/LogIn'
import ProfilePage from './elementsProfile/ProfilePage'
import ScrollViewPage from './layout/scrollviews/ScrollViewPage'
import { Fonts } from '../utils/Font'

class Profile extends Component {
  componentWillMount() {
    ReactPixel.pageView()
  }
  bodyContent() {
    return (
      <div style={{ marginTop: -40 }}>
        {
          this.props.dataUser.userConnected ?
            <ProfilePage {...this.props} journeyShow={this.props.journeyShow} />
            :
            <LogIn journeyShow={this.props.journeyShow} />
        }
      </div>
    )
  }
  render() {
    return (
      <div className="App">
        <ScrollViewPage
          content={this.bodyContent.bind(this)}
          loader={false}
          bookingFlow={true}
          header={false}
          styleTitle={{ fontSize: 27, fontFamily: Fonts.MarkOTBold }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, {})(Profile);
