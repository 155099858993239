import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import '../../../css/Home.css';
import Divider from '@material-ui/core/Divider'
import moment from 'moment'
import momentTZ from 'moment-timezone';
import { Fonts } from '../../../utils/Font'
import colors from '../../layout/colors'
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showShare: false,
      loaders: {},
      showAppt: false,
      link: '',
      showMore: false,
      img: undefined
    };
  }
  componentWillMount() {
    this.getImg()
  }
  call(phone) {
    window.open("tel:" + phone);
  }
  sms(phone) {
    window.location.href = "sms:" + phone;
  }
  getImg() {
    try {
      this.setState({ img: this.props.data.infoOffer.product.imageSmall })
    } catch {
      try {
        this.setState({ img: require(`../../../img/category/${this.props.data.infoOffer.product.type}/${this.props.data.infoOffer.product.name.toLowerCase()}.jpg`) })
      } catch {
        if (this.props.data.infoOffer.product.type === 'shots') {
          this.setState({ img: require(`../../../img/category/${this.props.data.infoOffer.product.type}/${this.props.data.infoOffer.product.type}.jpg`) })
        }
      }
    }
    if (this.state.img === undefined) this.setState({ img: this.props.data.infoOffer.product.image })
  }
  render() {
    return (
      <div>
        <Grid container jutify="center" alignItems="center">
          <Grid item xs={3}>
            <img
              style={{ width: '65%', maxWidth: 150, height: 70, borderRadius: 9, objectFit: 'cover' }}
              src={this.state.img}
              alt=""
            />
          </Grid>
          <Grid item xs={7} style={{ textAlign: 'left', paddingLeft: 5, paddingRight: 10 }}>

            {
              this.props.data.infoOffer.product.type === 'shots' ?
                <div>
                  <div style={styles.title}>{this.props.data.infoOffer.product.name}</div>
                  {
                    !this.props.data.location.timeZone ?
                      <div style={styles.contentText}>{moment(this.props.data.infoOffer.date).format('ddd, Do MMM • h:mm')} to {moment(this.props.data.infoOffer.date).add(this.props.data.infoOffer.durationEvent, 'm').format('h:mm a')}</div>
                      : moment().format('Z').toString() !== this.props.data.location.timeZone.offSet ?
                        <div style={styles.contentText}>{momentTZ.tz(this.props.data.infoOffer.date, this.props.data.location.timeZone.timeZoneId).format('ddd, Do MMM • h:mm a')} to {momentTZ.tz(this.props.data.infoOffer.date, this.props.data.location.timeZone.timeZoneId).add(this.props.data.infoOffer.durationEvent, 'm').format('h:mm a')} ({this.props.data.location.timeZone.timeZoneName2})</div>
                        :
                        <div style={styles.contentText}>{momentTZ.tz(this.props.data.infoOffer.date, this.props.data.location.timeZone.timeZoneId).format('ddd, Do MMM • h:mm a')} to {momentTZ.tz(this.props.data.infoOffer.date, this.props.data.location.timeZone.timeZoneId).add(this.props.data.infoOffer.durationEvent, 'm').format('h:mm a')}</div>
                  }
                  <div style={styles.contentText}>{this.props.data.location.businessName}</div>
                </div>
                :
                <div>
                  <div style={styles.title}>{this.props.data.infoOffer.product.place}• {this.props.data.infoOffer.product.name}</div>
                  {
                    !this.props.data.location.timeZone ?
                      <div style={styles.contentText}>{moment(this.props.data.infoOffer.date).format('ddd, Do MMM • h:mm a')}</div>
                      : moment().format('Z').toString() !== this.props.data.location.timeZone.offSet ?
                        <div style={styles.contentText}>{momentTZ.tz(this.props.data.infoOffer.date, this.props.data.location.timeZone.timeZoneId).format('ddd, Do MMM • h:mm a')} ({this.props.data.location.timeZone.timeZoneName2})</div>
                        :
                        <div style={styles.contentText}>{momentTZ.tz(this.props.data.infoOffer.date, this.props.data.location.timeZone.timeZoneId).format('ddd, Do MMM • h:mm a')}</div>
                  }
                </div>
            }
            <div style={styles.contentText}>{this.props.data.location.address}</div>
          </Grid>
        </Grid>

        {
          this.props.data.infoOffer.product.type !== 'shots' ?
            <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 15, marginBottom: 15 }} />
            : null
        }

        {
          !!this.props.data.nurseInfo && this.props.data.infoOffer.product.type !== 'shots' ?
            <Grid container justify="center" alignItems="center" onClick={() => this.props.openProviderPage()}>
              <Grid item xs={3} justifyContent="center" alignItems="center">
                <img
                  style={{ width: '65%', maxWidth: 150, height: 70, borderRadius: 9, objectFit: 'cover' }}
                  src={this.props.data.nurseInfo.picture}
                  alt=""
                />
              </Grid>

              <Grid item xs={7} style={{ textAlign: 'left', paddingLeft: 5, paddingRight: 10 }}>
                <div style={styles.title}>{this.props.data.nurseInfo.firstname} {this.props.data.nurseInfo.lastname}</div>
                {
                  !this.state.showMore ?
                    <div style={styles.contentText}>
                      {this.props.data.nurseInfo.description.slice(0, 70)}{(this.props.data.nurseInfo.description.length > 70) ? '...' : null}
                      {(this.props.data.nurseInfo.description.length > 70) ?
                        <div className="pointer" style={{ color: colors.primary, fontFamily: Fonts.MarkOTMedium }} onClick={() => { this.setState({ showMore: !this.state.showMore }) }}>
                          Show more
                    </div>
                        : null
                      }
                    </div>
                    :
                    <div style={styles.contentText}>
                      {this.props.data.nurseInfo.description}<div className="pointer" style={{ color: colors.primary, fontFamily: Fonts.MarkOTMedium }} onClick={() => { this.setState({ showMore: !this.state.showMore }) }}>Hide</div>
                    </div>
                }
              </Grid>
              <Grid item xs={2} alignContent="flex-end" alignItems="flex-end" justify="flex-end">
                {
                  this.props.data.status !== 'past' ?
                    <div onClick={() => { this.call(this.props.data.nurseInfo.phone) }}>
                      <i className="fa fa-1x fa-phone pointer" style={{ color: '#4a4a4a' }}></i>

                    </div>
                    :
                    null
                }
                {
                  this.props.data.status !== 'past' ?
                    <div onClick={() => { this.sms(this.props.data.nurseInfo.phone) }} style={{ marginTop: 10 }}>
                      <i className="fa fa-1x fa-comments pointer" style={{ color: '#4a4a4a' }}></i>
                    </div>
                    : null
                }

              </Grid>
            </Grid>
            : this.props.data.infoOffer.product.type !== 'shots' ?
              <Grid container jutify="center" alignItems="center">
                <Grid item style={styles.center} xs={4}>
                  <img style={{ width: 75, height: 75, }} src={require('../../../img/appts/clock.png')} alt="" />
                </Grid>
                <Grid item style={{ textAlign: 'left', paddingRight: 15 }} xs={8}>
                  {
                    !this.props.data.infoOffer.product.job ?
                      <div style={styles.subtitle}>Currently working to find the perfect nurse for you...</div>
                      :
                      <div style={styles.subtitle}>Currently working to find the perfect {this.props.data.infoOffer.product.job} for you...</div>
                  }
                </Grid>
              </Grid>
              : null
        }
      </div>
    );
  }
}

const styles = {
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  shareButton: {
    position: 'absolute',
    top: 10,
    width: 30,
    height: 30,
    zIndex: 400,
    left: window.innerWidth - 70
  },
  divider: {
    width: '90vw',
    height: 1,
    marginLeft: '5vw',
    backgroundColor: '#eaeaea',
    marginTop: 20,
    marginBottom: 10,
  },
  contentText: {
    color: colors.subtitle,
    fontSize: 12,
    fontFamily: Fonts.MarkOT,
    margin: 3
  },
  contentText2: {
    color: '#97BDAB',
    fontSize: 19,
    fontFamily: 'AvenirBold',
  },
  contentText3: {
    color: '#959393',
    fontSize: 13,
    fontFamily: 'AvenirBold',
  },
  title: {
    color: colors.title,
    fontSize: 15,
    fontFamily: Fonts.MarkOTMedium,
    margin: 3,
  },
  subtitle: {
    color: '#4A4A4A',
    fontSize: 13,
    fontFamily: 'AvenirMedium',
  },
  textHeader: {
    color: 'white', fontFamily: 'AvenirMedium'
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, {})(Home);
