import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import '../../../../css/Home.css';
import { bookingFlowAction } from '../../../../actions/bookingFlowActions'
import { homeAction } from '../../../../actions/homeActions'
import { locationAction } from '../../../../actions/locationActions'
import { Slider } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import UnitSelector from './UnitSelector'
import Grow from '@material-ui/core/Grow'
import Typography from '@material-ui/core/Typography'
import { Fonts } from '../../../../utils/Font';
import colors from '../../../layout/colors'

function Transition(props) {
  return <Grow {...props} timeout={300} />;
}

class CardUnitSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitSelected: this.props.minimumUnitsReceivedPerUser,
      showUnitEstimator: false
    };
  }
  changeUnit(numberUnit) {
    var currentState = this.state.unitSelected
    var currentProps = this.props.dataHome.numberUnitOrderedPerPatient - currentState
    currentProps = currentProps + numberUnit
    this.props.homeAction('setNumberUnitOrderedPerPatient', { value: currentProps })
  }
  render() {
    return (
      <div style={styles.card}>
        <Grid container direction="row" style={{ marginBottom: 10 }}>
          <Grid item style={{ ...styles.center2, paddingLeft: 5 }}>
            {
              this.props.showUserInfo ?
                <Typography style={{ ...styles.title, fontSize: 15 }}>{this.props.dataUser.trueVaultInfoUser.firstname} {this.props.dataUser.trueVaultInfoUser.lastname} • {this.state.unitSelected} units</Typography>
                :
                <Typography style={{ ...styles.title, fontSize: 15 }}>Person {this.props.personNumber} • {this.state.unitSelected} units</Typography>
            }
          </Grid>
        </Grid>
        <Grid item style={{ width: '90%', marginLeft: '5%' }}>
          <Slider
            step={5}
            max={100}
            min={this.props.category.minimumUnitsReceivedPerUser}
            onChange={(event, value) => {
              this.changeUnit(value)
              this.setState({ unitSelected: value })
            }}
            value={this.state.unitSelected}
          />
        </Grid>
        <Grid container direction="row" onClick={() => this.setState({ showUnitEstimator: true })}>
          <Grid item className="pointer" style={{ ...styles.center2, paddingLeft: 5, marginTop: 5 }}>
            <Typography style={styles.textKnow}>I don't know</Typography>
          </Grid>
        </Grid>
        <Dialog
          fullScreen
          open={this.state.showUnitEstimator}
          TransitionComponent={Transition}
        >
          <UnitSelector
            close={() => this.setState({ showUnitEstimator: false })}
            submit={(numberUnit) => {
              this.changeUnit(numberUnit)
              this.setState({ unitSelected: numberUnit, showUnitEstimator: false })
            }}
            areas={this.props.areas}
          />
        </Dialog>
      </div>
    );
  }
}
const styles = {
  card: {
    marginBottom: 10,
    backgroundColor: 'white',
    borderRadius: 0,
    borderColor: '#eaeaea',
    borderTopWidth: 1,
    paddingTop: 10,
    height: 110,
    width: '95vw',
    maxWidth: 600,
    shadowColor: '#D7D4E6',
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 3,
  },
  picture: {
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    height: '100%',
    width: '90%',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  center2: {
    justifyContent: 'center',
  },
  buttonOn: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 20,
    width: 20,
    borderRadius: 10,
    borderColor: '#C1DACE',
    backgroundColor: '#C1DACE',
    borderWidth: 1.5
  },
  title: {
    color: colors.title,
    fontSize: 14,
    fontFamily: Fonts.MarkOTMedium,
  },
  textKnow: {
    color: colors.primary,
    fontSize: 14,
    fontFamily: Fonts.MarkOTMedium,
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(CardUnitSelection);
