import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import colors from '../colors';
import { Fonts } from '../../../utils/Font'
import CircularProgress from '@material-ui/core/CircularProgress';

class Button extends Component {
  render() {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={(this.props.bookingFlow && this.props.off) ? 'VbtnOff'
          : (this.props.bookingFlow) ? 'Vbtn'
            : null}
        onClick={() => { if (!this.props.off) this.props.click() }}
        style={(!this.props.bookingFlow) ?
          {
            ...styles.buttonSubmit,
            backgroundColor: (!this.props.grey) ? colors.primary : colors.subtitle
          }
          : { width: '40vw', minWidth: 300, color: (this.props.off) ? colors.primary : 'white' }}
      >
        <Grid item alignContent="center" justify="center" style={styles.textButtonOn}>
          {(this.props.loading) ? <CircularProgress style={{ color: 'white' }} size={18} opacity={50} />
            : this.props.text}
        </Grid>
      </Grid>
    );
  }
}

export default Button;

const styles = {
  buttonSubmit: {
    height: 40,
    backgroundColor: colors.primary,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#46474B',
    shadowOffset: { width: 0, height: 2 },
    borderWidth: 0,
    shadowOpacity: 0,
    shadowRadius: 2,
    cursor: 'pointer',
    color: 'white'
  },
  textButtonOn: {
    textAlign: 'center',
    fontFamily: Fonts.MarkOTBold,
    fontSize: 15,
  },
}