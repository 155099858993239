import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import logo from '../../../img/home/wellLogo.png';
import hipaa from '../../../img/app/hipaa.png';
import { withRouter } from 'react-router-dom'
import { Fonts } from '../../../utils/Font';

class Footer extends Component {
  openLink(val) {
    if (val === 'instagram') {
      window.open("https://www.instagram.com/thewlnss/");
    } else if (val === 'privacy') {
      window.open("https://www.wlnss.co/privacy-policy");
    } else if (val === 'terms') {
      window.open("https://www.wlnss.co/terms-service");
    } else if (val === 'home') {
      window.open("https://www.wlnss.co/");
    }
  }
  render() {
    return (
      <div>
        <br /><br />
        <Divider light style={{ width: '90vw', marginLeft: '5vw' }} />
        <div align="center" className="footer" style={{ opacity: 0.5 }}>
          <div>
            <img style={{ cursor: 'pointer' }} onClick={() => this.openLink('home')} src={logo} className="footer-logo" alt="wlnss" />
          </div>
          <div>
            <img src={hipaa} className="hipaa-logo" alt="hipaa" />
          </div>
          <br />
          <Grid container alignContent='center' justify="center" >
            <Grid container style={{ maxWidth: 300 }}>
              <Grid item xs={6} style={{ fontFamily: Fonts.MarkOTMedium, fontSize: 13, cursor: 'pointer' }} onClick={() => this.openLink('privacy')}>
                Privacy Policy
          </Grid>
              <Grid item xs={6} style={{ fontFamily: Fonts.MarkOTMedium, fontSize: 13, cursor: 'pointer' }} onClick={() => this.openLink('terms')}>
                Terms of Service
          </Grid>
            </Grid>
          </Grid>
          <br /><br />
          <Grid container alignContent='center' justify="center" >
            <Grid container style={{ maxWidth: 300 }}>
              <Grid item md={3} xs={3} onClick={() => this.openLink('facebook')} >
                <i style={{ color: '#999999' }} className="fa fa-facebook"></i>
              </Grid>
              <Grid item md={3} xs={3} onClick={() => this.openLink('twitter')}>
                <i style={{ color: '#999999' }} className="fa fa-twitter"></i>
              </Grid>
              <Grid item md={3} xs={3} onClick={() => this.openLink('instagram')} >
                <i style={{ color: '#000000', cursor: 'pointer' }} className="fa fa-instagram"></i>
              </Grid>
              <Grid item md={3} xs={3} onClick={() => this.openLink('linkedin')}>
                <i style={{ color: '#999999' }} className="fa fa-linkedin"></i>
              </Grid>
            </Grid>
          </Grid>

          <div style={{ fontFamily: Fonts.MarkOT, width: '80%', maxWidth: 550, marginTop: 30, fontSize: 13 }}>
            Medical Services provided by Yen Tun Wang Medical Services Corporation. The Covid-19 antigen test we administer (BD Veritor Plus system for detection of SARS-CoV-2) has been authorized by the Food and Drug Administration under an Emergency Use Authorization for use by authorized laboratories. These products are not intended to diagnose, treat, cure or prevent any disease. The material on this website is provided for informational purposes only and is not medical advice.
          </div>
        </div>
        <div style={{ fontFamily: Fonts.MarkOT, opacity: 0.5 }} className={(this.props.history.location.pathname.split('/')[1] === 'book' && this.props.dataFlow.currentPage !== 'finalSummary' && this.props.dataFlow.currentPage !== 'Location' ? 'footer2 marginBottomFooter' : 'footer2')}>
          ©2021 by WLNSS Inc.
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location,
  dataFlow: state.bookingFlow
})
export default connect(mapStateToProps, {})(withRouter(Footer));
