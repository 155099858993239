import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { initApp } from '../../../actions/initAppActions'

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSettings: false,
      step: 1,
      cardNumber: '',
      expDate: '',
      cvv: '',
      cardType: '',
      digitsRequired: 16,
      lengthCVV: 3,
      inputFocus: '',
      error: false,
      errorMessage: '',
      zipCode: '',
      showChoiceCountry: false
    };
  }
  download() {
    window.open('https://itunes.apple.com/us/app/wlnss/id1420304128?mt=8', '_blank');
  }
  render() {
    return (
      <div className="App" >
        <Grid alignItems="center" justify="center" style={{ marginTop: 10, marginBottom: 10, paddingLeft: 15, paddingRight: 15 }}>
          <div style={styles.subtitle}>Apple pay is only available for IOS. Please download the app to use it.</div>
        </Grid>
        <Grid container style={{ paddingLeft: 15, paddingRight: 15, marginTop: 15 }}>
          <Button style={styles.button} variant="contained" color="primary" onClick={() => this.download()}>
            Download Wlnss for IOS
        </Button>
        </Grid>
      </div>
    );
  }
}
const styles = {
  text: {
    fontFamily: 'Avenir',
    fontSize: 15,
    fontWeight: 'bold',
    color: '#3173ED',
  },
  subtitle: {
    fontFamily: 'Avenir',
    fontSize: 15,
    fontWeight: 'bold',
    color: '#4a4a4a',
  },
  button: {
    height: 53, color: 'white', fontFamily: 'AvenirBold', width: '100%',
    fontWeight: 'bold',
    fontSize: 14
  },
  buttonOff: {
    height: 53, color: '#8CB5A1', fontFamily: 'Avenir', width: '100%',
    fontWeight: 'bold'
  },
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { initApp })(SignIn);
