import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import '../../../css/Home.css';
import HeaderBooking from './HeaderBooking'
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'
class Product extends Component {
  async componentWillMount() {
    window.scrollTo(0, 0)
  }
  back() {
    this.props.homeAction('openIVFLow', { value: false })
  }
  doneTime() {
    this.timePicker.doneTime()
  }
  submit() {
    this.props.bookingFlowAction('setCurrentPage', { value: 'Checkout' })
  }
  render() {
    return (
      <div className="App">
        <HeaderBooking title={'Patient consent'} back={this.back.bind(this)} loader={false} journeyShow={this.props.journeyShow} />

        {this.props.dataUser.instaviceVariables.consent[this.props.product.type].consent.map((paragraph, i) => (
          <div>
            <Grid container style={styles.subtitleBold}>
              {paragraph.boldText}
            </Grid>
            {
              paragraph.dotList ?
                <div>
                  {paragraph.dotListDetail.map((dot, i) => (
                    <Grid container style={styles.subtitle}>
                      • {dot}
                    </Grid>
                  ))}
                </div>
                :
                <Grid container style={styles.subtitle}>
                  {paragraph.text}
                </Grid>
            }
          </div>
        ))}
        <Button style={styles.button} variant="contained" color="primary" onClick={() => this.submit()}>
          Submit consent
          </Button>
      </div>
    );
  }
}
const styles = {
  buttonBack: {
    position: 'absolute',
    top: 75,
    left: 15,
    zIndex: 20,
  },
  button: {
    height: 53, color: 'white', fontFamily: 'Avenir', width: '90%',
    fontWeight: 'bold',
    fontSize: 16
  },
  title: {
    fontFamily: 'Avenir',
    fontSize: 23,
    fontWeight: 'Bold',
    paddingLeft: 15,
    color: '#4a4a4a',
    marginTop: 10,
  },
  subtitle: {
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 5,
    color: '#757575',
    textAlign: 'left',
  },
  subtitleBold: {
    fontFamily: 'AvenirBold',
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 5,
    color: '#4a4a4a',
    textAlign: 'left',
  },
  secondRes: {
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  mainRes: {
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'bold',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  rowResult: {
    flex: 1, borderBottomWidth: 1, borderColor: '#EAEAEA', paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15
  },
  textIngredient: {
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
    paddingLeft: 15,
  },
  textBenefit: {
    fontFamily: 'Avenir',
    fontSize: 17,
    marginTop: 10,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    paddingLeft: 15,
    height: 30,
  },
  titleBenefit: {
    fontFamily: 'Avenir',
    fontSize: 19,
    fontWeight: 'Bold',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
  },
  divider: {
    width: window.innerWidth - 30,
    height: 1,
    marginLeft: 15,
    backgroundColor: '#eaeaea',
    marginTop: 10,
    marginBottom: 10,
  },
  row: {
    textAlign: 'left',
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    color: '#4a4a4a',
    paddingTop: 5
  },
  boxPrice: {
    width: 70,
    height: 25,
    borderRadius: 15,
    bottom: 20,
    right: 15,
    backgroundColor: '#FAF9F9',
    alignItems: 'center',
    fontFamily: 'Avenir',
    justifyContent: 'center',
    borderColor: '#EAEAEA',
    color: '#8CB5A1',
    paddingTop: 9,
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(Product);
