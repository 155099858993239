import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import '../../../css/Home.css';
import { homeAction } from '../../../actions/homeActions'
import colors from '../../layout/colors'
import { Fonts } from '../../../utils/Font';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faCheck, faInfo } from '@fortawesome/free-solid-svg-icons'
import { faPagelines } from '@fortawesome/free-brands-svg-icons'

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'product',
      product: {},
      category: {},
      iconColor: '#ffffff'
    };
  }
  componentWillMount() {
    this.props.homeAction('setNumberUnit', { value: 1 })
  }

  close() {
    if (this.props.popup) {
      this.props.close(false)
    } else {
      this.props.history.goBack()
    }
  }
  render() {
    return (
      <div>
        <Grid container direction="column" className="App smoothScroll" style={{ alignSelf: 'center', overflowY: 'scroll' }}>
          {
            !this.props.popup ?
              <div style={styles.buttonBack} onClick={() => { this.close() }} className={(this.props.journeyShow ? 'marginMenu pointer' : 'pointer')}>
                <Icon style={{ color: colors.subtitle }}>arrow_back</Icon>
              </div>
              :
              <div className="pointer" onClick={() => { this.close() }} style={{
                top: this.props.journeyShow ? 110 : 35, position: 'absolute',
                left: 15,
                zIndex: 20,
              }}>
                <Icon style={{ color: colors.subtitle }}>arrow_back</Icon>
              </div>
          }

          <Grid item>
            <img id="cover" src={this.props.product.imageMedium} className="cardProductImage2" alt="" />
          </Grid>
          <Grid container direction="column" style={{ width: '95vw', maxWidth: '600px', alignSelf: 'center', marginTop: 10, }}>
            <Grid container style={styles.title}>
              {this.props.product.name}
            </Grid>
            <Grid container style={styles.subtitle}>
              {this.props.product.description}
            </Grid>
            <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 20, marginBottom: 20 }} light />

            {
              this.props.product.type !== 'shots' ?
                <div>
                  <Grid container style={{ height: 35, marginTop: 0, }} justify="center" alignItems="center">
                    <Grid item xs={9} sm={10} md={11} style={styles.row}>
                      Individual
                    </Grid>
                    <Grid container xs={3} sm={2} md={1} alignContent="center" justify="flex-end">
                      <Grid item style={{ paddingRight: 15 }}>
                        <div style={styles.boxPrice}>${this.props.product.pricesGroup.filter(price => price.value === 1)[0].price}</div>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container justify="center" alignItems="center" style={{ height: 35, marginTop: 10, }}>
                    <Grid item xs={9} sm={10} md={11} style={styles.row}>
                      2 People
                    </Grid>
                    <Grid container xs={3} sm={2} md={1} alignContent="center" justify="flex-end">
                      <Grid item style={{ paddingRight: 15 }}>
                        <div style={styles.boxPrice}>${this.props.product.pricesGroup.filter(price => price.value === 2)[0].price}</div>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container justify="center" alignItems="center" style={{ height: 35, marginTop: 10, }}>
                    <Grid item xs={9} sm={10} md={11} style={styles.row}>
                      3 People
                    </Grid>
                    <Grid container xs={3} sm={2} md={1} alignContent="center" justify="flex-end">
                      <Grid item style={{ paddingRight: 15 }}>
                        <div style={styles.boxPrice}>${this.props.product.pricesGroup.filter(price => price.type === 'greater')[0].price}</div>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 20, marginBottom: 20 }} light />
                </div>
                : null
            }

            <Grid container style={styles.subtitle2}>
              {this.props.product.description2}
            </Grid>

            <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 20, marginBottom: 20 }} light />

            <Grid container style={{ flex: 1, marginBottom: 10, paddingLeft: 15, marginTop: 10 }}>
              <Grid container xs={1} alignItems="flex-start">
                <Grid item><FontAwesomeIcon icon={faThumbsUp} /></Grid>
              </Grid>
              <Grid item xs={11} style={styles.titleBenefit}>
                Benefits
          </Grid>
            </Grid>

            {this.props.product.benefits.map((benefit, i) => (
              <Grid container key={i} alignItems="center" justify="center" style={{ paddingLeft: 15 }}>
                <Grid container xs={1} alignItems="flex-start">
                  <Grid item><FontAwesomeIcon icon={faCheck} /></Grid>
                </Grid>
                <Grid item xs={11} style={styles.textBenefit}>
                  {benefit}
                </Grid>
              </Grid>
            ))}

            {
              this.props.product.displayIngredients ?
                <div>
                  <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 20, marginBottom: 20 }} light />
                  <Grid container style={{ flex: 1, marginBottom: 10, paddingLeft: 15, marginTop: 10 }}>
                    <Grid container xs={1} alignItems="flex-start">
                      <Grid item><FontAwesomeIcon icon={faPagelines} size="lg" /></Grid>
                    </Grid>
                    <Grid item xs={11} style={styles.titleBenefit}>
                      Ingredients
          </Grid>
                  </Grid>
                  <Grid container >
                    {this.props.product.ingredients.map((ingredient, i) => (
                      <Grid item xs={12} style={styles.textIngredient} key={i}>
                        {ingredient.shortName}
                      </Grid>
                    ))}
                  </Grid>
                </div>
                : null
            }
            <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 20, marginBottom: 20 }} light />

            <Grid container style={{ flex: 1, marginBottom: 10, paddingLeft: 15, marginTop: 10 }}>
              <Grid container xs={1} alignItems="flex-start">
                <Grid item><FontAwesomeIcon icon={faInfo} style={{ paddingLeft: 6 }} /></Grid>
              </Grid>
              <Grid item xs={11} style={styles.titleBenefit}>
                Appointment information
          </Grid>
            </Grid>

            {Object.values(this.props.category.infoCategory).map((info, i) => (
              <Grid container direction="row" key={i} style={{ flex: 1, width: '100%', marginTop: 5, paddingLeft: 15 }}>
                <Grid container xs={1} alignItems="center" justify="flex-start" style={{ marginTop: -2 }}>
                  <Grid><Icon style={{ color: colors.subtitle, fontSize: 18 }}> {info.icon.replace(/-/g, "_")} </Icon></Grid>
                </Grid>
                <Grid xs={11}>
                  <div style={styles.textBenefit}>{info.text}</div>
                </Grid>
              </Grid>
            ))}

            <div style={{ height: 40 }}></div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
const styles = {
  buttonBack: {
    position: 'absolute',
    top: 75,
    left: 15,
    zIndex: 1,
  },
  buttonBack2: {
    position: 'absolute',
    top: 35,
    left: 15,
    zIndex: 20,
  },
  title: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 23,
    fontWeight: 'Bold',
    paddingLeft: '3%',
    color: colors.title,
    marginTop: 10,
    textAlign: 'left',
  },
  subtitle: {
    fontFamily: Fonts.MarkOT,
    fontSize: 15,
    fontWeight: 'normal',
    paddingLeft: '3%',
    marginLeft: 1,
    marginTop: 5,
    marginBottom: 5,
    color: colors.subtitle,
    textAlign: 'left',
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 'normal',
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 5,
    textAlign: 'left',
    color: colors.subtitle,
    fontFamily: Fonts.MarkOT,
  },
  textIngredient: {
    fontSize: 14,
    marginTop: 10,
    paddingLeft: 15,
    fontWeight: 'normal',
    textAlign: 'left',
    height: 30,
    color: colors.subtitle,
    fontFamily: Fonts.MarkOT,
  },
  textBenefit: {
    fontSize: 14,
    marginTop: 10,
    paddingLeft: 10,
    fontWeight: 'normal',
    textAlign: 'left',
    height: 30,
    color: colors.subtitle,
    fontFamily: Fonts.MarkOT,
  },
  titleBenefit: {
    marginTop: -3,
    textAlign: 'left',
    height: 30,
    color: colors.title,
    fontSize: 19,
    paddingLeft: 9,
    fontFamily: Fonts.MarkOTMedium,
  },
  divider: {
    width: window.innerWidth - 30,
    height: 1,
    marginLeft: 15,
    backgroundColor: '#eaeaea',
    marginTop: 20,
    marginBottom: 20,
  },
  row: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 'normal',
    paddingLeft: 15,
    paddingTop: 0,
    color: colors.title,
    fontFamily: Fonts.MarkOTMedium,
  },
  boxPrice: {
    width: 70,
    height: 25,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 7,
    fontSize: 16,
    color: colors.primary,
    fontFamily: Fonts.MarkOTBlack,
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { homeAction })(Product);
