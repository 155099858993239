import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import '../../../css/Home.css';
import { Fonts } from '../../../utils/Font'
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import colors from '../../layout/colors';
class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: {}
    };
  }
  componentWillMount() {
    if (!this.props.editable) {
      if (!this.props.secondary || this.props.secondary.length === 0) {
        this.setState({ products: this.props.category.secondaryProducts.list })
      } else {
        this.setState({ products: this.props.secondary })
      }
    } else {
      if (this.props.dataHome.secondaryProducts.length !== 0) {
        this.setState({ products: this.props.dataHome.secondaryProducts })
      } else {
        var list = []
        var totalList = this.props.category.secondaryProducts.list.slice(0)
        for (var i in totalList) {
          var prod = { ...totalList[i] }
          prod.selected = false
          list.push(prod)
        }
        this.setState({ products: list })
      }
    }
  }
  done() {
    this.props.homeAction('setBoosters', { boosters: this.state.boosters })
  }
  async tick(index) {
    if (this.props.editable) {
      var products = this.state.products
      products[index].selected = !products[index].selected
      await this.setState({ products: products })
      await this.props.homeAction('setSecondaryProducts', { value: products })
    }
  }
  render() {
    return (
      <div style={{ marginTop: 15 }}>
        <Grid container style={{ height: 40, marginBottom: 20 }} alignItems="center" justify="center">
          <Grid container justify="flex-start" alignItems="center" xs={7}>
            <Grid item style={styles.title}>{this.props.category.secondaryProducts.name}</Grid>
          </Grid>
          <Grid container justify="flex-end" alignItems="center" xs={5}>
            <Grid item style={styles.subtitle2}>Price per {this.props.category.secondaryProducts.unit}</Grid>
          </Grid>
        </Grid>
        {Object.values(this.state.products).map((product, i) => (
          <Grid className={product.selected ? 'borderOn pointer' : 'borderOff pointer'} container onClick={() => this.tick(i)} style={{ height: 50, marginTop: 10, borderRadius: 7 }} alignItems="center" justify="center">
            <Grid item xs={2} sm={2} style={{ paddingLeft: 10 }}>
              {
                product.selected ?
                  <FontAwesomeIcon icon={faCheck} color={colors.primary} />
                  :
                  <FontAwesomeIcon icon={faCheck} color='#eaeaea' />
              }
            </Grid>
            <Grid item xs={8} sm={8} style={styles.subtitle}>
              {product.name} {this.props.category.secondaryProducts.unit}
            </Grid>
            <Grid container justify="center" alignItems="center" xs={2} sm={2} style={styles.price}>
              <Grid>${product.price}</Grid>
            </Grid>
          </Grid>
        ))}
      </div>
    );
  }
}
const styles = {
  buttonBack: {
    position: 'absolute',
    top: 75,
    left: 15,
    zIndex: 20,
  },
  buttonOn: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 22,
    width: 25,
    paddingTop: 3,
  },
  buttonOff: {
    height: 22,
    width: 25,
    paddingTop: 4,
  },
  title: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 23,
    fontWeight: 'Bold',
    paddingLeft: 15,
    color: '#4a4a4a',
    marginTop: 10,
  },
  subtitle: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 13,
    textAlign: 'left',
    color: colors.title,
    paddingLeft: 10,
  },
  subtitle2: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 15,
    fontWeight: 'normal',
    textAlign: 'right',
    color: '#4a4a4a',
    paddingRight: 15,
    marginTop: 10,
  },
  price: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 13,
    fontWeight: 'normal',
    textAlign: 'right',
    color: colors.primary,
    paddingRight: 15,
  },
  secondRes: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  mainRes: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'bold',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  rowResult: {
    flex: 1, borderBottomWidth: 1, borderColor: '#EAEAEA', paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15
  },
  textIngredient: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
    paddingLeft: 15,
  },
  textBenefit: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    marginTop: 10,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    paddingLeft: 15,
    height: 30,
  },
  titleBenefit: {
    fontFamily: Fonts.MarkOT,
    fontSize: 19,
    fontWeight: 'Bold',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
  },
  divider: {
    width: window.innerWidth - 30,
    height: 1,
    marginLeft: 15,
    backgroundColor: '#eaeaea',
    marginTop: 10,
    marginBottom: 10,
  },
  row: {
    textAlign: 'left',
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    color: '#4a4a4a',
    paddingTop: 5
  },
  boxPrice: {
    width: 70,
    height: 25,
    borderRadius: 15,
    bottom: 20,
    right: 15,
    backgroundColor: '#FAF9F9',
    alignItems: 'center',
    fontFamily: Fonts.MarkOT,
    justifyContent: 'center',
    borderColor: '#EAEAEA',
    color: '#8CB5A1',
    paddingTop: 9,
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(Product);
