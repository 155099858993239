import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../css/App.css';
import '../css/Home.css';
import Header from './Header'
import Footer from './layout/footer/Footer'
import Home from './Home'
import Join from './Join'
import Profile from './Profile'
import Appts from './elementsAppts/Appts'

import ListProducts from './elementsHome/ListProducts'
import { Route, BrowserRouter as Router } from 'react-router-dom'
import BookingFlow from './elementsHome/BookingFlow';

class MainApp extends Component {
  render() {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)}>

        <Route render={props => <Header {...props} journeyShow={this.props.journeyShow} />} />

        <div className="mainApp">
          <Route exact path="/" render={props => <Home {...props} />} />
          <Route exact path="/category/:typeCategory" render={props => <ListProducts {...props} />} />
          <Route exact path="/book/:typeCategory/:productName" render={props => <BookingFlow {...props} journeyShow={this.props.journeyShow} />} />

          <Route exact path="/profile" render={props => <Profile {...props} journeyShow={this.props.journeyShow} />} />
          <Route exact path="/appointments" render={props => <Appts {...props} journeyShow={this.props.journeyShow} />} />
          <Route exact path="/join" render={props => <Join {...props} />} />

          <Route render={props => <Footer {...props} />} />
        </div>
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, {})(MainApp);
