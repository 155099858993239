import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import '../../../css/Home.css';
import HeaderBooking from './HeaderBooking'
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'
import Typography from '@material-ui/core/Typography'
import CardUnitSelection from './elementsUnitSelection/CardUnitSelection'
import { Fonts } from '../../../utils/Font';
import ScrollViewPage from '../../layout/scrollviews/ScrollViewPage'
import colors from '../../layout/colors'

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberPerson: this.props.dataHome.numberUnit,
      arrayPersons: []
    };
  }
  async componentWillMount() {
    window.scrollTo(0, 0)
    var arrayPersons = []
    for (let step = 0; step < this.state.numberPerson; step++) {
      arrayPersons.push(step)
    }
    this.setState({ arrayPersons: arrayPersons })
    this.props.homeAction('setNumberUnitOrderedPerPatient', { value: this.state.numberPerson * this.props.category.minimumUnitsReceivedPerUser })
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) return true
    return false
  }
  showUserInfo(person) {
    if (person === 0 && this.props.dataUser.userConnected && this.props.dataHome.receiver !== 'else') return true
    return false
  }
  back() {
    this.props.bookingFlowAction('setCurrentPage', { value: 'Person' })
  }
  loopPersonBooking() {
    return (
      <Grid container direction="column" style={{ marginTop: 20, marginBottom: 10 }}>
        {this.state.arrayPersons.map((person, i) => (
          <CardUnitSelection areas={this.props.category.listAreas} category={this.props.category} personNumber={person + 1} key={i} showUserInfo={this.showUserInfo(person)} infoUser={this.props.dataUser.infoUser} minimumUnitsReceivedPerUser={this.props.category.minimumUnitsReceivedPerUser} />
        ))}
      </Grid>
    )
  }
  bodyContent() {
    return (
      <div style={{ marginTop: -50 }}>
        <Grid container style={styles.subtitle2}>
          Let's get an estimate for your Botox needs so the practitioner can bring the right amount.
          </Grid>
        {this.loopPersonBooking()}
        <Typography style={styles.subtitle}>
          Botox is administered in units and as a rough guideline, each facial area takes between 10 and 20 units. You will only be charged for what you use once the appointment is complete, but we do have a minimum of {this.props.category.minimumUnitsReceivedPerUser} units per person to make it worthwhile for the practitioner to come to you.
          </Typography>
      </div>
    )
  }
  render() {
    return (
      <div className="App">
        <HeaderBooking title={'Estimate your needs'} back={this.back.bind(this)} loader={false} journeyShow={this.props.journeyShow} />
        <ScrollViewPage
          content={this.bodyContent.bind(this)}
          loader={false}
          bookingFlow={true}
          header={false}
          styleTitle={{ fontSize: 27, fontFamily: Fonts.MarkOTBold }}
        />
      </div>
    );
  }
}
const styles = {
  subtitle: {
    color: colors.title,
    fontSize: 12,
    fontFamily: Fonts.MarkOT,
    textAlign: 'left'
  },
  incrementBtn: {
    color: colors.primary,
    fontSize: 23,
    fontFamily: Fonts.MarkOTBold,
  },
  buttonBack: {
    position: 'absolute',
    top: 75,
    left: 15,
    zIndex: 20,
  },
  title: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 23,
    paddingLeft: 15,
    color: '#4a4a4a',
    marginTop: 10,
  },
  subText: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 16,
    color: '#4a4a4a',
    textAlign: 'center'
  },
  subTextOff: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 16,
    fontWeight: 'normal',
    color: '#CACACF',
  },
  subtitle2: {
    fontFamily: Fonts.MarkOT,
    fontSize: 16,
    fontWeight: 'normal',
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 25,
    marginBottom: 5,
    color: colors.subtitle,
    textAlign: 'left',
  },
  secondRes: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  mainRes: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 17,
    fontWeight: 'bold',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  rowResult: {
    flex: 1, borderBottomWidth: 1, borderColor: '#EAEAEA', paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15
  },
  textIngredient: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
    paddingLeft: 15,
  },
  textBenefit: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    marginTop: 10,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    paddingLeft: 15,
    height: 30,
  },
  titleBenefit: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 19,
    fontWeight: 'Bold',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
  },
  row: {
    textAlign: 'left',
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    color: '#4a4a4a',
    paddingTop: 5
  },
  boxPrice: {
    width: 70,
    height: 25,
    borderRadius: 15,
    bottom: 20,
    right: 15,
    backgroundColor: '#FAF9F9',
    alignItems: 'center',
    fontFamily: Fonts.MarkOT,
    justifyContent: 'center',
    borderColor: '#EAEAEA',
    color: '#8CB5A1',
    paddingTop: 9,
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(Product);
