import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import '../../../css/Home.css';
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import { Fonts } from '../../../utils/Font'
import colors from '../../layout/colors'

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'checkout',
      time: '',
      date: '',
      matDay: [],
      matTime: [],
      showSelectDate: false,
      showSelectTime: false,
      anchorEl: null,
      anchorEl2: null,
      matTimeToday: [],
      matTimeTomorrow: ['8:00 am', '8:15 am', '8:30 am', '8:45 am', '9:00 am', '9:15 am', '9:30 am', '9:45 am', '10:00 am', '10:15 am', '10:30 am', '10:45 am', '11:00 am', '11:15 am', '11:30 am', '11:45 am', '12:00 pm', '12:15 pm', '12:30 pm', '12:45 pm', '1:00 pm', '1:15 pm', '1:30 pm', '1:45 pm', '2:00 pm', '2:15 pm', '2:30 pm', '2:45 pm', '3:00 pm', '3:15 pm', '3:30 pm', '3:45 pm', '4:00 pm', '4:15 pm', '4:30 pm', '4:45 pm', '5:00 pm', '5:15 pm', '5:30 pm', '5:45 pm', '6:00 pm', '6:15 pm', '6:30 pm', '6:45 pm', '7:00 pm', '7:15 pm', '7:30 pm', '7:45 pm', '8:00 pm', '8:15 pm', '8:30 pm', '8:45 pm', '9:00 pm', '9:15 pm', '9:30 pm', '9:45 pm'],
    };
  }
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
    this.setState({ showSelectDate: true })
  };
  handleClick2 = event => {
    this.setState({ anchorEl2: event.currentTarget });
    this.setState({ showSelectTime: true })
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ showSelectDate: false })
  };
  handleClose2 = () => {
    this.setState({ anchorEl2: null });
    this.setState({ showSelectTime: false })
  };
  componentWillMount() {
    this.props.onRef(this)
    var today = (new Date()).toString()
    var matDay = []
    var todayFormat = (moment(new Date()).format('ddd, MMM Do')).toString()
    var tomorrowFormat = (moment(new Date()).add('d', 1).format('ddd, MMM Do')).toString()

    var counter2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]

    var m = moment(today)
    var nextTimeSlot = this.props.dataUser.instaviceVariables.timePicker.nextTimeSlot
    const roundedUp = Math.ceil(moment().minute() / 15) * 15;
    var roundUpDate = (m.startOf('hour').add(roundedUp + nextTimeSlot, 'm')).toString()
    var roundUpDate2 = moment(new Date()).toString()
    var momentDate = m.startOf('hour').add(roundedUp, 'm')
    var roundUpTime = moment(roundUpDate).format('h:mm a')
    var roundUp = Number(moment(roundUpDate).format('H'))
    var roundUp2 = Number(moment(roundUpDate2).add(nextTimeSlot, 'm').format('H'))
    var HourNow = Number(moment(roundUpDate2).add(nextTimeSlot, 'm').format('H'))
    var HourNow2 = Number(moment(roundUpDate2).format('H'))
    if (roundUp < this.props.dataUser.instaviceVariables.timePicker.firstSlotDay) {
      momentDate.hour(this.props.dataUser.instaviceVariables.timePicker.firstSlotDay)
      momentDate.minute(0)
      roundUpDate = momentDate.toString()
    }
    var startTimeDay = momentDate.hour(this.props.dataUser.instaviceVariables.timePicker.firstSlotDay)
    startTimeDay.minute(0)
    startTimeDay = startTimeDay.toString()
    var matTimeToday = []
    if (roundUp2 < this.props.dataUser.instaviceVariables.timePicker.firstSlotDay) {
      matTimeToday.push(this.props.dataUser.instaviceVariables.timePicker.firstSlotDay + ':00 am')
    } else {
      matTimeToday.push(roundUpTime)
    }

    var counter = [15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180, 195, 210, 225, 240, 255, 270, 285, 300, 315, 330, 345, 360, 375, 390, 405, 420, 435, 450, 465, 480, 495, 510, 525, 540, 555, 570, 585, 600, 615, 630, 645, 660, 675, 690, 705, 720, 735, 750, 765, 780, 795, 810, 825, 840, 855, 870, 885, 900, 915, 930, 945, 960, 975, 990, 1005, 1020, 1035, 1050, 1065, 1080, 1095, 1110, 1125, 1140, 1155, 1170, 1185, 1200, 1215, 1230, 1245, 1260, 1275, 1290, 1305]
    for (var i in counter) {
      var currentTimeNumber = Number(moment(roundUpDate).add(counter[i], 'm').format('H'))
      if (currentTimeNumber <= this.props.dataUser.instaviceVariables.timePicker.lastSlotDay - 1) {
        matTimeToday.push(moment(roundUpDate).add(counter[i], 'm').format('h:mm a'))
      } else {
        break
      }
    }
    var matTimeTomorrow = []
    matTimeTomorrow.push(this.props.dataUser.instaviceVariables.timePicker.firstSlotDay + ':00 am')
    for (var j in counter) {
      var currentTimeNumber2 = Number(moment(startTimeDay).add(counter[j], 'm').format('H'))
      if (currentTimeNumber2 <= this.props.dataUser.instaviceVariables.timePicker.lastSlotDay - 1) {
        matTimeTomorrow.push(moment(startTimeDay).add(counter[j], 'm').format('h:mm a'))
      } else {
        break
      }
    }
    this.setState({ matTimeToday: matTimeToday })
    if (roundUp2 >= this.props.dataUser.instaviceVariables.timePicker.lastSlotDay || roundUp2 === 0) {
    } else {
      matDay.push('Today')
    }
    for (i in counter2) {
      var day = moment(today).add(counter2[i], 'd').format('ddd, MMM Do')
      if (counter2[i] === 1) {
        matDay.push('Tomorrow')
      } else {
        matDay.push(day.toString())
      }
    }
    this.setState({ matDay: matDay })

    if (this.props.dataHome.preferencesDisplay !== '') {
      var dateSelect = (this.props.dataHome.preferencesDisplay).split('_')[0]
      var timeSelect = (this.props.dataHome.preferencesDisplay).split('_')[1]
      if (todayFormat === dateSelect) {
        if (HourNow >= this.props.dataUser.instaviceVariables.timePicker.lastSlotDay && HourNow !== 0) {
          this.setState({ date: 'Tomorrow' })
          this.setState({ time: this.props.dataUser.instaviceVariables.timePicker.firstSlotDay + ':00 am' })
          this.setState({ matTime: matTimeTomorrow })
        } else {
          this.setState({ date: 'Today' })
          this.setState({ time: timeSelect })
          this.setState({ matTime: matTimeToday })
        }

      } else {
        if (tomorrowFormat === dateSelect) {
          this.setState({ date: 'Tomorrow' })
        } else {
          this.setState({ date: dateSelect })
        }

        this.setState({ time: timeSelect })
        this.setState({ matTime: matTimeTomorrow })
      }
    } else {
      if (HourNow >= this.props.dataUser.instaviceVariables.timePicker.lastSlotDay && HourNow2 !== 0) {
        this.setState({ date: 'Tomorrow' })
        this.setState({ time: this.props.dataUser.instaviceVariables.timePicker.firstSlotDay + ':00 am' })
        this.setState({ matTime: matTimeTomorrow })
      } else if (HourNow2 < this.props.dataUser.instaviceVariables.timePicker.lastSlotDay && (HourNow2 === 0 || HourNow < this.props.dataUser.instaviceVariables.timePicker.firstSlotDay)) {
        this.setState({ date: 'Today' })
        this.setState({ time: this.props.dataUser.instaviceVariables.timePicker.firstSlotDay + ':00 am' })
        this.setState({ matTime: matTimeTomorrow })
      } else {
        this.setState({ date: 'Today' })
        this.setState({ time: roundUpTime })
        this.setState({ matTime: matTimeToday })
      }
    }
    this.setState({ matTimeTomorrow: matTimeTomorrow })
  }
  doneTime() {
    var date;
    if (this.state.date === 'Today') {
      date = moment(new Date()).format('ddd, MMM Do')
    } else if (this.state.date === 'Tomorrow') {
      date = moment(new Date()).add(1, 'd').format('ddd, MMM Do')
    } else {
      date = this.state.date
    }
    var totalDate = date + '_' + this.state.time
    this.props.homeAction('setAvailabilityDisplay', { value: totalDate })

    var dateDisplay = (totalDate.split('_'))[0] + ' at ' + (totalDate.split('_'))[1]
    var dateModif = dateDisplay.split(' at ')
    date = dateModif[0]
    var matTime = dateModif[1].split(' ')
    var minute = Number((matTime[0].split(':'))[1])
    var hour = Number((matTime[0].split(':'))[0])
    var period = matTime[1]
    if (period === 'pm' && hour !== 12) {
      hour = hour + 12
    }
    var offDate = momentTZ.tz(date, 'ddd, MMM Do', this.props.dataHome.timeZone.timeZoneId).add(hour, 'h').add(minute, 'm').format()
    var currentTimeZone = this.props.dataHome.timeZone
    currentTimeZone.offSet = momentTZ.tz(date, 'ddd, MMM Do', this.props.dataHome.timeZone.timeZoneId).add(hour, 'h').add(minute, 'm').format('Z')
    this.props.homeAction('setTimeZone', { value: currentTimeZone })
    this.props.homeAction('setDateAppt', { value: offDate })
  }
  render() {
    const { anchorEl, anchorEl2 } = this.state;
    return (
      <div style={{ marginTop: 15 }}>
        <Grid container style={{ height: 50 }}>
          <Grid item xs={6} style={{ height: 50 }}>

            <Button onClick={this.handleClick} variant="outlined" style={styles.button}>
              <Icon>date_range</Icon>
        &nbsp;&nbsp;{this.state.date}
            </Button>

            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
              PaperProps={{
                style: {
                  maxHeight: 250,
                  width: 200,
                  marginTop: 57,
                },
              }}
            >
              {this.state.matDay.map(option => (
                <MenuItem key={option} selected={option === this.state.date} style={{ fontFamily: Fonts.MarkOT, }} onClick={() => {
                  var date = option
                  if (date === 'Today') {
                    this.setState({ time: this.state.matTimeToday[0] })
                    this.setState({ matTime: this.state.matTimeToday })
                  } else {
                    this.setState({ time: this.state.matTimeTomorrow[0] })
                    this.setState({ matTime: this.state.matTimeTomorrow })
                  }
                  this.setState({ date: date })
                  this.handleClose()
                }}>
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
          <Grid item xs={6} style={{ height: 50 }}>

            <Button onClick={this.handleClick2} variant="outlined" style={styles.button}>
              <Icon>timer</Icon>
        &nbsp;&nbsp;{this.state.time}
            </Button>

            <Menu
              id="long-menu"
              anchorEl={anchorEl2}
              open={Boolean(anchorEl2)}
              onClose={this.handleClose2}
              PaperProps={{
                style: {
                  maxHeight: 250,
                  width: 200,
                  marginTop: 57
                },
              }}
            >
              {this.state.matTime.map(option => (
                <MenuItem key={option} selected={option === this.state.time} style={{ fontFamily: Fonts.MarkOT, }} onClick={() => {
                  this.setState({ time: option });
                  this.handleClose2()
                }}>
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>
      </div>
    );
  }
}
const styles = {
  incrementBtn: {
    color: colors.primary,
    fontSize: 23,
    fontFamily: Fonts.MarkOTBold,
  },
  buttonBack: {
    position: 'absolute',
    top: 75,
    left: 15,
    zIndex: 20,
  },
  title: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 23,
    paddingLeft: 15,
    color: '#4a4a4a',
    marginTop: 10,
  },
  subText: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 16,
    color: '#4a4a4a',
    textAlign: 'center'
  },
  subTextOff: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 16,
    fontWeight: 'normal',
    color: '#CACACF',
  },
  subtitle: {
    fontFamily: Fonts.MarkOT,
    fontSize: 16,
    color: colors.subtitle,
    fontWeight: 'normal',
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 15,
    marginBottom: 5,
    textAlign: 'left',
  },
  subtitle2: {
    fontFamily: Fonts.MarkOT,
    fontSize: 16,
    fontWeight: 'normal',
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 25,
    marginBottom: 5,
    color: colors.subtitle,
    textAlign: 'left',
  },
  button: {
    fontFamily: Fonts.MarkOT,
    fontSize: 15,
    height: 50,
    width: '90%',
    fontWeight: 'Bold',
    color: '#4a4a4a',
  },
  secondRes: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  mainRes: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 17,
    fontWeight: 'bold',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  rowResult: {
    flex: 1, borderBottomWidth: 1, borderColor: '#EAEAEA', paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15
  },
  textIngredient: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
    paddingLeft: 15,
  },
  textBenefit: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    marginTop: 10,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    paddingLeft: 15,
    height: 30,
  },
  titleBenefit: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 19,
    fontWeight: 'Bold',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
  },
  row: {
    textAlign: 'left',
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    color: '#4a4a4a',
    paddingTop: 5
  },
  boxPrice: {
    width: 70,
    height: 25,
    borderRadius: 15,
    bottom: 20,
    right: 15,
    backgroundColor: '#FAF9F9',
    alignItems: 'center',
    fontFamily: Fonts.MarkOT,
    justifyContent: 'center',
    borderColor: '#EAEAEA',
    color: '#8CB5A1',
    paddingTop: 9,
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(Product);
