export const FETCHING_DATA_USER_RESQUEST = 'FETCHING_DATA_USER_RESQUEST';
export const FETCHING_DATA_USER_SUCESS = 'FETCHING_DATA_USER_SUCESS';
export const FETCHING_DATA_USER_FAILURE = 'FETCHING_DATA_USER_FAILURE';

export const INIT_APP = 'INIT_APP';
export const UPDATE_STEP_LOGIN = 'UPDATE_STEP_LOGIN';
export const SET_EMAIL_USER = 'SET_EMAIL_USER';
export const SET_PASSWORD_USER = 'SET_PASSWORD_USER';
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_SIGNUP1 = 'USER_SIGNUP1';
export const SET_INFO_USER = 'SET_INFO_USER';
export const SET_INFO_OFFER = 'SET_INFO_OFFER';
export const SET_INFO_BIDS = 'SET_INFO_BIDS';
export const SET_INFO_PAST = 'SET_INFO_PAST';
export const SET_OFFER_ID = 'SET_OFFER_ID';
export const SET_INSTANT_BOOK = 'SET_INSTANT_BOOK';
export const RESET_LOGIN_DATA = 'RESET_LOGIN_DATA';
export const SET_TOKEN_DEVICE = 'SET_TOKEN_DEVICE';
export const SET_AVAILABILITIES_CALENDAR = 'SET_AVAILABILITIES_CALENDAR';
export const SET_DOC_OBJ = 'SET_DOC_OBJ';
export const SET_DATE_FILTER = 'SET_DATE_FILTER';
export const OPEN_LIST_CLINIC2 = 'OPEN_LIST_CLINIC2';
export const SET_NEXT_STEP_HOMEFLOW = 'SET_NEXT_STEP_HOMEFLOW';
export const SET_LIVE_OFFER = 'SET_LIVE_OFFER';
export const OPEN_LOGIN_HOME = 'OPEN_LOGIN_HOME';
export const SET_NUMBER_BIDS = 'SET_NUMBER_BIDS';
export const OPEN_RESET_PWD = 'OPEN_RESET_PWD';
export const SET_PRODUCT_CHOSEN = 'SET_PRODUCT_CHOSEN';
export const SET_OPEN_LINK_PARTY = 'SET_OPEN_LINK_PARTY';
export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_SUBSCRIBE = 'SET_SUBSCRIBE';
export const SET_ANSWERS = 'SET_ANSWERS';
export const SET_ATTENDEE_PRODUCT = 'SET_ATTENDEE_PRODUCT';
export const SET_ADDITIONAL_PRODUCT = 'SET_ADDITIONAL_PRODUCT';
export const SET_USERS_CONFIRMED = 'SET_USERS_CONFIRMED';
export const SET_INFO_REVIEW = 'SET_INFO_REVIEW';
export const SET_SECONDARY_PRODUCTS = 'SET_SECONDARY_PRODUCTS';
export const SET_BOOSTERS = 'SET_BOOSTERS';
export const SET_DATE_APPT = 'SET_DATE_APPT';
export const SET_NURSE_INFO = 'SET_NURSE_INFO';
export const SET_INVITE_CODE_EVENT = 'SET_INVITE_CODE_EVENT';
export const SET_DURATION_EVENT = 'SET_DURATION_EVENT';
export const SET_NOTES = 'SET_NOTES';
export const SET_BUSINESS_PHONE = 'SET_BUSINESS_PHONE';
export const SET_ORGANIZER_ID = 'SET_ORGANIZER_ID';
export const SET_BUSINESS_NAME = 'SET_BUSINESS_NAME';
export const SET_FOCUS_PHONE_INPUT = 'SET_FOCUS_PHONE_INPUT';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const RESET_LOCATION_DATA = 'RESET_LOCATION_DATA';
export const SET_CREDIT_CARD_REQUIRED = 'SET_CREDIT_CARD_REQUIRED';
export const SET_EMAIL_VERIFIED = 'SET_EMAIL_VERIFIED';
export const SET_SEARCH_REGION = 'SET_SEARCH_REGION';
export const SET_TRUE_VAULT_USER = 'SET_TRUE_VAULT_USER';
export const SET_ADDRESS_HOME_REQUEST = 'SET_ADDRESS_HOME_REQUEST';
export const OPEN_PARTY_FLOW = 'OPEN_PARTY_FLOW';
export const SET_USER_PWD = 'SET_USER_PWD';
export const SET_TIME_IV = 'SET_TIME_IV';
export const SET_LIST_CITIES = 'SET_LIST_CITIES';
export const SET_FIREBASE_CLINICS = 'SET_FIREBASE_CLINICS';
export const OPEN_BEFORE_LOGIN = 'OPEN_BEFORE_LOGIN';
export const SET_PINS_MAP = 'SET_PINS_MAP';
export const SET_CLINIC_REACHED = 'SET_CLINIC_REACHED';
export const SET_ALMOST_DONE = 'SET_ALMOST_DONE';
export const OPEN_IV_FLOW = 'OPEN_IV_FLOW';
export const SET_DISCOUNT_CODE = 'SET_DISCOUNT_CODE';
export const SET_ID_APPT = 'SET_ID_APPT';
export const SET_NO_FOOTER = 'SET_NO_FOOTER';
export const SET_INSTANT_BOOK_FROM_MAP = 'SET_INSTANT_BOOK_FROM_MAP';
export const OPEN_SIGN_UP_POPUP = 'OPEN_SIGN_UP_POPUP';
export const SET_FROM_INFOCLINIC_TO_BOOK = 'SET_FROM_INFOCLINIC_TO_BOOK';
export const showUserLocation = 'showUserLocation';
export const SET_LOADER_STATUS = 'SET_LOADER_STATUS';
export const SHOW_COMPLETE_PROFILE = 'SHOW_COMPLETE_PROFILE';
export const SET_USER_FIRSTNAME = 'SET_USER_FIRSTNAME';
export const SET_USER_LASTNAME = 'SET_USER_LASTNAME';
export const SET_USER_BIRTH = 'SET_USER_BIRTH';
export const SET_USER_PHONE = 'SET_USER_PHONE';
export const RESET_CACHE_INIT = 'RESET_CACHE_INIT';
export const SET_CLINICS_REQUEST = 'SET_CLINICS_REQUEST';
export const RESET_CACHE_HOME = 'RESET_CACHE_HOME';
export const OPEN_LIST = 'OPEN_LIST';
export const SET_USER_LOCATION = 'SET_USER_LOCATION';
export const SET_STEP_LOG = 'SET_STEP_LOG';
export const SET_SEARCH_PROVIDER = 'SET_SEARCH_PROVIDER';
export const OPEN_ALL_CLINICS = 'OPEN_ALL_CLINICS';
export const SET_CLINICS_DISPLAY = 'SET_CLINICS_DISPLAY';
export const SET_REGION_MAP = 'SET_REGION_MAP';
export const OPEN_LIST_CLINIC = 'OPEN_LIST_CLINIC';
export const SET_STEP_HOME = 'SET_STEP_HOME';
export const OPEN_SEARCH = 'OPEN_SEARCH';
export const OPEN_FILTER = 'OPEN_FILTER';
export const OPEN_INFO_CLINIC = 'OPEN_INFO_CLINIC';
export const SET_COUNTER_SELECT = 'SET_COUNTER_SELECT';
export const SET_INSTANT_ONLY = 'SET_INSTANT_ONLY';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_ESTIMATE_PPU = 'SET_ESTIMATE_PPU';
export const SET_PREFERENCE_DISPLAY = 'SET_PREFERENCE_DISPLAY';
export const SET_TYPE_CHOICE = 'SET_TYPE_CHOICE';
export const RESET_DATA_LOCATION = 'RESET_DATA_LOCATION';
export const OPEN_QUANTITY = 'OPEN_QUANTITY';
export const OPEN_PRICE = 'OPEN_PRICE';
export const OPEN_LOGIN = 'OPEN_LOGIN';
export const UPDATE_TAB_APP = 'UPDATE_TAB_APP';
export const OPEN_TIME = 'OPEN_TIME';
export const SET_AVAILABILITY_TYPE = 'SET_AVAILABILITY_TYPE';
export const LOCATION_ENTERED = 'LOCATION_ENTERED';
export const SET_DATE_SLOT_APPT = 'SET_DATE_SLOT_APPT';
export const OPEN_CLOSE_SETTINGS = 'OPEN_CLOSE_SETTINGS';
export const OPEN_CLOSE_PRIVACY = 'OPEN_CLOSE_PRIVACY';
export const OPEN_CLOSE_TERMS = 'OPEN_CLOSE_TERMS';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_DISTANCE = 'SET_DISTANCE';
export const SET_PRICE_PER_UNIT = 'SET_PRICE_PER_UNIT';
export const SET_TOTAL_PRICE = 'SET_TOTAL_PRICE';
export const SET_ANSWER1 = 'SET_ANSWER1';
export const SET_ANSWER3 = 'SET_ANSWER3';
export const SET_PREFERENCE = 'SET_PREFERENCE';
export const SET_PREFERENCE_DAY1 = 'SET_PREFERENCE_DAY1';
export const SET_PREFERENCE_TIME1 = 'SET_PREFERENCE_TIME1';
export const SET_PREFERENCE_DAY2 = 'SET_PREFERENCE_DAY2';
export const SET_PREFERENCE_TIME1_FROM = 'SET_PREFERENCE_TIME1_FROM';
export const SET_PREFERENCE_TIME2_FROM = 'SET_PREFERENCE_TIME2_FROM';
export const SET_PREFERENCE_TIME1_TO = 'SET_PREFERENCE_TIME1_TO';
export const SET_PREFERENCE_TIME2_TO = 'SET_PREFERENCE_TIME2_TO';
export const SET_FROM_MAP_TO_BOOK = 'SET_FROM_MAP_TO_BOOK';

export const SET_USER_CONNECTED = 'SET_USER_CONNECTED';
export const SET_PROVIDERS_PREFERENCE = 'SET_PROVIDERS_PREFERENCE';
export const SET_LOCATION_HIGH_UP = 'SET_LOCATION_HIGH_UP';
export const SET_ACCESSORIES = 'SET_ACCESSORIES';

export const UPDATE_OFFER_PLACED = 'UPDATE_OFFER_PLACED';
export const SET_LOADER_PRICE_ESTIMATOR = 'SET_LOADER_PRICE_ESTIMATOR';
export const SET_ERROR_PRICE_ESTIMATOR = 'SET_ERROR_PRICE_ESTIMATOR';
export const SET_AVERAGE_PRICE_PERUNIT = 'SET_AVERAGE_PRICE_PERUNIT';
export const SET_OFFER_ACCEPTED = 'SET_OFFER_ACCEPTED';
export const SET_VALUE_LOADER = 'SET_VALUE_LOADER';
export const SET_LOADER_HOME = 'SET_LOADER_HOME';
export const SET_CHARGE25 = 'SET_CHARGE25';
export const SET_PROVIDER_CHOICE_INFO = 'SET_PROVIDER_CHOICE_INFO';
export const OPEN_CLOSE_SYSTEM = 'OPEN_CLOSE_SYSTEM';
export const OPEN_CLOSE_ADD_CREDIT_CARD = 'OPEN_CLOSE_ADD_CREDIT_CARD';
export const OPEN_CLOSE_MODIFY_CREDIT_CARD = 'OPEN_CLOSE_MODIFY_CREDIT_CARD';
export const OPEN_CLOSE_CHOICE_CB = 'OPEN_CLOSE_CHOICE_CB';
export const SET_DEFAULT_CB = 'SET_DEFAULT_CB';
export const SET_INSTAVICE_VARIABLES = 'SET_INSTAVICE_VARIABLES';
export const SET_FULL_SCREEN_LOADER = 'SET_FULL_SCREEN_LOADER';
export const UPDATE_APP = 'UPDATE_APP';
export const SET_PROVIDER_CHOICE_TYPE = 'SET_PROVIDER_CHOICE_TYPE';
export const SET_CARDS_INFO = 'SET_CARDS_INFO';
export const SHOW_FULL_LOADER = 'SHOW_FULL_LOADER';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_NAME_LOCATION = 'SET_NAME_LOCATION';
export const SET_LIST_APPTS = 'SET_LIST_APPTS';
export const SET_LIST_TRANSFERS = 'SET_LIST_TRANSFERS';
export const OPEN_CHECKOUT = 'OPEN_CHECKOUT';
export const SET_TYPE_CHECKOUT = 'SET_TYPE_CHECKOUT';
export const RESET_DATA_HOME = 'RESET_DATA_HOME';
export const SET_REFERAL = 'SET_REFERAL';
export const OPEN_CHECKOUT2 = 'OPEN_CHECKOUT2';
export const OPEN_REVIEWS = 'OPEN_REVIEWS';
export const RESET_USER_DATA = 'RESET_USER_DATA';
export const OPEN_WALLET = 'OPEN_WALLET';
export const SET_TYPE_DEMAND = 'SET_TYPE_DEMAND';
export const SET_STEP_LOGIN = 'SET_STEP_LOGIN';
export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL';
export const SET_RECEIVER = 'SET_RECEIVER';
export const SET_PREVIOUS_PAGE = 'SET_PREVIOUS_PAGE';
export const SET_TIME_ZONE = 'SET_TIME_ZONE';

export const OPEN_CLOSE_SET_UNIT = 'OPEN_CLOSE_SET_UNIT';
export const SET_NUMBER_UNIT = 'SET_NUMBER_UNIT';
export const CHANGE_TAB_FORM = 'CHANGE_TAB_FORM';
export const SET_PAYMENT_OPTION = 'SET_PAYMENT_OPTION';
export const SET_NUMBER_UNIT_ORDERED_PER_PATIENT = 'SET_NUMBER_UNIT_ORDERED_PER_PATIENT';
export const RESET_NUMBER_UNITS = 'RESET_NUMBER_UNITS';
export const SET_INFO_CARDS = 'SET_INFO_CARDS';

export const CHANGE_VALUE_OFFER_PLACED = 'CHANGE_VALUE_OFFER_PLACED';

export const OPEN_CLOSE_PRACTICIAN = 'OPEN_CLOSE_PRACTICIAN';
export const OPEN_CLOSE_PRICE = 'OPEN_CLOSE_PRICE';
export const OPEN_CLOSE_EDIT_POPUP = 'OPEN_CLOSE_EDIT_POPUP';

export const SET_OFFER_PLACED_FALSE = 'SET_OFFER_PLACED_FALSE';
export const OPEN_CLOSE_TUTORIAL = 'OPEN_CLOSE_TUTORIAL';

export const OPEN_CLOSE_INVOICE = 'OPEN_CLOSE_INVOICE';
export const SET_INFO_INVOICE = 'SET_INFO_INVOICE';

export const OPEN_CLOSE_INVITE_FRIENDS = 'OPEN_CLOSE_INVITE_FRIENDS';
export const OPEN_CLOSE_PAYMENTS = 'OPEN_CLOSE_PAYMENTS';
export const UPDATE_TAB_LOADING = 'UPDATE_TAB_LOADING';
export const OPEN_CLOSE_REVIEWS = 'OPEN_CLOSE_REVIEWS';
export const SET_OFFER_REVIEW = 'SET_OFFER_REVIEW';
export const OPEN_REVIEW = 'OPEN_REVIEW';
export const SET_LAST_INFO_OFFER = 'SET_LAST_INFO_OFFER';
export const OPEN_CLOSE_REVIEWS2 = 'OPEN_CLOSE_REVIEWS2';
export const SET_UNPAID_OFFER_ID = 'SET_UNPAID_OFFER_ID';
export const SET_VALUE_SHOW_CHECKOUT = 'SET_VALUE_SHOW_CHECKOUT';
export const SET_VALUE_INFO_CHECKOUT = 'SET_VALUE_INFO_CHECKOUT';
