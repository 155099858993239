import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import '../../../css/Home.css';
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'
import moment from 'moment';
import momentTZ from 'moment-timezone';
import DiscountCode from './DiscountCode'
import ButtonCB from './ButtonCB';
import EditAdditional from './EditAdditional';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { Fonts } from '../../../utils/Font';
import colors from '../../layout/colors'
import Divider from '@material-ui/core/Divider';
import jstz from 'jstz';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPrice: 0,
      pricePerUnit: 0,
      UnitRequested: 0,
      creditCardCharge: 0,
      newWallet: 0,
      transferPrice: 0,
      modalChooseCB: false,
      date: '',
      showListCB: false,
      totalPriceBoosters: 0,
      estimatedTotal: 0,
      totalPriceSecondary: 0,
      priceAdditionalProduct: 0,
      totalBagsOrdered: 0,
      numberBagsOrdered: 0,
      showDetailAdditional: false,
      showDetailSecondary: false
    };
  }
  async componentWillMount() {
    this.props.onRef(this)
    if (this.props.dataHome.productChosen.type !== 'shots') {
      this.chargeDataCheckout()
    }
  }
  async chargeDataCheckout() {
    var priceProductChosen = 0
    var totalPriceSecondary = 0
    var estimatedTotal = 0
    var priceAdditionalProduct = 0
    var numberBagsOrdered = 1

    if (this.props.dataHome.productChosen.pricesGroup.filter(price => price.value === Number(this.props.dataHome.numberUnit)).length !== 0) {
      priceProductChosen = Number(this.props.dataHome.productChosen.pricesGroup.filter(price => price.value === Number(this.props.dataHome.numberUnit))[0].price)
    } else {
      priceProductChosen = Number(this.props.dataHome.productChosen.pricesGroup.filter(price => price.type === 'greater')[0].price)
    }
    var totalBagsOrdered = priceProductChosen
    if (this.props.dataHome.paymentOption.value === 'single') {
      if (this.props.dataHome.receiver === 'else') {
        estimatedTotal = 0
        priceProductChosen = 0
        totalPriceSecondary = 0
        numberBagsOrdered = 0
        const productsSelected = Object.values(this.props.dataHome.attendeeProduct).filter(product => product.numberAdded !== 0)
        for (var k in productsSelected) {
          numberBagsOrdered = numberBagsOrdered + Number(productsSelected[k].numberAdded)
        }
      } else {
        if (this.props.dataHome.additionalProduct.numberAdded !== 0) {
          if (!this.props.dataHome.subscribe) {
            if (this.props.dataHome.numberUnit === 1) {
              priceAdditionalProduct = this.props.dataHome.additionalProduct.price * this.props.dataHome.additionalProduct.numberAdded
            } else {
              priceAdditionalProduct = this.props.dataHome.additionalProduct.price
            }
          }
        }
        if (!this.props.dataHome.subscribe) {
          numberBagsOrdered = 1
        } else {
          numberBagsOrdered = 1
        }

        estimatedTotal = priceProductChosen
      }
    } else {
      if (!this.props.dataHome.subscribe) {
        if (this.props.dataHome.additionalProduct.numberAdded !== 0) {

          priceAdditionalProduct = Number(this.props.dataHome.additionalProduct.price) * Number(this.props.dataHome.additionalProduct.numberAdded)
        }
        const productsSelected = Object.values(this.props.dataHome.attendeeProduct).filter(product => product.numberAdded !== 0)

        numberBagsOrdered = 0
        totalBagsOrdered = 0
        for (var j in productsSelected) {
          numberBagsOrdered = numberBagsOrdered + Number(productsSelected[j].numberAdded)
          totalBagsOrdered = totalBagsOrdered + Number(productsSelected[j].price) * Number(productsSelected[j].numberAdded)
        }
        estimatedTotal = totalBagsOrdered
      } else {
        estimatedTotal = 0
        priceProductChosen = 0
        totalPriceSecondary = 0
        numberBagsOrdered = 1
      }
    }
    var pricePerUnit = totalBagsOrdered / numberBagsOrdered
    pricePerUnit = pricePerUnit.toFixed(0)
    if (this.props.category.unitPrice) {
      estimatedTotal = estimatedTotal * this.props.dataHome.numberUnitOrderedPerPatient
    }
    this.setState({ pricePerUnit: pricePerUnit })
    this.setState({ totalBagsOrdered: totalBagsOrdered })
    this.setState({ numberBagsOrdered: numberBagsOrdered })
    this.setState({ priceAdditionalProduct: priceAdditionalProduct })
    this.setState({ totalPriceSecondary: totalPriceSecondary })
    this.setState({ estimatedTotal: estimatedTotal })

    var pricing = {
      pricePerUnit: pricePerUnit,
      totalBagsOrdered: totalBagsOrdered,
      numberBagsOrdered: numberBagsOrdered,
      totalPriceSecondary: totalPriceSecondary,
      estimatedTotal: estimatedTotal
    }
    await this.props.setPricing(pricing)
    return true
  }
  openModalList() {
    window.scrollTo(0, 0)
    this.setState({ showListCB: !this.state.showListCB });
  }
  openPayments() {
    window.scrollTo(0, 0)
    this.buttonCBRef.openCB(true, true)
  }
  pressDetailPrice(val) {
    window.scrollTo(0, 0)
    this.setState({ showDetailAdditional: val })
  }
  pressDetailSecondary(val) {
    window.scrollTo(0, 0)
    this.setState({ showDetailSecondary: val })
  }

  rowDateApptSlot() {
    if (!this.props.dataHome.dateSlotAppt.dateNew.includes('GMT')) {
      var first = this.props.dataHome.dateSlotAppt.dateNew.split('T')[1]
      if (!this.props.dataHome.dateSlotAppt.dateNew.includes('+')) {
        first = first.split('-')[1]
        return this.props.dataHome.dateSlotAppt.dateNew.split('-' + first)[0]
      }
      else {
        first = first.split('+')[1]
        return this.props.dataHome.dateSlotAppt.dateNew.split('+' + first)[0]
      }
    }
    return this.props.dataHome.dateSlotAppt.dateNew.split(' GMT')[0]
  }
  getTimeAppt() {
    if ((this.props.dataHome.providersPreference.type === 'pick' || this.props.dataHome.providersPreference.type === 'rebook') && !this.props.dataHome.subscribe) {
      if (moment.tz(new Date(), jstz.determine().name()).format('Z') !== this.props.dataHome.dateSlotAppt.date.split(' GMT')[1]) {
        return moment(this.rowDateApptSlot()).format('h:mm a') + ' (' + this.props.dataHome.dateSlotAppt.availability.timeZoneName + ')'
      } else {
        return moment(this.rowDateApptSlot()).format('h:mm a')
      }
    } else {
      if (this.props.dataHome.productChosen.type !== 'shots') {
        if (this.props.dataHome.timeZone === '') {
          return moment(this.rawDateAppt()).format('h:mm a')
        } else if (moment().format('Z').toString() !== this.props.dataHome.timeZone.offSet) {
          return moment(this.rawDateAppt()).format('h:mm a') + ' (' + this.props.dataHome.timeZone.timeZoneName2 + ')'
        } else {
          return moment(this.rawDateAppt()).format('h:mm a')
        }
      } else {
        if (this.props.dataHome.timeZone === '') {
          return moment(this.props.dataHome.dateSlotAppt.date.split(' GMT')[0]).format('h:mm a') + ' to ' + moment(this.rawDateAppt()).add(this.props.dataHome.durationEvent, 'm').format('h:mm a')
        } else if (moment().format('Z').toString() !== this.props.dataHome.timeZone.offSet) {
          return moment(this.rawDateAppt()).format('h:mm a') + ' to ' + moment(this.rawDateAppt()).add(this.props.dataHome.durationEvent, 'm').format('h:mm a') + ' (' + this.props.dataHome.timeZone.timeZoneName2 + ')'
        } else {
          return moment(this.rawDateAppt()).format('h:mm a') + ' to ' + moment(this.rawDateAppt()).add(this.props.dataHome.durationEvent, 'm').format('h:mm a')
        }
      }
    }
  }
  getDateAppt() {
    if ((this.props.dataHome.providersPreference.type === 'pick' || this.props.dataHome.providersPreference.type === 'rebook') && !this.props.dataHome.subscribe) {
      return moment(this.rowDateApptSlot()).format('ddd, Do MMM')
    } else {
      return moment(this.rawDateAppt()).format('ddd, Do MMM')
    }
  }
  rawDateAppt() {
    var date = (!!this.props.dataHome.dateAppt && this.props.dataHome.dateAppt !== '') ?
      this.props.dataHome.dateAppt :
      (!!this.props.dataHome.dateSlotAppt) ?
        this.props.dataHome.dateSlotAppt.date : null
    if (!date.includes('GMT')) {
      var first = date.split('T')[1]
      if (!date.includes('+')) {
        first = first.split('-')[1]
        return date.split('-' + first)[0]
      }
      else {
        first = first.split('+')[1]
        return date.split('+' + first)[0]
      }
    }
    return date.split(' GMT')[0]
  }
  groupLabel() {
    if (this.props.category.unitPrice && this.props.dataHome.numberUnit > 1) {
      return '(group)'
    }
    return ''
  }
  render() {
    return (
      <div className="App" style={{ marginTop: 10 }}>
        {
          this.props.dataHome.productChosen.type !== 'shots' ?
            <div>
              <Grid container style={{ flex: 1, marginTop: 15, marginBottom: 15, marginRight: 0 }} alignItems="center" justify="center">
                <Grid item xs={8} style={{ textAlign: 'left' }}>
                  <div style={{ marginLeft: 15 }}>
                    <div style={styles.textCheckout2}>{this.props.dataHome.productChosen.categoryName} • {this.props.dataHome.productChosen.name}</div>
                    <div style={styles.textCheckout3}>{this.getDateAppt()} • {this.getTimeAppt()}</div>
                    <div style={styles.textCheckout3}>{this.props.dataHome.addressHomeRequest}</div>
                  </div>
                </Grid>
                <Grid item xs={4} style={styles.center}>
                  <div style={{ marginRight: 5 }}>
                    <img
                      className="imgCheckout"
                      src={this.props.dataHome.productChosen.imageSmall}
                      alt=""
                    />
                  </div>
                </Grid>
              </Grid>

              <Divider style={{ width: '96%', marginLeft: '3%', }} />
              {
                !this.props.dataHome.subscribe ?
                  <Grid container style={{ height: 30, marginTop: 5, }} alignItems="center" justify="center">
                    <Grid item xs={10} style={{ textAlign: 'left', paddingLeft: 15 }}>
                      <div style={styles.textCheckout}>Total {this.props.category.unit.charAt(0) + this.props.category.unit.slice(1)}s {this.props.category.verb}</div>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right', paddingRight: 15 }}>
                      {
                        !this.props.dataHome.subscribe ?
                          <div style={styles.textCheckout}>{this.props.dataHome.numberUnit}</div>
                          :
                          <div style={styles.textCheckout}>1</div>
                      }
                    </Grid>
                  </Grid>
                  : null
              }
              {
                !this.props.dataHome.subscribe ?
                  <Grid container style={{ height: 30, marginTop: 0, }} alignItems="center" justify="center">
                    <Grid item xs={10} style={{ textAlign: 'left', paddingLeft: 15 }}>
                      <div style={styles.textCheckout}>{this.props.category.unit.charAt(0).toUpperCase() + this.props.category.unit.slice(1)}s you pay for</div>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right', paddingRight: 15 }}>
                      {
                        !this.props.dataHome.subscribe && ((this.props.dataHome.receiver === 'else' && this.props.dataHome.paymentOption.value === 'single')) ?
                          <div style={styles.textCheckout}>0</div>
                          : this.props.dataHome.subscribe && (this.props.dataHome.receiver === 'else' && this.props.dataHome.paymentOption.value === 'group') ?
                            <div style={styles.textCheckout}>0</div>
                            : !this.props.dataHome.subscribe && this.props.dataHome.paymentOption.value === 'group' ?
                              <div style={styles.textCheckout}>{this.props.dataHome.numberUnit}</div>
                              :
                              <div style={styles.textCheckout}>1</div>
                      }

                    </Grid>
                  </Grid>
                  : null
              }
              <Grid container style={{ height: 30, marginTop: 0, }} alignItems="center" justify="center">
                <Grid item xs={10} style={{ textAlign: 'left', paddingLeft: 15 }}>
                  {this.props.category.unitPrice ?
                    <div style={styles.textCheckout}>Price per unit</div>
                    :
                    <div style={styles.textCheckout}>Price per {this.props.dataHome.productChosen.unit}</div>
                  }
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right', paddingRight: 15 }}>
                  <div style={styles.textCheckout}>${this.state.pricePerUnit}</div>
                </Grid>
              </Grid>

              {(this.props.category.unitPrice) ?
                <Grid container style={{ height: 30, marginTop: 0, }} alignItems="center" justify="center">
                  <Grid item xs={10} style={{ textAlign: 'left', paddingLeft: 15 }}>
                    <div style={styles.textCheckout}>Units ordered {this.groupLabel()}</div>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right', paddingRight: 15 }}>
                    <div style={styles.textCheckout}>{this.props.dataHome.numberUnitOrderedPerPatient}</div>
                  </Grid>
                </Grid>
                : null
              }

              <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 10 }} />

              <Grid container style={{ height: 30, marginTop: 10, }} alignItems="center" justify="center">
                <Grid item xs={10} style={{ textAlign: 'left', paddingLeft: 15 }}>
                  <div style={styles.textCheckout2}>Est. total</div>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right', paddingRight: 15 }}>
                  <div style={styles.textCheckout2}>${this.state.estimatedTotal}</div>
                </Grid>
              </Grid>
              {
                this.props.dataUser.userConnected ?
                  <Grid container style={{ height: 30, marginTop: 0, }} alignItems="center" justify="center">
                    <Grid item xs={10} style={{ textAlign: 'left', paddingLeft: 15 }}>
                      <div style={styles.textCheckoutTot}>Credits</div>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right', paddingRight: 15 }}>
                      <div style={styles.textCheckoutTot}>${this.props.dataUser.infoUser.wallet.totalWallet}</div>
                    </Grid>
                  </Grid>
                  : null
              }
              {
                this.props.dataUser.userConnected ?
                  <div>
                    <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 10 }} />
                    <Grid container style={{ height: 30, marginTop: 5, }} alignItems="center" justify="center">
                      <Grid item xs={10} style={{ textAlign: 'left', paddingLeft: 15 }}>
                        <div style={styles.textCheckout2}>Est. charge amount</div>
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: 'right', paddingRight: 15 }}>
                        <div style={styles.textCheckout2}>${Math.max(0, this.state.estimatedTotal - Number(this.props.dataUser.infoUser.wallet.totalWallet))}</div>
                      </Grid>
                    </Grid>
                  </div>
                  : null
              }

              {this.props.dataHome.numberUnit !== 1 || this.props.dataHome.receiver === 'else' ?
                <div>
                  <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 10 }} />

                  <Grid container style={{ height: 30, marginTop: 0, }} alignItems="center" justify="center">
                    <Grid item xs={5} style={{ textAlign: 'left', paddingLeft: 15 }}>
                      <div style={styles.textCheckout}>Invite code</div>
                    </Grid>
                    <Grid item xs={7} style={{ textAlign: 'right', paddingRight: 15 }}>
                      <div style={styles.textCheckout}>{this.props.dataHome.inviteCodeEvent}</div>
                    </Grid>
                  </Grid>
                </div>
                : null
              }
              {
                !this.props.category.secondary ?
                  <Grid container style={{ height: 30, marginTop: 0, marginBottom: 0 }} alignItems="center" justify="center">
                    <Grid item xs={10} style={{ textAlign: 'left', paddingLeft: 15 }}>
                      <div style={styles.textCheckout}>Add’l products (charged as used)</div>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right', paddingRight: 15 }}>
                      {
                        !this.props.dataHome.subscribe ?
                          <div style={styles.textCheckoutButton} onClick={() => { this.pressDetailPrice(true) }}>Edit</div>
                          :
                          <div style={styles.textCheckoutButton} onClick={() => { this.pressDetailPrice(true) }}>View</div>
                      }
                    </Grid>
                  </Grid>
                  : null
              }
            </div>
            : this.props.dataHome.productChosen.type === 'shots' ?
              <div>
                <Grid container style={{ flex: 1, marginTop: 10, marginBottom: 10, marginRight: 0 }} alignItems="center" justify="center">
                  <Grid item xs={2} style={styles.center}>
                    {
                      this.props.dataHome.timeZone === '' ?
                        <div style={styles.center}>
                          <div style={styles.contentText}>{moment(this.props.dataHome.dateAppt).format('ddd')}</div>
                          <div style={styles.contentText2}>{moment(this.props.dataHome.dateAppt).format('Do')}</div>
                          <div style={styles.contentText3}>{moment(this.props.dataHome.dateAppt).format('MMM')}</div>
                        </div>
                        :
                        <div style={styles.center}>
                          <div style={styles.contentText}>{momentTZ.tz(this.props.dataHome.dateAppt, this.props.dataHome.timeZone.timeZoneId).format('ddd')}</div>
                          <div style={styles.contentText2}>{momentTZ.tz(this.props.dataHome.dateAppt, this.props.dataHome.timeZone.timeZoneId).format('Do')}</div>
                          <div style={styles.contentText3}>{momentTZ.tz(this.props.dataHome.dateAppt, this.props.dataHome.timeZone.timeZoneId).format('MMM')}</div>
                        </div>
                    }
                  </Grid>
                  <Grid item xs={6} md={9} style={{ textAlign: 'left', paddingLeft: 10 }}>
                    <div style={styles.textCheckout2}>{this.props.dataHome.productChosen.place}{this.props.dataHome.productChosen.name}</div>
                    {
                      this.props.dataHome.timeZone === '' ?
                        <div style={styles.textCheckout4}>{moment(this.props.dataHome.dateAppt).format('h:mm a')} to {moment(this.props.dataHome.dateAppt).add(this.props.dataHome.durationEvent, 'm').format('h:mm a')}</div>
                        : moment().format('Z').toString() !== this.props.dataHome.timeZone.offSet ?
                          <div style={styles.textCheckout4}>{momentTZ.tz(this.props.dataHome.dateAppt, this.props.dataHome.timeZone.timeZoneId).format('h:mm a')} to {momentTZ.tz(this.props.dataHome.dateAppt, this.props.dataHome.timeZone.timeZoneId).add(this.props.dataHome.durationEvent, 'm').format('h:mm a')} ({this.props.dataHome.timeZone.timeZoneName2})</div>
                          :
                          <div style={styles.textCheckout4}>{momentTZ.tz(this.props.dataHome.dateAppt, this.props.dataHome.timeZone.timeZoneId).format('h:mm a')} to {momentTZ.tz(this.props.dataHome.dateAppt, this.props.dataHome.timeZone.timeZoneId).add(this.props.dataHome.durationEvent, 'm').format('h:mm a')}</div>
                    }
                    <div style={styles.textCheckout4}>{this.props.dataHome.businessName}</div>
                    <div style={styles.textCheckout4}>{this.props.dataHome.addressHomeRequest}</div>

                  </Grid>
                  <Grid item xs={4} md={1} style={styles.center}>
                    <img
                      className="imgCheckout"
                      src={this.props.dataHome.productChosen.imageSmall}
                      alt=""
                    />
                  </Grid>
                </Grid>

                <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 10 }} />

                <Grid container style={{ height: 30, marginTop: 10, }} alignItems="center" justify="center">
                  <Grid item xs={11} style={{ textAlign: 'left', paddingLeft: 15 }}>
                    <div style={styles.textCheckout2}>Boosters selected</div>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right', paddingRight: 15 }}></Grid>
                </Grid>

                {this.props.dataHome.boostersSelected.map((booster, i) => (
                  <Grid container style={{ height: 30, marginTop: 0, }} alignItems="center" justify="center">
                    <Grid item xs={8} style={{ textAlign: 'left', paddingLeft: 15 }}>
                      <div style={styles.textCheckout}>{booster.name}</div>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right', paddingRight: 15 }}>
                      {
                        booster.oldPrice !== undefined ?
                          <div style={styles.textCheckoutOld}>${booster.oldPrice}</div>
                          : null
                      }

                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right', paddingRight: 15 }}>
                      <div style={styles.textCheckout}>${booster.price}</div>
                    </Grid>
                  </Grid>
                ))}

                {
                  !this.props.dataHome.subscribe ?
                    <div>
                      <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 10 }} />
                      <Grid container style={{ height: 30, marginTop: 10, }} alignItems="center" justify="center">
                        <Grid item xs={10} style={{ textAlign: 'left', paddingLeft: 15 }}>
                          <div style={styles.textCheckout2}>Estimated people</div>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right', paddingRight: 15 }}>
                          <div style={styles.textCheckout2}>{this.props.dataHome.numberUnit}</div>
                        </Grid>
                      </Grid>
                    </div>
                    : this.props.dataUser.userConnected ?
                      <div>
                        <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 10 }} />
                        <Grid container style={{ height: 30, marginTop: 10, }} alignItems="center" justify="center">
                          <Grid item xs={10} style={{ textAlign: 'left', paddingLeft: 15 }}>
                            <div style={styles.textCheckout2}>Credits</div>
                          </Grid>
                          <Grid item xs={2} style={{ textAlign: 'right', paddingRight: 15 }}>
                            <div style={styles.textCheckout2}>${this.props.dataUser.infoUser.wallet.totalWallet}</div>
                          </Grid>
                        </Grid>
                      </div>
                      : null
                }

                <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 10 }} />
                <Grid container style={{ height: 30, marginTop: 10, }} alignItems="center" justify="center">
                  <Grid item xs={5} style={{ textAlign: 'left', paddingLeft: 15 }}>
                    <div style={styles.textCheckout}>Invite code</div>
                  </Grid>
                  <Grid item xs={7} style={{ textAlign: 'right', paddingRight: 15 }}>
                    <div style={styles.textCheckout}>{this.props.dataHome.inviteCodeEvent}</div>
                  </Grid>
                </Grid>
              </div>
              : null
        }

        {((this.props.dataHome.productChosen.type !== 'shots' && (this.props.dataHome.paymentOption.value === 'single' || (this.props.dataHome.paymentOption.value === 'group' && !this.props.dataHome.subscribe)) && this.props.dataUser.userConnected) || (this.props.dataHome.productChosen.type === 'shots' && this.props.dataUser.userConnected)) && (
          <DiscountCode product={this.props.dataHome.productChosen} />
        )}




        {
          ((this.props.dataHome.productChosen.type === 'shots' && this.props.dataHome.subscribe && this.props.dataHome.creditCardRequired) || (this.props.dataHome.productChosen.type !== 'shots' && (this.props.dataHome.paymentOption.value === 'single' || (this.props.dataHome.paymentOption.value === 'group' && !this.props.dataHome.subscribe)) && this.props.dataHome.creditCardRequired)) && this.props.dataUser.userConnected ?
            <div style={{ marginTop: 15 }}>
              <ButtonCB onRef={ref => (this.buttonCBRef = ref)} journeyShow={this.props.journeyShow} />
            </div>
            : null
        }

        <Divider style={{ width: '96%', marginLeft: '3%', marginTop: 20 }} />

        <Grid container style={{ flex: 1, textAlign: 'left', paddingLeft: 15, paddingRight: 15, marginTop: 10 }}>
          {
            this.props.dataUser.userConnected && this.props.dataHome.creditCardRequired && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.paymentOption.value === 'group' && !this.props.dataHome.subscribe && this.props.dataHome.creditCardRequired ?
              <div style={styles.textSubTitle}>Reminder • You are in charge of the group’s bill. We will process payment after the appointment takes places.</div>
              : this.props.dataUser.userConnected && this.props.dataHome.creditCardRequired && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.paymentOption.value === 'group' && this.props.dataHome.subscribe && this.props.dataHome.creditCardRequired ?
                <div style={styles.textSubTitle}>Reminder • Your event host is taking care of the bill. You won’t be charged for this event.</div>
                : this.props.dataUser.userConnected && this.props.dataHome.creditCardRequired && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.receiver === 'else' && this.props.dataHome.numberUnit === 1 && !this.props.dataHome.subscribe && this.props.dataHome.creditCardRequired ?
                  <div style={styles.textSubTitle}>Reminder • Your event attendee is taking care of the bill. You won’t be charged for this event.</div>
                  : this.props.dataUser.userConnected && this.props.dataHome.creditCardRequired && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.receiver === 'else' && this.props.dataHome.numberUnit !== 1 && !this.props.dataHome.subscribe && this.props.dataHome.creditCardRequired ?
                    <div style={styles.textSubTitle}>Reminder • Your event attendees are taking care of the bill. You won’t be charged for this event.</div>
                    : this.props.dataUser.userConnected && this.props.dataHome.creditCardRequired && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.subscribe ?
                      <div style={styles.textSubTitle}>Reminder • We need a credit card on file to confirm your attendance. We won't charge you until the treatment has taken place.</div>
                      : this.props.dataUser.userConnected && this.props.dataHome.creditCardRequired && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.receiver === 'me' && !this.props.dataHome.subscribe && this.props.dataHome.creditCardRequired ?
                        <div style={styles.textSubTitle}>Reminder • We need a credit card on file to confirm your booking. We won't charge you until the treatment has taken place.</div>
                        : this.props.dataUser.userConnected && this.props.dataHome.subscribe && this.props.dataHome.productChosen.type === 'shots' && this.props.dataHome.creditCardRequired ?
                          <div style={styles.textSubTitle}>Reminder • We need a credit card on file to confirm your booking. We won't charge you until the treatment has taken place.</div>
                          : this.props.dataUser.userConnected && this.props.dataHome.productChosen.type === 'shots' && this.props.dataHome.creditCardRequired ?
                            <div style={styles.textSubTitle}>Reminder • Share your invite code with your attendees.</div>
                            : !this.props.dataUser.userConnected && this.props.dataHome.creditCardRequired && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.paymentOption.value === 'group' && this.props.dataHome.subscribe && this.props.dataHome.creditCardRequired ?
                              <div style={styles.textSubTitle}>Reminder • You need to sign in to proceed with the booking. You will be in charge of the group’s bill. We will process payment after the appointment takes places.</div>
                              : !this.props.dataUser.userConnected && this.props.dataHome.creditCardRequired && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.paymentOption.value === 'group' && this.props.dataHome.subscribe && this.props.dataHome.creditCardRequired ?
                                <div style={styles.textSubTitle}>Reminder • You need to sign in to proceed with the booking. Your event will take care of the bill. You won’t be charged for this event.</div>
                                : !this.props.dataUser.userConnected && this.props.dataHome.creditCardRequired && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.receiver === 'else' && this.props.dataHome.numberUnit === 1 && this.props.dataHome.subscribe && this.props.dataHome.creditCardRequired ?
                                  <div style={styles.textSubTitle}>Reminder • You need to sign in to proceed with the booking. Your event attendee will take care of the bill. You won’t be charged for this event.</div>
                                  : !this.props.dataUser.userConnected && this.props.dataHome.creditCardRequired && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.receiver === 'else' && this.props.dataHome.numberUnit !== 1 && this.props.dataHome.subscribe && this.props.dataHome.creditCardRequired ?
                                    <div style={styles.textSubTitle}>Reminder • You need to sign in to proceed with the booking. Your event attendees will take care of the bill. You won’t be charged for this event.</div>
                                    : !this.props.dataUser.userConnected && this.props.dataHome.creditCardRequired && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.subscribe && this.props.dataHome.creditCardRequired ?
                                      <div style={styles.textSubTitle}>Reminder • You need to sign in to proceed with the booking. We will need a credit card on file to confirm your attendance. We won't charge you until the treatment has taken place.</div>
                                      : !this.props.dataUser.userConnected && this.props.dataHome.creditCardRequired && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.receiver === 'me' && this.props.dataHome.subscribe && this.props.dataHome.creditCardRequired ?
                                        <div style={styles.textSubTitle}>Reminder • You need to sign in to proceed with the booking. We will need a credit card on file to confirm your booking. We won't charge you until the treatment has taken place.</div>
                                        : !this.props.dataUser.userConnected && this.props.dataHome.subscribe && this.props.dataHome.productChosen.type === 'shots' && this.props.dataHome.creditCardRequired ?
                                          <div style={styles.textSubTitle}>Reminder • You need to sign in to proceed with the booking. We will need a credit card on file to confirm your booking. We won't charge you until the treatment has taken place.</div>
                                          : !this.props.dataUser.userConnected && this.props.dataHome.productChosen.type === 'shots' && this.props.dataHome.creditCardRequired ?
                                            <div style={styles.textSubTitle}>Reminder • You need to sign in to proceed with the booking. You will need to share your invite code with your attendees.</div>
                                            : null
          }
        </Grid>

        <Dialog
          fullScreen
          scroll={'paper'}
          className="fullModal"
          open={this.state.showDetailAdditional}
          TransitionComponent={Transition}
        >
          <EditAdditional category={this.props.category} secondary={this.props.dataHome.secondaryProducts} additionalOn={true} additional={this.props.dataHome.additionalProduct} editable={!this.props.dataHome.subscribe} close={this.pressDetailPrice.bind(this)} chargeDataCheckout={this.chargeDataCheckout.bind(this)} journeyShow={this.props.journeyShow} />
        </Dialog>
      </div>
    );
  }
}
const styles = {
  textCheckout: {
    color: '#9A9A9F',
    fontSize: 16,
    fontFamily: Fonts.MarkOT,
  },
  textCheckoutButton: {
    color: colors.primary,
    fontSize: 16,
    fontFamily: Fonts.MarkOTMedium,
  },
  textCheckout3: {
    color: '#9A9A9F',
    fontSize: 13,
    fontFamily: Fonts.MarkOT,
  },
  textCheckout4: {
    color: '#9A9A9F',
    fontSize: 13,
    fontFamily: Fonts.MarkOT,
  },
  textCheckoutSelect: {
    color: '#F4BC9C',
    fontSize: 14,
    fontFamily: Fonts.MarkOT,
  },
  textCheckoutOld: {
    color: '#F4BC9C',
    fontSize: 16,
    fontFamily: Fonts.MarkOT,
    textDecorationLine: 'line-through'
  },
  contentText: {
    color: '#959393',
    fontSize: 13,
    fontFamily: Fonts.MarkOTMedium,
  },
  contentText2: {
    color: '#F4BC9C',
    fontSize: 19,
    fontFamily: Fonts.MarkOTMedium,
  },
  contentText3: {
    color: '#959393',
    fontSize: 13,
    fontFamily: Fonts.MarkOTMedium,
  },
  textCheckout2: {
    color: '#585858',
    fontSize: 16,
    fontFamily: Fonts.MarkOTMedium,
  },
  textCheckoutTot: {
    color: '#F4BC9C',
    fontSize: 16,
    fontFamily: Fonts.MarkOTMedium,
  },
  textTitle: {
    color: '#46474B',
    fontSize: 22,
    fontFamily: Fonts.MarkOT,
    marginTop: 0
  },
  textSubTitle: {
    color: '#66676D',
    fontFamily: Fonts.MarkOT,
    fontSize: 12,
  },
  divider: {
    width: window.innerWidth - 30,
    height: 1,
    marginLeft: 15,
    backgroundColor: '#eaeaea',
    marginTop: 10,
    marginBottom: 10,
  },
  row: {
    textAlign: 'left',
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    color: '#4a4a4a',
    paddingTop: 5
  },
  boxPrice: {
    width: 70,
    height: 25,
    borderRadius: 15,
    bottom: 20,
    right: 15,
    backgroundColor: '#FAF9F9',
    alignItems: 'center',
    fontFamily: Fonts.MarkOT,
    justifyContent: 'center',
    borderColor: '#EAEAEA',
    color: '#8CB5A1',
    paddingTop: 9,
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(Product);
