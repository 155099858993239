import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import logo from '../img/home/wellLogo.png';
import colors from './layout/colors'
import { withRouter } from 'react-router-dom'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import profileOn from '../img/footer/profileOn.png'
import findOn from '../img/footer/findOn.png'
import apptOn from '../img/footer/apptOn.png'
import infoOn from '../img/footer/infoOn.png'
import Icon from '@material-ui/core/Icon';

var headerPosition = 76

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      itemsMenu: [
        {
          text: 'Home',
          link: '/',
          icon: findOn
        },
        {
          text: 'Sign in',
          link: '/profile',
          icon: profileOn
        },
        {
          text: 'About Us',
          link: "https://www.wlnss.co",
          icon: infoOn
        },
      ],
      itemsMenuConnected: [
        {
          text: 'Home',
          link: '/',
          icon: findOn
        },
        {
          text: 'Appointments',
          link: '/appointments',
          icon: apptOn
        },
        {
          text: 'Profile',
          link: '/profile',
          icon: profileOn
        },
        {
          text: 'About Us',
          link: "https://www.wlnss.co",
          icon: infoOn
        },
      ],
      journeyShow: false,
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
  }
  handleDrawerOpen(val) {
    if (window.pageYOffset > 76 || !this.props.journeyShow) headerPosition = 0;
    else headerPosition = 76 - window.pageYOffset
    this.setState({ showMenu: val })
  }
  toggleDrawer = (side, open, link) => () => {
    this.setState({
      [side]: open,
    });
    if (link !== "https://www.wlnss.co")
      this.props.history.push(link)
    else window.open("https://www.wlnss.co")
  };

  render() {
    const fullList = (
      <div style={{ borderTopWidth: 1, borderColor: '#eaeaea' }}>
        {
          this.props.dataUser.userConnected ?
            <List>
              {this.state.itemsMenuConnected.map((item, index) => (
                <ListItem button key={index} onClick={this.toggleDrawer('showMenu', false, item.link)}>
                  <ListItemIcon><img src={item.icon} style={{ height: 30, width: 30, color: 'black' }} alt="" /></ListItemIcon>
                  <ListItemText disableTypography style={{ color: '#4a4a4a', fontFamily: 'AvenirMedium', fontSize: 15 }} primary={item.text} />
                </ListItem>
              ))}
            </List>
            :
            <List>
              {this.state.itemsMenu.map((item, index) => (
                <ListItem button key={index} onClick={this.toggleDrawer('showMenu', false, item.link)}>
                  <ListItemIcon><img src={item.icon} style={{ height: 30, width: 30, color: 'black' }} alt="" /></ListItemIcon>
                  <ListItemText disableTypography style={{ color: '#4a4a4a', fontFamily: 'AvenirMedium', fontSize: 16 }} primary={item.text} />
                </ListItem>
              ))}
            </List>
        }
      </div>
    );
    return (
      <Grid container justify="center" className={(this.props.journeyShow ? 'header2' : 'header')} style={{ zIndex: 3 }}>
        <Grid item md={3} sm={9} xs={9} style={{ textAlign: 'left' }}>
          <img src={logo} style={{ filter: 'invert(100%)' }} className="header-logo pointer" alt="logo" onClick={this.toggleDrawer('showMenu', false, '/')} />
        </Grid>
        <Grid item md={4} sm={false} xs={false} className="colLinkHeader"></Grid>
        {
          this.props.dataUser.userConnected ?
            <Grid className="colLinkHeader pointer" item md={2} sm={false} xs={false} style={{ textAlign: 'right', paddingRight: 15, paddingTop: 21, fontFamily: 'AvenirMedium', fontSize: 14, fontWeight: 'normal' }} onClick={this.toggleDrawer('showMenu', false, '/appointments')}>
              Appointments
              </Grid>
            : <Grid className="colLinkHeader pointer" item md={2} sm={false} xs={false}></Grid>
        }
        <Grid className="colLinkHeader pointer" item md={1} sm={false} xs={false} style={{ textAlign: 'right', paddingRight: 15, paddingTop: 21, fontFamily: 'AvenirMedium', fontSize: 14, fontWeight: 'normal' }} onClick={() => { window.open("https://www.wlnss.co") }}>
          About Us
            </Grid>
        {
          this.props.dataUser.userConnected ?
            <Grid className="colLinkHeader pointer" item md={1} sm={false} xs={false} style={{ textAlign: 'right', paddingRight: 15, paddingTop: 21, fontFamily: 'AvenirMedium', fontSize: 14, fontWeight: 'normal', zIndex: 100 }} onClick={this.toggleDrawer('showMenu', false, '/profile')}>
              Profile
              </Grid>
            :
            <Grid className="colLinkHeader pointer" item md={1} sm={false} xs={false} style={{ textAlign: 'right', paddingRight: 15, paddingTop: 21, fontFamily: 'AvenirMedium', fontSize: 14, fontWeight: 'normal', zIndex: 100 }} onClick={this.toggleDrawer('showMenu', false, '/profile')}>
              Sign In
              </Grid>
        }
        <Grid item md={2} sm={false} xs={false} className="colLinkHeader"></Grid>
        <Grid item xs={1} sm={1} className="iconMenuHeader" style={{ textAlign: 'right', paddingRight: 0, paddingTop: 19, color: colors.primary }} onClick={() => this.props.history.push('/profile')}>
          <Icon>account_circle</Icon>
        </Grid>
        <Grid item xs={2} sm={1} className="iconMenuHeader" style={{ textAlign: 'right', paddingRight: 15, paddingTop: 19, color: colors.primary }} onClick={() => this.handleDrawerOpen(true)}>
          <Icon>menu</Icon>
        </Grid>

        <SwipeableDrawer
          anchor="right"
          open={this.state.showMenu}
          classes={{ paper: styles.paper }}
          onClose={this.toggleDrawer('showMenu', false)}
          onOpen={this.toggleDrawer('showMenu', true)}
        >
          <div className='headerMenu' style={{ borderBottonWidth: 1, borderColor: '#eaeaea', textAlign: 'right', marginTop: headerPosition }} onClick={this.toggleDrawer('showMenu', false)}>
            <Icon style={{ marginTop: 18, color: colors.primary, marginRight: 15 }}>close</Icon>
          </div>

          <Grid container alignItems="center" justify="center">
            {
              this.props.dataUser.userConnected ?
                <Grid container alignItems="center" justify="center" style={styles.boxName}>{this.props.dataUser.trueVaultInfoUser.firstname[0].toUpperCase()}{this.props.dataUser.trueVaultInfoUser.lastname[0].toUpperCase()}</Grid>
                : null
            }
          </Grid>
          {fullList}
        </SwipeableDrawer>
      </Grid>
    );
  }
}
const styles = {
  boxName: {
    backgroundColor: '#dd2c00',
    color: 'white',
    height: 60,
    width: 60,
    marginTop: 20,
    fontSize: 15,
    textAlign: 'center',
    fontFamily: 'AvenirBold',
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    background: 'blue'
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, {})(withRouter(Header));
