import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import '../../../css/Home.css';
import HeaderBooking from './HeaderBooking'
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'
import TextField from '@material-ui/core/TextField';
import { Fonts } from '../../../utils/Font'
import colors from '../../layout/colors'
import ScrollViewPage from '../../layout/scrollviews/ScrollViewPage'

class Product extends Component {
  async componentWillMount() {
    window.scrollTo(0, 0)
  }
  back() {
    if (this.props.dataHome.productChosen.type === 'shots') {
      this.props.bookingFlowAction('setCurrentPage', { value: 'Booster' })
    } else {
      if (this.props.category.enableAddProduct) {
        if (this.props.dataHome.subscribe) {
          this.props.bookingFlowAction('setCurrentPage', { value: 'AddProduct' })
        } else {
          this.props.bookingFlowAction('setCurrentPage', { value: 'AddAdditional' })
        }
      } else {
        this.props.bookingFlowAction('setCurrentPage', { value: 'Person' })
      }
    }
  }
  handleChange = name => event => {
    var val = event.target.value
    if (name === 'invite') {
      this.props.homeAction('setInviteCode', { value: val })
    } else if (name === 'businessName') {
      this.props.homeAction('setBusinessName', { value: val })
    } else if (name === 'businessPhone') {
      this.props.homeAction('setBusinessPhone', { value: val })
    } else if (name === 'notes') {
      this.props.homeAction('setNotes', { value: val })
    }
  }
  bodyContent() {
    return (
      <div style={{ marginTop: -40 }}>
        {
          (this.props.dataHome.numberUnit > 1 || (this.props.dataHome.numberUnit === 1 && this.props.dataHome.receiver === 'else')) || this.props.dataHome.productChosen.type === 'shots' ?
            <Grid container style={styles.subtitle}>
              Create an invite code. Share this with anybody who wants to join the booking.
          </Grid>
            : null
        }
        {
          (this.props.dataHome.numberUnit > 1 || (this.props.dataHome.numberUnit === 1 && this.props.dataHome.receiver === 'else')) || this.props.dataHome.productChosen.type === 'shots' ?
            <Grid container style={styles.subtitle}>
              <TextField
                style={styles.input}
                placeholder='Invite code'
                autoFocus={true}
                onChange={this.handleChange('invite')}
                value={this.props.dataHome.inviteCodeEvent}
              />
            </Grid>
            : null
        }

        {
          this.props.dataHome.receiver === 'else' ?
            <div>
              <Grid container style={styles.subtitle}>
                Contact number
            </Grid>
              <Grid container style={styles.subtitle}>
                <TextField
                  style={styles.input}
                  placeholder='Phone number'
                  onChange={this.handleChange('businessPhone')}
                  value={this.props.dataHome.businessPhone}
                />
              </Grid>
            </div>
            : null
        }

        <Grid container style={styles.subtitle}>
          Additional instructions
        </Grid>

        <Grid container style={styles.subtitle}>
          <TextField
            style={styles.input}
            multiline
            rows="6"
            placeholder='E.g. appartment number, parking, gate code etc. (optional)'
            onChange={this.handleChange('notes')}
            value={this.props.dataHome.notes}
          />
        </Grid>
      </div>
    )
  }
  render() {
    return (
      <div className="App">
        <HeaderBooking title={'More information'} back={this.back.bind(this)} loader={false} journeyShow={this.props.journeyShow} />
        <ScrollViewPage
          content={this.bodyContent.bind(this)}
          loader={false}
          bookingFlow={true}
          header={false}
          styleTitle={{ fontSize: 27, fontFamily: Fonts.MarkOTBold, }}
        />
      </div>
    );
  }
}
const styles = {
  incrementBtn: {
    color: colors.primary,
    fontSize: 23,
    fontFamily: Fonts.MarkOTBold,
  },
  buttonBack: {
    position: 'absolute',
    top: 75,
    left: 15,
    zIndex: 20,
  },
  title: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 23,
    paddingLeft: 15,
    color: '#4a4a4a',
    marginTop: 10,
  },
  input: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    width: '100%',
  },
  subText: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 16,
    color: '#4a4a4a',
    textAlign: 'center'
  },
  subTextOff: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 16,
    fontWeight: 'normal',
    color: '#CACACF',
  },
  subtitle: {
    fontFamily: Fonts.MarkOT,
    fontSize: 16,
    color: colors.subtitle,
    fontWeight: 'normal',
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 15,
    marginBottom: 5,
    textAlign: 'left',
  },
  subtitle2: {
    fontFamily: Fonts.MarkOT,
    fontSize: 16,
    fontWeight: 'normal',
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 25,
    marginBottom: 5,
    color: colors.subtitle,
    textAlign: 'left',
  },
  secondRes: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  mainRes: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 17,
    fontWeight: 'bold',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  rowResult: {
    flex: 1, borderBottomWidth: 1, borderColor: '#EAEAEA', paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15
  },
  textIngredient: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
    paddingLeft: 15,
  },
  textBenefit: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    marginTop: 10,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    paddingLeft: 15,
    height: 30,
  },
  titleBenefit: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 19,
    fontWeight: 'Bold',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
  },
  row: {
    textAlign: 'left',
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    color: '#4a4a4a',
    paddingTop: 5
  },
  boxPrice: {
    width: 70,
    height: 25,
    borderRadius: 15,
    bottom: 20,
    right: 15,
    backgroundColor: '#FAF9F9',
    alignItems: 'center',
    fontFamily: Fonts.MarkOT,
    justifyContent: 'center',
    borderColor: '#EAEAEA',
    color: '#8CB5A1',
    paddingTop: 9,
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(Product);
