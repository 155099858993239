
import mixpanel from 'mixpanel-browser'
import firebase, { UserRef } from '../firebase/Tokens';

import {
  SET_USER_CONNECTED,
  SET_INSTAVICE_VARIABLES,
  SET_INFO_USER,
  SET_CARDS_INFO,
  SET_USER_ID,
  SET_LIST_APPTS,
  SET_DEFAULT_CB,
  OPEN_REVIEWS,
  RESET_DATA_LOCATION,
  RESET_DATA_HOME,
  RESET_USER_DATA,
  SET_NO_FOOTER,
  RESET_LOGIN_DATA,
  SET_REFERAL,
  SET_EMAIL_VERIFIED,
  SET_FIREBASE_CLINICS,
  SET_TRUE_VAULT_USER,
  SET_FOCUS_PHONE_INPUT,
  SET_STEP_LOGIN,
  SHOW_LOGIN_MODAL,
  SET_COUNTRY
} from './types';
const mixPanelToken = 'bef3efb4cd721628459b35e7a4c1abb6'
mixpanel.init(mixPanelToken);

const setUserConnected = (value) => ({
  type: SET_USER_CONNECTED,
  userConnected: value
});

const setInstaviceVariables = (variables) => ({
  type: SET_INSTAVICE_VARIABLES,
  instaviceVariables: variables
});

const setInfoUser = (infoUser) => ({
  type: SET_INFO_USER,
  infoUser: infoUser
});

const setUserID = (userID) => ({
  type: SET_USER_ID,
  userID: userID
});

const setCardsInfo = (listCards, listCardsJSON, numberOfCB, defaultCB) => ({
  type: SET_CARDS_INFO,
  listCards: listCards,
  listCardsJSON: listCardsJSON,
  numberOfCB: numberOfCB,
  defaultCB: defaultCB
});

const setListAppts = (list) => ({
  type: SET_LIST_APPTS,
  listAppts: list
});

const openReviews = (value) => ({
  type: OPEN_REVIEWS,
  showReviews: value
});

const setNoFooter = (value) => ({
  type: SET_NO_FOOTER,
  noFooter: value
});

const resetUserData = (value) => ({
  type: RESET_USER_DATA
});


const resetLoginData = (value) => ({
  type: RESET_LOGIN_DATA
});


const resetDataHome = () => ({
  type: RESET_DATA_HOME
});

const resetDataLocation = () => ({
  type: RESET_DATA_LOCATION
});

const setDefaultCB = (value) => ({
  type: SET_DEFAULT_CB,
  defaultCB: value,
});

const setReferals = (value) => ({
  type: SET_REFERAL,
  idReferal: value,
});

const emailVerified = (value) => ({
  type: SET_EMAIL_VERIFIED,
  emailVerified: value
});

const setFirebaseClinics = (value) => ({
  type: SET_FIREBASE_CLINICS,
  firebaseClinics: value,
});

const setTrueVaultInfoUser = (value) => ({
  type: SET_TRUE_VAULT_USER,
  trueVaultInfoUser: value,
});

const setFocusPhoneInput = (value) => ({
  type: SET_FOCUS_PHONE_INPUT,
  focusPhoneInput: value,
});

const showLoginModal = (value) => ({
  type: SHOW_LOGIN_MODAL,
  showLoginModal: value,
});

const setStepLogin = (value) => ({
  type: SET_STEP_LOGIN,
  stepLogin: value,
});

const setCountry = (value) => ({
  type: SET_COUNTRY,
  country: value,
});

export const initApp = (val, data) => {
  return async function (dispatch) {
    if (val === 'setUserConnected') {
      dispatch(setUserConnected(data.value));
    }
    else if (val === 'setNoFooter') {
      dispatch(setNoFooter(data.value));
    }
    else if (val === 'setInstaviceVariables') {
      const refInstavice = firebase.database().ref('instavice/variables')
      refInstavice.on('value', function (snapshot) {
        const variables = snapshot.val()
        dispatch(setInstaviceVariables(variables));
        return true
      })
    }
    else if (val === 'setInstaviceVariables2') {
      dispatch(setInstaviceVariables(data.variables));
    }
    else if (val === 'setFocusPhoneInput') {
      dispatch(setFocusPhoneInput(data.value));
    }
    else if (val === 'setFirebaseClinics') {
      const promiseAvailabilities = firebase.database().ref('clinics').once('value')
      return Promise.all([promiseAvailabilities]).then(results => {
        var matLocations = {}
        var clinics = results[0].val()
        for (var i in clinics) {
          for (var j in clinics[i].locations) {
            var location = clinics[i].locations[j]
            location.practicians = clinics[i].practicians
            matLocations = {
              ...matLocations,
              [location.id]: location
            }
            //matLocations.push(location)
          }
        }
        dispatch(setFirebaseClinics(matLocations));
      })
    }
    else if (val === 'openReviews') {
      dispatch(openReviews(data.value))
    }
    else if (val === 'setTruvaultUserInfo') {
      dispatch(setTrueVaultInfoUser(data.value))
    }
    else if (val === 'setCountry') {
      dispatch(setCountry(data.value))
    }
    else if (val === 'firstSignIn') {
      var phoneNumber = data.phoneNumber;
      var signInToken = data.firebaseSignInToken
      var infoUserTrueVault = data.infoUser

      firebase.auth().signInWithCustomToken(signInToken).then(function (user) {
        var userID = user.user.uid
        localStorage.setItem('phoneNumber', phoneNumber);

        mixpanel.identify(userID)
        mixpanel.people.set({ "userID": userID })
        dispatch(setTrueVaultInfoUser(infoUserTrueVault))
        const info_userRef = UserRef.child(userID);
        info_userRef.on('value', function (snap) {
          var infoUser = snap.val()
          dispatch(setInfoUser(infoUser))
          dispatch(setUserID(userID));
          var itemsCard = []
          if (!!infoUser.wallet.cards) {
            var listCardsJSON = infoUser.wallet.cards

            var defaultCB = infoUser.wallet.defaultCard
            if (!defaultCB) {
              defaultCB = Object.values(infoUser.wallet.cards)[0]
            }
            var numberOfCB = Object.keys(infoUser.wallet.cards).length

            dispatch(setCardsInfo([], listCardsJSON, numberOfCB, defaultCB));

          } else {
            dispatch(setCardsInfo([], itemsCard, 0, {}));
          }

          var listAppts = []
          if (!!infoUser.appointments) {
            for (var appt in infoUser.appointments) {
              listAppts.push({ appt: infoUser.appointments[appt], bids: [], infoCardAppts: appt })
            }
            listAppts = Object.keys(infoUser.appointments).map(function (key) {
              return infoUser.appointments[key]
            })

          }
          listAppts = []
          if (!!infoUser.appointments) {
            listAppts = Object.keys(infoUser.appointments).map(function (key) {
              var appt = infoUser.appointments[key]
              appt.userOfferID = key
              return appt
            })
          }
          listAppts.reverse()
          dispatch(setListAppts(listAppts))

          if (infoUser.phoneNumberVerified) {
            if (infoUser.profileCompleted) {
              dispatch(setUserConnected(true));
              dispatch(showLoginModal(false));
            } else {
              dispatch(setUserConnected(false));
              dispatch(setStepLogin('profile'));
            }
          } else {
            dispatch(showLoginModal(true));
            dispatch(setStepLogin('verify'));
            dispatch(setUserConnected(false));
          }

          return true
        })
      })
    }
    else if (val === 'setStepLogin') {
      dispatch(setStepLogin(data.value))
    }
    else if (val === 'showLoginModal') {
      dispatch(showLoginModal(data.value))
    }
    else if (val === 'logout') {
      localStorage.removeItem('phoneNumber');
      const info_userRef = UserRef.child(data.userID);
      info_userRef.off('value')
      dispatch(resetUserData())
      dispatch(resetDataHome())
      dispatch(resetLoginData())
      dispatch(resetDataLocation())
      dispatch(setUserConnected(false));
    }
    else if (val === 'setChosenCB') {
      dispatch(setDefaultCB(data.value))
    }
    else if (val === 'setReferals') {
      dispatch(setReferals(data.value))
    }
    else if (val === 'setEmailVerified') {
      dispatch(emailVerified(data.value))
    }
  }
}

