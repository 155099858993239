

import {
  OPEN_CHECKOUT,
  OPEN_CHECKOUT2,
  SET_TYPE_CHECKOUT,
  SET_CHARGE25
} from '../actions/types';
const initialState = {
  showCheckout: false,
  showCheckout2: false,
  typeCheckout: '',
  charge25: false,
  offer: {},
  bid: {},
  bids: {}
}

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CHECKOUT:
      return { ...state, showCheckout: action.showCheckout };
    case OPEN_CHECKOUT2:
      return { ...state, showCheckout2: action.showCheckout2 };
    case SET_TYPE_CHECKOUT:
      return { ...state, typeCheckout: action.typeCheckout, offer: action.offer, bid: action.bid, bids: action.bids };
    case SET_CHARGE25:
      return { ...state, charge25: action.charge25 };
    default:
      return state;
  }
}

export default calendarReducer;
