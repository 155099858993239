import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../css/Home.css';
import colors from '../../../layout/colors'

export default class RowProductAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSettings: false,
      step: 1,
      gender: '',
      areaTop: false,
      areaBottom: false,
      areaSideEyes: false,
      areaBottomEyes: false,
      areaMiddleEyes: false,
      areaExtChicks: false,
      areaIntChicks: false,
      areaBottomNose: false,
      areaBottomMouse: false,
      areaIntMouse: false,
    };
    this.selectTopArea = this.selectTopArea.bind(this);
    this.selectBottomArea = this.selectBottomArea.bind(this);
    this.selectBottomEyes = this.selectBottomEyes.bind(this);
    this.selectSideEyes = this.selectSideEyes.bind(this);
    this.selectMiddleEyes = this.selectMiddleEyes.bind(this);
    this.selectExtChicks = this.selectExtChicks.bind(this);
    this.selectIntChicks = this.selectIntChicks.bind(this);
    this.selectBottomNose = this.selectBottomNose.bind(this);
    this.selectIntMouse = this.selectIntMouse.bind(this);
    this.selectBottomMouse = this.selectBottomMouse.bind(this);
  }
  selectTopArea() {
    var areaTop = this.state.areaTop;
    this.setState({ areaTop: !areaTop });
    this.props.changeArea('areaTop', !areaTop);
  }
  selectBottomArea() {
    var areaBottom = this.state.areaBottom;
    this.setState({ areaBottom: !areaBottom });
    this.props.changeArea('areaBottom', !areaBottom);
  }
  selectSideEyes() {
    var areaSideEyes = this.state.areaSideEyes;
    this.setState({ areaSideEyes: !areaSideEyes });
    this.props.changeArea('areaSideEyes', !areaSideEyes);
  }
  selectBottomEyes() {
    var areaBottomEyes = this.state.areaBottomEyes;
    this.setState({ areaBottomEyes: !areaBottomEyes });
    this.props.changeArea('areaBottomEyes', !areaBottomEyes);
  }
  selectMiddleEyes() {
    var areaMiddleEyes = this.state.areaMiddleEyes;
    this.setState({ areaMiddleEyes: !areaMiddleEyes });
    this.props.changeArea('areaMiddleEyes', !areaMiddleEyes);
  }
  selectExtChicks() {
    var areaExtChicks = this.state.areaExtChicks;
    this.setState({ areaExtChicks: !areaExtChicks });
    this.props.changeArea('areaExtChicks', !areaExtChicks);
  }
  selectIntChicks() {
    var areaIntChicks = this.state.areaIntChicks;
    this.setState({ areaIntChicks: !areaIntChicks });
    this.props.changeArea('areaIntChicks', !areaIntChicks);
  }
  selectBottomNose() {
    var areaBottomNose = this.state.areaBottomNose;
    this.setState({ areaBottomNose: !areaBottomNose });
    this.props.changeArea('areaBottomNose', !areaBottomNose);
  }
  selectBottomMouse() {
    var areaBottomMouse = this.state.areaBottomMouse;
    this.setState({ areaBottomMouse: !areaBottomMouse });
    this.props.changeArea('areaBottomMouse', !areaBottomMouse);
  }
  selectIntMouse() {
    var areaIntMouse = this.state.areaIntMouse;
    this.setState({ areaIntMouse: !areaIntMouse });
    this.props.changeArea('areaIntMouse', !areaIntMouse);
  }
  styleAreasTop() {
    if (this.state.areaTop) return styles.colAreasTop
    return styles.colAreasTopOff
  }
  styleAreasSideEyes() {
    if (this.state.areaSideEyes) return styles.colAreasSideEyes
    return styles.colAreasSideEyesOff
  }
  styleAreaMiddleEyes() {
    if (this.state.areaMiddleEyes) return styles.colAreas
    return styles.colAreasOff
  }
  styleAreaBottomEyes() {
    if (this.state.areaBottomEyes) return styles.colAreas
    return styles.colAreasOff
  }
  styleAreaIntChicks() {
    if (this.state.areaIntChicks) return styles.colAreas
    return styles.colAreasOff
  }
  styleAreaBottomNose() {
    if (this.state.areaBottomNose) return styles.colAreasBottomNose
    return styles.colAreasBottomNoseOff
  }
  styleAreasSideMouse() {
    if (this.state.areaIntMouse) return styles.colAreasBottom
    return styles.colAreasBottomOff
  }
  styleAreaBottomMouse() {
    if (this.state.areaBottomMouse) return styles.colAreasBottomNose
    return styles.colAreasBottomNoseOff
  }
  styleAreaBottom() {
    if (this.state.areaBottom) return styles.colAreas
    return styles.colAreasOff
  }
  render() {
    return (
      <Grid direction="column" container style={{ marginTop: -30, width: 300, height: 400 }}>
        <img style={styles.img} src={require('../../../../img/areas/man.png')} alt="" />
        <Grid container direction="column" style={{ marginTop: 115, width: '100%', zIndex: 2 }}>
          <Grid container direction="row" style={{ height: 30 }}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <div style={this.styleAreasTop()} onClick={() => this.selectTopArea()} />
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <Grid container direction="row" style={{ height: 20, marginTop: 20 }}>
            <Grid item xs={3}></Grid>
            <Grid item xs={1}>
              <div style={this.styleAreasSideEyes()} onClick={() => this.selectSideEyes()} />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <div style={{ ...this.styleAreaMiddleEyes(), marginTop: 4 }} onClick={() => this.selectMiddleEyes()} />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
              <div style={this.styleAreasSideEyes()} onClick={() => this.selectSideEyes()} />
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <Grid container direction="row" style={{ height: 20, marginTop: 90 }}>
            <Grid item xs={3}></Grid>
            <Grid item xs={2}>
              <div style={this.styleAreasSideMouse()} onClick={() => this.selectIntMouse()} />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}>
              <div style={this.styleAreasSideMouse()} onClick={() => this.selectIntMouse()} />
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <Grid container direction="row" style={{ height: 15, marginTop: 1 }}>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <div style={this.styleAreaBottom()} onClick={() => this.selectBottomArea()} />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = {
  container: {
    marginTop: -10,
    flex: 1,
    alignSelf: 'stretch',
    width: '100%',
    alignItems: 'center',
  },
  img: {
    width: 300,
    height: 400,
    zIndex: 0,
    marginLeft: -10,
    position: 'absolute',
  },
  imgWoman: {
    width: 280,
    height: 400,
    position: 'absolute',
    marginLeft: 70,
  },
  gridAreas: {
    width: 300,
    height: 300,
    zIndex: 100,
    position: 'absolute',
    marginTop: 95,
  },
  rowAreas: {
    backgroundColor: 'transparent',
    position: 'relative',
    height: 23,
    marginTop: 0,
  },
  rowAreasTransparent: {
    position: 'relative',
    height: 10,
    marginTop: 6,
  },
  colAreasTopOff: {
    backgroundColor: 'white',
    border: '1px solid #eaeaea',
    borderRadius: 30,
    width: '100%',
    height: 50,
  },
  colAreasTop: {
    backgroundColor: colors.primary,
    borderRadius: 30,
    width: '100%',
    height: 50,
  },
  colAreasOff: {
    backgroundColor: 'white',
    border: '1px solid #eaeaea',
    borderRadius: 30,
    height: 15,
    marginTop: 0,
  },
  colAreas: {
    backgroundColor: colors.primary,
    borderRadius: 30,
    height: 15,
    marginTop: 0,
  },
  colAreasBottomNoseOff: {
    backgroundColor: 'white',
    border: '1px solid #eaeaea',
    borderRadius: 30,
    height: 12,
    marginBottom: 5,
  },
  colAreasBottomNose: {
    backgroundColor: colors.primary,
    borderRadius: 30,
    height: 12,
    marginBottom: 5,
  },
  colAreasSideEyesOff: {
    backgroundColor: 'white',
    border: '1px solid #eaeaea',
    borderRadius: 30,
    height: 15,
    marginTop: 25,
  },
  colAreasSideEyes: {
    backgroundColor: colors.primary,
    borderRadius: 30,
    height: 15,
    marginTop: 25,
  },
  colAreasSideChicksOff: {
    backgroundColor: 'white',
    border: '1px solid #eaeaea',
    borderRadius: 30,
    height: 30,
    marginTop: 6,
  },
  colAreasSideChicks: {
    backgroundColor: colors.primary,
    borderRadius: 30,
    height: 30,
    marginTop: 6,
  },
  colAreasBottom: {
    backgroundColor: colors.primary,
    borderRadius: 30,
    height: 15,
    marginBottom: 25,
  },
  colAreasBottomOff: {
    backgroundColor: 'white',
    border: '1px solid #eaeaea',
    borderRadius: 30,
    height: 15,
    marginBottom: 25,
  },
  title: {
    color: '#4A4A4A',
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 10,
  },
}
