import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import colors from '../../../layout/colors';
import { Fonts } from '../../../../utils/Font';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons'

class CardSelection extends Component {
  render() {
    return (
      <div>
        <Grid className={(this.props.expanded) ? 'borderOn' : 'borderOff'} container direction="column" style={{ marginTop: 10, borderRadius: 7, borderColor: (!this.props.expanded) ? '#cecece' : null, opacity: (!this.props.expanded) ? 0.9 : 1.0 }} alignItems="center" justify="center">
          <Grid direction="row" container style={{ width: '100%' }} className='pointer' justify="center" alignItems="center" onClick={() => { if (!!this.props.expansion) this.props.onExpand() }}>
            <Grid item xs={11} sm={11} >
              <Grid container direction="column">
                <Grid item style={styles.title}>
                  {this.props.text}
                </Grid>
                <Grid item style={styles.subtitle}>
                  {this.props.subtext}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} justify="center" alignItems="center">
              <FontAwesomeIcon icon={(this.props.expanded) ? faAngleDown : faAngleRight} color={colors.subtitle} size="sm" />
            </Grid>
          </Grid>
          {
            (this.props.expanded) ?
              <Grid item style={{ paddingBottom: 15, width: '100%' }}>
                {this.props.expansion()}
              </Grid>
              : null
          }
        </Grid>
      </div>
    );
  }
}
export default CardSelection;

const styles = {
  title: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 14,
    textAlign: 'left',
    color: colors.title,
    paddingLeft: 20,
    paddingTop: 15
  },
  subtitle: {
    fontFamily: Fonts.MarkOT,
    fontSize: 12,
    width: '100%',
    maxWidth: 600 * 0.5,
    textAlign: 'left',
    color: colors.title,
    paddingLeft: 20,
    paddingBottom: 15,
    marginTop: 2
  }
};
