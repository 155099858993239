import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import colors from '../../../layout/colors';
import { Fonts } from '../../../../utils/Font'
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar'
import ListProviderCard from './ListProviderCard'
import { Divider } from '@material-ui/core';

class ProviderList extends Component {
  render() {
    return (
      <Grid container direction="column" style={{ maxWidth: '100vw', overflowY: 'hidden', minHeight: (this.props.journeyShow) ? 210 : 280, maxHeight: (this.props.journeyShow) ? 610 : (this.props.small) ? '100%' : 680 }}>
        <Toolbar style={{ position: 'absolute', zIndex: 2, marginTop: (this.props.journeyShow) ? 60 : 0, top: 'inherit', left: 'inherit', width: (!this.props.provPage) ? '85%' : 450, height: 80, backgroundColor: 'white', }}>
          <Grid container direction="row">
            <Grid item xs={1}>
              <IconButton color="inherit" onClick={() => this.props.close()} aria-label="Close">
                <Icon style={{ color: 'black' }}>clear</Icon>
              </IconButton>
            </Grid>
            <Grid container xs={11} direction="column">
              <div style={styles.title}>Pick your provider</div>
              <div style={styles.subtitle}>Please select from available appointment slots.</div>
            </Grid>
          </Grid>
          <Divider />
        </Toolbar>
        <Grid container direction="column" style={{ marginTop: (this.props.journeyShow) ? 130 : 70, maxWidth: '100vw', overflowY: 'scroll' }}>
          {(this.props.list.length === 0)
            ?
            <Grid container direction="column" alignContent="center" justify="center" style={{ marginTop: 60, textAlign: 'center' }}>
              <Grid item style={styles.noProvText}>
                No providers are available in this area. Please change your location or check back later.
              </Grid>
            </Grid>
            :
            <div style={{ ...styles.center, marginTop: -10 }}>
              {Object.values(this.props.list).map((provider, i) => (
                <Grid item style={{ height: 160 }} className="pointer" onClick={() => { this.props.selectProvider(provider) }}>
                  <ListProviderCard
                    provider={provider}
                    date={this.props.date}
                    setSlotAppt={this.props.setSlotAppt.bind(this)} />
                  <Divider style={{ marginLeft: '19%', width: '64%' }} />
                </Grid>
              ))}
            </div>
          }
        </Grid>
      </Grid>
    )
  }
}
export default ProviderList;

const styles = {
  header: {
    margin: 3,
    position: 'absolute',
    left: 'inherit',
    top: 0
  },
  title: {
    color: '#4a4a4a', fontFamily: Fonts.MarkOTBold, fontSize: 20, marginLeft: 20, marginTop: 10
  },
  providerTitle: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 16,
    textAlign: 'left',
    color: colors.title,
    paddingTop: 15
  },
  subtitle: {
    fontFamily: Fonts.MarkOT,
    fontSize: 13,
    textAlign: 'left',
    color: colors.subtitle,
    marginTop: 5,
    marginBottom: 10,
    marginLeft: 21
  },
  noProvText: {
    fontFamily: Fonts.MarkOT,
    fontSize: 14,
    width: '94%',
    marginLeft: '3%',
    textAlign: 'left',
    color: colors.subtitle,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
};
