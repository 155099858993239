import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import { initApp } from '../../../actions/initAppActions'
import CardListCB from './CardListCB';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Fonts } from '../../../utils/Font';

class SignIn extends Component {
  selectDefaultCard(card) {
    this.props.selectDefaultCard(card)
  }
  deleteCard(card) {
    this.props.deleteCard(card)
  }
  clickOnCard(card) {
    this.props.clickOnCard(card)
  }
  render() {
    return (
      <div className="App" >
        <Grid container style={{ fontFamily: Fonts.MarkOTBold, marginTop: 5, color: '#4a4a4a', paddingLeft: 15, marginBottom: 20 }}>
          Payment methods
      </Grid>
        {
          this.props.dataUser.numberOfCB !== 0 && !!this.props.dataUser.infoUser.wallet.cards ?
            <Divider style={{ marginLeft: '3%', width: '96%' }} />
            : null
        }
        {
          this.props.dataUser.numberOfCB !== 0 && !!this.props.dataUser.infoUser.wallet.cards ?
            <List component="nav">
              {Object.values(this.props.dataUser.infoUser.wallet.cards).map((card, i) => (
                <CardListCB clickOnCard={this.clickOnCard.bind(this)} deleteCard={this.deleteCard.bind(this)} card={card} selectDefaultCard={this.selectDefaultCard.bind(this)} />
              ))}
            </List>
            : null
        }
        <Divider style={{ marginLeft: '3%', width: '96%' }} />
        <ListItem button onClick={() => { this.props.changeStep('new') }} >
          <div style={styles.text}>Add payment method</div>
          <ListItemSecondaryAction style={{ paddingRight: 15 }}>
            <Icon style={{ color: '#9E9E9E' }}>keyboard_arrow_right</Icon>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    );
  }
}
const styles = {
  text: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 15,
    color: '#3173ED',
  },
  divider: {
    width: window.innerWidth - 30,
    height: 1,
    marginLeft: 15,
    backgroundColor: '#eaeaea',
    marginTop: 10,
    marginBottom: 10,
  },
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { initApp })(SignIn);
