import React, { Component } from 'react';
import { connect } from 'react-redux';
import './css/App.css';
import './css/Home.css';
import MainApp from './components/MainApp'
import FirstScreen from './components/FirstScreen'
import { initApp } from './actions/initAppActions'
import * as firebase from 'firebase'
import axios from 'axios'
import branch from 'branch-sdk'
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journeyShow: false
    };
  }
  async componentWillMount() {

    var that = this
    branch.addListener("willShowJourney", function () {
      that.listenerJourneyShow()
    });
    branch.addListener("willCloseJourney", function () {
      that.listenerJourneyHide()
    });
    branch.track("pageview");

    const phoneNumber = localStorage.getItem('phoneNumber');

    var instaviceVariables = await (firebase.database().ref('instavice/variables').once('value'))
    var variables = instaviceVariables.val()
    this.props.initApp('setInstaviceVariables2', { variables: variables })
    this.props.initApp('setInstaviceVariables', true)

    var promiseAxios = true
    if (phoneNumber != null) {
      var url = 'https://us-central1-instabo-production.cloudfunctions.net/signInUser'
      promiseAxios = await (axios.get(url, {
        params: {
          phoneNumber: phoneNumber
        }
      }))
      var infoUser = promiseAxios.data.infoUser
      var firebaseSignInToken = promiseAxios.data.signInToken
      this.props.initApp('firstSignIn', { phoneNumber: phoneNumber, firebaseSignInToken: firebaseSignInToken, infoUser: infoUser })
    } else {
      this.props.initApp('setUserConnected', { value: false });
    }
  }
  listenerJourneyShow() {
    this.setState({ journeyShow: true })
  }
  listenerJourneyHide() {
    this.setState({ journeyShow: false })
  }
  render() {
    return (
      <div className={(this.state.journeyShow ? 'App2' : 'App2')}>
        {
          this.props.dataUser.userConnected === 'load' ?
            <FirstScreen />
            :
            <MainApp journeyShow={this.state.journeyShow} />
        }
      </div>
    );
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})

export default connect(mapStateToProps, { initApp })(App);
