import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import '../../../css/Home.css';
import HeaderBooking from './HeaderBooking'
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'
import DatePicker from '../modulesBooking/DatePicker'
import { Fonts } from '../../../utils/Font';
import ScrollViewPage from '../../layout/scrollviews/ScrollViewPage'
import colors from '../../layout/colors'
class Product extends Component {
  async componentWillMount() {
    window.scrollTo(0, 0)
    this.props.onRef(this)
  }
  back() {
    this.props.bookingFlowAction('setCurrentPage', { value: 'Location' })
  }
  doneTime() {
    this.timePicker.doneTime()
  }
  bodyContent() {
    return (
      <div style={{ marginTop: -40 }}>
        <Grid container style={styles.subtitle} onClick={() => { console.log(this.props) }}>
          Please select the date and time of your appointment.
        </Grid>
        <DatePicker onRef={ref => (this.timePicker = ref)} />
      </div>
    )
  }
  render() {
    return (
      <div className="App">
        <HeaderBooking title={'When?'} back={this.back.bind(this)} loader={false} journeyShow={this.props.journeyShow} />
        <ScrollViewPage
          content={this.bodyContent.bind(this)}
          loader={false}
          bookingFlow={true}
          header={false}
          styleTitle={{ fontSize: 27, fontFamily: Fonts.MarkOTBold }}
        />
      </div>
    );
  }
}
const styles = {
  incrementBtn: {
    color: colors.primary,
    fontSize: 23,
    fontFamily: Fonts.MarkOTBold,
  },
  buttonBack: {
    position: 'absolute',
    top: 75,
    left: 15,
    zIndex: 20,
  },
  title: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 23,
    paddingLeft: 15,
    color: '#4a4a4a',
    marginTop: 10,
  },
  subText: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 16,
    color: '#4a4a4a',
    textAlign: 'center'
  },
  subTextOff: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 16,
    fontWeight: 'normal',
    color: '#CACACF',
  },
  subtitle: {
    fontFamily: Fonts.MarkOT,
    fontSize: 16,
    color: colors.subtitle,
    fontWeight: 'normal',
    marginTop: 15,
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 5,
    textAlign: 'left',
  },
  subtitle2: {
    fontFamily: Fonts.MarkOT,
    fontSize: 16,
    fontWeight: 'normal',
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 25,
    marginBottom: 5,
    color: colors.subtitle,
    textAlign: 'left',
  },
  secondRes: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  mainRes: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 17,
    fontWeight: 'bold',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  rowResult: {
    flex: 1, borderBottomWidth: 1, borderColor: '#EAEAEA', paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15
  },
  textIngredient: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
    paddingLeft: 15,
  },
  textBenefit: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    marginTop: 10,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    paddingLeft: 15,
    height: 30,
  },
  titleBenefit: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 19,
    fontWeight: 'Bold',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
  },
  row: {
    textAlign: 'left',
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    color: '#4a4a4a',
    paddingTop: 5
  },
  boxPrice: {
    width: 70,
    height: 25,
    borderRadius: 15,
    bottom: 20,
    right: 15,
    backgroundColor: '#FAF9F9',
    alignItems: 'center',
    fontFamily: Fonts.MarkOT,
    justifyContent: 'center',
    borderColor: '#EAEAEA',
    color: '#8CB5A1',
    paddingTop: 9,
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(Product);
