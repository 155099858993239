import React, { Component } from 'react';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { initApp } from '../../../actions/initAppActions'
import VerifyPhone from './VerifyPhone';
import Phone from './Phone';
import CompleteProfile from './CompleteProfile';
import { Fonts } from '../../../utils/Font'
import ScrollViewPage from '../../layout/scrollviews/ScrollViewPage'

class SignIn extends Component {
  openFlags(val) {
    this.setState({ openFlags: val })
  }
  selectCountry(country) {
    this.props.initApp('setCountry', { value: country })
    this.props.openFlags(false)
  }
  bodyContent() {
    return (
      <div style={{ marginTop: -80 }}>
        <List style={{ marginTop: this.props.journeyShow ? 145 : 80, paddingLeft: 20, paddingRight: 20 }}>
          {
            this.props.dataUser.stepLogin === 'phone' ?
              <Phone journeyShow={this.props.journeyShow} />
              : this.props.dataUser.stepLogin === 'verify' ?
                <VerifyPhone />
                :
                <CompleteProfile />
          }
        </List>
      </div>
    )
  }
  render() {
    return (
      <div className="App" >
        <AppBar className={(this.props.journeyShow ? 'marginMenu' : '')}>
          <Toolbar style={{ backgroundColor: 'white', height: 60 }}>
            <IconButton color="inherit" onClick={() => { this.props.close(); this.props.initApp('showLoginModal', { value: false }) }} aria-label="Close">
              <Icon style={{ color: 'black' }}>close</Icon>
            </IconButton>
            {
              this.props.dataUser.stepLogin === 'phone' ?
                <Typography variant="h9" color="secondary" style={{ color: 'black', fontFamily: Fonts.MarkOTMedium }}>
                  Sign in
              </Typography>
                : this.props.dataUser.stepLogin === 'verify' ?
                  <Typography variant="h9" color="secondary" style={{ color: 'black', fontFamily: Fonts.MarkOTMedium }}>
                    Verify your phone number
              </Typography>
                  :
                  <Typography variant="h9" color="secondary" style={{ color: 'black', fontFamily: Fonts.MarkOTMedium }}>
                    Complete your profile
              </Typography>
            }
          </Toolbar>
        </AppBar>

        <ScrollViewPage
          content={this.bodyContent.bind(this)}
          loader={false}
          bookingFlow={true}
          header={false}
          styleTitle={{ fontSize: 27, fontFamily: Fonts.MarkOTBold }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { initApp })(SignIn);
