import React, { Component } from 'react';
import { connect } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Icon from '@material-ui/core/Icon';
import { Fonts } from '../../../utils/Font'
import { initApp } from '../../../actions/initAppActions'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

class SignIn extends Component {
  selectDefaultCard(card) {
    this.props.selectDefaultCard(card)
  }
  deleteCard(card) {
    this.props.deleteCard(card)
  }
  clickOnCard(card) {
    this.props.clickOnCard(card)
  }
  render() {
    return (
      <div className="App" >
        <List component="nav">
          <ListItem button onClick={() => { this.props.changeStep('cb') }} >
            <ListItemIcon>
              <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/creditCard.png')} alt="" />
            </ListItemIcon>
            <div style={styles.text}>Credit/Debit card</div>
            <ListItemSecondaryAction style={{ paddingRight: 15 }}>
              <Icon style={{ color: '#9E9E9E' }}>keyboard_arrow_right</Icon>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button onClick={() => { this.props.changeStep('applePay') }} >
            <ListItemIcon>
              <img style={{ width: 29, height: 20, borderRadius: 3 }} src={require('../../../img/addCB/applePay.png')} alt="" />
            </ListItemIcon>
            <div style={styles.text}>Apple Pay</div>
            <ListItemSecondaryAction style={{ paddingRight: 15 }}>
              <Icon style={{ color: '#9E9E9E' }}>keyboard_arrow_right</Icon>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </div>
    );
  }
}
const styles = {
  text: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 15,
    color: '#4a4a4a',
  },
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { initApp })(SignIn);
