

import {
  OPEN_CLOSE_SETTINGS,
  OPEN_CLOSE_TERMS,
  OPEN_CLOSE_PRIVACY,
  OPEN_CLOSE_INVITE_FRIENDS,
  OPEN_CLOSE_PAYMENTS,
  OPEN_CLOSE_SYSTEM,
  OPEN_CLOSE_ADD_CREDIT_CARD,
  OPEN_CLOSE_MODIFY_CREDIT_CARD,
  OPEN_RESET_PWD,
  OPEN_WALLET
} from '../actions/types';

const initialState = {
  showSettings: false,
  showTerms: false,
  showPrivacy: false,
  showInviteFriends: false,
  showPayments: false,
  showSystemCheck: false,
  showAddCreditCard: false,
  showModifyCB: false,
  infoCB: {},
  showResetPwd: false,
  showWallet: false
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CLOSE_SETTINGS:
      return { ...state, showSettings: action.showSettings };
    case OPEN_CLOSE_TERMS:
      return { ...state, showTerms: action.showTerms };
    case OPEN_CLOSE_PRIVACY:
      return { ...state, showPrivacy: action.showPrivacy };
    case OPEN_CLOSE_INVITE_FRIENDS:
      return { ...state, showInviteFriends: action.showInviteFriends };
    case OPEN_CLOSE_PAYMENTS:
      return { ...state, showPayments: action.showPayments };
    case OPEN_CLOSE_SYSTEM:
      return { ...state, showSystemCheck: action.showSystemCheck };
    case OPEN_CLOSE_ADD_CREDIT_CARD:
      return { ...state, showAddCreditCard: action.showAddCreditCard };
    case OPEN_CLOSE_MODIFY_CREDIT_CARD:
      return { ...state, showModifyCB: action.showModifyCB, infoCB: action.infoCB };
    case OPEN_RESET_PWD:
      return { ...state, showResetPwd: action.showResetPwd };
    case OPEN_WALLET:
      return { ...state, showWallet: action.showWallet };
    default:
      return state;
  }
}

export default settingsReducer;
