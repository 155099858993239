import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { initApp } from '../../../actions/initAppActions'

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: '•••• •••• •••• ' + this.props.cardSelected.last4,
      expDate: '',
      cvv: '•••',
      cardType: '',
      digitsRequired: 16,
      inputFocus: '',
      loader: false,
      error: false,
      errorMessage: '',
      zipCode: '',
      showChoiceCountry: false
    };
  }
  componentWillMount() {
    var month = this.props.cardSelected.exp_month.toString()
    if (month.length === 1) {
      month = '0' + month
    }
    var year = this.props.cardSelected.exp_year.toString()
    var date = month + '/' + year[2] + year[3]
    this.setState({ expDate: date })
    var zipCode = this.props.cardSelected.zipCode
    if (!!zipCode) {
      this.setState({ zipCode: zipCode })
    }
  }
  handleChangeZip = name => event => {
    var text = event.target.value
    this.setState({ zipCode: text })
  }
  submit() {
    this.setState({ loader: true })
    var tokenStripeCus = this.props.dataUser.infoUser.wallet.tokenCusStripe
    var url = 'https://us-central1-instabo-production.cloudfunctions.net/editCreditCardUser'
    const promiseAxios = axios.get(url, {
      params: {
        userID: this.props.dataUser.userID,
        cardID: this.props.cardSelected.id,
        zipCode: this.state.zipCode,
        name: this.props.dataUser.trueVaultInfoUser.firstname + ' ' + this.props.dataUser.trueVaultInfoUser.lastname,
        tokenStripeCus: tokenStripeCus,
      }
    })
    return Promise.all([promiseAxios]).then(results => {
      if (results[0].data.response) {
        if (this.props.straightToCard) {
          this.props.close()
        } else {
          this.props.reload(this.state.zipCode)
          this.props.back()
        }
      } else {
        this.wrongCB('An error has occured. Please try again.')
      }
    })
  }
  wrongCB(message) {
    this.setState({ loader: false })
    this.setState({ error: true })
    this.setState({ errorMessage: message })
  }
  render() {
    return (
      <div className="App" >

        <Grid container alignItems="center" justify="center" style={{ paddingLeft: 15, paddingRight: 15, }}>
          <Grid item xs={12}>
            <Input
              id="input-with-icon-adornment"
              style={{ width: '100%', fontFamily: 'Avenir' }}
              label="Card number"
              type="tel"
              value={this.state.cardNumber}
              disabled
              margin="normal"
              color="secondary"
              startAdornment={
                <InputAdornment position="start">
                  {
                    this.props.cardSelected.brand === '' ?
                      <img style={{ width: 29, height: 31, borderRadius: 1 }} src={require('../../../img/addCB/creditCard.png')} alt="" />
                      : this.props.cardSelected.brand === 'Visa' ?
                        <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/visa.png')} alt="" />
                        : this.props.cardSelected.brand === 'MasterCard' ?
                          <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/mastercard.png')} alt="" />
                          : this.props.cardSelected.brand === 'American Express' ?
                            <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/amEx.png')} alt="" />
                            : this.props.cardSelected.brand === 'Maestro' ?
                              <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/maestro.png')} alt="" />
                              : this.props.cardSelected.brand === 'JCB' ?
                                <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/jcb.png')} alt="" />
                                : this.props.cardSelected.brand === 'Discover' ?
                                  <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/discover.png')} alt="" />
                                  : this.props.cardSelected.brand === 'Diners Club' ?
                                    <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/dinnersClub.png')} alt="" />
                                    :
                                    <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/visa.png')} alt="" />
                  }
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center" style={{ paddingLeft: 15, paddingRight: 15 }}>
          <Grid item xs={5}>
            <TextField
              style={{ width: '100%', height: 50, fontFamily: 'Avenir' }}
              id="outlined-password-input"
              label="MM/YY"
              value={this.state.expDate}
              type="tel"
              disabled
              margin="normal"
              ref={(input) => { this.dateTextInput = input }}
              color="secondary"
            />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={5}>
            <TextField
              style={{ width: '100%', height: 50, fontFamily: 'Avenir' }}
              id="outlined-password-input"
              label="CVV"
              value={this.state.cvv}
              type="tel"
              disabled
              ref={(input) => { this.cvvTextInput = input }}
              margin="normal"
              color="secondary"
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center" style={{ paddingLeft: 15, paddingRight: 15, marginTop: 15 }}>
          <Grid item xs={12}>
            <Input
              style={{ width: '100%', fontFamily: 'Avenir' }}
              id="outlined-password-input"
              label="Country"
              value={(Object.values(this.props.dataUser.ListCountry).filter(country2 => this.props.cardSelected.country === country2.code))[0].name}
              disabled
              margin="normal"
              color="secondary"
              startAdornment={
                <InputAdornment position="start">
                  <img
                    src={'data:image/png;base64,' + Object.values(this.props.dataUser.ListFlags).filter(country2 => this.props.cardSelected.country === country2.isoAlpha2)[0].flag}
                    style={{ width: 24, height: 16, borderRadius: 2 }}
                    alt=""
                  />
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center" style={{ paddingLeft: 15, paddingRight: 15 }}>
          <Grid item xs={12}>
            <TextField
              style={{ width: '100%', height: 50, fontFamily: 'Avenir' }}
              id="outlined-password-input"
              label="Zip code"
              value={this.state.zipCode}
              type="tel"
              autoFocus={true}
              onChange={this.handleChangeZip()}
              ref={(input) => { this.zipTextInput = input }}
              // autoComplete="phone-number"
              margin="normal"
              color="secondary"
            // variant="outlined"
            // maxLength={this.state.digitsRequired}
            />
          </Grid>
        </Grid>

        <Grid container style={{ paddingLeft: 15, paddingRight: 15, marginTop: 15 }}>
          {
            this.state.loader ?
              <Button style={{ height: 53, color: 'white', width: '100%' }} variant="contained" color="primary">
                <CircularProgress style={{ color: 'white' }} size={24} opacity={50} />
              </Button>
              : this.state.cardNumber.length === 0 || this.state.cvv.length < 3 || this.state.expDate.length < 5 || this.state.zipCode.length < 3 ?
                <Button style={styles.buttonOff} variant="outlined" color="primary">
                  Confirm
          </Button>
                :
                <Button style={styles.button} variant="contained" color="primary" onClick={() => this.submit()}>
                  Confirm
          </Button>
          }
        </Grid>

        <Grid alignItems="center" justify="center" style={{ marginTop: 20 }}>
          {
            this.state.error ?
              <div style={styles.subtitle}>{this.state.errorMessage}</div>
              : null
          }
        </Grid>
      </div>
    );
  }
}
const styles = {
  text: {
    fontFamily: 'Avenir',
    fontSize: 15,
    fontWeight: 'bold',
    color: '#3173ED',
  },
  subtitle: {
    fontFamily: 'Avenir',
    fontSize: 15,
    fontWeight: 'bold',
    color: '#4a4a4a',
  },
  button: {
    height: 53, color: 'white', fontFamily: 'AvenirBold', width: '100%',
    fontWeight: 'bold',
    fontSize: 14
  },
  buttonOff: {
    height: 53, color: '#8CB5A1', fontFamily: 'Avenir', width: '100%',
    fontWeight: 'bold'
  },
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { initApp })(SignIn);
