import React, { Component } from 'react';
import CardBenefit from './CardBenefit'

export default class ListBenefits extends Component {
  render() {
    return (
      <div className="benefitScrollView">
        {Object.values(this.props.benefits).map((benefit, i) => (
          <div key={i} style={{ marginRight: (i < (Object.values(this.props.benefits).length - 1) ? 10 : 0) }} className="benefitCard">
            <CardBenefit benefit={benefit} />
          </div>
        ))}
      </div>
    )
  }
}