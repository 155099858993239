import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { initApp } from '../../../actions/initAppActions'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Slide from '@material-ui/core/Slide';
import Payments from '../Payments';
import { Fonts } from '../../../utils/Font';
function Transition(props) {
  return <Slide direction="left" {...props} />;
}
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPayments: false,
      showLogout: false
    };
  }
  open(state, val) {
    this.setState({ [state]: val })
  }
  closePayments() {
    this.setState({ showPayments: false })
  }
  logout() {
    this.props.initApp('logout', { variables: this.props.dataUser.instaviceVariables, userID: this.props.dataUser.userID })
  }
  instagram() {
    window.open('https://www.instagram.com/thewlnss/', '_blank');
  }
  render() {
    return (
      <div className="App" >
        <List component="nav" style={{ marginLeft: '3%', marginRight: 5 }}>
          <ListItem button onClick={() => { this.props.history.push('/appointments') }} >
            <div style={styles.text}>Appointments</div>
            <ListItemSecondaryAction style={{ paddingRight: 15 }}>
              <Icon style={{ color: '#9E9E9E' }}>keyboard_arrow_right</Icon>
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem button onClick={() => { this.open('showPayments', true) }} >
            <div style={styles.text}>Payments</div>
            <ListItemSecondaryAction style={{ paddingRight: 15 }}>
              <Icon style={{ color: '#9E9E9E' }}>keyboard_arrow_right</Icon>
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem button onClick={() => { this.instagram() }} >
            <div style={styles.text}>Visit us on Instagram</div>
            <ListItemSecondaryAction style={{ paddingRight: 15 }}>
              <img style={styles.icon} src={require('../../../img/app/instagram.png')} alt="" />
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem button onClick={() => { this.open('showLogout', true) }} >
            <div style={styles.text}>Log out?</div>
            <ListItemSecondaryAction style={{ paddingRight: 15 }}>
              <img style={styles.icon} src={require('../../../img/app/logout.png')} alt="" />
            </ListItemSecondaryAction>
          </ListItem>
        </List>

        <Dialog
          fullScreen
          open={this.state.showPayments}
          TransitionComponent={Transition}
        >
          <Payments close={this.closePayments.bind(this)} journeyShow={this.props.journeyShow} />
        </Dialog>

        <Dialog
          open={this.state.showLogout}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Do you want to log out?"}</DialogTitle>
          <DialogContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { this.setState({ showLogout: false }) }} color="primary">
              Cancel
            </Button>
            <Button onClick={() => this.logout()} color="primary" autoFocus>
              Logout
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const styles = {
  text: {
    fontFamily: Fonts.MarkOTMedium,
    fontSize: 16,
    color: '#4a4a4a',
    marginTop: 15,
    marginBottom: 15
  },
  icon: {
    width: 25,
    height: 25,
    marginTop: 2
  },
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { initApp })(SignIn);
