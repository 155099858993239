
import {
	UPDATE_STEP_LOGIN,
	SET_USER_EMAIL,
	SET_USER_PWD,
	SET_USER_LASTNAME,
	SET_USER_FIRSTNAME,
	SET_USER_PHONE,
	SET_USER_BIRTH,
	SET_ANSWERS,
	SET_STEP_LOG,
} from './types';

const setStepSignUp = (value) => ({
	type: UPDATE_STEP_LOGIN,
	stepSignUp: value,
});

const setEmail = (email) => ({
	type: SET_USER_EMAIL,
	email: email,
});

const setPwd = (password) => ({
	type: SET_USER_PWD,
	password: password,
});
const setLastname = (lastname) => ({
	type: SET_USER_LASTNAME,
	lastname: lastname,
});

const setFirstname = (firstname) => ({
	type: SET_USER_FIRSTNAME,
	firstname: firstname,
});

const setPhone = (phone) => ({
	type: SET_USER_PHONE,
	phone: phone,
});

const setBirth = (birth) => ({
	type: SET_USER_BIRTH,
	birth: birth,
});

const setStepLog = (value) => ({
	type: SET_STEP_LOG,
	stateLogin: value,
});

const setAnswers = (value) => ({
	type: SET_ANSWERS,
	answers: value,
});

export const updateStepLogin = (val, data) => {
	return async function (dispatch) {
		if (val === 'setStepSignUp') {
			dispatch(setStepSignUp(data.value))
		}
		else if (val === 'setFirstname') {
			dispatch(setFirstname(data.value))
		}
		else if (val === 'setLastname') {
			dispatch(setLastname(data.value))
		}
		else if (val === 'setEmail') {
			dispatch(setEmail(data.value))
		}
		else if (val === 'setPwd') {
			dispatch(setPwd(data.value))
		}
		else if (val === 'setPhone') {
			dispatch(setPhone(data.value))
		}
		else if (val === 'setStepLog') {
			dispatch(setStepLog(data.value))
		}
		else if (val === 'setBirth') {
			dispatch(setBirth(data.value))
		}
		else if (val === 'setAnswers') {
			dispatch(setAnswers(data.value))
		}
	}
}
