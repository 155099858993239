import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import '../../../css/Home.css';
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Payments from '../../elementsProfile/Payments';
function Transition(props) {
  return <Slide direction="up" {...props} />;
}
class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPayments: false,
      straightToCard: false
    };
  }
  componentWillMount() {
    this.props.onRef(this)
  }
  async openCB(val, straightToCard) {
    window.scrollTo(0, 0)
    await this.setState({ straightToCard: straightToCard })
    this.setState({ showPayments: val })
  }
  render() {
    return (
      <Grid style={{ height: 50, marginTop: 5, paddingLeft: 15 }}>
        {
          this.props.dataUser.numberOfCB === 0 ?
            <Grid container style={styles.buttonCB} alignItems="center" justify="center" onClick={() => { this.openCB(true) }} className="pointer">
              <Grid item size={3} >
                <img style={{ width: 29, height: 20, borderRadius: 3 }} src={require('../../../img/addCB/creditCard.png')} alt="" />
              </Grid>
              <Grid item size={8} style={{ textAlign: 'left' }}>
                <div style={styles.textCard}>
                  Add payment method
                </div>
              </Grid>
              <Grid item size={1}>
                <Icon style={{ color: '#9E9E9E' }}>keyboard_arrow_down</Icon>
              </Grid>
            </Grid>
            :
            <Grid container style={styles.buttonCB} className="borderOn shadeOn pointer" onClick={() => { this.openCB(true) }} alignItems="center" justify="center" >
              <Grid item xs={3} >
                {
                  this.props.dataUser.defaultCB.brand === 'Visa' ?
                    <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/visa.png')} alt="" />
                    : this.props.dataUser.defaultCB.brand === 'applePay' ?
                      <img style={{ width: 29, height: 18, borderRadius: 1 }} src={require('../../../img/addCB/applePay.png')} alt="" />
                      : this.props.dataUser.defaultCB.brand === 'googlePay' ?
                        <img style={{ width: 29, height: 20, borderRadius: 3 }} src={require('../../../img/addCB/googlePay.png')} alt="" />
                        : this.props.dataUser.defaultCB.brand === 'MasterCard' ?
                          <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/mastercard.png')} alt="" />
                          : this.props.dataUser.defaultCB.brand === 'American Express' ?
                            <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/amEx.png')} alt="" />
                            : this.props.dataUser.defaultCB.brand === 'JCB' ?
                              <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/jcb.png')} alt="" />
                              : this.props.dataUser.defaultCB.brand === 'Discover' ?
                                <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/discover.png')} alt="" />
                                : this.props.dataUser.defaultCB.brand === 'Diners Club' ?
                                  <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/dinnersClub.png')} alt="" />
                                  :
                                  <img style={{ width: 29, height: 31, borderRadius: 3 }} src={require('../../../img/addCB/creditCard.png')} alt="" />
                }
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'left' }}>
                {
                  this.props.dataUser.defaultCB.brand === 'applePay' || this.props.dataUser.defaultCB.brand === 'googlePay' ?
                    <div style={styles.textCard}>{this.props.dataUser.defaultCB.title}</div>
                    :
                    <div style={styles.textCard}>•••• {this.props.dataUser.defaultCB.last4}</div>
                }
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'left' }}>
                <div style={styles.textCard}>
                  {
                    this.props.dataUser.defaultCB.brand === 'applePay' || this.props.dataUser.defaultCB.brand === 'googlePay' ?
                      null
                      :
                      <div style={styles.textCard}>{this.props.dataUser.defaultCB.exp_month}/{this.props.dataUser.defaultCB.exp_year}</div>
                  }
                </div>
              </Grid>
              <Grid item xs={2}>
                <Icon style={{ color: '#9E9E9E' }}>keyboard_arrow_down</Icon>
              </Grid>
            </Grid>
        }
        <Dialog
          fullScreen
          open={this.state.showPayments}
          TransitionComponent={Transition}
        >
          <Payments straightToCard={this.state.straightToCard} close={this.openCB.bind(this)} journeyShow={this.props.journeyShow} />
        </Dialog>
      </Grid>
    );
  }
}
const styles = {
  buttonCB: {
    borderRadius: 4,
    backgroundColor: 'white',
    borderWidth: 1,
    width: '100%',
    height: 50,
    shadowColor: '#46474B',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.10,
    shadowRadius: 5,
    borderColor: '#EAEAEA',
  },
  textCard: {
    fontSize: 15,
    color: '#4F4F4F',
    fontFamily: 'Avenir',
    fontWeight: 'bold'
  },
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(Product);
