import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ReactPixel from 'react-facebook-pixel';
import '../../../css/Home.css';
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import mixpanel from 'mixpanel-browser'
import colors from '../../layout/colors'
import { Fonts } from '../../../utils/Font'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck, faLocationArrow, faCheck, faTags, faCreditCard } from '@fortawesome/free-solid-svg-icons'

const mixPanelToken = 'bef3efb4cd721628459b35e7a4c1abb6'
mixpanel.init(mixPanelToken);
class Product extends Component {
  async componentWillMount() {
    window.scrollTo(0, 0)
  }

  viewAppointments() {
    this.props.history.push('/appointments');
    mixpanel.track('Click OK final summary', { 'userID': this.props.dataUser.userID });
    ReactPixel.track('Click OK final summary', { 'userID': this.props.dataUser.userID });
  }

  viewEvent() {
    this.props.history.push('/appointments');
    mixpanel.track('Click OK final summary', { 'userID': this.props.dataUser.userID });
    ReactPixel.track('Click OK final summary', { 'userID': this.props.dataUser.userID });
  }

  render() {
    return (
      <Grid container style={styles.container} alignItems="center" justify="center">
        <div style={styles.cardFinal} className="shadeOn" >
          <Grid container style={{ marginTop: 10 }} alignItems="center" justify="center">
            {
              this.props.dataHome.productChosen.type !== 'shots' ?
                <Grid item xs={12} md={12} style={styles.contentText}>{this.props.dataHome.productChosen.name} booked!</Grid>
                : this.props.dataHome.subscribe ?
                  <Grid item xs={12} md={12} style={styles.contentText}>Attendance confirmed!</Grid>
                  :
                  <Grid item xs={12} md={12} style={styles.contentText}>Event created!</Grid>
            }
          </Grid>
          <div style={styles.divider}></div>

          <Grid container alignItems="center" justify="center">
            <Grid item xs={2} alignContent="center" justify="center">
              <FontAwesomeIcon icon={faCalendarCheck} style={{ borderWidth: '0px', color: colors.title, marginTop: 10, marginBottom: 10 }} />
            </Grid>
            {
              this.props.dataHome.timeZone === '' && this.props.dataHome.productChosen.type !== 'shots' ?
                <Grid item xs={10} style={styles.contentText2}>{moment(this.props.dataHome.dateAppt).format('ddd, MMM Do')} at {moment(this.props.dataHome.dateAppt).format('h:mm a')}</Grid>
                : this.props.dataHome.timeZone === '' ?
                  <Grid item xs={10} style={styles.contentText2}>{moment(this.props.dataHome.dateAppt).format('ddd, MMM Do')} from {moment(this.props.dataHome.dateAppt).format('h:mm')} to {moment(this.props.dataHome.dateAppt).add(this.props.dataHome.durationEvent, 'm').format('h:mm a')}</Grid>
                  : this.props.dataHome.productChosen.type !== 'shots' && moment().format('Z').toString() !== this.props.dataHome.timeZone.offSet ?
                    <Grid item xs={10} style={styles.contentText2}>Wed, Oct 16th at 2:30pm{/*momentTZ.tz(this.props.dataHome.dateAppt,this.props.dataHome.timeZone.timeZoneId).format('ddd, MMM Do')} at {momentTZ.tz(this.props.dataHome.dateAppt,this.props.dataHome.timeZone.timeZoneId).format('h:mm a')} ({this.props.dataHome.timeZone.timeZoneName2*/}</Grid>
                    : this.props.dataHome.productChosen.type !== 'shots' ?
                      <Grid item xs={10} style={styles.contentText2}>{momentTZ.tz(this.props.dataHome.dateAppt, this.props.dataHome.timeZone.timeZoneId).format('ddd, MMM Do')} at {momentTZ.tz(this.props.dataHome.dateAppt, this.props.dataHome.timeZone.timeZoneId).format('h:mm a')}</Grid>
                      : this.props.dataHome.productChosen.type === 'shots' && moment().format('Z').toString() !== this.props.dataHome.timeZone.offSet ?
                        <Grid item xs={10} style={styles.contentText2}>{momentTZ.tz(this.props.dataHome.dateAppt, this.props.dataHome.timeZone.timeZoneId).format('ddd, MMM Do')} from {momentTZ.tz(this.props.dataHome.dateAppt, this.props.dataHome.timeZone.timeZoneId).format('h:mm a')} to {momentTZ.tz(this.props.dataHome.dateAppt, this.props.dataHome.timeZone.timeZoneId).add(this.props.dataHome.durationEvent, 'm').format('h:mm a')} ({this.props.dataHome.timeZone.timeZoneName2})</Grid>
                        : this.props.dataHome.productChosen.type === 'shots' ?
                          <Grid item xs={10} style={styles.contentText2}>{momentTZ.tz(this.props.dataHome.dateAppt, this.props.dataHome.timeZone.timeZoneId).format('ddd, MMM Do')} from {momentTZ.tz(this.props.dataHome.dateAppt, this.props.dataHome.timeZone.timeZoneId).format('h:mm a')} to {momentTZ.tz(this.props.dataHome.dateAppt, this.props.dataHome.timeZone.timeZoneId).add(this.props.dataHome.durationEvent, 'm').format('h:mm a')}</Grid>
                          : null
            }
          </Grid>

          <Grid container alignItems="center" justify="center">
            <Grid item xs={2} alignContent="center" justify="center">
              <FontAwesomeIcon icon={faLocationArrow} style={{ color: colors.title, marginTop: 10, marginBottom: 10 }} />
            </Grid>
            <Grid item xs={10} style={styles.contentText2}>{this.props.dataHome.addressHomeRequest}</Grid>
          </Grid>

          {
            this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.numberUnit !== 1 && !!this.props.dataHome.inviteCodeEvent ?
              <Grid container alignItems="center" justify="center">
                <Grid item xs={2} alignContent="center" justify="center">
                  <FontAwesomeIcon icon={faCheck} style={{ color: colors.title, marginTop: 10, marginBottom: 10 }} />
                </Grid>
                <Grid item xs={10} style={styles.contentText2}>{this.props.dataHome.inviteCodeEvent}</Grid>
              </Grid>
              : !this.props.dataHome.subscribe ?
                <Grid container alignItems="center" justify="center">
                  <Grid item xs={2} alignContent="center" justify="center">
                    <FontAwesomeIcon icon={faCheck} style={{ color: colors.title, marginTop: 10, marginBottom: 10 }} />
                  </Grid>
                  <Grid item xs={10} style={styles.contentText2}>{this.props.dataHome.numberUnit} people estimated</Grid>
                </Grid>
                : null
          }

          {
            this.props.dataHome.productChosen.type === 'shots' || (this.props.dataHome.productChosen.type !== 'shots' && ((this.props.dataHome.paymentOption.value === 'single') || (!this.props.dataHome.subscribe && this.props.dataHome.paymentOption.value === 'group'))) ?
              <Grid container alignItems="center" justify="center">
                <Grid item xs={2} alignContent="center" justify="center">
                  <FontAwesomeIcon icon={faTags} style={{ color: colors.title, marginTop: 10, marginBottom: 10 }} />
                </Grid>
                {
                  this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.paymentOption.value === 'single' && ((this.props.dataHome.receiver !== 'else' && !this.props.dataHome.subscribe) || this.props.dataHome.subscribe) && this.props.dataHome.productChosen.pricesGroup.filter(price => price.value === Number(this.props.dataHome.numberUnit)).length !== 0 ?
                    <Grid item xs={10} style={styles.contentText2}>${this.props.pricing.estimatedTotal} estimated total</Grid>
                    : this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.paymentOption.value === 'single' && ((this.props.dataHome.receiver !== 'else' && !this.props.dataHome.subscribe) || this.props.dataHome.subscribe) && this.props.dataHome.productChosen.pricesGroup.filter(price => price.value === Number(this.props.dataHome.numberUnit)).length === 0 ?
                      <Grid item xs={10} style={styles.contentText2}>${this.props.pricing.estimatedTotal} estimated total</Grid>
                      : this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.paymentOption.value === 'group' && this.props.dataHome.productChosen.pricesGroup.filter(price => price.value === Number(this.props.dataHome.numberUnit)).length !== 0 && !this.props.dataHome.subscribe ?
                        <Grid item xs={10} style={styles.contentText2}>${this.props.pricing.estimatedTotal} estimated total</Grid>
                        : this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.paymentOption.value === 'group' && this.props.dataHome.productChosen.pricesGroup.filter(price => price.value === Number(this.props.dataHome.numberUnit)).length === 0 && !this.props.dataHome.subscribe ?
                          <Grid item xs={10} style={styles.contentText2}>${this.props.pricing.estimatedTotal} estimated total</Grid>
                          : this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.receiver === 'else' && this.props.dataHome.numberUnit === 1 ?
                            <Grid item xs={10} style={styles.contentText2}>Charged on attendee</Grid>
                            : this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.receiver === 'else' && this.props.dataHome.numberUnit !== 1 ?
                              <Grid item xs={10} style={styles.contentText2}>Charged on attendees</Grid>
                              : this.props.dataHome.productChosen.type !== 'shots' ?
                                <Grid item xs={10} style={styles.contentText2}>Charged on host</Grid>
                                : this.props.dataHome.subscribe && this.props.dataHome.productChosen.type === 'shots' ?
                                  <Grid item xs={10} style={styles.contentText2}>{this.props.dataHome.boostersSelected.length} boosters offered</Grid>
                                  : this.props.dataHome.productChosen.type === 'shots' ?
                                    <Grid item xs={10} style={styles.contentText2}>{this.props.dataHome.boostersSelected.length} boosters selected</Grid>
                                    : null
                }
              </Grid>
              : null
          }

          {
            this.props.dataHome.productChosen.type !== 'shots' && ((this.props.dataHome.paymentOption.value === 'single') || (!this.props.dataHome.subscribe && this.props.dataHome.paymentOption.value === 'group')) && this.props.dataHome.creditCardRequired ?
              <Grid container alignItems="center" justify="center">
                <Grid item xs={2} alignContent="center" justify="center">
                  <FontAwesomeIcon icon={faCreditCard} style={{ color: colors.title, marginTop: 10, marginBottom: 10 }} />
                </Grid>
                <Grid item xs={10} style={styles.contentText2}>
                  {this.props.dataUser.defaultCB.brand} •••• {this.props.dataUser.defaultCB.last4}
                </Grid>
              </Grid>
              : null
          }

          <div style={styles.divider}></div>

          {
            this.props.dataHome.creditCardRequired ?
              <Grid container alignItems="center" justify="center">
                {
                  this.props.dataUser.userConnected && this.props.dataHome.creditCardRequired && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.paymentOption.value === 'group' && !this.props.dataHome.subscribe ?
                    <div style={styles.contentText3}>Reminder • You are in charge of the group’s bill. We will process payment after the appointment takes places.</div>
                    : this.props.dataUser.userConnected && this.props.dataHome.creditCardRequired && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.paymentOption.value === 'group' && this.props.dataHome.subscribe ?
                      <div style={styles.contentText3}>Reminder • Your event host is taking care of the bill. You won’t be charged for this event.</div>


                      : this.props.dataUser.userConnected && this.props.dataHome.creditCardRequired && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.receiver === 'else' && this.props.dataHome.numberUnit === 1 && !this.props.dataHome.subscribe ?
                        <div style={styles.contentText3}>Reminder • Your event attendee is taking care of the bill. You won’t be charged for this event.</div>
                        : this.props.dataUser.userConnected && this.props.dataHome.creditCardRequired && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.receiver === 'else' && this.props.dataHome.numberUnit !== 1 && !this.props.dataHome.subscribe ?
                          <div style={styles.contentText3}>Reminder • Your event attendees are taking care of the bill. You won’t be charged for this event.</div>
                          : this.props.dataUser.userConnected && this.props.dataHome.creditCardRequired && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.subscribe ?
                            <div style={styles.contentText3}>Reminder • We need a credit card on file to confirm your attendance. We won't charge you until the treatment has taken place.</div>


                            : this.props.dataUser.userConnected && this.props.dataHome.creditCardRequired && this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.receiver === 'me' && !this.props.dataHome.subscribe ?
                              <div style={styles.contentText3}>Reminder • We need a credit card on file to confirm your booking. We won't charge you until the treatment has taken place.</div>
                              : this.props.dataUser.userConnected && this.props.dataHome.subscribe && this.props.dataHome.productChosen.type === 'shots' ?
                                <div style={styles.contentText3}>Reminder • We need a credit card on file to confirm your booking. We won't charge you until the treatment has taken place.</div>
                                : this.props.dataUser.userConnected && this.props.dataHome.productChosen.type === 'shots' ?
                                  <div style={styles.contentText3}>Reminder • Share your invite code with your attendees.</div>
                                  : null
                }
              </Grid>
              : null
          }

          <Grid container style={{ ...styles.buttonOff, width: '100%' }} justify="center" alignContent="center" color="primary">
            {
              this.props.dataHome.productChosen.type !== 'shots' ?
                <Grid item className="pointer" onClick={() => this.viewAppointments()}>
                  Ok! View appointments
                </Grid>
                :
                <Grid item className="pointer" onClick={() => this.viewEvent()}>
                  Ok! View event
                </Grid>
            }
          </Grid>
        </div>
      </Grid>
    );
  }
}
const styles = {
  container: {
    minHeight: window.innerHeight - 50,
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: -10,
    backgroundColor: colors.primary,
  },
  buttonOff: {
    height: 45, color: colors.primary, fontFamily: Fonts.MarkOTBold, width: '90%',
    marginTop: 20, fontSize: 14,
    marginBottom: 10,
  },
  cardFinal: {
    backgroundColor: 'white',
    width: '95vw',
    maxWidth: 400,
    borderRadius: 5,
    flex: 1,
  },
  buttonBack: {
    position: 'absolute',
    top: 75,
    left: 15,
    zIndex: 20,
  },
  contentText: {
    fontSize: 17,
    color: '#4A4A4A',
    fontFamily: Fonts.MarkOTBold,
    textAlign: 'left',
    paddingLeft: 20,
    marginBottom: 7,
    marginTop: 5
  },
  contentText2: {
    fontSize: 14,
    color: colors.title,
    fontFamily: Fonts.MarkOT,
    textAlign: 'left',
    paddingRight: 15,
    marginBottom: 2,
  },
  contentText3: {
    fontSize: 12,
    marginTop: 5,
    color: colors.subtitle,
    fontFamily: Fonts.MarkOT,
    textAlign: 'left',
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 2,
  },
  title: {
    fontFamily: Fonts.MarkOT,
    fontSize: 23,
    fontWeight: 'Bold',
    paddingLeft: 15,
    color: '#4a4a4a',
    marginTop: 10,
  },
  subtitle: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 65,
    marginBottom: 5,
    color: '#4a4a4a',
    textAlign: 'left',
  },
  secondRes: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  mainRes: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 17,
    color: '#4a4a4a',
    textAlign: 'left',
  },
  rowResult: {
    flex: 1, borderBottomWidth: 1, borderColor: '#EAEAEA', paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15
  },
  textIngredient: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
    paddingLeft: 15,
  },
  textBenefit: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    marginTop: 10,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    paddingLeft: 15,
    height: 30,
  },
  titleBenefit: {
    fontFamily: Fonts.MarkOTBold,
    fontSize: 19,
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: '#eaeaea',
    marginTop: 10,
    marginBottom: 10,
  },
  row: {
    textAlign: 'left',
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    color: '#4a4a4a',
    paddingTop: 5
  },
  boxPrice: {
    width: 70,
    height: 25,
    borderRadius: 15,
    bottom: 20,
    right: 15,
    backgroundColor: '#FAF9F9',
    alignItems: 'center',
    fontFamily: Fonts.MarkOT,
    justifyContent: 'center',
    borderColor: '#EAEAEA',
    color: '#8CB5A1',
    paddingTop: 9,
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(Product);
