import * as firebase from 'firebase';

var version = 'Prod'
var stripeToken2 = ''
var index2 = ''
var indexSearch2 = ''
var mixPanelToken2 = ''
if (version === 'Dev') {
  firebase.initializeApp({
    apiKey: 'AIzaSyAzqjdQLsJMLiQABxXHEIBNYq6YqFj8tPw',
    authDomain: 'instavice-app.firebaseapp.com',
    databaseURL: 'https://instavice-app.firebaseio.com',
    projectId: 'instavice-app',
    storageBucket: 'instavice-app.appspot.com',
    messagingSenderId: '819834063353'
  });
  stripeToken2 = 'pk_test_KtALJyQdVKhMPijrc1izFhcG'
  index2 = 'Test'
  mixPanelToken2 = 'sdfdgfsdadasdfdf'
}
else if (version === 'Prod') {
  firebase.initializeApp({
    apiKey: 'AIzaSyCq0yr7q0lZBskgropbskdkGdW_bn6_avw',
    authDomain: 'instabo-production.firebaseapp.com',
    databaseURL: 'https://instabo-production.firebaseio.com',
    projectId: 'instabo-production',
    storageBucket: 'instabo-production.appspot.com',
    messagingSenderId: '992541758294'
  });
  stripeToken2 = 'pk_live_VuBehiJaK0u21XGNHznwUCVp'
  index2 = 'Prod'
  mixPanelToken2 = 'bef3efb4cd721628459b35e7a4c1abb6'
}
const firebaseRef1 = firebase.database().ref();
const UserRef1 = firebase.database().ref('users/');

export const firebaseRef = firebaseRef1;
export const UserRef = UserRef1;
export const stripeToken = stripeToken2;
export const index = index2;
export const indexSearch = indexSearch2
export const mixPanelToken = mixPanelToken2
export default firebase
