
import {
  SET_NUMBER_UNIT,
  SET_LOADER_HOME,
  SET_PRICE_PER_UNIT,
  SET_ESTIMATE_PPU,
  SET_AVAILABILITY_TYPE,
  SET_PREFERENCE_DAY1,
  SET_PREFERENCE_DAY2,
  SET_PREFERENCE_TIME1_FROM,
  SET_PREFERENCE_TIME2_FROM,
  SET_PREFERENCE_TIME1_TO,
  SET_PREFERENCE_TIME2_TO,
  SET_PREFERENCE_DISPLAY,
  SET_PROVIDERS_PREFERENCE,
  SET_NAME_LOCATION,
  RESET_DATA_HOME,
  OPEN_REVIEWS,
  SET_STEP_HOME,
  OPEN_PARTY_FLOW,
  SET_PROVIDER_CHOICE_TYPE,
  SET_TYPE_CHOICE,
  SET_ACCESSORIES,
  SHOW_COMPLETE_PROFILE,
  SET_PROVIDER_CHOICE_INFO,
  SET_TYPE_DEMAND,
  SET_OPEN_LINK_PARTY,
  SET_DOC_OBJ,
  SET_DATE_FILTER,
  SET_DISCOUNT_CODE,
  SET_ID_APPT,
  OPEN_IV_FLOW,
  SET_TIME_IV,
  SET_PRODUCT_CHOSEN,
  SET_NOTES,
  SET_ADDRESS_HOME_REQUEST,
  SET_BOOSTERS,
  SET_TIME_ZONE,
  SET_DURATION_EVENT,
  SET_INVITE_CODE_EVENT,
  SET_BUSINESS_PHONE,
  SET_BUSINESS_NAME,
  SET_SUBSCRIBE,
  SET_DATE_SLOT_APPT,
  SET_DATE_APPT,
  SET_ORGANIZER_ID,
  SET_USERS_CONFIRMED,
  SET_NUMBER_UNIT_ORDERED_PER_PATIENT,
  SET_NURSE_INFO,
  SET_CREDIT_CARD_REQUIRED,
  SET_PAYMENT_OPTION,
  SET_RECEIVER,
  SET_SECONDARY_PRODUCTS,
  SET_ADDITIONAL_PRODUCT,
  SET_LOCATION_HIGH_UP,
  SET_ATTENDEE_PRODUCT,
} from './types';

const setLoaderHome = (value) => ({
  type: SET_LOADER_HOME,
  showLoaderHome: value
});

const setNumberUnit = (numberUnit) => ({
  type: SET_NUMBER_UNIT,
  numberUnit: numberUnit
});

const setPricePerUnit = (pricePerUnit, pricePerUnitDisplay) => ({
  type: SET_PRICE_PER_UNIT,
  pricePerUnit: pricePerUnit,
  pricePerUnitDisplay: pricePerUnitDisplay
});

const setAveragePrice = (value) => ({
  type: SET_ESTIMATE_PPU,
  estimatedPricePerUnit: value
});

const setAvailabilityType = (value) => ({
  type: SET_AVAILABILITY_TYPE,
  availabilityType: value
});

const setPreferenceday1 = (value) => ({
  type: SET_PREFERENCE_DAY1,
  preferenceday1: value
});

const setPreferenceday2 = (value) => ({
  type: SET_PREFERENCE_DAY2,
  preferenceday2: value
});

const setPreferencetime1from = (value) => ({
  type: SET_PREFERENCE_TIME1_FROM,
  preferencetime1from: value
});

const setPreferencetime2from = (value) => ({
  type: SET_PREFERENCE_TIME2_FROM,
  preferencetime2from: value
});

const setPreferencetime1to = (value) => ({
  type: SET_PREFERENCE_TIME1_TO,
  preferencetime1to: value
});

const setPreferencetime2to = (value) => ({
  type: SET_PREFERENCE_TIME2_TO,
  preferencetime2to: value
});

const setAvailabilityDisplay = (value) => ({
  type: SET_PREFERENCE_DISPLAY,
  preferencesDisplay: value
});

const setNameLocation = (value) => ({
  type: SET_NAME_LOCATION,
  nameLocation: value
});

const resetDataHome = () => ({
  type: RESET_DATA_HOME
});

const openReviews = (value) => ({
  type: OPEN_REVIEWS,
  showReviews: value
});

const setProvidersPreference = (value) => ({
  type: SET_PROVIDERS_PREFERENCE,
  providersPreference: value
})

const setStepHome = (value) => ({
  type: SET_STEP_HOME,
  stepHome: value
});

const setTypeChoice = (value) => ({
  type: SET_TYPE_CHOICE,
  typeProduct: value
});

const showCompletProfile = (value) => ({
  type: SHOW_COMPLETE_PROFILE,
  showCompleteProfile: value
});

const setProviderChoiceType = (value) => ({
  type: SET_PROVIDER_CHOICE_TYPE,
  providerChoiceType: value
});

const setTypeDemand = (value) => ({
  type: SET_TYPE_DEMAND,
  typeDemand: value
});

const setProviderChoiceInfo = (value) => ({
  type: SET_PROVIDER_CHOICE_INFO,
  providerChoiceInfo: value
});

const setOpenLinkParty = (value) => ({
  type: SET_OPEN_LINK_PARTY,
  openLinkParty: value
})

const setObjMat = (value) => ({
  type: SET_DOC_OBJ,
  docObjMat: value
})

const setDateFilter = (value) => ({
  type: SET_DATE_FILTER,
  dateFilter: value
})

const setDateSlotAppt = (value) => {
  return {
    type: SET_DATE_SLOT_APPT,
    dateSlotAppt: value,
  }
}

const setDiscountCodeInformation = (value, infoCode) => ({
  type: SET_DISCOUNT_CODE,
  discountCodeUsed: value,
  infoCode: infoCode
})

const setIdAppt = (value) => ({
  type: SET_ID_APPT,
  idAppt: value
})
const openIVFLow = (value) => ({
  type: OPEN_IV_FLOW,
  showIVFlow: value
})

const openPartyFLow = (value) => ({
  type: OPEN_PARTY_FLOW,
  showPartyFlow: value
})

const setTimeIV = (value) => ({
  type: SET_TIME_IV,
  timeIV: value
})

const setLocationHighUp = (value) => ({
  type: SET_LOCATION_HIGH_UP,
  locationHighUp: value
})

const setNumberUnitOrderedPerPatient = (value) => ({
  type: SET_NUMBER_UNIT_ORDERED_PER_PATIENT,
  numberUnitOrderedPerPatient: value
})

const setAddressHomeRequest = (value, accessories, locationAllowCar, jsonTimeZone) => ({
  type: SET_ADDRESS_HOME_REQUEST,
  addressHomeRequest: value,
  accessories: accessories,
  locationAllowCar: locationAllowCar,
  timeZone: jsonTimeZone,
})

const setNotes = (value) => ({
  type: SET_NOTES,
  notes: value
})

const setAccessories = (value) => ({
  type: SET_ACCESSORIES,
  accessories: value
})

const setSecondaryProducts = (value) => ({
  type: SET_SECONDARY_PRODUCTS,
  secondaryProducts: value
})

const setProductChosen = (value) => ({
  type: SET_PRODUCT_CHOSEN,
  productChosen: value
})

const setDurationEvent = (value) => ({
  type: SET_DURATION_EVENT,
  durationEvent: value
})

const setBusinessPhone = (value) => ({
  type: SET_BUSINESS_PHONE,
  businessPhone: value
})

const setBusinessName = (value) => ({
  type: SET_BUSINESS_NAME,
  businessName: value
})

const setInviteCode = (value) => ({
  type: SET_INVITE_CODE_EVENT,
  inviteCodeEvent: value
})

const setDateAppt = (value) => ({
  type: SET_DATE_APPT,
  dateAppt: value
})

const setOrganizerID = (value) => ({
  type: SET_ORGANIZER_ID,
  organizerID: value
})

const setSubscribe = (value) => ({
  type: SET_SUBSCRIBE,
  subscribe: value
})

const setUsersConfirmed = (value) => ({
  type: SET_USERS_CONFIRMED,
  usersConfirmed: value
})

const setNurseInfo = (value) => ({
  type: SET_NURSE_INFO,
  nurseInfo: value
})

const setCreditCardRequired = (value) => ({
  type: SET_CREDIT_CARD_REQUIRED,
  creditCardRequired: value
})

const setPaymentOption = (value) => ({
  type: SET_PAYMENT_OPTION,
  paymentOption: value
})

const setReceiver = (value) => ({
  type: SET_RECEIVER,
  receiver: value
})

const setTimeZone = (value) => ({
  type: SET_TIME_ZONE,
  timeZone: value
})

const setAdditionalProduct = (value) => ({
  type: SET_ADDITIONAL_PRODUCT,
  additionalProduct: value
})

const setAttendeeProduct = (value) => ({
  type: SET_ATTENDEE_PRODUCT,
  attendeeProduct: value
})

const setBoosters = (boosters, boostersSelected, numberBoosters) => ({
  type: SET_BOOSTERS,
  boosters: boosters,
  boostersSelected: boostersSelected,
  numberBoosters: numberBoosters
})

export const homeAction = (val, data) => {
  return async function (dispatch) {
    if (val === 'setLoaderHome') {
      dispatch(setLoaderHome(data.value))
    }
    else if (val === 'setNumberUnit') {
      dispatch(setNumberUnit(data.value))
    }
    else if (val === 'setPricePerUnit') {
      var pricePerUnit = data.value
      var pricePerUnitDisplay = pricePerUnit.toString()
      var check = Number.isInteger(pricePerUnit)
      if (check) {
        pricePerUnitDisplay = pricePerUnit.toString() + '.0';
      }
      dispatch(setPricePerUnit(pricePerUnit, pricePerUnitDisplay))
    }
    else if (val === 'loadPriceEstimator') {
      var numberUnit = data.numberUnit
      var locations = data.clinicsDisplay
      var matPrices = []
      for (var i in locations) {
        var locationEnCours = locations[i]
        var currentExcessPrice = locationEnCours.excessPrice
        if (numberUnit >= locationEnCours.discountedUnits && locationEnCours.volumeDiscount === true) {
          currentExcessPrice = locationEnCours.discountedPrice
        }
        matPrices.push(currentExcessPrice)
      }

      var sum = matPrices.reduce((a, b) => a + b, 0);
      var averagePPU = (sum / (matPrices.length)).toFixed(1);
      var price = data.instaviceVariables.price
      averagePPU = Number(averagePPU)

      var low = price.unlikely + averagePPU
      var middle = price.likely + averagePPU
      var high = price.veryLikely + averagePPU
      var boudaries = {
        low: low,
        middle: middle,
        high: high,
        averagePPU: averagePPU,
      }
      dispatch(setAveragePrice(boudaries));
    }
    else if (val === 'setAvailabilityType') {
      dispatch(setAvailabilityType(data.value))
    }
    else if (val === 'setAccessories') {
      dispatch(setAccessories(data.value))
    }
    else if (val === 'setProviderChoiceType') {
      dispatch(setProviderChoiceType(data.value))
    }
    else if (val === 'setNumberUnitOrderedPerPatient') {
      dispatch(setNumberUnitOrderedPerPatient(data.value))
    }
    else if (val === 'setPreferencesDate') {
      var valDate = data.valDate
      var date = data.date
      if (valDate === 1) {
        dispatch(setPreferenceday1(date));
      } else {
        dispatch(setPreferenceday2(date));
      }
    }
    else if (val === 'setPreferencesTime') {
      var valTime = data.valTime
      var type = data.type
      var time = data.time
      if (valTime === 1) {
        if (type === 'from') {
          dispatch(setPreferencetime1from(time));
        } else {
          dispatch(setPreferencetime1to(time));
        }
      }
      else if (valTime === 2) {
        if (type === 'from') {
          dispatch(setPreferencetime2from(time));
        } else {
          dispatch(setPreferencetime2to(time));
        }
      }
    }
    else if (val === 'setLocationHighUp') {
      dispatch(setLocationHighUp(data.value))
    }
    else if (val === 'setProvidersPreference') {
      dispatch(setProvidersPreference(data.value))
    }
    else if (val === 'setAvailabilityDisplay') {
      dispatch(setAvailabilityDisplay(data.value))
    }
    else if (val === 'setNameLocation') {
      dispatch(setNameLocation(data.value))
    }
    else if (val === 'setReceiver') {
      dispatch(setReceiver(data.value))
    }
    else if (val === 'setDateSlotAppt') {
      dispatch(setDateSlotAppt(data.value))
    }
    else if (val === 'setPaymentOption') {
      dispatch(setPaymentOption(data.value))
    }
    else if (val === 'setSecondaryProducts') {
      dispatch(setSecondaryProducts(data.value))
    }
    else if (val === 'setDateAppt') {
      dispatch(setDateAppt(data.value))
    }
    else if (val === 'setAdditionalProduct') {
      dispatch(setAdditionalProduct(data.value))
    }
    else if (val === 'resetDataHome') {
      dispatch(resetDataHome())
    }
    else if (val === 'closeReview') {
      dispatch(openReviews(data.value))
    }
    else if (val === 'setSubscribe') {
      dispatch(setSubscribe(data.value))
    }
    else if (val === 'setStepHome') {
      dispatch(setStepHome(data.value))
    }
    else if (val === 'setOrganizerID') {
      dispatch(setOrganizerID(data.value))
    }
    else if (val === 'setAttendeeProduct') {
      dispatch(setAttendeeProduct(data.value))
    }
    else if (val === 'setTypeChoice') {
      dispatch(setTypeChoice(data.value))
    }
    else if (val === 'setCreditCardRequired') {
      dispatch(setCreditCardRequired(data.value))
    }
    else if (val === 'showCompletProfile') {
      dispatch(showCompletProfile(data.value))
    }
    else if (val === 'setDurationEvent') {
      dispatch(setDurationEvent(data.value))
    }
    else if (val === 'setNurseInfo') {
      dispatch(setNurseInfo(data.value))
    }
    else if (val === 'setTimeZone') {
      dispatch(setTimeZone(data.value))
    }
    else if (val === 'setBusinessName') {
      dispatch(setBusinessName(data.value))
    }
    else if (val === 'setBusinessPhone') {
      dispatch(setBusinessPhone(data.value))
    }
    else if (val === 'setUsersConfirmed') {
      dispatch(setUsersConfirmed(data.value))
    }
    else if (val === 'setInviteCode') {
      dispatch(setInviteCode(data.value))
    }
    else if (val === 'setProviderChoiceInfo') {
      dispatch(setProviderChoiceInfo(data.value))
    }
    else if (val === 'setTypeDemand') {
      dispatch(setTypeDemand(data.value))
    }
    else if (val === 'setOpenLinkParty') {
      dispatch(setOpenLinkParty(data.value))
    }
    else if (val === 'setObjMat') {
      dispatch(setObjMat(data.value))
    }
    else if (val === 'setDateFilter') {
      dispatch(setDateFilter(data.value))
    }
    else if (val === 'setDiscountCodeInformation') {
      dispatch(setDiscountCodeInformation(data.value, data.infoCode))
    }
    else if (val === 'setIdAppt') {
      dispatch(setIdAppt(data.value))
    }
    else if (val === 'openIVFLow') {
      dispatch(openIVFLow(data.value))
    }
    else if (val === 'setBoosters') {
      var boostersSelected = []
      var numberBoosters = 0
      var boosters = data.boosters
      for (i in boosters) {
        var booster = boosters[i]
        if (booster.selected) {
          numberBoosters = numberBoosters + 1
          boostersSelected.push(booster)
        }
      }
      dispatch(setBoosters(boosters, boostersSelected, numberBoosters))
    }
    else if (val === 'openPartyFLow') {
      dispatch(openPartyFLow(data.value))
    }
    else if (val === 'setTimeIV') {
      dispatch(setTimeIV(data.value))
    }
    else if (val === 'setAddressHomeRequest') {
      dispatch(setAddressHomeRequest(data.value, data.accessories, data.locationAllowCar, data.jsonTimeZone))
    }
    else if (val === 'setNotes') {
      dispatch(setNotes(data.value))
    }
    else if (val === 'setProductChosen') {
      dispatch(setProductChosen(data.value))
    }
  }
}

