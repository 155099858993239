import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as firebase from 'firebase'
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { homeAction } from '../../../actions/homeActions'
import colors from '../../layout/colors'

import Dialog from '@material-ui/core/Dialog';
import Alert from '../../layout/alerts/AlertSubtitle'
const StyledTextField = styled(TextField)`
  label.Mui-focused {
    color: ${colors.primary};
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: #eaeaea; 
    }
    &:hover fieldset {
      border-color: ${colors.primaryLight}; 
    }
  }
`;

class DiscountCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSettings: false,
      activate: false,
      discountCode: '',
      loader: false,
      inputEditable: true,
      showAlert: false,
      messageAlert: ''
    };
    this.applyCode = this.applyCode.bind(this);
    this.alertWrongCode = this.alertWrongCode.bind(this);
    this.alertUsedCode = this.alertUsedCode.bind(this);
    this.alertUsedCode2 = this.alertUsedCode2.bind(this);
    this.applyIVCode = this.applyIVCode.bind(this)
  }

  renderActionButton() {
    if (!this.state.activate) {
      return <div onClick={() => this.setState({ activate: true })}>Add</div>
    } else if (!this.state.discountCode) {
      return <div onClick={() => this.setState({ activate: false })}>Cancel</div>
    } else if (!this.state.loader) {
      return <div onClick={() => this.applyCode()}>Apply</div>
    }

    return <CircularProgress style={{ color: 'blue' }} size={18} opacity={18} />
  }
  applyCode() {
    this.setState({ loader: true })
    var initialBoosters = this.props.boosters
    var discountCode = this.state.discountCode.toLowerCase()
    if (!!discountCode) {
      if (this.props.product.type === 'shots') {
        if (!this.props.discountCodeUsed) {
          var boostersCode = this.props.instaviceVariables.discountCodeBoosters
          let filterCodes = (boostersCode.filter(codeBooster => (codeBooster.code.toLowerCase() === discountCode)))
          if (!!filterCodes.length) {
            var codeInfo = filterCodes[0]
            var newBoostersSelected = []
            for (var i in codeInfo.boosters) {
              var currentBooster = codeInfo.boosters[i]
              for (var j in initialBoosters) {
                if (initialBoosters[j].name === currentBooster.name) {
                  currentBooster.selected = initialBoosters[j].selected
                  currentBooster.quantity = 1
                  currentBooster.oldPrice = initialBoosters[j].price
                  newBoostersSelected.push(currentBooster)
                }
              }
            }
            this.props.homeAction('setDiscountCodeInformation', { value: true, infoCode: codeInfo })
            this.props.homeAction('setBoosters', { boosters: newBoostersSelected })
            this.setState({ loader: false })
            this.setState({ activate: false })
          } else {
            this.alertWrongCode()
          }
        } else {
          if (!this.props.subscribe) {
            this.alertUsedCode3()
          } else {
            this.alertUsedCode2()
          }
        }
      } else {
        if (!this.props.discountCodeUsed) {
          var IVCodes = this.props.instaviceVariables.discountCodeIV
          let filterCodes = (IVCodes.filter(codeIV => (codeIV.code.toLowerCase() === discountCode)))
          if (!!filterCodes.length) {
            var userCodes = this.props.dataUser.infoUser.wallet.IVCodes
            if (!userCodes) {
              this.applyIVCode(filterCodes[0])
            } else {
              var filterCodesUser = (userCodes.filter(codeIV => (codeIV.code.toLowerCase() === discountCode)))
              if (!filterCodesUser.length) {
                this.applyIVCode(filterCodes[0])
              } else {
                this.alertUsedCode()
              }
            }
          } else {
            this.alertWrongCode()
          }
        } else {
          this.alertUsedCode2()
        }
      }
    } else {
      this.alertWrongCode()
    }
  }
  applyIVCode(infoCode) {
    this.props.homeAction('setDiscountCodeInformation', { value: true, infoCode: infoCode })
    var discount = infoCode.value
    var userID = this.props.dataUser.userID
    const instaviceWallet = firebase.database().ref('instavice/totalWallet').once('value')
    return Promise.all([instaviceWallet]).then(results => {
      var InitialtotalWalletInstavice = Number(results[0].val())
      var totalWalletInstavice = InitialtotalWalletInstavice - discount
      var totalWallet = Number(this.props.dataUser.infoUser.wallet.totalWallet)
      var transferUser = {
        invoice: {
          totalPrice: discount,
          credits: totalWallet,
        },
        title: 'Discount code',
        type: 'plus',
        date: (new Date()).toString()
      }
      const pushNewTransferUserWallet = firebase.database().ref('users/' + userID + '/wallet/transfers').push(transferUser)
      const updateUserWallet = firebase.database().ref('users/' + userID + '/wallet').update({ totalWallet: totalWallet + discount });

      const updateInstaviceWallet = firebase.database().ref('instavice').update({ totalWallet: totalWalletInstavice });
      var transferInstavice = {
        oldWallet: InitialtotalWalletInstavice,
        newWallet: totalWalletInstavice,
        totalRevenue: discount,
        type: 'minus',
        date: (new Date()).toString()
      }
      var IVCodes = this.props.dataUser.infoUser.wallet.IVCodes || []
      IVCodes.push(infoCode)
      const updateUserCodeUser = firebase.database().ref('users/' + userID + '/wallet/').update({ 'IVCodes': IVCodes })
      const pushNewTransferInstaviceWallet = firebase.database().ref('instavice/transfers').push(transferInstavice);
      return Promise.all([pushNewTransferUserWallet, updateUserWallet, updateUserCodeUser, updateInstaviceWallet, pushNewTransferInstaviceWallet]).then(results => {
        this.props.homeAction('setDiscountCodeInformation', { value: true, infoCode: infoCode })
        this.setState({ loader: false })
        this.setState({ activate: false })
        this.setState({ discountCode: '' })
      })
    })
  }
  alertWrongCode() {
    var messageAlert = 'Discount code invalid. Please check your entry.'
    this.setState({ messageAlert: messageAlert })
    this.setState({ showAlert: true })
    this.setState({ loader: false })
  }
  alertUsedCode() {
    var messageAlert = 'Discount code expired. You can only use each code once.'
    this.setState({ messageAlert: messageAlert })
    this.setState({ showAlert: true })
    this.setState({ loader: false })
  }
  alertUsedCode2() {
    var messageAlert = 'Discount code expired. You can only use one code per booking.'
    this.setState({ messageAlert: messageAlert })
    this.setState({ showAlert: true })
    this.setState({ loader: false })
  }
  alertUsedCode3() {
    var messageAlert = 'Organizer code expired. You can only use one code per booking.'
    this.setState({ messageAlert: messageAlert })
    this.setState({ showAlert: true })
    this.setState({ loader: false })
  }
  closeAlert() {
    this.setState({ showAlert: false })
  }
  render() {
    return (
      <Grid container style={{ height: 30, marginTop: 10, }} alignItems="center" justify="center">
        <Grid item xs={10} style={{ textAlign: 'left', paddingLeft: 15 }}>
          {this.state.activate ? (
            <StyledTextField
              placeholder={this.props.product.type === 'shots' && !this.props.subscribe ? 'Organizer code' : 'Discount code'}
              autoFocus={true}
              onChange={({ target }) => this.setState({ discountCode: target.value })}
              value={this.state.discountCode}
            />
          ) : (
            <div>{(this.props.product.type === 'shots' && !this.props.subscribe) ? 'Organizer' : 'Discount'} code</div>
          )}
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'right', paddingRight: 15, color: colors.primary, cursor: 'pointer' }}>
          {this.renderActionButton()}
        </Grid>

        <Dialog
          open={this.state.showAlert}
          onBackdropClick={() => this.setState({ showAlert: false })}>
          <Alert
            textButtonSubmit="OK"
            // title={this.state.titleAlert}
            subtitle={this.state.messageAlert}
            close={() => this.closeAlert()} />
        </Dialog>

      </Grid >
    );
  }
}

const mapStateToProps = state => {
  return {
    dataUser: state.initApp,
    discountCodeUsed: state.home.discountCodeUsed,
    subscribe: state.home.subscribe,
    boosters: state.home.boosters,
    instaviceVariables: state.initApp.instaviceVariables
  };
};

export default connect(mapStateToProps, { homeAction })(DiscountCode);
