import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider'
import Alert from '../../layout/alerts/Alert';
import '../../../css/Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import HeaderBooking from './HeaderBooking'
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'
import Geocode from 'react-geocode'
import colors from '../../layout/colors'
import { Fonts } from '../../../utils/Font'
import Typography from '@material-ui/core/Typography'
import ScrollViewPage from '../../layout/scrollviews/ScrollViewPage'
import styled from 'styled-components';
import { TextField, NoSsr } from '@material-ui/core';
import * as firebase from 'firebase';
import momentTZ from 'moment-timezone';
import moment from 'moment';
import logoPowered_by_google from '../../../img/powered_by_google/powered_by_google_on_white_hdpi.png';

const StyledTextField = styled(TextField)`
  label.Mui-focused {
    color: ${colors.primary};
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: #eaeaea; 
    }
    &:hover fieldset {
      border-color: ${colors.primaryLight}; 
    }
  }
`;

Geocode.setApiKey("AIzaSyCd9p3zytwz49m6zGCEyQMEw7GChAPhkOw");
class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessories: false,
      loader: false,
      locationEntered: '',
      locationNotes: '',
      results: [{
        type: 'currentLocation',
        description: 'Current Location',
        structured_formatting: {
          main_text: 'Current Location'
        }
      }],
      titlePopup1: "We're not in this area yet",
      titlePopup2: 'Invalid address',
      titlePopupOff: '',
      text1: 'We’re working hard to bring this service to you.',
      text2: 'Please enter another location.',
      textOff: '',
      historicSearchLocation: [],
      initialResults: [{
        type: 'currentLocation',
        description: 'Current Location',
        structured_formatting: {
          main_text: 'Current Location'
        }
      }],
    };
  }
  async componentWillMount() {
    window.scrollTo(0, 0)
    var accessories = this.props.category.accessories
    if (accessories) {
      for (var acc in accessories) { accessories[acc].selected = false }
    }
    this.setState({ accessories })
    if (this.state.locationNotes === '') this.state.locationNotes = this.props.dataHome.notes
    if (this.props.dataHome.addressHomeRequest !== '') {
      var address = this.props.dataHome.addressHomeRequest
      this.setState({ textInput: address })
      this.changeLocation(address)
    }

    var historicSearchLocation = await localStorage.getItem('historicSearchLocation')
    historicSearchLocation = []

    var initialResult = this.state.initialResults
    this.setState({
      historicSearchLocation: historicSearchLocation,
      initialResults: initialResult.concat(historicSearchLocation),
      results: initialResult.concat(historicSearchLocation)
    })
  }
  async currentLocation() {
    navigator.geolocation.getCurrentPosition(
      position => {
        var pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        var region = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          latitudeDelta: 0.22,
          longitudeDelta: 0.22,
        }
        this.props.locationAction('setRegionMap', { region: region })
        const geocode = Geocode.fromLatLng(pos.lat, pos.lng)
        Promise.all([geocode]).then(results => {
          var addressComponent = results[0].results[0]
          for (var i in addressComponent.address_components) {
            var compo = addressComponent.address_components[i]
            var filterPostal = compo.types.filter(comp => comp === 'postal_code')
            if (filterPostal.length !== 0) {
              var zipComponent = compo
              break
            }
          }
          var locationAllowCar = this.props.dataUser.instaviceVariables.noCarAreas.filter(area => (pos.lat <= Math.max(area.zone.lat1, area.zone.lat2) && pos.lat >= Math.min(area.zone.lat1, area.zone.lat2) && pos.lng <= Math.max(area.zone.lng1, area.zone.lng2) && pos.lng >= Math.min(area.zone.lng1, area.zone.lng2))).length === 0

          var zipCode = Number(zipComponent.long_name)
          var matZipCodes = this.props.dataUser.instaviceVariables.areas[this.props.dataHome.productChosen.type]
          var arraycontainsZip = (matZipCodes.indexOf(zipCode) > -1)

          var location = addressComponent.formatted_address
          location = location.replace(', United States', '')
          location = location.replace(', USA', '')
          this.state.locationEntered = location
          if (!arraycontainsZip) {
            this.setState({ loader: false })
            this.setState({ cityRequested: false })
            this.setState({ titlePopupOff: this.state.titlePopup1 })
            this.setState({ textOff: this.state.text1 })
            this.setState({ showNoIVArea: true })
          } else {
            this.currentLocationOK(position, location, locationAllowCar)
          }
        }).catch(err => {
          console.log(err)
          this.setState({ loader: false })
        })
      },
      error => {
        console.log(error)
        this.setState({ loader: false })
      },
      { enableHighAccuracy: false }
    )
  }
  success(pos) {
    var crd = pos.coords;
    console.log('Your current position is:');
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
  }
  error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  async currentLocationOK(position, location, locationAllowCar) {
    var proxy_url = 'https://cors-proxy-wild.herokuapp.com/';
    var urlTimeZone = 'https://maps.googleapis.com/maps/api/timezone/json?location=' + position.coords.latitude + ',' + position.coords.longitude + '&timestamp=' + (Math.round((new Date().getTime()) / 1000)).toString() + '&key=AIzaSyDmY5dDppV_dAZDv9PYRjfAgxJKn3-U5gk'
    const timeZoneresult = await fetch(proxy_url + urlTimeZone)
    var jsonTimeZone = await timeZoneresult.json()
    var timeZoneName = jsonTimeZone.timeZoneName
    timeZoneName = timeZoneName.split(' ')
    var timeZoneName2 = ''
    for (var i in timeZoneName) {
      timeZoneName2 = timeZoneName2 + timeZoneName[i][0]
    }
    jsonTimeZone.timeZoneName2 = timeZoneName2
    var filteredCategories = Object.values(this.props.dataUser.instaviceVariables.homePage2).filter(category => category.type === this.props.category.type)
    var accessories;
    if (!!filteredCategories[0] && filteredCategories[0].accessories) {
      if (locationAllowCar) {
        accessories = filteredCategories[0].accessories.map(accessory => {
          accessory.selected = false
          return accessory
        })
      } else {
        accessories = filteredCategories[0].accessories.map(accessory => {
          accessory.selected = true
          return accessory
        })
      }
    } else {
      accessories = false
    }
    if (Object.values(jsonTimeZone).length === 0) jsonTimeZone = this.props.dataHome.timeZone
    jsonTimeZone.offSet = momentTZ.tz(moment(new Date()), 'ddd, MMM Do', jsonTimeZone.timeZoneId).format('Z')

    this.props.homeAction('setAddressHomeRequest', { value: location, accessories: accessories, locationAllowCar: locationAllowCar, jsonTimeZone: jsonTimeZone })

    this.props.locationAction('locationEntered', { value: true })
    this.state.locationEntered = location
    this.setState({ loader: false })
  }
  back() {
    this.props.bookingFlowAction('setCurrentPage', { value: 'Product' })
  }
  handleChange = name => event => {
    var value = event.target.value
    this.changeLocation(value)
  }
  handleNotes = name => event => {
    var value = event.target.value
    this.setState({ locationNotes: value })
    this.props.homeAction('setNotes', { value: this.state.locationNotes })
  }
  async changeLocation(value) {
    this.setState({ locationEntered: value })
    var proxy_url = 'https://cors-proxy-wild.herokuapp.com/';
    const apiUrl = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?key=AIzaSyDmY5dDppV_dAZDv9PYRjfAgxJKn3-U5gk&input=' + value + '&fields=formatted_address'

    const resp = await fetch(`${proxy_url}${apiUrl}`)

    const json = await resp.json()
    if (json.status === 'INVALID_REQUEST' || json.status === 'ZERO_RESULTS') {
      this.setState({ results: this.state.initialResults })
    } else {
      this.setState({ results: json.predictions })
    }

  }
  async onclickLocation(address) {
    this.setState({ loader: true })
    if (address.type === 'currentLocation' && !this.state.loader) {
      this.currentLocation()
    } else if (!this.state.loader) {
      var placeID = address.description
      var proxy_url = 'https://cors-proxy-wild.herokuapp.com/';
      var url2 = 'https://maps.googleapis.com/maps/api/geocode/json?new_forward_geocoder=true&address=' + placeID + '&key=AIzaSyDmY5dDppV_dAZDv9PYRjfAgxJKn3-U5gk'
      const result2 = await fetch(proxy_url + url2)
      const json = await result2.json()
      var locationObj = json.results[0]
      this.setState({ locationEntered: locationObj.formatted_address })
      // const geoCodeAddress = await Geocoder.geocodeAddress(address.description);
      var currentHistoricSearchLocation = this.state.historicSearchLocation
      var checkAddressExists = currentHistoricSearchLocation.filter(location => (location.id === address.id))
      if (checkAddressExists.length === 0) {
        var add = address
        add.type = 'past'
        currentHistoricSearchLocation.push(add)
        localStorage.setItem('historicSearchLocation', currentHistoricSearchLocation)
        this.setState({ historicSearchLocation: currentHistoricSearchLocation })
        var initialResults = this.state.initialResults
        initialResults.push(add)
        this.setState({ initialResults: initialResults })
        this.setState({ textInput: address.description })
      }

      var region = {
        latitude: locationObj.geometry.location.lat,
        longitude: locationObj.geometry.location.lng,
        latitudeDelta: 0.22,
        longitudeDelta: 0.22,
      }
      var addressComponent = locationObj.address_components
      var filterZip = addressComponent.filter(component => component.types.indexOf('postal_code') === 0)
      var zipCode = 0
      if (filterZip.length !== 0) {
        zipCode = Number(filterZip[0].long_name)
      }

      if (zipCode === 0) {
        this.setState({ loader: false })
        this.setState({ cityRequested: true })
        this.setState({ titlePopupOff: this.state.titlePopup2 })
        this.setState({ textOff: this.state.text2 })
        this.setState({ showNoIVArea: true })
        this.okPopupNoLocation()
      } else {
        var matZipCodes = this.props.dataUser.instaviceVariables.areas[this.props.dataHome.productChosen.type]
        var arraycontainsZip = (matZipCodes.indexOf(zipCode) > -1)
        if (!arraycontainsZip) {
          this.setState({ loader: false })
          this.setState({ cityRequested: true })
          this.setState({ titlePopupOff: this.state.titlePopup1 })
          this.setState({ textOff: this.state.text1 })
          this.setState({ showNoIVArea: true })
          this.okPopupNoLocation()
        } else {
          var latOffer = locationObj.geometry.location.lat
          var lngOffer = locationObj.geometry.location.lng
          var locationAllowCar = this.props.dataUser.instaviceVariables.noCarAreas.filter(area => (latOffer <= Math.max(area.zone.lat1, area.zone.lat2) && latOffer >= Math.min(area.zone.lat1, area.zone.lat2) && lngOffer <= Math.max(area.zone.lng1, area.zone.lng2) && lngOffer >= Math.min(area.zone.lng1, area.zone.lng2))).length === 0
          var filteredCategories = Object.values(this.props.dataUser.instaviceVariables.homePage2).filter(category => category.type === this.props.category.type)
          if (!!filteredCategories[0] && !!filteredCategories[0].accessoriese) {
            var acc;
            var accessories;
            if (locationAllowCar) {
              acc = Object.values(this.props.dataUser.instaviceVariables.homePage2).filter(category => category.type === this.props.category.type)[0].accessories
              accessories = acc ? acc.map(accessory => {
                accessory.selected = false
                return accessory
              })
                : acc
            } else {
              acc = Object.values(this.props.dataUser.instaviceVariables.homePage2).filter(category => category.type === this.props.category.type)[0].accessories
              accessories = acc ? acc.map(accessory => {
                accessory.selected = true
                accessory.price = this.props.dataUser.instaviceVariables.noCarAreas.filter(area => (latOffer <= Math.max(area.zone.lat1, area.zone.lat2) && latOffer >= Math.min(area.zone.lat1, area.zone.lat2) && lngOffer <= Math.max(area.zone.lng1, area.zone.lng2) && lngOffer >= Math.min(area.zone.lng1, area.zone.lng2)))[0].price
                return accessory
              })
                : acc
            }
          } else {
            accessories = false
          }

          var urlTimeZone = 'https://maps.googleapis.com/maps/api/timezone/json?location=' + locationObj.geometry.location.lat + ',' + locationObj.geometry.location.lng + '&timestamp=' + (Math.round((new Date().getTime()) / 1000)).toString() + '&key=AIzaSyDmY5dDppV_dAZDv9PYRjfAgxJKn3-U5gk'
          const timeZoneresult = await fetch(proxy_url + urlTimeZone)
          var jsonTimeZone = await timeZoneresult.json()
          var timeZoneName = jsonTimeZone.timeZoneName
          timeZoneName = timeZoneName.split(' ')
          var timeZoneName2 = ''
          for (var i in timeZoneName) {
            timeZoneName2 = timeZoneName2 + timeZoneName[i][0]
          }
          jsonTimeZone.timeZoneName2 = timeZoneName2
          jsonTimeZone.offSet = momentTZ.tz(moment(new Date()), 'ddd, MMM Do', jsonTimeZone.timeZoneId).format('Z')

          this.props.homeAction('setTimeZone', { value: jsonTimeZone })
          var addressOff = this.state.locationEntered
          addressOff = addressOff.replace(', USA', '')
          addressOff = addressOff.replace(', Australia', '')
          if (Object.values(jsonTimeZone).length === 0) jsonTimeZone = this.props.dataHome.timeZone
          await this.props.homeAction('setAddressHomeRequest', { value: addressOff, accessories: accessories, locationAllowCar: locationAllowCar, jsonTimeZone: jsonTimeZone })
          this.props.locationAction('setRegionMap', { region: region })
          this.props.locationAction('locationEntered', { value: true })
          this.setState({ loader: false })
        }
      }
    }
  }
  styleAccessoryRow(index) {
    if (this.props.category.accessories.filter(accessory => accessory.selected).length === 0) return { borderTopWidth: 1 }
    else {
      if (index === this.props.category.accessories.length - 1) return { borderTopWidth: 1, borderBottomWidth: 1 }
      return { borderTopWidth: 1 }
    }
  }
  tickAccessory(index, value) {
    var currentAccessories = this.state.accessories.slice(0)
    currentAccessories[index].selected = value
    this.props.homeAction('setAccessories', { value: currentAccessories })
  }
  okPopupNoLocation() {
    if (this.state.cityRequested) return this.gotIt()
    return this.setState({ showNoIVArea: false })
  }
  gotIt() {
    firebase.database().ref('onDemandIVRequests').push({
      region: this.props.dataLocation.region,
      address: this.props.addressAppt,
      infoUser: {
        userID: this.props.dataUser.userID,
      },
      date: (new Date()).toString()
    });
  }
  async closeNotes() {
    if (this.state.showNotes) {
      this.openNotes(false)
      this.setState({ showNotes: false })
    }
  }
  productColor(productSelected) {
    if (productSelected) return colors.primary
    return 'rgb(196,196,196)'
  }
  bodyContent() {
    return (
      <div style={{ marginTop: -40 }}>
        <Grid container style={styles.subtitle}>
          One of our individually vetted provider partners will be coming to the location of your choosing.
        </Grid>

        <Grid container style={{ paddingLeft: 15, paddingRight: 15 }}>
          <Grid item xs={12}>
            <NoSsr>
              <StyledTextField
                style={{ width: '100%', height: 53, opacity: (this.props.dataLocation.locationEntered) ? 0.8 : 1 }}
                id="outlined-password-input"
                label="Search for your address"
                value={this.state.locationEntered}
                onChange={this.handleChange('location')}
                margin="normal"
                autoFocus={true}
                variant="outlined"
                InputProps={{
                  readOnly: this.props.dataLocation.locationEntered,
                  style: {
                    fontFamily: Fonts.MarkOT,
                  }
                }}
              />
            </NoSsr>
            {(this.state.locationEntered.length > 1) ?
              <Grid container justify="flex-end" style={{ zIndex: 2, position: "relative", marginTop: 0 }}>
                <Grid item style={{ marginRight: 15, marginTop: -45 }}>
                  <Icon
                    onClick={() => {
                      this.setState({ locationEntered: '' });
                      this.props.locationAction('locationEntered', { value: false })
                    }}
                    style={{ color: 'grey', cursor: 'pointer' }}
                    size={16}
                  >clear</Icon>
                </Grid>
              </Grid>
              :
              null
            }
          </Grid>
        </Grid>

        {!(this.props.dataLocation.locationEntered) ?
          <div style={{ marginTop: 10 }}>
            {
              Object.values(this.state.results).map((result, i) => (
                <Grid container onClick={() => { this.onclickLocation(result) }} style={styles.rowResult} className="pointer">
                  <Grid item xs={2} style={styles.center}>
                    {
                      result.type === 'currentLocation' ?
                        <Icon color="grey" size={16}>my_location</Icon>
                        :
                        <Icon color="grey" size={16}>location_on</Icon>
                    }
                  </Grid>
                  <Grid item xs={10} style={styles.center2}>
                    {
                      result.type === 'currentLocation' ?
                        <div style={styles.mainRes}>{result.structured_formatting.main_text}</div>
                        :
                        <div>
                          <div style={styles.mainRes}>{result.structured_formatting.main_text}</div>
                          <div style={styles.secondRes}>{result.structured_formatting.secondary_text}</div>
                        </div>
                    }
                  </Grid>
                </Grid>
              ))}
            <img src={logoPowered_by_google} className="" alt="powered_by_google" />
          </div>
          :
          <Grid direction="column" style={{ paddingLeft: 15, paddingRight: 15, marginTop: -30 }}>
            <Divider style={{ opacity: 0, width: '93%', marginLeft: '3%', marginTop: 25 }} />
            <NoSsr>
              <StyledTextField
                style={{ width: '100%', height: 100 }}
                id="outlined-password-input"
                label="Apartment number, parking, gate code, etc. (optional)"
                value={this.state.locationNotes}
                onChange={this.handleNotes('notes')}
                margin="normal"
                autoFocus={true}
                variant="outlined"
                multiline
                rowsMax="4"
                InputProps={{
                  style: {
                    textAlign: 'left',
                    height: 100,
                    borderColor: colors.primary,
                    fontFamily: Fonts.MarkOT
                  }
                }}
              />
            </NoSsr>
            {
              this.state.accessories ?
                <Grid direction="column" style={{ marginTop: 10, border: '1px solid #eaeaea', borderRadius: 4 }}>

                  {Object.values(this.state.accessories).map((accessory, i) => (
                    <Grid container style={{ height: 45 }} onClick={() => this.tickAccessory(i, !accessory.selected)} className="pointer">
                      <Grid container xs={2} justify="center" alignContent="center" alignItems="center">
                        <Grid item style={{ marginTop: -3 }}><FontAwesomeIcon icon={faCheck} color={this.productColor(accessory.selected)} size="lg" /></Grid>
                      </Grid>
                      <Grid container justify='flex-start' alignContent='center' alignItems="center" xs={7}>
                        <Grid item>
                          <Typography style={{ ...styles.subtitle, marginTop: 2, fontSize: 15, color: this.productColor(accessory.selected) }}>
                            Bring a treatment {accessory.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container xs={3} justify='flex-start' alignContent='center'>
                        {
                          !this.props.dataHome.locationAllowCar ?
                            <Typography style={{ ...styles.subtitle, marginTop: 2, color: this.productColor(accessory.selected), fontSize: 15 }}>${accessory.price}</Typography>
                            : null
                        }
                      </Grid>
                    </Grid>
                  ))}
                  {
                    this.state.accessories.filter(accessory => accessory.selected).length !== 0 ?
                      <Grid container style={{ height: 45 }} onClick={() => this.props.homeAction('setLocationHighUp', { value: !this.props.dataHome.locationHighUp })} className="pointer">
                        <Grid container xs={2} justify="center" alignContent="center" alignItems="center">
                          <Grid item style={{ marginTop: -3 }}><FontAwesomeIcon icon={faCheck} color={this.productColor(this.props.dataHome.locationHighUp)} size="lg" /></Grid>
                        </Grid>
                        <Grid container justify='flex-start' alignContent='center' xs={10} alignItems="center">
                          <Grid item>
                            <Typography style={{ ...styles.subtitle, marginTop: 2, fontSize: 15, color: this.productColor(this.props.dataHome.locationHighUp) }}>
                              Access involves stairs
                    </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      : null
                  }
                </Grid>
                : null
            }
          </Grid>
        }

        <Dialog
          open={this.state.showNoIVArea}
          onBackdropClick={() => this.setState({ showNoIVArea: false })}>
          <Alert
            textButtonSubmit="Got it!"
            message={this.state.titlePopupOff + '. ' + this.state.textOff}
            close={() => this.setState({ showNoIVArea: false })} />
        </Dialog>
        <div style={{ height: 40 }}></div>
      </div>
    )
  }
  render() {
    return (
      <div className="App">
        <HeaderBooking title={'Where?'} back={this.back.bind(this)} loader={this.state.loader} journeyShow={this.props.journeyShow} />
        <ScrollViewPage
          content={this.bodyContent.bind(this)}
          loader={false}
          bookingFlow={true}
          header={false}
          styleTitle={{ fontSize: 27, fontFamily: Fonts.MarkOTBold, }}
        />
      </div>
    );
  }
}
const styles = {
  buttonBack: {
    position: 'absolute',
    top: 75,
    left: 15,
    zIndex: 20,
  },
  title: {
    fontFamily: Fonts.MarkOT,
    fontSize: 23,
    fontWeight: 'Bold',
    paddingLeft: 15,
    color: '#4a4a4a',
    marginTop: 10,
  },
  subtitle: {
    fontFamily: Fonts.MarkOT,
    fontSize: 16,
    color: colors.subtitle,
    fontWeight: 'normal',
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 15,
    marginBottom: 5,
    textAlign: 'left',
  },
  secondRes: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  mainRes: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'bold',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  rowResult: {
    flex: 1, borderBottomWidth: 1, borderColor: '#EAEAEA', paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15
  },
  textIngredient: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
    paddingLeft: 15,
  },
  textBenefit: {
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    marginTop: 10,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    paddingLeft: 15,
    height: 30,
  },
  titleBenefit: {
    fontFamily: Fonts.MarkOT,
    fontSize: 19,
    fontWeight: 'Bold',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
  },
  divider: {
    width: window.innerWidth - 30,
    height: 1,
    marginLeft: 15,
    backgroundColor: '#eaeaea',
    marginTop: 10,
    marginBottom: 10,
  },
  row: {
    textAlign: 'left',
    fontFamily: Fonts.MarkOT,
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    color: '#4a4a4a',
    paddingTop: 5
  },
  boxPrice: {
    width: 70,
    height: 25,
    borderRadius: 15,
    bottom: 20,
    right: 15,
    backgroundColor: '#FAF9F9',
    alignItems: 'center',
    fontFamily: Fonts.MarkOT,
    justifyContent: 'center',
    borderColor: '#EAEAEA',
    color: '#8CB5A1',
    paddingTop: 9,
  },
  popupNoProviders: {
    top: 0,
    height: 100,
    width: '100%',
    marginLeft: 0,
  },
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location,
  addressAppt: state.home.addressHomeRequest
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(Product);
