

import {
  SET_CURRENT_PAGE,
  SET_FROM_MAP_TO_BOOK,
  SET_FROM_INFOCLINIC_TO_BOOK,
  SET_INSTANT_BOOK,
  SET_INSTANT_BOOK_FROM_MAP,
  SET_ALMOST_DONE,
  SET_PREVIOUS_PAGE
} from '../actions/types';

const initialState = {
  currentPage: 'Product',
  fromMapToBook: false,
  fromInfoClinicToBook: false,
  instantBook: false,
  instantBookFromMap: false,
  showAlmostDone: false,
  previousPage: ''
}

const bookingFlowReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.currentPage };
    case SET_FROM_MAP_TO_BOOK:
      return { ...state, fromMapToBook: action.fromMapToBook };
    case SET_FROM_INFOCLINIC_TO_BOOK:
      return { ...state, fromInfoClinicToBook: action.fromInfoClinicToBook };
    case SET_INSTANT_BOOK:
      return { ...state, instantBook: action.instantBook };
    case SET_INSTANT_BOOK_FROM_MAP:
      return { ...state, instantBookFromMap: action.instantBookFromMap };
    case SET_PREVIOUS_PAGE:
      return { ...state, showAlmostDone: action.showAlmostDone };
    case SET_ALMOST_DONE:
      return { ...state, previousPage: action.previousPage };
    default:
      return state;
  }
}

export default bookingFlowReducer;
