import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import { Fonts } from '../../../utils/Font'
import { initApp } from '../../../actions/initAppActions'
import * as firebase from 'firebase'
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      loader: 'initial'
    };
  }
  componentWillMount() {
    this.sendSMS()
  }
  openFlags(val) {
    this.setState({ openFlags: val })
  }
  selectCountry(country) {
    this.props.initApp('setCountry', { value: country })
    this.props.openFlags(false)
  }
  handleChange = name => event => {
    var val = event.target.value
    if (val.length === 6) {
      this.verifPhone(val)
    }
    this.setState({ code: val })
  }
  sendSMS() {
    var phoneNumber = this.props.dataUser.trueVaultInfoUser.phoneNumber
    this.setState({ loader: 'initial' })
    if (!phoneNumber) {
      phoneNumber = this.props.dataLogin.email
    }
    var url = 'https://us-central1-instabo-production.cloudfunctions.net/sendSMSVerification'
    const promiseAxios = axios.get(url, {
      params: {
        phoneNumber: phoneNumber,
        countryCode: '' + this.props.dataUser.country.dial_code.replace('+', '') + '',
        userID: this.props.dataUser.userID,
      }
    })
    return Promise.all([promiseAxios]).then(results => {
      if (!results[0].data.response) {
        this.setState({ loader: 'wrongNumber' })
      } else {
        this.setState({ loader: 'sent' })
      }
    })
  }
  verifPhone(code) {
    this.setState({ loader: true })
    var url = 'https://us-central1-instabo-production.cloudfunctions.net/verifyNumber2'

    var phoneNumber = this.props.dataUser.trueVaultInfoUser.phoneNumber
    const promiseAxios = axios.get(url, {
      params: {
        phoneNumber: phoneNumber,
        countryCode: '' + this.props.dataUser.country.dial_code.replace('+', '') + '',
        userID: this.props.dataUser.userID,
        code: code,
      }
    })
    return Promise.all([promiseAxios]).then(results => {
      if (!results[0].data.response) {
        this.setState({ loader: 'error' })
        this.setState({ code: '' })
      } else {
        firebase.database().ref('users/' + this.props.dataUser.userID).update({
          'phoneNumberVerified': true,
        })
        if (this.props.dataUser.infoUser.profileCompleted) {
          if (!this.props.dataHome.productChosen.specialEvent && this.props.dataConnected.CurrentTab !== 'join') {
            this.props.initApp('showLoginModal', { value: false });
          } else {
            this.props.initApp('showLoginModal', { value: false });
          }
        } else {
          this.props.initApp('setStepLogin', { value: 'profile' });
        }
      }
    })
  }
  render() {
    return (
      <div className="App" >
        <Grid container style={{ fontFamily: Fonts.MarkOT, marginTop: 15 }}>
          Enter the code sent to {this.props.dataUser.country.dial_code} ({this.props.dataUser.trueVaultInfoUser.phoneNumber[0] + this.props.dataUser.trueVaultInfoUser.phoneNumber[1] + this.props.dataUser.trueVaultInfoUser.phoneNumber[2]}) {this.props.dataUser.trueVaultInfoUser.phoneNumber[3] + this.props.dataUser.trueVaultInfoUser.phoneNumber[4] + this.props.dataUser.trueVaultInfoUser.phoneNumber[5]}-{this.props.dataUser.trueVaultInfoUser.phoneNumber[6] + this.props.dataUser.trueVaultInfoUser.phoneNumber[7] + this.props.dataUser.trueVaultInfoUser.phoneNumber[8] + this.props.dataUser.trueVaultInfoUser.phoneNumber[9]}
        </Grid>
        <Grid container alignItems="center" justify="center" style={{ marginTop: 20, paddingLeft: 30, paddingRight: 30 }}>
          <Grid md={12} xs={12} item>
            <TextField
              style={{ width: '100%', height: 53, fontFamily: Fonts.MarkOT }}
              id="outlined-password-input"
              label="XXX-XXX"
              type="tel"
              value={this.state.code}
              onChange={this.handleChange('code')}
              // autoComplete="phone-number"
              margin="normal"
              autoFocus={true}
              color="secondary"
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center" style={{ marginTop: 15, paddingLeft: 30, paddingRight: 30 }}>
          <Grid md={12} xs={12} item>
            <div style={{ fontFamily: Fonts.MarkOT }}>
              {
                this.state.loader ?
                  <div >Verifying code...</div>
                  : this.state.loader === 'initial' ?
                    <div >SMS being sent...</div>
                    : this.state.loader === 'error' ?
                      <div >Wrong code</div>
                      : this.state.loader === 'wrongNumber' ?
                        <div >error, verify your number</div>
                        : this.state.loader === 'sent' ?
                          <div >SMS sent.</div>
                          : null
              }
            </div>
            {
              this.state.loader === 'initial' || this.state.loader ?
                <CircularProgress color="primary" size={24} opacity={50} style={{ marginTop: 15 }} />
                : null
            }
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="center">
          {
            this.state.error ?
              <div style={{ fontSize: 14, color: '#4a4a4a', marginTop: 20, fontFamily: Fonts.MarkOT }}>Wrong verification code, please try again</div>
              :
              null
          }
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { initApp })(SignIn);
