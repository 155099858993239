import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import '../../../css/Home.css';
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { locationAction } from '../../../actions/locationActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import colors from '../../layout/colors'
import { Fonts } from '../../../utils/Font'

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: ['Everyone pays for themselves', 'I will pay for everyone'],
      options2: ['The patient will pay by himself/herself', 'I will pay for the patient'],
      optionsDisplayed: [],
      optionSelected: ''
    };
  }
  componentWillMount() {
    this.props.onRef(this)
    this.loadList()
  }
  loadList() {
    var optionsDisplay = this.state.options
    if (this.props.dataHome.numberUnit === 1) {
      optionsDisplay = this.state.options2
    }
    this.setState({ optionsDisplayed: optionsDisplay })

    if (!!this.props.dataHome.paymentOption.value) {
      this.setState({ optionSelected: this.props.dataHome.paymentOption.text })
    } else {
      // .setState({optionSelected:optionsDisplay[0]})
    }
  }
  reset() {
    this.setState({ optionSelected: '' })
  }
  tick(option) {
    this.setState({ optionSelected: option })
    var optionObj = {}
    optionObj.text = option
    optionObj.value = 'single'
    if (option === 'I will pay for everyone' || option === 'I will pay for the patient') {
      optionObj.value = 'group'
    }
    this.props.homeAction('setPaymentOption', { value: optionObj })
  }
  render() {
    return (
      <Grid container row alignContent="center" justify="center" className="borderOff pointer" style={{ width: '96%', marginLeft: '3%', marginTop: 20, borderRadius: 7 }}>
        <Grid container column style={{ margin: 5 }}>
          {this.state.optionsDisplayed.map((option, i) => (
            <Grid container style={{ height: 45 }} onClick={() => { this.tick(option) }} className="pointer">
              <Grid container xs={2} justify="center" alignContent="center" style={{}}>
                {
                  option === this.state.optionSelected ?
                    <Grid item><FontAwesomeIcon icon={faCheck} color={colors.primary} size="lg" /></Grid>
                    :
                    <Grid item><FontAwesomeIcon icon={faCheck} color='#eaeaea' size="lg" /></Grid>
                }
              </Grid>
              <Grid container justify='flex-start' alignContent='center' xs={10} style={styles.subtitle}>
                <Grid item>{option}</Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  }
}
const styles = {
  buttonBack: {
    position: 'absolute',
    top: 75,
    left: 15,
    zIndex: 20,
  },
  buttonOff: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 23,
    width: 27,
    borderRadius: 13.5,
    textAlign: 'center',
    paddingTop: 3,
    borderColor: '#C1DACE',
    backgroundColor: '#eaeaea',
    borderWidth: 2
  },
  buttonOn: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 23,
    width: 27,
    borderRadius: 13.5,
    textAlign: 'center',
    paddingTop: 3,
    borderColor: '#C1DACE',
    backgroundColor: '#C1DACE',
    borderWidth: 2
  },
  title: {
    fontFamily: 'Avenir',
    fontSize: 23,
    fontWeight: 'Bold',
    paddingLeft: 15,
    color: '#4a4a4a',
    marginTop: 10,
  },
  subtitle: {
    fontFamily: Fonts.MarkOT,
    fontSize: 14,
    paddingLeft: 5,
    textAlign: 'left',
    color: '#4a4a4a',
  },
  secondRes: {
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  mainRes: {
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'bold',
    color: '#4a4a4a',
    textAlign: 'left',
  },
  rowResult: {
    flex: 1, borderBottomWidth: 1, borderColor: '#EAEAEA', paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15
  },
  textIngredient: {
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
    paddingLeft: 15,
  },
  textBenefit: {
    fontFamily: 'Avenir',
    fontSize: 17,
    marginTop: 10,
    fontWeight: 'normal',
    color: '#4a4a4a',
    textAlign: 'left',
    paddingLeft: 15,
    height: 30,
  },
  titleBenefit: {
    fontFamily: 'Avenir',
    fontSize: 19,
    fontWeight: 'Bold',
    color: '#4a4a4a',
    textAlign: 'left',
    height: 30,
  },
  divider: {
    width: window.innerWidth - 30,
    height: 1,
    marginLeft: 15,
    backgroundColor: '#eaeaea',
    marginTop: 10,
    marginBottom: 10,
  },
  row: {
    textAlign: 'left',
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    color: '#4a4a4a',
    paddingTop: 5
  },
  boxPrice: {
    width: 70,
    height: 25,
    borderRadius: 15,
    bottom: 20,
    right: 15,
    backgroundColor: '#FAF9F9',
    alignItems: 'center',
    fontFamily: 'Avenir',
    justifyContent: 'center',
    borderColor: '#EAEAEA',
    color: '#8CB5A1',
    paddingTop: 9,
  }
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, { bookingFlowAction, locationAction, homeAction })(Product);
