import React, { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import { Fonts } from '../../../utils/Font'

export default class ScrollViewPage extends PureComponent {
  render() {
    return (
      <div>
        <Grid>
          <Grid container direction="row">
            {
              this.props.back && !this.props.backAction ?
                <Grid container alignItems="center" justify="center" xs={2} md={1} item onClick={() => { this.props.history.goBack() }} className="pointer">
                  <Grid item> <Icon style={styles.backButton}>arrow_back</Icon> </Grid>
                </Grid>
                : this.props.back && !!this.props.backAction ?
                  <Grid container alignItems="center" justify="center" xs={2} md={1} item onClick={() => { this.props.backAction() }} className="pointer">
                    <Grid item> <Icon style={styles.backButton}>arrow_back</Icon> </Grid>
                  </Grid>
                  :
                  <Grid xs={1} md={1} ></Grid>
            }
            {
              this.props.back ?
                <Grid item xs={false} md={1}></Grid>
                : null
            }
            {
              this.props.title !== undefined ?
                <Grid xs={9} md={9} item className="containerTitle">
                  <div style={{ color: '#4a4a4a', fontFamily: Fonts.MarkOTBold, fontSize: 24, marginTop: 10 }}>{this.props.title}</div>
                </Grid>
                : null
            }
            {
              !this.props.back && !!this.props.newAppt ?
                <Grid xs={2} md={2}><div style={{ marginTop: 12 }}>{this.props.newAppt()}</div></Grid>
                :
                null
            }
          </Grid>
          <Grid>
            {
              !this.props.header ?
                <div>
                  <Grid direction="row" container style={{ marginBottom: 20 }}>
                    <Grid xs={1} md={1} ></Grid>
                    <Grid xs={10} md={11} style={{ color: '#6a6a6a', fontFamily: Fonts.MarkOT, fontSize: 16, marginTop: 10, width: '70vw', maxWidth: 500, textAlign: 'left' }}>
                      {this.props.subtitle}
                    </Grid>
                  </Grid>
                </div>
                :
                <div>
                  {this.props.header()}
                </div>
            }
            {this.props.bookingFlow ? null : <Divider style={{ width: '90vw', marginLeft: '5vw', marginTop: 20 }} light />}
          </Grid>
          <br />
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} style={this.props.bookingFlow ? { width: '95vw', maxWidth: 600 } : null}>
              {this.props.content()}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}
const styles = {
  backButton: {
    height: 25,
    textAlign: 'left',
    color: '#4a4a4a',
    marginTop: 13
  },
}