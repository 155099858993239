export default {
  primary: '#5DB380',
  primaryLight: '#78D19C',
  secondary: '#F5A172',
  terciary: '#5A5E74',
  title: '#3c3c3c',
  subtitle: '#656565',
  subtitleLight: '#4A4A4A',
  subtitleCard: '#AFAFAF',
  footerOff: '#3C3C3C',
  backdropModal: '#3C3C3C',
  placeHolder1: '#eaeaea',
  placeHolder2: '#f6f6f6',
}