import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../css/App.css';
import ReactPixel from 'react-facebook-pixel';
import Grid from '@material-ui/core/Grid';
import ScrollViewPage from './layout/scrollviews/ScrollViewPage';
import { Fonts } from '../utils/Font';
import CardCategory from './elementsHome/CardCategory';

class Home extends Component {
  componentWillMount() {
    ReactPixel.pageView()
  }
  homeComponent() {
    return (
      <div className="categoryContainer">
        <Grid container alignItems="center" justify="center">
          {Object.values(this.props.dataUser.instaviceVariables.homePage2).filter(category => category.displayInApp2).map((category, i) => (
            <Grid md={6} sm={9} xs={11} item key={i} >
              <CardCategory {...this.props} product={category.card} category={category} productCard={false} imgName={category.type} />
            </Grid>
          ))}
          {
            Object.values(this.props.dataUser.instaviceVariables.homePage2).length % 2 === 1 && <Grid md={6} sm={0} xs={0} item></Grid>
          }
        </Grid>
      </div>
    );
  }
  render() {
    return (
      <div className="App">
        <ScrollViewPage
          content={this.homeComponent.bind(this)}
          title={this.props.dataUser.instaviceVariables.homePageTitle2}
          subtitle={this.props.dataUser.instaviceVariables.homePageSubtitle}
          loader={false}
          header={false}
          styleTitle={{ fontSize: 27, fontFamily: Fonts.MarkOTBold, }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location
})
export default connect(mapStateToProps, {})(Home);
