import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ReactPixel from 'react-facebook-pixel';
import '../../../css/Home.css';
import { bookingFlowAction } from '../../../actions/bookingFlowActions'
import { homeAction } from '../../../actions/homeActions'
import { initApp } from '../../../actions/initAppActions'
import axios from 'axios'
import * as firebase from 'firebase'
import SignInFlow from '../../elementsProfile/elementsSignin/SignInFlow';
import DatePicker from '../modulesBooking/DatePicker';
import ButtonBooking from '../../layout/buttons/ButtonBooking'
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import mixpanel from 'mixpanel-browser'
const mixPanelToken = 'bef3efb4cd721628459b35e7a4c1abb6'
mixpanel.init(mixPanelToken);

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'product',
      product: {},
      loader: false,
      showAddCreditCard: false,
      mostExpensiveShots: 0,
      CBrequired: false,
      changeTime: false,
      showAlertTimePicker: false,
      showAlertDouble: false,
      straightToCard: false,
      showAlertApplePay: false
    };
  }
  componentWillMount() {
    if ((this.props.dataHome.productChosen.type === 'shots' && this.props.dataHome.subscribe && this.props.dataHome.creditCardRequired) || (this.props.dataHome.productChosen.type !== 'shots' && (this.props.dataHome.paymentOption.value === 'single' || (this.props.dataHome.paymentOption.value === 'group' && !this.props.dataHome.subscribe)) && this.props.dataHome.creditCardRequired)) {
      this.setState({ CBrequired: true })
    }
  }
  async submitIV() {
    var defaultCB = {}
    var now = Number(new Date())
    var dateAppt = Number(new Date(this.props.dataHome.dateAppt))

    var offSet = this.props.dataHome.timeZone.offSet
    offSet = offSet.split(':')
    offSet = Number(offSet[0])

    var dateApptInlocalTime = dateAppt + offSet * 60 * 60 * 1000
    var nowInLocalTime = now + offSet * 60 * 60 * 1000
    var offsetVariable = this.props.dataUser.instaviceVariables.timePicker.nextTimeSlot * 60 * 1000

    if (dateApptInlocalTime - offsetVariable < nowInLocalTime) {
      this.setState({ showAlertTimePicker: true })
    } else {
      if (!this.state.CBrequired) {
        defaultCB.id = ''
        this.submitIVPart2(defaultCB)
      } else if (!this.props.dataUser.infoUser.wallet.cards[this.props.dataUser.defaultCB.id].zipCode && this.props.dataUser.defaultCB.brand !== 'applePay' && this.props.dataUser.defaultCB.brand !== 'googlePay') {
        this.setState({ showAlertDouble: true })
      } else {
        if (this.props.dataUser.defaultCB.brand === 'applePay') {
          this.setState({ showAlertApplePay: true })
        } else if (this.props.dataUser.defaultCB.brand === 'googlePay') {
          this.setState({ showAlertApplePay: true })
        } else {
          this.checkAvailabilities(this.props.dataUser.defaultCB)
        }
      }
    }
  }
  async checkAvailabilities(defaultCB) {
    try {
      if (this.props.dataHome.providersPreference.type === 'pick' || this.props.dataHome.providersPreference.type === 'rebook') {
        var url = 'https://us-central1-instabo-production.cloudfunctions.net/checkAvailabilities'
        var results = await (axios.get(url, {
          params: {
            nurseID: this.props.dataHome.dateSlotAppt.nurseID,
            dateAppt: this.props.dataHome.dateSlotAppt.date
          }
        }))
        if (!results.data.check) {
          await this.props.loaderAction(false)
          return this.setState({ showAlert: true, messageAlert: "We’re sorry, this appointment slot is no longer available. Please pick an alternative time." })
        }
        return this.submitIVPart2(defaultCB)
      }
      return this.submitIVPart2(defaultCB)
    } catch (err) {
      firebase.database().ref('users/' + this.props.dataUser.userID + '/logs1/').push(err)
    }
  }
  submitIVPart2(defaultCB) {
    mixpanel.track('Click on confirm booking', { 'userID': this.props.dataUser.userID });
    ReactPixel.track('Click on confirm booking', { 'userID': this.props.dataUser.userID });
    this.setState({ loader: true })
    var now = (new Date()).toString()

    var dateAppt = this.props.dataHome.dateAppt
    if (this.props.dataHome.providersPreference.type === 'pick' || this.props.dataHome.providersPreference.type === 'rebook') dateAppt = this.props.dataHome.dateSlotAppt.dateNew

    var offer = {
      inviteCode: this.props.dataHome.inviteCodeEvent.toLowerCase(),
      infoUser: {
        userID: this.props.dataUser.userID,
        creditCard: defaultCB,
        tokenPayment: this.props.dataUser.infoUser.wallet.tokenCusStripe,
        idCardStripe: defaultCB.id,
        organizerID: this.props.dataUser.userID,
      },
      infoOffer: {
        availability: dateAppt,
        numberUnit: this.props.dataHome.numberUnit,
        date: dateAppt,
        notes: this.props.dataHome.notes,
        boostersSelected: this.props.dataHome.boostersSelected,
        boosters: this.props.dataHome.boosters,
        locationAllowCar: this.props.dataHome.locationAllowCar,
        accessories: this.props.dataHome.accessories,
        locationHighUp: (!!this.props.dataHome.locationHighUp) ? this.props.dataHome.locationHighUp : false,
        paymentOption: this.props.dataHome.paymentOption.value,
        pricePerUnit: this.props.dataHome.pricePerUnit,
        receiver: this.props.dataHome.receiver,
        numberUnitOrderedPerPatient: this.props.dataHome.numberUnitOrderedPerPatient,
        product: this.props.dataHome.productChosen,
        durationEvent: this.props.dataHome.durationEvent,
        subscribe: this.props.dataHome.subscribe,
        discountCodeUsed: this.props.dataHome.discountCodeUsed,
        infoDiscountCode: this.props.dataHome.infoCode,
        creditCardRequired: true,
        secondaryProducts: this.props.dataHome.secondaryProducts,
        attendeeProduct: this.props.dataHome.attendeeProduct,
        additionalProducts: this.props.dataHome.additionalProduct
      },
      pricing: this.props.pricing,
      location: {
        lat: this.props.dataLocation.region.latitude,
        lng: this.props.dataLocation.region.longitude,
        timeZone: this.props.dataHome.timeZone,
        address: this.props.dataHome.addressHomeRequest,
        businessName: this.props.dataHome.businessName,
        businessPhone: this.props.dataHome.businessPhone,
      },
      status: 'created',
      dateCreation: now,
      lastUpdate: now
    }

    firebase.database().ref('offersNurse').push(offer)
      .then((snap) => {
        const key = snap.key;
        offer.offerID = key
        var allInviteCodes;
        this.props.homeAction('setIdAppt', { value: key })
        if (this.props.dataHome.productChosen.type === 'shots') {
          allInviteCodes = this.props.dataUser.instaviceVariables.allInviteCodes || []
          allInviteCodes.push(this.props.dataHome.inviteCodeEvent.toLowerCase())
          firebase.database().ref('instavice/variables').update({
            'allInviteCodes': allInviteCodes
          })
        } if (this.props.dataHome.productChosen.type === 'covid') {
          const confirmedUser = {
            'userID': this.props.dataUser.userID,
            'userOfferID': key,
            'consentCompleted': true,
            'tokenPayment': this.props.dataUser.infoUser.wallet.tokenCusStripe,
            'idCardStripe': defaultCB.id,
            'dateSubscription': now,
            'status': 'created',
            'command': {
              secondaryProducts: this.props.dataHome.secondaryProducts,
              attendeeProduct: this.props.dataHome.attendeeProduct,
              additionalProducts: this.props.dataHome.additionalProduct,
              product: this.props.dataHome.productChosen
            }
          }

          firebase.database().ref('offersNurse/' + key + '/usersConfirmed/' + this.props.dataUser.userID).update(confirmedUser)

          // add mock other users
          if (this.props.dataHome.numberUnit > 1) {
            const confirmedUserPromises = []

            for (let i = 1; i < this.props.dataHome.numberUnit; i++) {
              const userPromise = firebase.database().ref('offersNurse/' + key + '/usersConfirmed/' + this.props.dataUser.userID + i).update(confirmedUser)
              confirmedUserPromises.push(userPromise)
            }
            Promise.all(confirmedUserPromises).then(results => {
              console.log('---result covid appointment---', results)
            })
          }

          firebase.database().ref('offersNurse/' + key + '/infoUser/').update({
            'userOfferID': this.props.dataUser.userID,
          })
          if (this.props.dataHome.numberUnit !== 1 && this.props.dataHome.inviteCodeEvent) {
            allInviteCodes = this.props.dataUser.instaviceVariables.allInviteCodes || []
            allInviteCodes.push(this.props.dataHome.inviteCodeEvent.toLowerCase())
            firebase.database().ref('instavice/variables').update({
              'allInviteCodes': allInviteCodes
            })
          }
        } else {
          firebase.database().ref('offersNurse/' + key + '/usersConfirmed/' + this.props.dataUser.userID).update({
            'userID': this.props.dataUser.userID,
            'userOfferID': key,
            'consentCompleted': false,
            'tokenPayment': this.props.dataUser.infoUser.wallet.tokenCusStripe,
            'idCardStripe': defaultCB.id,
            'dateSubscription': now,
            'status': 'created',
            'command': {
              secondaryProducts: this.props.dataHome.secondaryProducts,
              attendeeProduct: this.props.dataHome.attendeeProduct,
              additionalProducts: this.props.dataHome.additionalProduct,
              product: this.props.dataHome.productChosen
            }
          })
          firebase.database().ref('offersNurse/' + key + '/infoUser/').update({
            'userOfferID': this.props.dataUser.userID,
          })
          if (this.props.dataHome.numberUnit !== 1) {
            allInviteCodes = this.props.dataUser.instaviceVariables.allInviteCodes || []
            allInviteCodes.push(this.props.dataHome.inviteCodeEvent.toLowerCase())
            firebase.database().ref('instavice/variables').update({
              'allInviteCodes': allInviteCodes
            })
          }
        }
        firebase.database().ref('offersNurse/' + key).update({
          'offerID': key
        })
        firebase.database().ref('users/' + this.props.dataUser.userID + '/appointments/' + key).update(offer)
        this.props.bookingFlowAction('setCurrentPage', { value: 'finalSummary' })
      })
  }
  async submitAttendance() {
    var defaultCB = {}
    if (!this.state.CBrequired) {
      defaultCB.id = ''
      this.submitAttendancePart2(defaultCB)
    } else if (!this.props.dataUser.infoUser.wallet.cards[this.props.dataUser.defaultCB.id].zipCode && this.props.dataUser.defaultCB.brand !== 'applePay' && this.props.dataUser.defaultCB.brand !== 'googlePay') {
      this.setState({ showAlertDouble: true })
    } else {
      if (this.props.dataUser.defaultCB.brand === 'applePay') {
        this.setState({ showAlertApplePay: true })
      } else if (this.props.dataUser.defaultCB.brand === 'googlePay') {
        this.setState({ showAlertApplePay: true })
      } else {
        this.submitAttendancePart2(this.props.dataUser.defaultCB)
      }
    }
  }
  submitAttendancePart2(defaultCB) {
    this.setState({ loader: true })
    mixpanel.track('Click on confirm attendance boosters', { 'userID': this.props.dataUser.userID });
    ReactPixel.track('Click on confirm attendance boosters', { 'userID': this.props.dataUser.userID });
    var now = (new Date()).toString()
    var offer = {
      inviteCode: this.props.dataHome.inviteCodeEvent.toLowerCase(),
      offerID: this.props.dataHome.idAppt,
      infoUser: {
        userID: this.props.dataUser.userID,
        creditCard: defaultCB,
        tokenPayment: this.props.dataUser.infoUser.wallet.tokenCusStripe,
        idCardStripe: defaultCB.id,
        organizerID: this.props.dataHome.organizerID,
      },
      infoOffer: {
        availability: this.props.dataHome.dateAppt,
        numberUnit: this.props.dataHome.numberUnit,
        date: this.props.dataHome.dateAppt,
        notes: this.props.dataHome.notes,
        providersPreference: this.props.dataHome.providersPreference,
        boostersSelected: this.props.dataHome.boostersSelected,
        boosters: this.props.dataHome.boosters,
        paymentOption: this.props.dataHome.paymentOption.value,
        pricePerUnit: this.props.dataHome.pricePerUnit,
        receiver: this.props.dataHome.receiver,
        nurseBooked: this.props.dataHome.dateSlotAppt.nurseID,
        infoSlot: this.props.dataHome.dateSlotAppt,
        product: this.props.dataHome.productChosen,
        durationEvent: this.props.dataHome.durationEvent,
        subscribe: this.props.dataHome.subscribe,
        creditCardRequired: true,
        secondaryProducts: this.props.dataHome.secondaryProducts,
        attendeeProduct: this.props.dataHome.attendeeProduct,
        additionalProducts: this.props.dataHome.additionalProduct
      },
      location: {
        lat: this.props.dataLocation.region.latitude,
        lng: this.props.dataLocation.region.longitude,
        timeZone: this.props.dataHome.timeZone,
        address: this.props.dataHome.addressHomeRequest,
        businessName: this.props.dataHome.businessName,
        businessPhone: this.props.dataHome.businessPhone,
      },
      status: 'created',
      dateCreation: now,
      lastUpdate: now
    }

    firebase.database().ref('users/' + this.props.dataUser.userID + '/appointments/').push(offer).then((snap) => {
      const key = snap.key;
      const addUserToOrganizer = firebase.database().ref('users/' + this.props.dataHome.organizerID + '/appointments/' + this.props.dataHome.idAppt + '/usersConfirmed').push({
        'userID': this.props.dataUser.userID,
        'userOfferID': key,
        'tokenPayment': this.props.dataUser.infoUser.wallet.tokenCusStripe,
        'idCardStripe': defaultCB.id,
        'dateSubscription': now,
        'status': 'created'
      })
      const addUserToOffer = firebase.database().ref('offersNurse/' + this.props.dataHome.idAppt + '/usersConfirmed').push({
        'userID': this.props.dataUser.userID,
        'userOfferID': key,
        'tokenPayment': this.props.dataUser.infoUser.wallet.tokenCusStripe,
        'idCardStripe': defaultCB.id,
        'dateSubscription': now,
        'status': 'created'
      })

      Promise.all([addUserToOrganizer, addUserToOffer]).then(results => {
        this.props.bookingFlowAction('setCurrentPage', { value: 'finalSummary' })
      })
    })

  }
  async submitAttendanceIV() {
    var defaultCB = {}
    if (!this.state.CBrequired) {
      defaultCB.id = ''
      this.submitAttendanceIVPart2(defaultCB)
    } else if (!this.props.dataUser.infoUser.wallet.cards[this.props.dataUser.defaultCB.id].zipCode && this.props.dataUser.defaultCB.brand !== 'applePay' && this.props.dataUser.defaultCB.brand !== 'googlePay') {
      this.setState({ showAlertDouble: true })
    } else {
      if (this.props.dataUser.defaultCB.brand === 'applePay') {
        this.setState({ showAlertApplePay: true })
      } else if (this.props.dataUser.defaultCB.brand === 'googlePay') {
        this.setState({ showAlertApplePay: true })
      } else {
        this.submitAttendanceIVPart2(this.props.dataUser.defaultCB)
      }
    }
  }
  submitAttendanceIVPart2(defaultCB) {
    this.setState({ loader: true })
    mixpanel.track('Click on confirm attendance IV', { 'userID': this.props.dataUser.userID });
    ReactPixel.track('Click on confirm attendance IV', { 'userID': this.props.dataUser.userID });
    var now = (new Date()).toString()
    var offer = {
      inviteCode: this.props.dataHome.inviteCodeEvent.toLowerCase(),
      offerID: this.props.dataHome.idAppt,
      infoUser: {
        userID: this.props.dataUser.userID,
        creditCard: defaultCB,
        tokenPayment: this.props.dataUser.infoUser.wallet.tokenCusStripe,
        idCardStripe: defaultCB.id,
        organizerID: this.props.dataHome.organizerID,
      },
      infoOffer: {
        availability: this.props.dataHome.dateAppt,
        numberUnit: this.props.dataHome.numberUnit,
        date: this.props.dataHome.dateAppt,
        notes: this.props.dataHome.notes,
        pricePerUnit: this.props.dataHome.pricePerUnit,
        paymentOption: this.props.dataHome.paymentOption.value,
        product: this.props.dataHome.productChosen,
        durationEvent: this.props.dataHome.durationEvent,
        subscribe: this.props.dataHome.subscribe,
        discountCodeUsed: this.props.dataHome.discountCodeUsed,
        infoDiscountCode: this.props.dataHome.infoCode,
        creditCardRequired: true,
        secondaryProducts: this.props.dataHome.secondaryProducts,
        attendeeProduct: this.props.dataHome.attendeeProduct,
        additionalProducts: this.props.dataHome.additionalProduct
      },
      pricing: this.props.pricing,
      nurseID: this.props.dataHome.nurseInfo.nurseID,
      nurseInfo: this.props.dataHome.nurseInfo.nurseInfo,
      location: {
        lat: this.props.dataLocation.region.latitude,
        lng: this.props.dataLocation.region.longitude,
        address: this.props.dataHome.addressHomeRequest,
        businessName: this.props.dataHome.businessName,
        timeZone: this.props.dataHome.timeZone,
        businessPhone: this.props.dataHome.businessPhone,
      },
      status: 'created',
      dateCreation: now,
      lastUpdate: now
    }

    firebase.database().ref('users/' + this.props.dataUser.userID + '/appointments/').push(offer).then((snap) => {
      const key = snap.key;
      const addUserToOffer = firebase.database().ref('offersNurse/' + this.props.dataHome.idAppt + '/usersConfirmed/' + this.props.dataUser.userID).update({
        'userID': this.props.dataUser.userID,
        'userOfferID': key,
        'consentCompleted': false,
        'tokenPayment': this.props.dataUser.infoUser.wallet.tokenCusStripe,
        'idCardStripe': defaultCB.id,
        'dateSubscription': now,
        'status': 'created',
        'command': {
          secondaryProducts: this.props.dataHome.secondaryProducts,
          attendeeProduct: this.props.dataHome.attendeeProduct,
          additionalProducts: this.props.dataHome.additionalProduct,
          product: this.props.dataHome.productChosen
        }
      })

      Promise.all([addUserToOffer]).then(results => {
        this.props.bookingFlowAction('setCurrentPage', { value: 'finalSummary' })
      })
    })

  }
  async signIn() {
    window.scrollTo(0, 0)
    await this.props.initApp('setStepLogin', { value: 'phone' });
    this.props.initApp('showLoginModal', { value: true });
  }
  closeAlertTime() {
    this.setState({ showAlertTimePicker: false })
    this.setState({ showAlertDouble: false })
  }
  changeTime() {
    this.setState({ changeTime: true })
  }
  async confirmChangeTime() {
    await this.timePicker.doneTime()
    await this.setState({ showAlertTimePicker: false })
    this.setState({ changeTime: false })
  }
  async openPayments() {
    await this.setState({ showAlertDouble: false })
    this.props.openPayments()
  }
  closeSignIn() {
  }
  downloadApp() {
    window.open('https://itunes.apple.com/us/app/wlnss/id1420304128?mt=8', '_blank');
  }
  render() {
    return (
      <Grid container justify="center">
        {
          this.state.loader ?
            <ButtonBooking
              bookingFlow
              text="Next"
              click={() => { }}
              loading
            />
            : !this.props.dataUser.userConnected ?
              <ButtonBooking
                bookingFlow
                text="Sign in to proceed"
                click={() => this.signIn()}
              />
              : (
                this.props.dataUser.numberOfCB === 0 && this.props.dataHome.productChosen.type !== 'shots' && ((this.props.dataHome.paymentOption.value === 'single' && this.props.dataHome.receiver === 'me' && !this.props.dataHome.subscribe) || (this.props.dataHome.paymentOption.value === 'single' && this.props.dataHome.subscribe) ||
                  (!this.props.dataHome.subscribe && this.props.dataHome.paymentOption.value === 'group')) && this.props.dataHome.creditCardRequired) || (this.props.dataUser.numberOfCB === 0 && this.props.dataHome.productChosen.type === 'shots' && this.props.dataHome.subscribe && this.props.dataHome.creditCardRequired) ?
                <div
                  className="VbtnOff">
                  {
                    (this.props.dataHome.productChosen.type !== 'shots' && !this.props.dataHome.subscribe) || (this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.subscribe && this.props.dataHome.paymentOption.value === 'single') ?
                      <Grid container direction='row' justify='center' alignItems="center" style={{ height: '100%' }}>
                        <Grid item>
                          <Icon style={{ color: 'white', marginRight: 15, height: 25, width: 25 }}>lock</Icon>
                        </Grid>
                        <Grid item>
                          Book now &amp; pay later
                </Grid>
                      </Grid>
                      : (this.props.dataHome.productChosen.type === 'shots' && !!this.props.dataHome.boostersSelected[0].oldPrice && this.props.dataHome.subscribe) || (this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.subscribe && this.props.dataHome.paymentOption.value === 'group') ?
                        <Grid container direction='row' justify='center' alignItems="center" style={{ height: '100%' }}>
                          <Grid item>
                            <Icon style={{ color: 'white', marginRight: 15, height: 25, width: 25 }}>lock</Icon>
                          </Grid>
                          <Grid item>
                            Confirm attendance
                          </Grid>
                        </Grid>
                        : null
                  }
                </div>
                : this.props.dataHome.productChosen.type !== 'shots' && !this.props.dataHome.subscribe ?
                  <div
                    className="Vbtn"
                    onClick={() => this.submitIV()}>
                    <Grid container direction='row' justify='center' alignItems="center" style={{ height: '100%' }}>
                      <Grid item>
                        <Icon style={{ color: 'white', marginRight: 15, height: 25, width: 25 }}>lock</Icon>
                      </Grid>
                      <Grid item>
                        Book now &amp; pay later
                      </Grid>
                    </Grid>
                  </div>
                  : this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.subscribe && this.props.dataHome.paymentOption.value === 'group' ?
                    <div
                      className="Vbtn"
                      onClick={() => this.submitAttendanceIV()}>
                      <Grid container direction='row' justify='center' alignItems="center" style={{ height: '100%' }}>
                        <Grid item>
                          <Icon style={{ color: 'white', marginRight: 15, height: 25, width: 25 }}>lock</Icon>
                        </Grid>
                        <Grid item>
                          Confirm attendance
                        </Grid>
                      </Grid>
                    </div>
                    : this.props.dataHome.productChosen.type !== 'shots' && this.props.dataHome.subscribe && this.props.dataHome.paymentOption.value === 'single' ?
                      <div
                        className="Vbtn"
                        onClick={() => this.submitAttendanceIV()}>
                        <Grid container direction='row' justify='center' alignItems="center" style={{ height: '100%' }}>
                          <Grid item>
                            <Icon style={{ color: 'white', marginRight: 15, height: 25, width: 25 }}>lock</Icon>
                          </Grid>
                          <Grid item>
                            Book now &amp; pay later
                          </Grid>
                        </Grid>
                      </div>
                      : this.props.dataHome.productChosen.type === 'shots' && !this.props.dataHome.subscribe ?
                        <div
                          className="Vbtn"
                          onClick={() => this.submitIV()}>
                          <Grid container direction='row' justify='center' alignItems="center" style={{ height: '100%' }}>
                            <Grid item>
                              <Icon style={{ color: 'white', marginRight: 15, height: 25, width: 25 }}>lock</Icon>
                            </Grid>
                            <Grid item>
                              Book event
                            </Grid>
                          </Grid>
                        </div>
                        : this.props.dataHome.productChosen.type === 'shots' && this.props.dataHome.subscribe ?
                          <div
                            className="Vbtn"
                            onClick={() => this.submitAttendance()}>
                            <Grid container direction='row' justify='center' alignItems="center" style={{ height: '100%' }}>
                              <Grid item>
                                <Icon style={{ color: 'white', marginRight: 15, height: 25, width: 25 }}>lock</Icon>
                              </Grid>
                              <Grid item>
                                Confirm attendance
                              </Grid>
                            </Grid>
                          </div>
                          : null
        }

        <Dialog
          fullScreen
          open={this.props.dataUser.showLoginModal}
          TransitionComponent={Transition}
        >
          <SignInFlow initiaStep={'phone'} close={this.closeSignIn.bind(this)} journeyShow={this.props.journeyShow} />
        </Dialog>

        <Dialog
          open={this.state.showAlertTimePicker}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ fontFamily: 'Avenir', }} id="alert-dialog-title">Your appointment time needs to be at least {this.props.dataUser.instaviceVariables.timePicker.nextTimeSlot} min from now.</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ fontFamily: 'Avenir', }} id="alert-dialog-description">
              Please select a new time slot.
            </DialogContentText>
            {
              this.state.changeTime ?
                <DatePicker onRef={ref => (this.timePicker = ref)} />
                : null
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeAlertTime()} color="primary" style={{ fontFamily: 'Avenir', }} autoFocus>
              Cancel
            </Button>
            {
              this.state.changeTime ?
                <Button onClick={() => this.confirmChangeTime()} color="primary" style={{ fontFamily: 'Avenir', }} autoFocus>
                  Confirm changed
            </Button>
                :
                <Button onClick={() => this.changeTime()} color="primary" style={{ fontFamily: 'Avenir', }} autoFocus>
                  Change time
            </Button>
            }
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.showAlertDouble}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ fontFamily: 'Avenir', }} id="alert-dialog-title">Your zip code is missing on this payment method</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ fontFamily: 'Avenir', }} id="alert-dialog-description">
              Please update your credit card information.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeAlertTime()} color="primary" style={{ fontFamily: 'Avenir', }} autoFocus>
              Cancel
            </Button>
            <Button onClick={() => this.openPayments()} color="primary" style={{ fontFamily: 'Avenir', }} autoFocus>
              Open settings
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.showAlertApplePay}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ fontFamily: 'Avenir', }} id="alert-dialog-title">Apple Pay is only available on our IOS app.</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ fontFamily: 'Avenir', }} id="alert-dialog-description">
              Please download the app to book with it.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ showAlertApplePay: false })} color="primary" style={{ fontFamily: 'Avenir', }} autoFocus>
              Cancel
            </Button>
            <Button onClick={() => this.downloadApp()} color="primary" style={{ fontFamily: 'Avenir', }} autoFocus>
              Download
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLocation: state.location,
  dataFlow: state.bookingFlow
})
export default connect(mapStateToProps, { bookingFlowAction, homeAction, initApp })(Product);
