import {
  OPEN_CLOSE_INVOICE,
  SET_INFO_INVOICE,
  OPEN_REVIEW
} from '../actions/types';

const initialState = {
  showInvoice: false,
  showReview: false,
  totalPrice: 0,
  unitUsed: 0,
  resultAppointment: '',
  averagePricePerUnit: 0,
  infoReview: {},
  payment: {}
}

const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CLOSE_INVOICE:
      return { ...state, showInvoice: action.showInvoice };
    case SET_INFO_INVOICE:
      return { ...state, totalPrice: action.totalPrice, unitUsed: action.unitUsed, resultAppointment: action.resultAppointment, averagePricePerUnit: action.averagePricePerUnit, payment: action.payment };
    case OPEN_REVIEW:
      return { ...state, showReview: action.showReview, infoReview: action.infoReview };
    default:
      return state;
  }
}

export default walletReducer;
