import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../css/App.css';
import ReactPixel from 'react-facebook-pixel';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { initApp } from '../actions/initAppActions'
import { homeAction } from '../actions/homeActions'
import { locationAction } from '../actions/locationActions'
import { updateStepLogin } from '../actions/loginActions'
import { bookingFlowAction } from '../actions/bookingFlowActions'
import SignInFlow from './elementsProfile/elementsSignin/SignInFlow';
import BookingFlow from './elementsHome/BookingFlow'
import * as firebase from 'firebase'

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      loader: false,
      category: {},
      error: false
    };
  }
  componentWillMount() {
    window.scrollTo(0, 0)
    this.setState({ EventCode: this.props.dataHome.inviteCodeEvent })
    ReactPixel.pageView()
  }
  handleChange = name => event => {
    var val = event.target.value
    this.props.homeAction('setInviteCode', { value: val })
  };
  signIn() {
    this.props.initApp('setStepLogin', { value: 'phone' });
    this.props.initApp('showLoginModal', { value: true });
  }
  subscribeEvent() {
    this.setState({ loader: true })
    if (this.props.dataHome.inviteCodeEvent === '') {
      this.wrongInviteCode()
    } else {
      const searchOffer = firebase.database().ref('offersNurse').orderByChild('inviteCode').equalTo(this.props.dataHome.inviteCodeEvent.toLowerCase()).once('value')
      Promise.all([searchOffer]).then(results => {
        var offer = results[0].val()
        if (offer == null) {
          this.wrongInviteCode()
        } else {
          offer = (Object.values(offer))[0]
          if (!!this.props.dataUser.infoUser.appointments) {
            var checkUserAlreadySubscribe = (Object.values(this.props.dataUser.infoUser.appointments).filter(appt => (appt.inviteCode.toLowerCase() === this.props.dataHome.inviteCodeEvent.toLowerCase())))

            if (checkUserAlreadySubscribe.length === 0) {
              this.nextStep(offer)
            } else {
              if (offer.infoOffer.product.type === 'shots') {
                var checkUserAlreadySubscribe2 = (Object.values(this.props.dataUser.infoUser.appointments).filter(appt => (appt.inviteCode.toLowerCase() === this.props.dataHome.inviteCodeEvent.toLowerCase() && appt.infoOffer.subscribe && appt.status !== 'past')))
                if (checkUserAlreadySubscribe2.length === 0) {
                  this.nextStep(offer)
                } else {
                  this.userAlreadySubscribed()
                }
              } else {
                var checkUserAlreadySubscribe3 = (Object.values(this.props.dataUser.infoUser.appointments).filter(appt => (appt.inviteCode.toLowerCase() === this.props.dataHome.inviteCodeEvent.toLowerCase() && appt.status !== 'past')))
                if (checkUserAlreadySubscribe3.length === 0) {
                  this.nextStep(offer)
                } else {
                  this.userAlreadySubscribed()
                }
              }
            }
          } else {
            this.nextStep(offer)
          }
        }
      })
    }
  }
  async nextStep(offer) {
    this.setState({ loader: false })
    var region = {
      latitude: offer.location.lat,
      longitude: offer.location.lng,
      latitudeDelta: 0.22,
      longitudeDelta: 0.22,
    }
    this.props.locationAction('setRegionMap', { region: region })
    this.props.homeAction('setIdAppt', { value: offer.offerID })
    this.props.homeAction('setInviteCode', { value: offer.inviteCode })
    this.props.homeAction('setNumberUnit', { value: offer.infoOffer.numberUnit })
    this.props.homeAction('setDateAppt', { value: offer.infoOffer.date })
    this.props.homeAction('setDurationEvent', { value: offer.infoOffer.durationEvent })

    var category = this.props.dataUser.instaviceVariables.homePage2.filter(category => category.type === offer.infoOffer.product.type)[0]
    await this.setState({ category: category })
    var attendeeProduct = offer.infoOffer.attendeeProduct
    if (!attendeeProduct && !!category.list) {
      var totalList = category.list
      for (var i in totalList) {
        var product = totalList[i]
        var productSaved = product
        productSaved.numberAdded = 0
        productSaved.name = product.name
        productSaved.image = product.image
        var price = 0
        if ((product.pricesGroup.filter(price => price.value === Number(offer.infoOffer.numberUnit)).length !== 0)) {
          price = product.pricesGroup.filter(price => price.value === Number(offer.infoOffer.numberUnit))[0].price
        } else {
          price = product.pricesGroup.filter(price => price.type === 'greater')[0].price
        }
        if (product.name === offer.infoOffer.product.name) {
          productSaved.numberAdded = offer.infoOffer.numberUnit
        }
        productSaved.price = price
        attendeeProduct = {
          ...attendeeProduct,
          [i]: productSaved
        }
      }
    }
    this.props.homeAction('setAttendeeProduct', { value: attendeeProduct })
    if (!category.list) {
      this.props.homeAction('setProductChosen', { value: offer.infoOffer.product })
    } else {
      this.props.homeAction('setProductChosen', { value: {} })
    }
    var additionalProducts = offer.infoOffer.additionalProducts
    if (!additionalProducts) {
      additionalProducts = {}
    }
    var secondaryProducts = offer.infoOffer.secondaryProducts
    if (!secondaryProducts) {
      secondaryProducts = []
    }
    this.props.homeAction('setAdditionalProduct', { value: additionalProducts })
    this.props.homeAction('setSecondaryProducts', { value: secondaryProducts })
    this.props.homeAction('setBoosters', { boosters: offer.infoOffer.boosters })

    var creditCardRequired = offer.infoOffer.creditCardRequired
    if (!creditCardRequired) {
      creditCardRequired = true
    }
    this.props.homeAction('setCreditCardRequired', { value: creditCardRequired })

    var nurseInfo = {
      nurseID: '',
      nurseInfo: {}
    }
    if (offer.nurseID !== undefined) {
      nurseInfo = {
        nurseID: offer.nurseID,
        nurseInfo: offer.infoNurse
      }
    }
    this.props.homeAction('setNurseInfo', { value: nurseInfo })
    var timeZone = offer.location.timeZone || ''
    this.props.homeAction('setTimeZone', { value: timeZone })
    var optionObj = {}
    var paymentOption = offer.infoOffer.paymentOption || 'single'
    optionObj.value = paymentOption
    this.props.homeAction('setPaymentOption', { value: optionObj })

    this.props.homeAction('setOrganizerID', { value: offer.infoUser.userID })
    this.props.homeAction('setBusinessName', { value: offer.location.businessName })
    this.props.homeAction('setBusinessPhone', { value: offer.location.businessPhone })
    this.props.homeAction('setSubscribe', { value: true })

    if (offer.infoOffer.product.type === 'shots') {
      await this.props.bookingFlowAction('setCurrentPage', { value: 'PatientConsent' })
    } else {
      if (!category.list) {
        await this.props.bookingFlowAction('setCurrentPage', { value: 'Checkout' })
      } else {
        await this.props.bookingFlowAction('setCurrentPage', { value: 'AddProduct' })
      }
    }
    this.props.homeAction('openIVFLow', { value: true })
  }
  wrongInviteCode() {
    this.setState({ loader: false })
    this.setState({ error: true })
    this.setState({ errorMessage: 'Wrong invite code.' })
  }
  userAlreadySubscribed() {
    this.setState({ loader: false })
    this.setState({ error: true })
    this.setState({ errorMessage: 'You are already attending this event.' })
  }
  closeSignIn() {
    this.setState({ loader: false })
  }
  render() {
    return (
      <div className="App">
        <Grid container style={styles.subtitle}>
          Join an event. The organizer will share the invite code with you.
        </Grid>
        <Grid container alignItems="center" justify="center" style={{ marginTop: 20, paddingLeft: 30, paddingRight: 30 }}>
          <Grid md={12} xs={12} item>
            <TextField
              style={{ width: '100%', height: 53, fontFamily: 'Avenir' }}
              id="outlined-password-input"
              label="Event code"
              value={this.props.dataHome.inviteCodeEvent}
              onChange={this.handleChange()}
              autoComplete="phone-number"
              margin="normal"
              color="secondary"
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center" style={{ marginTop: 20, paddingLeft: 30, paddingRight: 30 }}>
          <Grid md={12} xs={12} item>
            <div>
              {
                this.state.loader ?
                  <Button style={{ height: 53, color: 'white', width: '100%' }} variant="contained" color="primary">
                    <CircularProgress style={{ color: 'white' }} size={24} opacity={50} />
                  </Button>
                  : !this.props.dataUser.userConnected ?
                    <Button style={{ height: 53, color: 'white', fontFamily: 'AvenirBold', width: '100%', fontSize: 14 }} variant="contained" color="primary" onClick={() => this.signIn(this.props.dataLogin.email)}>
                      Sign in to Attend
                    </Button>
                    :
                    <Button style={{ height: 53, color: 'white', fontFamily: 'AvenirBold', width: '100%', fontSize: 14 }} variant="contained" color="primary" onClick={() => this.subscribeEvent()}>
                      Attend
                    </Button>
              }
            </div>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="center">
          {
            this.state.error ?
              <div style={{ fontSize: 14, color: '#4a4a4a', marginTop: 20, fontFamily: 'Avenir' }}>{this.state.errorMessage}</div>
              :
              null
          }
        </Grid>

        <Dialog
          fullScreen
          open={this.props.dataUser.showLoginModal}
          TransitionComponent={Transition}
        >
          <SignInFlow initiaStep={'verify'} close={this.closeSignIn.bind(this)} />
        </Dialog>

        <Dialog
          fullScreen
          open={this.props.dataHome.showIVFlow}
          TransitionComponent={Transition}
        >
          <BookingFlow {...this.props} category={this.state.category} />
        </Dialog>
      </div>
    );
  }
}
const styles = {
  subtitle: {
    fontFamily: 'Avenir',
    fontSize: 17,
    fontWeight: 'normal',
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 20,
    marginBottom: 5,
    color: '#757575',
    textAlign: 'left',
  },
}
const mapStateToProps = state => ({
  dataConnected: state.initConnected,
  dataUser: state.initApp,
  dataHome: state.home,
  dataWallet: state.wallet,
  dataCalendar: state.calendar,
  dataSettings: state.settings,
  dataLogin: state.login,
  dataLocation: state.location
})
export default connect(mapStateToProps, { updateStepLogin, homeAction, initApp, locationAction, bookingFlowAction })(Home);
